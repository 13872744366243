import {
  QuestionService,
  CreateQuestionDto,
  UpdateQuestionDto,
} from '@services/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'

// const questionsList: QuestionDto[] = [
//   {
//     id: 1,
//     siteId: 2,
//     label: 'Origine',
//     question: 'Suite à quel évenement la douleur est-elle apparue?',
//     questionType: QuestionTypeEnum.open,
//     exams: [],
//   },
//   {
//     id: 2,
//     siteId: 2,
//     label: 'Temps',
//     question: 'Quand la douleur est-elle apparue?',
//     questionType: QuestionTypeEnum.date,
//     exams: [],
//   },
//   {
//     id: 3,
//     siteId: 2,
//     label: 'Allergie?',
//     question: 'Avez-vous des allergies connues?',
//     questionType: QuestionTypeEnum.open,
//     exams: [],
//   },
//   {
//     id: 4,
//     siteId: 2,
//     label: 'Allergie(s)',
//     question: 'Si oui lesquelles:',
//     questionType: QuestionTypeEnum.open,
//     exams: [],
//   },
//   {
//     id: 5,
//     siteId: 2,
//     label: 'Antécédent(s)',
//     question: 'Avez-vous déjà subie un traumatisme?',
//     questionType: QuestionTypeEnum.close,
//     exams: [],
//   },
// ]
export const findAllQuestions = createAsyncThunk(
  Actions.QUESTION_FIND_ALL,
  async (examId: number | null, { rejectWithValue }) => {
    try {
      // const questions: any = await QuestionService.findAllQuestions()
      // return questions
      return QuestionService.findAll({ examId })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const updateQuestion = createAsyncThunk(
  Actions.QUESTION_UPDATE,
  async (
    { id, body }: { id: number; body: UpdateQuestionDto },
    { rejectWithValue },
  ) => {
    try {
      return await QuestionService.update({ id, body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const createQuestion = createAsyncThunk(
  Actions.QUESTION_CREATE,
  async (body: CreateQuestionDto, { rejectWithValue }) => {
    try {
      return await QuestionService.create({ body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const deleteQuestions = createAsyncThunk(
  Actions.QUESTION_REMOVE,
  async ({ ids }: { ids: number[] }, { rejectWithValue }) => {
    try {
      return await QuestionService.delete({ ids })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
