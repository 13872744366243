import { Button } from '@components/buttons'
import { Text } from '@components/texts'
import { DialogTitle } from '@components/titles'
import { DataTable } from '@components/tables'
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  Paper,
  Popover,
  Typography,
} from '@mui/material'
import {
  GridColumns,
  GridRowId,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import theme from '@utils/theme'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import 'moment/locale/fr'

export interface DialogProps {
  openPatientDetails: boolean
}

export default function TabBilling (props) {
  const dispatch = useDispatch()
  const [test, setTest] = useState(false)


  const handleClickActionUpdateMO = () => {
    setTest(true)
  }

  const onRefresh = useCallback(() => {
    // TODO
    // dispatch(findAllMedicalOrder())
  }, [dispatch])

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 0px' }}>
      <Grid container spacing={4} style={{ height: '96vh' }}>
        <Grid item container>
          <Paper
            elevation={2}
            style={{
              width: '100%',
              borderRadius: '0px 25px 25px 25px',
            }}
          >
            
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
