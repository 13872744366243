import moment from 'moment'
import { axios, ExamDto, getConfigs, IRequestConfig } from './api'
import {
  CCAMDto,
  FindCCAMQuery,
  FindExamFromBillingQuery,
  NGAPDto,
  SearchCCAMQuery,
  SearchNGAPQuery,
} from './dtos'
import queryString from 'querystring'

export class CCAMService {
  static findExamsFromBilling(
    query: FindExamFromBillingQuery,
  ): Promise<ExamDto[]> {
    const url =
      process.env.REACT_APP_API_URL +
      '/billing/Exam?' +
      queryString.stringify(query)

    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  private static readonly endpoint =
    process.env.REACT_APP_API_URL + '/billing/cotation'

  static get(query: FindCCAMQuery): Promise<CCAMDto[]> {
    return new Promise((resolve, reject) => {
      try {
        const url = `${this.endpoint}/ccam/${query.code}?examDate=${moment(
          query.examDate,
        ).format('YYYY-MM-DD')}`

        const configs: IRequestConfig = getConfigs(
          'get',
          'application/json',
          url,
          {},
        )
        axios(configs, resolve, reject)
      } catch (e) {
        if (e.response) {
          return reject(e.response.data.message)
        }
        return reject(e)
      }
    })
  }

  static getNgap(query: FindCCAMQuery): Promise<NGAPDto[]> {
    return new Promise((resolve, reject) => {
      try {
        const url = `${this.endpoint}/NGAP/${query.code}?examDate=${moment(
          query.examDate,
        ).format('YYYY-MM-DD')}`

        const configs: IRequestConfig = getConfigs(
          'get',
          'application/json',
          url,
          {},
        )
        axios(configs, resolve, reject)
      } catch (e) {
        if (e.response) {
          return reject(e.response.data.message)
        }
        return reject(e)
      }
    })
  }

  static searchCcam(query: SearchCCAMQuery): Promise<CCAMDto[]> {
    return new Promise((resolve, reject) => {
      try {
        const url = `${this.endpoint}/CCAM/SearchByLibelle?libelle=${
          query.libelle
        }&examDate=${moment(query.examDate).format('YYYY-MM-DD')}`

        const configs: IRequestConfig = getConfigs(
          'get',
          'application/json',
          url,
          {},
        )
        axios(configs, resolve, reject)
      } catch (e) {
        if (e.response) {
          return reject(e.response.data.message)
        }
        return reject(e)
      }
    })
  }

  static searchNgap(query: SearchNGAPQuery): Promise<NGAPDto[]> {
    return new Promise((resolve, reject) => {
      try {
        const url = `${this.endpoint}/NGAP/SearchByLibelle?libelle=${
          query.libelle
        }&examDate=${moment(query.examDate).format('YYYY-MM-DD')}`

        const configs: IRequestConfig = getConfigs(
          'get',
          'application/json',
          url,
          {},
        )
        axios(configs, resolve, reject)
      } catch (e) {
        if (e.response) {
          return reject(e.response.data.message)
        }
        return reject(e)
      }
    })
  }
}
