import { useCallback, useEffect, useState } from 'react'
import { DataTable } from '@components/tables'
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Icon,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  GridColumns,
  GridRowId,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import FormDetails from './form/FormDetails'
import { Actions } from '@state/actions'
import { useSelector } from '@state/store'
import { useDispatch } from 'react-redux'
import { RegisterPatientForm } from './form/RegisterPatientForm'
import DialogTitle from '@components/dialogs/DialogTitle'
import axios from 'axios'
import { formatSsn, formatSsnPrefix } from '@utils/ssnUtil'
import theme from '@utils/theme'
import { getPatient } from '@state/reducers/patientsReducer'
import { fetchPatients } from '@state/thunks/patientsThunk'
import { debounce, set } from 'lodash'
import { PatientDto, PatientService } from '@services/api'
import moment from 'moment'

export const DeleteForm = ({ onClose }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  return (
    <Dialog
      open={openDeleteModal}
      onClose={() => setOpenDeleteModal(false)}
      maxWidth="lg"
      aria-labelledby="form-dialog-title"
      PaperProps={{
        sx: {
          borderRadius: 5,
          background: theme.palette.primary.main,
        },
      }}
    >
      <DialogTitle
        onClose={() => setOpenDeleteModal(false)}
        title="Supression multiple"
        style={{
          background: '#fff',
          color: theme.palette.primary.main,
        }}
      />
      <DialogContent
        style={{
          minWidth: '250px',
          display: 'flex',
          justifyContent: 'center',
          background: '#E2E6F2',
        }}
      >
        {/* <Text
          text="deleteConfirmation"
          variant="h5"
          color="secondary"
          sx={{ fontWeight: 'bold', paddingTop: '20px', paddingBottom: '20px'}}
          format
        /> */}
      </DialogContent>
      <DialogActions
        sx={{
          background: '#fff',
          paddingBottom: '20px',
          paddingTop: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        <Button
          sx={{
            ':hover': {
              backgroundColor: '#333333',
            },
            color: 'white',
            background: '#3F3F3F',
            borderRadius: 5,
          }}
          variant="contained"
          color="success"
          fullWidth
          onClick={() => setOpenDeleteModal(false)}
        >
          Annuler
        </Button>
        <Button
          style={{ color: 'white' }}
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ borderRadius: 5 }}
          // onClick={}
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const TabLot = ({ onClose }) => {
  const dispatch = useDispatch()
  const [verifLot, setVerifLot] = useState(false)
  const [verifLotCall, setVerifLotCall] = useState(false)
  const [resultats, setResultats] = useState([
    {
      insValue: '1010663220754',
      insCle: '60',
      oid: '1.2.250.1.213.1.4.8',
      erreur: false,
      erreurMessage: 'OK INS vérifié',
      dateHeureRetour: '0001-01-01T00:00:00',
    },
    {
      insValue: '297022A020778',
      insCle: '78',
      oid: '1.2.250.1.213.1.4.8',
      erreur: true,
      erreurMessage: 'KO INS non vérifié',
      dateHeureRetour: '0001-01-01T00:00:00',
    },
    {
      insValue: '2751263220749',
      insCle: '74',
      oid: '1.2.250.1.213.1.4.8',
      erreur: false,
      erreurMessage: 'OK INS vérifié',
      dateHeureRetour: '0001-01-01T00:00:00',
    },
    {
      insValue: '1100863220830',
      insCle: '60',
      oid: '1.2.250.1.213.1.4.8',
      erreur: false,
      erreurMessage: 'OK INS vérifié',
      dateHeureRetour: '0001-01-01T00:00:00',
    },
    {
      insValue: '1100863220831',
      insCle: '59',
      oid: '1.2.250.1.213.1.4.8',
      erreur: false,
      erreurMessage: 'OK INS vérifié',
      dateHeureRetour: '0001-01-01T00:00:00',
    },
    {
      insValue: '1100863220832',
      insCle: '58',
      oid: '1.2.250.1.213.1.4.8',
      erreur: false,
      erreurMessage: 'OK INS vérifié',
      dateHeureRetour: '0001-01-01T00:00:00',
    },
    {
      insValue: '1140263220833',
      insCle: '56',
      oid: '1.2.250.1.213.1.4.8',
      erreur: false,
      erreurMessage: 'OK INS vérifié',
      dateHeureRetour: '0001-01-01T00:00:00',
    },
    {
      insValue: '1090763220834',
      insCle: '89',
      oid: '1.2.250.1.213.1.4.8',
      erreur: false,
      erreurMessage: 'OK INS vérifié',
      dateHeureRetour: '0001-01-01T00:00:00',
    },
    {
      insValue: '2360663220836',
      insCle: '56',
      oid: '1.2.250.1.213.1.4.8',
      erreur: false,
      erreurMessage: 'OK INS vérifié',
      dateHeureRetour: '0001-01-01T00:00:00',
    },
    {
      insValue: '160012B020777',
      insCle: '13',
      oid: '1.2.250.1.213.1.4.8',
      erreur: true,
      erreurMessage: 'KO INS non vérifié',
      dateHeureRetour: '0001-01-01T00:00:00',
    },
    {
      insValue: '1750163220748',
      insCle: '46',
      oid: '1.2.250.1.213.1.4.8',
      erreur: false,
      erreurMessage: 'OK INS vérifié',
      dateHeureRetour: '0001-01-01T00:00:00',
    },
    {
      insValue: '1990163220752',
      insCle: '30',
      oid: '1.2.250.1.213.1.4.9',
      erreur: true,
      erreurMessage: 'KO INS non vérifié',
      dateHeureRetour: '0001-01-01T00:00:00',
    },
    {
      insValue: '2770763220829',
      insCle: '10',
      oid: '1.2.250.1.213.1.4.8',
      erreur: true,
      erreurMessage: 'KO INS non vérifié',
      dateHeureRetour: '0001-01-01T00:00:00',
    },
  ])

  const [open, setOpen] = useState(false)
  const [erreurMessage, setErreurMessage] = useState('')
  const [erreurCode, setErreurCode] = useState('')
  const [codeRetour, setCdeRetour] = useState('')

  const clearErrosMessage = () => {
    setErreurMessage('')
    setErreurCode('')
    setCdeRetour('')
  }

  const [countdown, setCountdown] = useState(3)

  useEffect(() => {
    if (countdown === 0) {
      setVerifLotCall(true)
    }
  }, [countdown])

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) =>
        prevCountdown > 0 ? prevCountdown - 1 : 0,
      )
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const medicalOrderMockup = [
    {
      id: '1453497',
      createdAt: '2024-06-17',
      horodatageFin: '2024-03-13T14:57:26.673+01:00',
      delais: '0001-01-01T00:01:00',
    },
  ]

  const columns: GridColumns = [
    {
      field: 'col1',
      headerName: '',
      width: 80,
      sortable: false,
      renderCell: (value) => (
        <div
          style={{
            width: '100%',
          }}
        >
          {verifLotCall ? (
            <Tooltip title="Vérifier le lot" placement="top">
              <Icon
                style={{ marginRight: '10px' }}
                className="fas fa-clipboard-check"
                fontSize="small"
                onClick={() => {
                  setVerifLot(true) // TODO : suppr for prod
                  callInsiVerifP()
                }}
                sx={{ color: '#3F3F3F' }}
              />
            </Tooltip>
          ) : null}
          <Tooltip title="Supprimer le lot" placement="top">
            <Icon
              className="fas fa-trash-alt"
              fontSize="small"
              onClick={() => alert('delete WIP')}
              sx={{ color: '#3F3F3F' }}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'col2',
      headerName: 'N° de lot',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.id}`,
    },
    {
      field: 'col3',
      headerName: 'Date de création',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.createdAt}`,
    },
    {
      field: 'col4',
      headerName: 'Délais',
      flex: 1,
      valueGetter: (params) => countdown + 's',
    },
  ]

  //
  // Depot d'un lot de patients

  const MockupVerifLotSend = {
    psModel: {
      psNumeroFacturation: '2451702831',
      psIdentStructure: '44461871400026',
      authentificationMode: 0,
      codePorteurCPS: '1234',
    },
    lot: {
      idLot: '1453497',
    },
  }

  const callInsiVerifP = () => {
    axios
      .post(
        'http://localhost:5162/insi/verification/lot/resultat',
        MockupVerifLotSend,
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then((response) => {
        console.log(response)
        if (response.status === 200 && response.data.erreur === false) {
          setVerifLot(true)
          setResultats(response.data.resultats)
        } else {
          setErreurCode('404')
          setErreurMessage('Failed to fetch insi')
          setCdeRetour('ADD09-D8B')
          setOpen(true)
          console.error('Failed to fetch INSI verification lot:', response)
        }
      })
      .catch((error) => {
        setErreurCode('404')
        setErreurMessage('Failed to fetch insi')
        setCdeRetour('ADD09-D8B')
        setOpen(true)
        console.error('Failed to fetch INSI verification lot:', error)
      })
  }

  const { loading } = useSelector(({ loading }) => ({
    loading:
      loading[Actions.PROCEDURE_TYPE_FIND_ALL] ||
      loading[Actions.PROCEDURE_TYPE_CREATE] ||
      loading[Actions.PROCEDURE_TYPE_UPDATE],
  }))

  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const _onSelectionChange = (selection: GridRowId[]) => {
    setSelectedRows(selection as number[])
  }

  const onRefresh = useCallback(() => {
    // TODO
    // dispatch(findAllMedicalOrder())
  }, [dispatch])

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 0px' }}>
      <Grid
        container
        spacing={4}
        style={{ height: '85vh', width: '100%', minWidth: '1000px' }}
      >
        <Grid item container>
          <DataTable
            rows={medicalOrderMockup}
            columns={columns}
            loading={loading}
            size={35}
            isDetails={verifLot ? false : true}
            toolBarProps={{
              title: 'Lot INSI',
              icon: 'fas fa-user',
              onRefresh,
              // onDelete: () => {
              //   selectedRows.length
              //     ? setOpenMultiDeleteModal(true)
              //     : alert('please select at least one reception')
              // },
            }}
            detailPanelHeight={400}
            form={(props) => (
              <>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Ins</TableCell>
                      <TableCell align="left">Clé ins</TableCell>
                      <TableCell align="left">Oid</TableCell>
                      <TableCell align="left">Erreur Message</TableCell>
                      <TableCell align="left">Date de retour</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resultats.map((row) => (
                      <TableRow
                        key={row.insValue}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          backgroundColor:
                            row.erreurMessage === 'OK INS vérifié'
                              ? '#b5fcb0'
                              : '#f98484',
                        }}
                      >
                        <TableCell align="left">
                          {formatSsnPrefix(row.insValue.slice(0, 7))}{' '}
                          {formatSsn(row.insValue.slice(7, 13))}
                        </TableCell>
                        <TableCell align="left">{row.insCle}</TableCell>
                        <TableCell align="left">{row.oid}</TableCell>
                        <TableCell align="left">{row.erreurMessage}</TableCell>
                        <TableCell align="left">
                          {row.dateHeureRetour}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
            // checkboxSelection
            onSelectionModelChange={_onSelectionChange}
          />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClick={() => {
          setOpen(false)
          clearErrosMessage()
        }}
        maxWidth={'sm'}
        fullWidth
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            overflow: 'hidden',
            borderRadius: 5,
            background: '#E2E6F2',
          },
        }}
      >
        <DialogTitle
          title={'Erreur Insi'}
          style={{
            background: '#fff',
          }}
          onClick={() => {
            setOpen(false)
            clearErrosMessage()
          }}
        />
        <DialogContent
          style={{
            background: '#e91e63',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            minHeight: '120px',
          }}
        >
          <Icon
            className="fas fa-times-circle"
            fontSize="inherit"
            sx={{ color: '#FFF', fontSize: '100px' }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            bgcolor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <Typography sx={{ color: 'black' }} variant="h5" color="initial">
            {codeRetour ? codeRetour : ''}
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
              marginBottom: '20px',
            }}
          >
            <Typography sx={{ color: 'black' }} variant="h5" color="initial">
              {erreurCode ? erreurCode + ': ' : ''}
            </Typography>
            <Typography sx={{ color: 'black' }} variant="h5" color="initial">
              {erreurMessage ? erreurMessage : ''}{' '}
            </Typography>
          </div>
          <Button
            style={{ color: 'white', width: '90%' }}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => {
              setOpen(false)
              clearErrosMessage()
            }}
            sx={{ borderRadius: 5 }}
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default function InsiView() {
  const dispatch = useDispatch()
  const [modalView, setModalView] = useState(1)
  const [openModal, setOpenModal] = useState(false)
  const [open, setOpen] = useState(false)
  const [erreurMessage, setErreurMessage] = useState('')
  const [erreurCode, setErreurCode] = useState('')
  const [codeRetour, setCdeRetour] = useState('')
  const [patientForm, setPatientForm] = useState(0)

  const clearErrosMessage = () => {
    setErreurMessage('')
    setErreurCode('')
    setCdeRetour('')
  }

  // dispatch(filterChangePeriod(selectedYear))
  // dispatch(getAllPrescriber())

  const medicalOrderMockup = [
    {
      id: '0',
      sex: 'M',
      firstName: 'PIERRE-ALAIN GUNTHER',
      lastName: 'D\'ARTAGNAN DE L\'HERAULT',
      birthDate: '2001-06-17',
      phoneNumber: '',
      email: '',
      address: '124 Rue de Puy-de-Dôme, 63000 Puy-de-Dôme',
      birthPlace: 'Puy-de-Dôme',
      birthPlaceDepartment: '63',
      ssn: '101066322075460',
      weight: 65,
      height: 170,
      familyDoctor: {
        rpps: '',
      },
      comments: '',
    },
    {
      id: '1',
      sex: 'F',
      firstName: 'DOMINIQUE',
      lastName: 'ADRTROIS',
      maidenName: 'Smith',
      birthDate: '1997-02-26',
      phoneNumber: '',
      email: '',
      address: '',
      birthPlace: '',
      birthPlaceDepartment: '20',
      ssn: '297022A02077878',
      weight: 65,
      height: 170,
      familyDoctor: {
        rpps: '',
      },
      comments: '.',
    },
  ]

  const columns: GridColumns = [
    {
      field: 'col1',
      headerName: '',
      width: 80,
      sortable: false,
      renderCell: (value) => (
        <div
          style={{
            width: '100%',
          }}
        >
          <Tooltip title="Modifier un patient" placement="top">
            <Icon
              style={{ marginRight: '10px' }}
              className="fas fa-marker"
              fontSize="small"
              onClick={() => {
                setOpenModal(true)
                setModalView(1)
                setPatientForm(value.row)
              }}
              sx={{ color: '#3F3F3F' }}
            />
          </Tooltip>
          <Tooltip title="Supprimer un patient" placement="top">
            <Icon
              className="fas fa-trash-alt"
              fontSize="small"
              onClick={() => {
                setOpenModal(true)
                setModalView(2)
              }}
              sx={{ color: '#3F3F3F' }}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'col2',
      headerName: 'Sex',
      width: 80,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          params.row.sex === 'M'
            ? 'H'
            : params.row.sex === 'F'
              ? 'F'
              : 'Unknown'
        }`,
    },
    {
      field: 'col3',
      headerName: 'Nom',
      width: 250,
      valueGetter: (params: GridValueGetterParams) => `${params.row.lastName}`,
    },
    {
      field: 'col4',
      headerName: 'Prénom',
      width: 250,
      valueGetter: (params: GridValueGetterParams) => `${params.row.firstName}`,
    },
    {
      field: 'col5',
      headerName: 'Date de naissance',
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        `${moment(params.row.birthDate).format('DD/MM/YYYY')}`,
    },
    {
      field: 'col6',
      headerName: 'Numéro de sécurité sociale',
      width: 220,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          params.row.ssn
            ? formatSsnPrefix(params.row.ssn.slice(0, 7)) +
              ' ' +
              formatSsn(params.row.ssn.slice(7, 15))
            : ''
        }`,
    },
    {
      field: 'col7',
      headerName: 'Numéro de téléphone',
      width: 180,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.phoneNumber ? params.row.phoneNumber : ''}`,
    },
    {
      field: 'col8',
      headerName: 'Adresse',
      width: 350,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.address ? params.row.address : ''}`,
    },
    {
      field: 'col9',
      headerName: 'Email',
      width: 250,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.email ? params.row.email : ''}`,
    },
    {
      field: 'col10',
      headerName: 'Lieu de naissance',
      width: 180,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.placeOfBirth ? params.row.placeOfBirth : ''}`,
    },
    {
      field: 'col11',
      headerName: 'Département de naissance',
      width: 220,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          params.row.placeOfBirthDepartment
            ? params.row.placeOfBirthDepartment
            : ''
        }`,
    },
  ]

  const { loading } = useSelector(({ loading }) => ({
    loading:
      loading[Actions.PROCEDURE_TYPE_FIND_ALL] ||
      loading[Actions.PROCEDURE_TYPE_CREATE] ||
      loading[Actions.PROCEDURE_TYPE_UPDATE],
  }))

  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const _onSelectionChange = (selection: GridRowId[]) => {
    setSelectedRows(selection as number[])
  }

  const onRefresh = useCallback(() => {
    // TODO
    // console.log('test dispach')
    // dispatch(getPatient([1]))
    // dispatch(fetchPatients())
    // dispatch(findAllMedicalOrder())
  }, [dispatch])

  //
  // Depot d'un lot de patients

  const MockupLotDepotSend = {
    psModel: {
      psNumeroFacturation: '2451702831',
      psIdentStructure: '44461871400026',
      authentificationMode: 0,
      codePorteurCPS: '1234',
    },
    lots: [
      {
        assureNumSecu: '',
        beneficiaireNom: 'D\'ARTAGNAN DE L\'HERAULT',
        beneficiaireNomUsage: '',
        beneficiairePrenom: 'PIERRE-ALAIN GUNTHER',
        beneficiaireSexe: 'M',
        beneficiaireLieuNaissance: '63220',
        beneficiaireDateNaissance: '2001-06-17',
        beneficiaireRang: '1',
        beneficiaireNumSecu: '',
        oid: '1.2.250.1.213.1.4.8',
        insaVerifier: '101066322075460',
      },
      {
        assureNumSecu: '',
        beneficiaireNom: 'ADRTROIS',
        beneficiaireNomUsage: '',
        beneficiairePrenom: 'DOMINIQUE',
        beneficiaireSexe: 'F',
        beneficiaireLieuNaissance: '20020',
        beneficiaireDateNaissance: '1997-02-26',
        beneficiaireRang: '1',
        beneficiaireNumSecu: '',
        oid: '1.2.250.1.213.1.4.8',
        insaVerifier: '297022A02077878',
      },
    ],
  }

  const callInsiDepotP = () => {
    axios
      .post(
        'http://localhost:5162/insi/verification/lot/depot',
        MockupLotDepotSend,
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then((response) => {
        console.log(response)
        if (
          response.status === 200 &&
          response.data.codeRetour === 'IVIRLOT_DEPOSER_OK'
        ) {
          // setResultats(response.data.resultats)
          // setVerifLot(true)
          // TODO : set horodatage
          setOpen(true)
          setErreurCode('404')
          setErreurMessage('Failed to fetch insi')
          setCdeRetour('ADD09-D8B')
        } else {
          console.error('Failed to fetch INSI depot:', response)
        }
      })
      .catch((error) => {
        setOpen(true)
        setErreurCode('404')
        setErreurMessage('Failed to fetch insi')
        setCdeRetour('ADD09-D8B')
        console.error('Failed to fetch INSI depot:', error)
      })
  }

  const [patientOptions, setPatientOptions] = useState<PatientDto[]>([])
  const [patientSearch, setPatientSearch] = useState('')
  const [sex, setSex] = useState('F')

  const debouncedSearchPatient = useCallback(
    debounce((limit, search, sex) => {
      PatientService.findAllv2({
        limit: limit,
        sex: sex,
        search: search,
        siteId: 1,
        page: 0,
      })
        .then((data) => {
          if (data.totalCount !== 0) {
            setPatientOptions(data.datas)
          }
          console.log('data', data)
        })
        .catch((error) => {
          console.error('Failed to fetch addresses:', error)
        })
    }, 500),
    [],
  )

  useEffect(() => {
    debouncedSearchPatient(25, patientSearch, sex)
  }, [patientSearch, debouncedSearchPatient, sex])

  return (
    <Container
      maxWidth={false}
      style={{ height: '100%', padding: '10px 10px' }}
    >
      <Grid container spacing={4} style={{ height: '100vh' }}>
        <Grid item container>
          <Paper
            elevation={2}
            style={{
              width: '100%',
              borderRadius: '25px 25px 25px 25px',
            }}
          >
            <DataTable
              rows={patientOptions}
              columns={columns}
              loading={loading}
              size={35}
              isDetails
              toolBarProps={{
                title: 'Patient',
                icon: 'fas fa-user',
                onAdd: () => {
                  setOpenModal(true)
                  setModalView(4)
                },
                onRefresh,
                onMore: () => {
                  setOpenModal(true)
                  setModalView(3)
                },
                onLot: () => {
                  callInsiDepotP()
                },
                searchSex: true,
                onSearch: (searchTerm: string, sexValue: string) => {
                  setPatientSearch(searchTerm)
                  setSex(sexValue)
                  console.log('Search term:', searchTerm, sexValue)
                },
                onDelete: () => {
                  selectedRows.length
                    ? setOpenModal(true)
                    : alert('please select at least one reception')
                  setModalView(2)
                },
              }}
              detailPanelHeight={400}
              checkboxSelection
              isScrollable
              onSelectionModelChange={_onSelectionChange}
            />
            <Dialog
              open={openModal}
              onClose={() => setOpenModal(false)}
              maxWidth={
                modalView === 1 || modalView === 2 || modalView === 4
                  ? 'lg'
                  : 'xl'
              }
              aria-labelledby="form-dialog-title"
              PaperProps={{
                sx: {
                  borderRadius: 5,
                  background: '#E2E6F2',
                },
              }}
            >
              <DialogTitle
                title={
                  modalView === 1
                    ? 'Editer un patient'
                    : modalView === 2
                      ? 'Supprimer un patient'
                      : modalView === 3
                        ? 'Consultation d\'un lot de patients'
                        : modalView === 4
                          ? 'Enregister un nouveau patient'
                          : ''
                }
                style={{
                  background: '#fff',
                  color: 'E2E6F2',
                }}
                onClose={() => setOpenModal(false)}
              />
              <DialogContent
                style={{
                  minWidth: '850px',
                  display: 'flex',
                  justifyContent: 'center',
                  background: modalView === 3 ? 'white' : '#E2E6F2',
                }}
              >
                {modalView === 1 ? (
                  <RegisterPatientForm
                    onClose={() => setOpenModal(false)}
                    patientObj={patientForm}
                  />
                ) : modalView === 2 ? (
                  <DeleteForm onClose={() => setOpenModal(false)} />
                ) : modalView === 3 ? (
                  <TabLot onClose={() => setOpenModal(false)} />
                ) : modalView === 4 ? (
                  <RegisterPatientForm onClose={() => setOpenModal(false)} />
                ) : (
                  <></>
                )}
              </DialogContent>
            </Dialog>
          </Paper>
          <Dialog
            open={open}
            onClick={() => {
              setOpen(false)
              clearErrosMessage()
            }}
            maxWidth={'sm'}
            fullWidth
            aria-labelledby="form-dialog-title"
            PaperProps={{
              sx: {
                overflow: 'hidden',
                borderRadius: 5,
                background: '#E2E6F2',
              },
            }}
          >
            <DialogTitle
              title={'Erreur Insi'}
              style={{
                background: '#fff',
              }}
              onClick={() => {
                setOpen(false)
                clearErrosMessage()
              }}
            />
            <DialogContent
              style={{
                background: '#e91e63',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                minHeight: '120px',
              }}
            >
              <Icon
                className="fas fa-times-circle"
                fontSize="inherit"
                sx={{ color: '#FFF', fontSize: '100px' }}
              />
            </DialogContent>
            <DialogActions
              sx={{
                bgcolor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '20px',
              }}
            >
              <Typography sx={{ color: 'black' }} variant="h5" color="initial">
                {codeRetour ? codeRetour : ''}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '10px',
                  marginBottom: '20px',
                }}
              >
                <Typography
                  sx={{ color: 'black' }}
                  variant="h5"
                  color="initial"
                >
                  {erreurCode ? erreurCode + ': ' : ''}
                </Typography>
                <Typography
                  sx={{ color: 'black' }}
                  variant="h5"
                  color="initial"
                >
                  {erreurMessage ? erreurMessage : ''}{' '}
                </Typography>
              </div>
              <Button
                style={{ color: 'white', width: '90%' }}
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => {
                  setOpen(false)
                  clearErrosMessage()
                }}
                sx={{ borderRadius: 5 }}
              >
                Fermer
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Container>
  )
}
