import { Button } from '@components/buttons'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import { Actions } from '@state/actions'
import { useSelector } from '@state/store'
import { loginSchema } from '@utils/schemas'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { AuthCard } from '../../components/cards/AuthCard'

import dashboardLogo from '@assets/img/logos/dashboardLogo.png'
import { PasswordInput, TextInput } from '@components/inputs'
import { CaptchaInput } from '@ether-healthcare/inputs'
import { LoginDto } from '@services/api'
import { getCaptcha, login } from '@state/thunks/authThunk'
import theme from '@utils/theme'

export default function Login() {
  const dispatch = useDispatch()
  const { captcha, loading } = useSelector(({ auth, loading }) => ({
    captcha: auth.captcha,
    loading: loading[Actions.LOGIN],
  }))
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginDto>({
    defaultValues: {
      captcha: '',
      email: '',
      password: '',
    },
    resolver: yupResolver(loginSchema) as any,
  })

  const onSubmit = (values: LoginDto) => {
    dispatch(
      login({
        captcha: values.captcha,
        email: values.email.toLowerCase().trim(),
        password: values.password.trim(),
      }),
    )
  }

  const getAuthCaptcha = () => {
    dispatch(getCaptcha())
  }

  useEffect(() => {
    getAuthCaptcha()
  }, [])

  const Content = (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12} sm={12}>
        <TextInput
          variant="filled"
          type="email"
          name="email" // {...register('email')}
          label="Email"
          errors={errors}
          control={control}
          autoFocus
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <PasswordInput
          variant="filled"
          name="password" // {...register('password')}
          label="Password"
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item container justifyContent="center" xs={12} sm={12}>
        <CaptchaInput
          captcha={captcha}
          getCaptcha={getAuthCaptcha}
          errors={errors}
          control={control}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Button
          sx={{
            '&.MuiButtonBase-root': {
              bgcolor: theme.palette.secondary.main,
            },
          }}
          textColor="white"
          type="submit"
          text="submit"
          loading={loading}
          fullWidth
          format
        />
      </Grid>
    </Grid>
  )
  const ActionsContent = (
    <Grid container justifyContent={'flex-end'} direction="row">
      <Button
        link="/auth/forgotten-password"
        text="forgot-password"
        textColor="white"
        format
      />
    </Grid>
  )
  return (
    <AuthCard>
      <Grid container alignItems="center" justifyContent="center" spacing={3}>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <img
            src={dashboardLogo}
            alt="logo"
            style={{
              maxWidth: '35%',
              marginBottom: -50,
              alignSelf: 'center',
            }}
          />
          <AuthCard.Header title="login" />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            backgroundImage: 'linear-gradient(45deg, #303f9f, #7b1fa2)',
            borderRadius: 5,
          }}
        >
          <AuthCard.Content>{Content}</AuthCard.Content>
          <AuthCard.Actions>{ActionsContent}</AuthCard.Actions>
        </Grid>
      </Grid>
    </AuthCard>
  )
}
