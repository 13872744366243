import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RoleDto } from '@services/api'
import {
  createRole,
  deleteRoles,
  findAllRoles,
  updateRole,
} from '@state/thunks/roleThunk'

type roleState = {
  roles: RoleDto[]
}

const initialState: roleState = {
  roles: [],
}

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    removeRoles: (state) => {
      state.roles = initialState.roles
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(
        findAllRoles.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.roles = payload
        },
      )
      .addCase(
        createRole.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.roles = [...state.roles, payload]
        },
      )
      .addCase(
        updateRole.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          const index = state.roles.findIndex((role) => role.id === payload.id)
          if (index !== -1) {
            state.roles[index] = payload
          }
        },
      )
      .addCase(
        deleteRoles.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.roles = state.roles.filter((role) => !payload.includes(role.id))
        },
      ),
})

export const { removeRoles } = roleSlice.actions

export default roleSlice.reducer
