import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { QuestionDto } from '@services/api'
import {
  createQuestion,
  deleteQuestions,
  findAllQuestions,
  updateQuestion,
} from '@state/thunks/questionThunk'

type questionState = {
  questions: QuestionDto[]
}

const initialState: questionState = {
  questions: [],
}

const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    removeQuestions: (state) => {
      state.questions = initialState.questions
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(
        findAllQuestions.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.questions = payload
        },
      )
      .addCase(
        createQuestion.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.questions = [...state.questions, payload]
        },
      )
      .addCase(
        updateQuestion.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          const index = state.questions.findIndex((question) => question.id === payload.id)
          if (index !== -1) {
            state.questions[index] = payload
          }
        },
      )
      .addCase(
        deleteQuestions.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.questions = state.questions.filter((question) => !payload.includes(question.id))
        },
      ),
})

export const { removeQuestions } = questionSlice.actions

export default questionSlice.reducer
