import { TextField, TextFieldProps } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'

export type TextInputProps = TextFieldProps & {
  name: string
  control?: Control<any, object>
  errors?: DeepMap<any, FieldError>
  fullWidth?: boolean
  readOnly?: boolean
}

export function TextInput({
  name,
  defaultValue,
  control,
  fullWidth = true,
  errors,
  readOnly,
  InputProps,
  ...props
}: TextInputProps) {
  const intl = useIntl()

  return control ? (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field }) => (
        <TextField
          {...props}
          {...field}
          fullWidth={fullWidth}
          InputProps={{
            style: {
              backgroundColor: '#fff',
              color: readOnly ? 'rgba(0, 0, 0, 0.38)' : 'black',
            },
            readOnly,
            ...InputProps,
          }}
          name={name && intl.formatMessage({ id: name })}
          label={name && intl.formatMessage({ id: name })}
          placeholder={name && intl.formatMessage({ id: name })}
          error={errors && !!errors[name]}
          helperText={
            errors &&
            errors[name] && <FormattedMessage id={errors[name].message} />
          }
        />
      )}
    />
  ) : (
    <TextField
      {...props}
      fullWidth={fullWidth}
      InputProps={{
        style: {
          backgroundColor: '#fff',
          color: readOnly ? 'rgba(0, 0, 0, 0.38)' : 'black',
        },
        readOnly,
        ...InputProps,
      }}
      name={name && intl.formatMessage({ id: name })}
      label={props.value ? undefined : name && intl.formatMessage({ id: name })}
      placeholder={name && intl.formatMessage({ id: name })}
      error={errors && !!errors[name]}
      helperText={
        errors && errors[name] && <FormattedMessage id={errors[name].message} />
      }
    />
  )
}
