import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { MouseEventHandler, useEffect, useMemo } from 'react'
import { ambulanceCompanySchema } from '../../../utils/schemas'
import {
  AmbulanceCompanyDto,
  CreateAmbulanceCompanyDto,
  UpdateAmbulanceCompanyDto,
} from '../../../services/api'
import { useSelector } from '../../../state/store'
import { useAppDispatch } from '../../../hooks/reduxHooks'
import { findAll } from '../../../state/thunks/siteThunk'
import { Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { TextInput } from '@ether-healthcare/inputs'
import { Button } from '../../../components/buttons'
import {
  createAmbulanceCompany,
  updateAmbulanceCompany,
} from '../../../state/thunks/ambulanceThunk'
import { enqueueSnackbar } from '@state/reducers/alertReducer'

type AddAmbulanceCompanyProps = {
  row?: AmbulanceCompanyDto
  onClose?: () => void
  onCancelButtonPress?: (
    ev: MouseEventHandler<HTMLButtonElement> | undefined,
  ) => void
}

export function AmbulanceForm({ row, onClose }: AddAmbulanceCompanyProps) {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreateAmbulanceCompanyDto>({
    resolver: yupResolver(ambulanceCompanySchema),
    defaultValues: {
      ...row,
      siteIds: row?.sites.map((site) => site.id) || [],
    },
  })

  const dispatch = useAppDispatch()

  const sites = useSelector((state) => state.site.sites)

  const sitesOptions: {
    value: string | number
    label: string
  }[] =
    useMemo(() => {
      return sites.datas.map((site) => ({
        value: site.id,
        label: site.label,
      }))
    }, [sites]) || []

  useEffect(() => {
    dispatch(findAll())
  }, [])

  const onSubmit = (values: CreateAmbulanceCompanyDto) => {
    if (row?.id) {
      const updateAmbulanceCompanyDto: UpdateAmbulanceCompanyDto = {
        ...values,
      }
      dispatch(
        updateAmbulanceCompany({ id: row.id, dto: updateAmbulanceCompanyDto }),
      )
        .unwrap()
        .then(() => {
          dispatch(
            enqueueSnackbar({
              message: 'Ambulance mise à jour avec succès',
              type: 'success',
            }),
          )
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              message: error.message,
              type: 'error',
            }),
          )
        })
    } else {
      const createAmbulanceCompanyDto: CreateAmbulanceCompanyDto = {
        ...values,
      }
      dispatch(createAmbulanceCompany(createAmbulanceCompanyDto))
        .unwrap()
        .then(() => {
          dispatch(
            enqueueSnackbar({
              message: 'Ambulance créée avec succès',
              type: 'success',
            }),
          )
        })
        .catch((error) => {
          dispatch(
            enqueueSnackbar({
              message: error.message,
              type: 'error',
            }),
          )
        })
    }
    if (onClose) {
      onClose()
    }
  }

  const handleCancelPress = () => {
    if (onClose) {
      onClose()
    }
    // Closes the update form - Datagrid didnt provide a prop function to close form ()
    const el = (document as Document).querySelector(
      `.MuiDataGrid-row[data-id="${row?.id}"] .MuiIconButton-root.MuiIconButton-sizeSmall`,
    ) as any
    if (el) {
      el.click()
    }
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: 2,
        borderRadius: 5,
      }}
    >
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={6}>
          <InputLabel id="sitesl">Choisir site(s)</InputLabel>
          <Select
            variant="outlined"
            multiple
            {...register('siteIds')}
            defaultValue={row?.sites.map((site) => site.id) || []}
            fullWidth
          >
            {sitesOptions.map((site) => (
              <MenuItem key={site.value} value={site.value}>
                {site.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            variant="outlined"
            name="name"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            variant="outlined"
            name="contact"
            type="tel"
            errors={errors}
            control={control}
            inputProps={{ maxLength: 10 }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            variant="outlined"
            name="email"
            type="email"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            variant="outlined"
            name="siret"
            errors={errors}
            control={control}
            // limit to 14 characters
            inputProps={{ maxLength: 14 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            variant="outlined"
            name="address"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            variant="outlined"
            name="zipCode"
            type="number"
            errors={errors}
            control={control}
            inputProps={{ maxLength: 5 }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextInput
            variant="outlined"
            name="city"
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="flex-end">
            {onClose && (
              <Grid item xs={2}>
                <Button
                  onClick={handleCancelPress}
                  color="secondary"
                  text="cancel"
                  textColor="white"
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={2}>
              <Button
                onClick={handleSubmit(onSubmit)}
                color="primary"
                text="save"
                textColor="white"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
