import {
  Container,
  Grid,
  Icon,
  Paper,
  Table,
  Typography,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Tooltip,
  TableHead,
  Button,
  Box,
  DialogActions,
  DialogContent,
  Dialog,
  TextField,
  AccordionDetails,
  Divider,
} from '@mui/material'
import DialogTitle from '@components/dialogs/DialogTitle'
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress'
import * as React from 'react'
import { useEffect, useRef } from 'react'
import { FormAddPatient } from './form/FormAddPatient'

function DeletePatientFromWattingRoom() {
  return (
    <div>
      <h3 style={{ margin: 4 }}>
        Êtes-vous sûr de vouloir supprimer ce patient ? Cette opération est
        irréversible.
      </h3>
    </div>
  )
}

export default function TabBilling() {
  const date = new Date().toLocaleDateString()

  const [FakeDataConsultation, setFakeDataConsultation] = React.useState([
    // Patients with appointments
    {
      id: 0,
      name: 'Joe Danuel',
      heure: '12H00',
      motif: 'Consultation',
      doctor: 'Dr. Dupont',
      salle: null,
      status: 'appointment',
    },
    {
      id: 1,
      name: 'Johny Doe',
      heure: '12H30',
      motif: 'Consultation',
      doctor: 'Dr. Dupont',
      salle: null,
      status: 'appointment',
    },

    // Patients in the waiting room
    {
      id: 2,
      name: 'Carla Smith',
      heure: '14H00',
      motif: 'Follow-up',
      doctor: 'Dr. Martin',
      salle: null,
      status: 'waiting',
    },
    {
      id: 3,
      name: 'Robert Johnson',
      heure: '15H30',
      motif: 'Check-up',
      doctor: 'Dr. Dupont',
      salle: null,
      status: 'waiting',
    },

    // Patients currently in a room (undergoing examination)
    {
      id: 4,
      name: 'Emily Davis',
      heure: '16H00',
      motif: 'Consultation',
      doctor: 'Dr. Martin',
      salle: 'Room 1',
      status: 'inRoom',
    },
    {
      id: 5,
      name: 'Sophia Williams',
      heure: '17H30',
      motif: 'Consultation',
      doctor: 'Dr. Dupont',
      salle: 'Room 2',
      status: 'inRoom',
    },

    // More patients with appointments
    {
      id: 6,
      name: 'Liam Brown',
      heure: '18H00',
      motif: 'Consultation',
      doctor: 'Dr. Martin',
      salle: null,
      status: 'appointment',
    },
    {
      id: 7,
      name: 'Ava Jones',
      heure: '19H30',
      motif: 'Consultation',
      doctor: 'Dr. Dupont',
      salle: null,
      status: 'appointment',
    },
    {
      id: 8,
      name: 'Noah Garcia',
      heure: '20H00',
      motif: 'Consultation',
      doctor: 'Dr. Martin',
      salle: null,
      status: 'appointment',
    },
    {
      id: 9,
      name: 'Isabella Miller',
      heure: '20H30',
      motif: 'Consultation',
      doctor: 'Dr. Dupont',
      salle: null,
      status: 'appointment',
    },
    {
      id: 10,
      name: 'Lucas Davis',
      heure: '21H00',
      motif: 'Consultation',
      doctor: 'Dr. Martin',
      salle: null,
      status: 'appointment',
    },
    {
      id: 11,
      name: 'Mia Rodriguez',
      heure: '21H30',
      motif: 'Consultation',
      doctor: 'Dr. Dupont',
      salle: null,
      status: 'appointment',
    },
    {
      id: 12,
      name: 'Ethan Martinez',
      heure: '22H00',
      motif: 'Consultation',
      doctor: 'Dr. Martin',
      salle: null,
      status: 'appointment',
    },
  ])

  const [modalView, setModalView] = React.useState(1)
  const [openModal, setOpenModal] = React.useState(false)

  const [patients, setPatients] = React.useState(0)
  const [register, setRegister] = React.useState(0)

  // deifnition status = appointment -> waiting -> registered -> inRoom

  useEffect(() => {
    const waitingPatients = FakeDataConsultation.filter(
      (patient) => patient.status === 'appointment',
    ).length
    const registerPatients = FakeDataConsultation.filter(
      (patient) =>
        patient.status === 'registered' || patient.status === 'inRoom',
    ).length
    setPatients(waitingPatients)
    setRegister(registerPatients)
  }, [FakeDataConsultation])

  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number; maxValue: number },
  ) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={(props.value * 100) / props.maxValue}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            (props.value * 100) / props.maxValue,
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  const findPatientIndexById = (id) => {
    return FakeDataConsultation.findIndex((patient) => patient.id === id)
  }

  const updatePatientStatusById = (id, newStatus) => {
    const index = findPatientIndexById(id)
    if (index !== -1) {
      const updatedData = [...FakeDataConsultation]
      updatedData[index].status = newStatus
      setFakeDataConsultation(updatedData)
    }
  }

  return (
    <Container maxWidth={false} sx={{ marginTop: '20px' }}>
      {/* bento design 6*3 */}
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Paper
            elevation={2}
            style={{
              borderRadius: '25px',
              height: '62vh',
              marginBottom: '20px',
            }}
          >
            <Grid container p={2}>
              <Typography
                sx={{ paddingLeft: '10px', paddingTop: '5px' }}
                variant="h6"
              >
                Liste des patients en examen :
              </Typography>
              <TableContainer style={{ maxHeight: 500 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableCell>Nom</TableCell>
                    <TableCell>Numéro</TableCell>
                    <TableCell>Salle</TableCell>
                  </TableHead>
                  <TableBody>
                    {FakeDataConsultation.filter(
                      (patient) => patient.status === 'inRoom',
                    ).map((patient, index) => (
                      <TableRow
                        sx={{
                          '&:hover': {
                            backgroundColor: '#f5f5f5',
                          },
                        }}
                        key={index}
                      >
                        <TableCell>{patient.name}</TableCell>
                        <TableCell>{patient.id}</TableCell>
                        <TableCell>{patient.salle}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Paper>
          <Paper
            elevation={2}
            style={{
              borderRadius: '25px',
              height: '31vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Grid container p={1} alignItems="center">
              <Grid item xs={4}>
                <Typography sx={{ textAlign: 'center' }} variant="body1">
                  Patient accueillie
                </Typography>
                <Typography
                  sx={{ textAlign: 'center', fontWeight: 'normal' }}
                  variant="h1"
                >
                  {register}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ textAlign: 'center' }} variant="body1">
                  Examen(s) effectué(s)
                </Typography>
                <Typography
                  sx={{ textAlign: 'center', fontWeight: 'normal' }}
                  variant="h1"
                >
                  {patients}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ textAlign: 'center' }} variant="body1">
                  Examen(s) prévu(s)
                </Typography>
                <Typography
                  sx={{ textAlign: 'center', fontWeight: 'normal' }}
                  variant="h1"
                >
                  {FakeDataConsultation.length}
                </Typography>
              </Grid>
              <Grid sx={{ marginTop: 2 }} xs={12}>
                <Box
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <LinearProgressWithLabel
                    value={patients}
                    maxValue={FakeDataConsultation.length}
                  />
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Typography
                      sx={{ textAlign: 'center', fontWeight: 'normal' }}
                      variant="body1"
                    >
                      Examen(s) prévu(s)
                    </Typography>
                    <Typography
                      sx={{ textAlign: 'center', fontWeight: 'normal' }}
                      variant="body1"
                    >
                      Examen(s) efectué(s)
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper
            elevation={2}
            style={{
              borderRadius: '25px',
              height: '31vh',
              marginBottom: '20px',
            }}
          >
            <Grid container p={1}>
              <Grid item xs={8}>
                <Typography
                  sx={{ paddingLeft: '10px', paddingTop: '5px' }}
                  variant="h6"
                >
                  Liste des consultations: {date}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Tooltip title="Accueillir le patient" placement="top">
                  <Icon
                    className="fas fa-user-plus"
                    onClick={() => {
                      setModalView(1)
                      setOpenModal(true)
                    }}
                    sx={{
                      color: '#3F3F3F',
                      marginTop: '5px',
                      marginRight: '15px',
                      width: '30px',
                    }}
                  />
                </Tooltip>
                <Tooltip
                  title="Supprimer un patient de la liste"
                  placement="top"
                >
                  <Icon
                    className="fas fa-user-minus"
                    onClick={() => {
                      setModalView(2)
                      setOpenModal(true)
                    }}
                    sx={{
                      color: '#3F3F3F',
                      marginTop: '5px',
                      marginRight: '15px',
                      width: '30px',
                    }}
                  />
                </Tooltip>
              </Grid>
              <TableContainer style={{ maxHeight: 230 }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableCell>Action</TableCell>
                    <TableCell>Nom</TableCell>
                    <TableCell>Heure du rdv</TableCell>
                    <TableCell>Motif</TableCell>
                    <TableCell>Docteur référent</TableCell>
                  </TableHead>
                  <TableBody>
                    {FakeDataConsultation.filter(
                      (patient) => patient.status === 'appointment',
                    ).map((patient, index) => (
                      <TableRow
                        sx={{
                          '&:hover': {
                            backgroundColor: '#f5f5f5',
                          },
                        }}
                        key={index}
                      >
                        <TableCell>
                          <Tooltip
                            title="Ajouter dans la salle d'attente"
                            placement="top"
                          >
                            <Icon
                              className="fas fa-calendar-plus"
                              onClick={() => {
                                updatePatientStatusById(patient.id, 'waiting')
                              }}
                              sx={{ color: '#3F3F3F' }}
                            />
                          </Tooltip>
                          <Tooltip
                            title="Compléter le dossier patient"
                            placement="top"
                          >
                            <Icon
                              className="fas fa-file"
                              onClick={() => {
                                setModalView(1)
                                setOpenModal(true)
                              }}
                              sx={{
                                color: '#3F3F3F',
                                marginLeft: '10px',
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell>{patient.name}</TableCell>
                        <TableCell>{patient.heure}</TableCell>
                        <TableCell>{patient.motif}</TableCell>
                        <TableCell>{patient.doctor}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Paper>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Paper
                elevation={2}
                style={{
                  borderRadius: '25px',
                  height: '62vh',
                }}
              >
                <Grid container p={1}>
                  <Typography
                    sx={{ paddingLeft: '10px', paddingTop: '5px' }}
                    variant="h6"
                  >
                    Patients en salle d&apos;attente
                  </Typography>
                  <TableContainer style={{ maxHeight: 480 }}>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableCell>Action</TableCell>
                        <TableCell>Nom</TableCell>
                        <TableCell>Heure du rdv</TableCell>
                        <TableCell>Motif</TableCell>
                        <TableCell>Docteur référent</TableCell>
                      </TableHead>
                      <TableBody>
                        {FakeDataConsultation.filter(
                          (patient) => patient.status === 'waiting',
                        ).map((patient, index) => (
                          <TableRow
                            sx={{
                              '&:hover': {
                                backgroundColor: '#f5f5f5',
                              },
                            }}
                            key={patient.id}
                          >
                            <TableCell>
                              <Tooltip
                                title="Appeler au guichet"
                                placement="top"
                              >
                                <Icon
                                  className="fas fa-phone-alt"
                                  onClick={() => {
                                    updatePatientStatusById(
                                      patient.id,
                                      'registered',
                                    )
                                  }}
                                  sx={{ color: '#3F3F3F' }}
                                />
                              </Tooltip>
                              <Tooltip
                                title="Supprimé de la salle d'attente"
                                placement="top"
                              >
                                <Icon
                                  className="fas fa-user-minus"
                                  onClick={() => {
                                    setModalView(2)
                                    setOpenModal(true)
                                  }}
                                  sx={{
                                    color: '#3F3F3F',
                                    marginLeft: '10px',
                                    width: '30px',
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                            <TableCell>{patient.name}</TableCell>
                            <TableCell>{patient.heure}</TableCell>
                            <TableCell>{patient.motif}</TableCell>
                            <TableCell>{patient.doctor}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                elevation={2}
                style={{
                  borderRadius: '25px',
                  height: '30vh',
                  marginBottom: '20px',
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: '100%' }}
                >
                  <Typography sx={{ textAlign: 'center' }} variant="h5">
                    Liste de travail
                  </Typography>
                  <Typography
                    sx={{ textAlign: 'center', fontWeight: 'bold' }}
                    variant="h1"
                  >
                    5
                  </Typography>
                  <Typography sx={{ textAlign: 'center' }}>
                    Personnes restantes à enregistrer
                  </Typography>
                  <Grid item></Grid>
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{ marginTop: 1 }}
                  >
                    <Button
                      onClick={() => {
                        setModalView(1)
                        setOpenModal(true)
                      }}
                      variant="contained"
                      color="success"
                    >
                      Enregistrer le patient
                    </Button>
                  </Box>
                </Box>
              </Paper>
              <Paper
                elevation={2}
                style={{
                  borderRadius: '25px',
                  height: '30vh',
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: '100%' }}
                >
                  <Typography sx={{ textAlign: 'center' }} variant="h5">
                    Rapprochements bancaires
                  </Typography>
                  <Typography
                    sx={{ textAlign: 'center', fontWeight: 'bold' }}
                    variant="h1"
                  >
                    2
                  </Typography>
                  {/* <Typography sx={{ textAlign: 'center' }}>Personnes restantes à enregistrer</Typography> */}
                  <Grid item></Grid>
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{ marginTop: 1 }}
                  >
                    <Button variant="contained" color="success">
                      Effectuer le rapprochement
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: '#E2E6F2',
          },
        }}
      >
        <DialogTitle
          onClose={() => setOpenModal(false)}
          title={
            modalView === 1
              ? 'Enregister un patient'
              : modalView === 2
                ? 'Supprimer un patient'
                : ''
          }
          style={{
            background: '#fff',
            color: 'E2E6F2',
          }}
        />
        <DialogContent
          style={{
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
            background: '#E2E6F2',
          }}
        >
          {modalView === 1 ? (
            <FormAddPatient onClose={() => setOpenModal(false)} />
          ) : modalView === 2 ? (
            <DeletePatientFromWattingRoom />
          ) : (
            <></>
          )}
        </DialogContent>
      </Dialog>
    </Container>
  )
}
