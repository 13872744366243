import React, { useEffect } from 'react'
import { OrderDto } from '@services/dtos'
import { Box, Divider, IconButton, Typography } from '@mui/material'

type Props = {
  orders: OrderDto[]
  selectedOrder: OrderDto | null
  onOrderChange: (order: OrderDto) => void
  onRemove: (orderId: number) => void
}

const OrderSelectionComponent: React.FC<Props> = ({
  orders,
  selectedOrder,
  onOrderChange,
  onRemove,
}) => {
  const parentOrders = orders.filter((order) => !order.parentId)
  const findChildOrders = (parentId: number) => {
    return orders.filter((order) => order.parentId === parentId)
  }

  const handleRemove = (orderId: number) => {
    onRemove(orderId)
  }

  return (
    <div className="flex flex-col">
      {parentOrders.map((parentOrder) => {
        const childOrders = findChildOrders(parentOrder.id)
        return (
          <div key={parentOrder.id} className="w-full mb-12 relative flex">
            <div className="flex-1">
              <Box
                onClick={() => onOrderChange(parentOrder)}
                className={`cursor-pointer p-4 rounded-lg w-full ${
                  selectedOrder?.id === parentOrder.id
                    ? 'bg-blue-100 border-blue-500'
                    : 'bg-white border-gray-300'
                } border transition-all duration-200 relative`}
              >
                {parentOrder.medicalOrders.map((medicalOrder, index) => (
                  <>
                    <div
                      key={index}
                      className="text-gray-700 font-medium text-xs text-ellipsis overflow-hidden"
                    >
                      {medicalOrder.exam.label} - ({medicalOrder.exam.code})
                    </div>
                    <Divider
                      style={{
                        margin: '8px 0',
                      }}
                    />
                  </>
                ))}

                <div className="text-gray-600 text-xs">
                  Créé le {new Date(parentOrder.createdAt).toLocaleString()}
                </div>
              </Box>

              {childOrders.length > 0 && (
                <div className="mt-4 flex">
                  <div className="flex-1">
                    {childOrders.map((childOrder) => (
                      <div
                        key={childOrder.id}
                        className="flex flex-row items-center ml-4"
                      >
                        <i className="fas fa-code-branch text-gray-600 mr-2 rotate-90 translate-y-[-5px] text-2xl"></i>
                        <Box
                          onClick={() => onOrderChange(childOrder)}
                          className={`cursor-pointer p-4 rounded-lg w-full ${
                            selectedOrder?.id === childOrder.id
                              ? 'bg-blue-100 border-blue-500'
                              : 'bg-white border-gray-300'
                          } border transition-all duration-200 mb-4 relative`}
                        >
                          <div className="absolute top-0 right-0">
                            <IconButton
                              onClick={() => handleRemove(childOrder.id)}
                            >
                              <i className="fas fa-trash text-black"></i>
                            </IconButton>
                          </div>
                          {childOrder.medicalOrders.length > 0 ? (
                            childOrder.medicalOrders.map(
                              (medicalOrder, idx) => (
                                <Typography
                                  key={idx}
                                  className="text-gray-600 font-medium"
                                >
                                  {medicalOrder.exam.label} -{' '}
                                  {medicalOrder.exam.code}
                                </Typography>
                              ),
                            )
                          ) : (
                            <span className="text-gray-600 font-medium text-xs">
                              Aucun examen associé
                            </span>
                          )}
                          <div className="text-gray-600 text-xs">
                            Créé le{' '}
                            {new Date(childOrder.createdAt).toLocaleString()}
                          </div>
                        </Box>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default OrderSelectionComponent
