import { useForm, SubmitHandler } from 'react-hook-form'
import { useEffect } from 'react'
import PersonIcon from '@mui/icons-material/Person'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import BusinessIcon from '@mui/icons-material/Business'
import { Button } from '@mui/material'
import { CouvertureDto } from '../../../../common/interfaces'
import moment from 'moment'
import { doCreateCoverege } from '../../../../state/reducers/patientsReducer'
import { useAppDispatch } from '../../../../hooks/reduxHooks'

interface CouvertureFormProps {
  initialData?: CouvertureDto | null
  isOutdated: boolean
  isControlled?: boolean
  onSubmit: (data: CouvertureDto) => void
  visitId: number
  patientId: number
}

const CouvertureForm = ({
  initialData,
  isControlled,
  onSubmit,
  visitId,
  patientId,
}: CouvertureFormProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<CouvertureDto>({
    defaultValues: initialData || {},
  })

  const dispatch = useAppDispatch()

  const beneficaireDateNaissance = watch('beneficaireDateNaissance')

  useEffect(() => {
    if (initialData) {
      Object.keys(initialData).forEach((key) => {
        setValue(
          key as keyof CouvertureDto,
          initialData[key as keyof CouvertureDto],
        )
      })
      setValue('assureNir', initialData.assureNir)
      setValue('beneficiaireNir', initialData.beneficiaireNir)
    }
  }, [initialData, setValue])

  const handleFormSubmit: SubmitHandler<CouvertureDto> = (data) => {
    const dto: CouvertureDto = {
      assureNir: data.assureNir,
      beneficiaireNir: data.beneficiaireNir,
      beneficiaireNom: data.beneficiaireNom,
      beneficiairePrenom: data.beneficiairePrenom,
      beneficaireDateNaissance: data.beneficaireDateNaissance,
      beneficaireNomUsuel: data.beneficaireNomUsuel,
      rangNaissance: data.rangNaissance,
      assureNom: data.assureNom,
      assureNomUsuel: data.assureNomUsuel,
      assurePrenom: data.assurePrenom,
      dateDebutAmo: data.dateDebutAmo,
      codeGestionAmo: '',
      codeServiceAmo: '',
      codeSituationAmo: '',
      codeAiguillageStsAmc: '',
      identifiantHoteAmc: '',
      nomDomaineAmc: '',
      codeRoutageAmc: '',
      dateDebutAmc: data.dateDebutAmc,
      dateFinAmc: data.dateFinAmc,
      dateFinAmo: data.dateFinAmo,
      idOrganismeAmo: data.organismeAmo?.id || 0,
      idOrganismeAmc: data.organismeAmc?.id || 0,
      exoneration: '',
      idExoneration: 0,
      idNature: 0,
      idPatient: patientId,
      idVisit: visitId,
      natureAssurance: '',
      numeroSerieCv: '',
      qualite: 0,
      organismeAmo: data.organismeAmo,
      organismeAmc: data.organismeAmc,
      numeroAdherentAmc: '',
      indicateurTraitementAmc: '',
    } as CouvertureDto

    dispatch(doCreateCoverege({ patientId, dto }))
      .unwrap()
      .then((res) => {
        onSubmit(res)
      })
  }
  const organismeAmo = watch('organismeAmo')
  const organismeAmc = watch('organismeAmc')

  console.log(errors)

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4 p-4">
      <section className="p-6 bg-gray-100 rounded-md">
        <div className="flex items-center mb-4">
          <PersonIcon className="text-gray-700 mr-2" />
          <h2 className="text-lg font-semibold">Informations Générales</h2>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nom du bénéficiaire
            </label>
            <input
              type="text"
              {...register('beneficiaireNom', {
                required: 'Ce champ est requis',
              })}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
            />
            {errors.beneficiaireNom && (
              <span className="text-red-600 text-sm">
                {errors.beneficiaireNom.message}
              </span>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Prénom du bénéficiaire
            </label>
            <input
              type="text"
              {...register('beneficiairePrenom', {
                required: 'Ce champ est requis',
              })}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
            />
            {errors.beneficiairePrenom && (
              <span className="text-red-600 text-sm">
                {errors.beneficiairePrenom.message}
              </span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              NIR
            </label>
            <input
              type="text"
              {...register('beneficiaireNir')}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Date de naissance
            </label>
            <input
              type="date"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
              value={moment(beneficaireDateNaissance, 'YYYYMMDD').format(
                'YYYY-MM-DD',
              )}
              onChange={(e) => {
                setValue(
                  'beneficaireDateNaissance',
                  e.target.value.replace(/-/g, ''),
                )
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nom Usuel
            </label>
            <input
              type="text"
              {...register('beneficaireNomUsuel')}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Rang de Naissance
            </label>
            <input
              type="number"
              {...register('rangNaissance')}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
            />
          </div>
        </div>
      </section>

      <section className="p-6 bg-white rounded-md">
        <div className="flex items-center mb-4">
          <AssignmentIndIcon className="text-gray-700 mr-2" />
          <h2 className="text-lg font-semibold">Détails de l&apos;Assuré</h2>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nom de l&apos;assuré
            </label>
            <input
              type="text"
              {...register('assureNom')}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Prénom de l&apos;assuré
            </label>
            <input
              type="text"
              {...register('assurePrenom')}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              NIR de l&apos;assuré
            </label>
            <input
              type="text"
              {...register('assureNir')}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nom Usuel de l&apos;assuré
            </label>
            <input
              type="text"
              {...register('assureNomUsuel')}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
            />
          </div>
        </div>
      </section>

      <section className="p-6 bg-gray-100 rounded-md">
        <div className="flex items-center mb-4">
          <BusinessIcon className="text-gray-700 mr-2" />
          <h2 className="text-lg font-semibold">Organisme AMO</h2>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              ID Organisme AMO
            </label>
            <input
              type="text"
              value={organismeAmo?.code}
              onChange={(e) => {
                if (organismeAmo) {
                  setValue('organismeAmo', {
                    ...organismeAmo,
                    code: e.target.value,
                  })
                } else {
                  setValue('organismeAmo', {
                    code: e.target.value,
                  } as any)
                }
              }}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Date de début AMO
            </label>
            <input
              type="date"
              {...register('dateDebutAmo')}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Date de fin AMO
            </label>
            <input
              type="date"
              {...register('dateFinAmo')}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
            />
          </div>
        </div>
      </section>

      <section className="p-6 bg-white rounded-md">
        <div className="flex items-center mb-4">
          <BusinessIcon className="text-gray-700 mr-2" />
          <h2 className="text-lg font-semibold">Organisme AMC</h2>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              ID Organisme AMC
            </label>
            <input
              type="text"
              {...register('idOrganismeAmc')}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Date de début AMC
            </label>
            <input
              type="date"
              {...register('dateDebutAmc')}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Date de fin AMC
            </label>
            <input
              type="date"
              {...register('dateFinAmc')}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
            />
          </div>
        </div>
      </section>

      {!isControlled && (
        <div className="flex justify-start mt-6 sticky bottom-0 w-full pt-2">
          <Button
            style={{ color: 'white' }}
            variant="contained"
            fullWidth
            type="submit"
            sx={{
              borderRadius: 5,
              width: '50%',
            }}
          >
            Sauvegarder
          </Button>
        </div>
      )}
    </form>
  )
}

export default CouvertureForm
