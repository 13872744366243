import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RoomType, RoomWithTypeDto } from '@services/api'
import {
  createDoctorOffice,
  createExamRoom,
  createPrepRoom,
  createReception,
  createWaitingRoom,
  findAllRooms,
  removeDoctorOffice,
  removeExamRoom,
  removePrepRoom,
  removeReception,
  removeWaitingRooms,
  updateDoctorOffice,
  updateExamRoom,
  updatePrepRoom,
  updateReception,
} from '@state/thunks/roomThunk'

type roomState = {
  rooms: RoomWithTypeDto[]
}

const initialState: roomState = {
  rooms: [],
}

const roomSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      findAllRooms.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.rooms = payload
      },
    )
    builder.addCase(
      removeReception.fulfilled,
      (state, { payload }: PayloadAction<number[]>) => {
        state.rooms = state.rooms.filter(
          (_room) =>
            !payload.includes(_room.id) && _room.type === RoomType.Reception,
        )
      },
    )
    builder.addCase(
      removeWaitingRooms.fulfilled,
      (state, { payload }: PayloadAction<number[]>) => {
        state.rooms = state.rooms.filter(
          (_room) =>
            !payload.includes(_room.id) && _room.type === RoomType.WaitingRoom,
        )
      },
    )
    builder.addCase(
      removePrepRoom.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.rooms = state.rooms.filter(
          (_room) =>
            !payload.includes(_room.id) && _room.type === RoomType.PrepRoom,
        )
      },
    )
    builder.addCase(
      removeExamRoom.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.rooms = state.rooms.filter(
          (_room) =>
            !payload.includes(_room.id) && _room.type === RoomType.ExamRoom,
        )
      },
    )
    builder.addCase(
      removeDoctorOffice.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.rooms = state.rooms.filter(
          (_room) =>
            !payload.includes(_room.id) && _room.type === RoomType.DoctorOffice,
        )
      },
    )
    builder.addCase(
      createReception.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.rooms = [...state.rooms, payload]
      },
    )
    builder.addCase(
      createPrepRoom.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.rooms = [...state.rooms, payload]
      },
    )
    builder.addCase(
      createExamRoom.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.rooms = [...state.rooms, { ...payload, type: RoomType.ExamRoom }]
      },
    )
    builder.addCase(
      createDoctorOffice.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.rooms = [...state.rooms, payload]
      },
    )
    builder.addCase(
      createWaitingRoom.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.rooms = [...state.rooms, payload]
      },
    )
    builder.addCase(
      updateReception.fulfilled,
      (state, action: PayloadAction<any>) => {
        const payload = action.payload

        const index = state.rooms.findIndex(
          (_room) => _room.id === payload.id && _room.type === payload.type,
        )

        if (index !== -1) {
          state.rooms[index] = payload
        }
      },
    )
    builder.addCase(
      updatePrepRoom.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        const index = state.rooms.findIndex(
          (_room) => _room.id === payload.id && _room.type === payload.type,
        )
        state.rooms[index] = payload
      },
    )
    builder.addCase(
      updateExamRoom.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        const index = state.rooms.findIndex(
          (_room) => _room.id === payload.id && _room.type === payload.type,
        )

        state.rooms[index] = payload
      },
    )
    builder.addCase(
      updateDoctorOffice.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        const index = state.rooms.findIndex(
          (_room) => _room.id === payload.id && _room.type === payload.type,
        )

        state.rooms[index] = payload
      },
    )
  },
})

// eslint-disable-next-line no-empty-pattern
export const {} = roomSlice.actions

export default roomSlice.reducer
