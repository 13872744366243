import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  debounce,
  Divider,
  FormControl,
  Grid,
  IconButton,
  TextField,
} from '@mui/material'
import { PatientDto } from '@services/api'
import { setPatients, setPatientsFilter } from '@state/reducers/patientsReducer'
import { fetchPatients } from '@state/thunks/patientsThunk'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { CouvertureDto } from 'src/common/interfaces'
import {
  RegisterPatientForm,
  RegisterPatientRef,
} from '../../containers/app/billing/form/RegisterPatientForm'

type Props = {
  onChange: (selectedPatientId?: number) => void
  defaultValue?: number
  visitId?: number
  onCoverageChange?: (coverage: CouvertureDto | null) => void
}
const SearchOrCreatePatient = forwardRef<RegisterPatientRef, Props>(
  ({ onChange, defaultValue, visitId, onCoverageChange }, ref) => {
    const dispatch = useAppDispatch()

    const [isPatientExists, setIsPatientExists] = useState(!!defaultValue)
    const [isSearchPatientOpen, setIsSearchPatientOpen] = useState(false)
    const [isFetching, setIsFetching] = useState(false)

    const [selectedPatient, setSelectedPatient] = useState<
      PatientDto | undefined
    >(defaultValue ? ({ id: defaultValue } as PatientDto) : undefined)

    const { patients, filters } = useAppSelector((state) => ({
      patients: state.patients.patients,
      filters: state.patients.patientsFilter,
    }))

    useEffect(() => {
      if (!filters.search) {
        return
      }
      dispatch(fetchPatients())
        .unwrap()
        .then(() => {
          setIsFetching(false)
        })
    }, [filters])

    useEffect(() => {
      if (selectedPatient) {
        onChange(selectedPatient.id)
      } else {
        onChange(undefined)
      }
    }, [selectedPatient])

    const findPatients = useMemo(
      () =>
        debounce((value: string) => {
          if (!value) {
            dispatch(setPatients([]))
          }
          dispatch(
            setPatientsFilter({
              search: value,
            }),
          )
        }, 400),
      [],
    )

    const onSearchPatient = (value: string) => {
      setIsFetching(true)
      findPatients(value)
    }

    const handleSubmit = () => {
      if (ref && 'current' in ref && ref.current) {
        ref.current.saveForm()
      }
    }

    const renderSelectedPatient = () => {
      return (
        <p className="bg-purple-700 inline px-4 pt-2 pb-2 text-white rounded-full  items-center justify-between">
          <IconButton
            className="mr-4 translate-y-[-2px]"
            onClick={() => setSelectedPatient(undefined)}
          >
            <i className="fas fa-times text-white" />
          </IconButton>
          <span className="text-sm ml-4 font-semibold">
            {selectedPatient?.firstName} {selectedPatient?.lastName} ( SSN:
            {selectedPatient?.ssn})
          </span>
        </p>
      )
    }

    const _renderSelectedPatient = renderSelectedPatient()

    return (
      <form>
        <FormControl>
          <div>
            Déjà enregistré?
            <Checkbox
              onChange={(_, checked) => {
                if (!checked && selectedPatient) {
                  setSelectedPatient(undefined)
                }
                setIsPatientExists(checked)
              }}
              checked={isPatientExists}
            />
          </div>
        </FormControl>

        <Grid item xs={12}>
          {selectedPatient && _renderSelectedPatient}
          <Divider sx={{ my: 2 }} />
          {isPatientExists && !selectedPatient && (
            <Grid item xs={6} my={2}>
              <FormControl size="small" fullWidth>
                <Autocomplete
                  sx={{
                    width: '300px',
                  }}
                  open={isSearchPatientOpen}
                  componentsProps={{
                    popper: {
                      style: {
                        width: 'fit-content',
                      },
                    },
                  }}
                  onOpen={() => {
                    setIsSearchPatientOpen(true)
                  }}
                  onClose={() => {
                    setIsSearchPatientOpen(false)
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (!value) {
                      return false
                    }
                    return option.id === value.id
                  }}
                  getOptionLabel={(option) =>
                    option.firstName + ' ' + option.lastName
                  }
                  options={patients}
                  onChange={(_, value) =>
                    setSelectedPatient(value ?? undefined)
                  }
                  loading={isFetching}
                  value={selectedPatient}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(ev) => {
                        onSearchPatient(ev.target.value)
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isFetching ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
        <RegisterPatientForm
          ref={ref}
          containerClassName="max-h-[calc(101vh-500px)] overflow-y-scroll"
          patientId={selectedPatient?.id}
          onCoverageChange={onCoverageChange}
          onClose={() => {
            setIsPatientExists(true)
          }}
          onSuccess={(patient) => {
            console.log('patient selected!', patient)
            setSelectedPatient(patient)
          }}
          hideCloseButton
          visitId={visitId}
        />
      </form>
    )
  },
)

export default SearchOrCreatePatient
