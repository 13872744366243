import { axios, Document, getConfigs, IRequestConfig } from './api'
import { CreateDocumentDto, UpdateDocumentDTO } from './dtos'

export class DocumentService {
  private static readonly endpoint = 'ged'

  static createDocument(dto: CreateDocumentDto): Promise<Document> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        this.endpoint + '/documents',
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static async deleteDocument(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        this.endpoint + '/documents/' + id,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static updateDocuments(data: { id: number; dto: UpdateDocumentDTO }): any {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        this.endpoint + '/documents/' + data.id,
        {},
      )
      configs.data = data.dto
      axios(configs, resolve, reject)
    })
  }
}
