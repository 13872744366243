import { useDispatch } from 'react-redux'
import { useSelector } from '@state/store'
import { useForm } from 'react-hook-form'

import { Grid } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { SelectInput } from '@components/inputs'
import { TextInput } from '@components/inputs'
import {
  WaitingRoom,
  RoomType,
  UpdateWaitingRoomDto,
  CreateWaitingRoomDto,
} from '@services/api'
import { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import {
  createWaitingRoom,
  updateWaitingRoom,
} from '../../../../state/thunks/roomThunk'
import { addWaitingRoomSchema } from '../../../../utils/schemas'
import { Button } from '@components/buttons'

type AddReceptionFormProps = {
  row?: WaitingRoom
  onClose?: () => void
  onCancelButtonPress?: (
    ev: MouseEventHandler<HTMLButtonElement> | undefined,
  ) => void
}

export function WaitingRoomsForm({ row, onClose }: AddReceptionFormProps) {
  const dispatch = useDispatch()
  const sites = useSelector(({ site }) => site.sites.datas)
  const rooms = useSelector(({ room }) => room.rooms)

  const [selectedSite, setSelectedSite] = useState<number | null>(null)

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<WaitingRoom>({
    resolver: yupResolver(addWaitingRoomSchema),
    defaultValues: {
      ...row,
    },
  })

  useEffect(() => {
    setSelectedSite(row?.siteId || null)
  }, [row])

  const onSubmit = (values: WaitingRoom & { type?: RoomType }) => {
    if (!selectedSite) {
      return
    }
    if (row?.id) {
      const body: UpdateWaitingRoomDto = {
        code: values.code,
        label: values.label,
        siteId: selectedSite,
        receptionId: values.receptionId,
        capacity: values.capacity,
      }
      dispatch(updateWaitingRoom({ id: row.id, body }))
    } else {
      //add room
      const body: CreateWaitingRoomDto = {
        code: values.code,
        label: values.label,
        siteId: selectedSite,
        receptionId: values.receptionId,
        capacity: values.capacity,
      }
      dispatch(createWaitingRoom(body))
    }
    if (onClose) onClose()
  }

  const handleCancelPress = () => {
    if (onClose) {
      onClose()
    }
    // Closes the update form - Datagrid didnt provide a prop function to close form ()
    const el = (document as Document).querySelector(
      `.MuiDataGrid-row[data-id="${row?.id}"] .MuiIconButton-root.MuiIconButton-sizeSmall`,
    ) as any
    if (el) {
      el.click()
    }
  }

  const sitesOptions = useMemo(
    () =>
      sites.map((_site) => ({
        label: _site.label,
        value: _site.id,
      })),
    [sites],
  )
  const receptionOptions = useMemo(
    () =>
      rooms.reduce(
        (acc, _reception) => {
          if (
            _reception.type === RoomType.Reception &&
            _reception.siteId === selectedSite
          ) {
            acc.push({
              label: _reception.label,
              value: _reception.id,
            })
          }
          return acc
        },
        [] as {
          label: string
          value: number
        }[],
      ),
    [rooms, selectedSite],
  )

  const _handleSiteChange = (ev) => {
    setSelectedSite(ev.target.value)
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="flex-start"
      sx={{
        padding: '20px',
      }}
    >
      <Grid item xs={12} md={6}>
        <SelectInput
          value={selectedSite}
          name="siteId"
          variant="outlined"
          options={sitesOptions}
          onChange={_handleSiteChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput
          options={receptionOptions}
          control={control}
          name="receptionId"
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput name="code" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput name="label" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          name="capacity"
          type="number"
          InputProps={{
            inputProps: { min: 0 },
          }}
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} md={6}></Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-end">
          {onClose && (
            <Grid item xs={2}>
              <Button
                onClick={handleCancelPress}
                color="secondary"
                text="cancel"
                textColor="white"
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <Button
              onClick={handleSubmit(onSubmit)}
              color="primary"
              text="save"
              textColor="white"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
