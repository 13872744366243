import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { CreateDoctorDto, DoctorDto } from '@services/api'
import { DoctorV2Service } from '@services/DoctorService'
import { FindDoctorsQuery } from '@services/dtos'
import { PaginatedDto } from '@services/extendedType'
import { findAll } from '@state/thunks/doctorThunk'

type doctorState = {
  doctors: {
    totalCount: number
    datas: DoctorDto[]
  }
  filters: FindDoctorsQuery
}

const initialState: doctorState = {
  doctors: {
    totalCount: 0,
    datas: [],
  },
  filters: {
    page: 1,
    limit: 20,
  },
}

export const doFindDoctors = createAsyncThunk<PaginatedDto<DoctorDto>>(
  'doctor/findAllV2',
  async (_, { getState }) => {
    const {
      doctor: { filters },
    } = getState() as { doctor: doctorState }
    return DoctorV2Service.findMany(filters)
  },
)

export const doCreateDoctor = createAsyncThunk<DoctorDto, CreateDoctorDto>(
  'doctor/create',
  async (dto, { rejectWithValue }) => {
    try {
      return await DoctorV2Service.create(dto)
    } catch (err) {
      if (err.response.data) {
        return rejectWithValue(err.response.data.message)
      }
      return rejectWithValue(err)
    }
  },
)

export const doUpdateDoctor = createAsyncThunk<
  DoctorDto,
  { id: number; dto: CreateDoctorDto }
>('doctor/update', async ({ id, dto }) => DoctorV2Service.update(id, dto))

export const doRemoveDoctor = createAsyncThunk<void, number>(
  'doctor/remove',
  async (id) => DoctorV2Service.remove(id),
)

const doctorSlice = createSlice({
  name: 'doctor',
  initialState,
  reducers: {
    setDoctorFilters: (
      state,
      { payload }: PayloadAction<Partial<FindDoctorsQuery>>,
    ) => {
      state.filters = { ...state.filters, ...payload }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(findAll.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.doctors = payload
      })
      .addCase(doFindDoctors.fulfilled, (state, { payload }) => {
        state.doctors = payload
      })
      .addCase(doCreateDoctor.fulfilled, (state, { payload }) => {
        state.doctors.datas.push(payload)
      })
      .addCase(doUpdateDoctor.fulfilled, (state, { payload }) => {
        const doctors = [...state.doctors.datas]
        const index = doctors.findIndex((doctor) => doctor.id === payload.id)
        doctors[index] = payload
        doctors[index].addresses = payload.addresses
        state.doctors.datas = doctors
      })
      .addCase(doRemoveDoctor.fulfilled, (state, { meta: { arg } }) => {
        state.doctors.datas = state.doctors.datas.filter(
          (doctor) => doctor.id !== arg,
        )
      }),
})

export const { setDoctorFilters } = doctorSlice.actions
export default doctorSlice.reducer
