import { createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'
import { RootState } from '@state/store'
import { StatsServices, TableStatsPrescriberDti, TableStatsUserDti, convertedDataForCharts } from '@services/api'

export const getAllPrescriber = createAsyncThunk(
  Actions.STATS_GET_ALL_PRESCRIBER,
  async (_, { rejectWithValue, getState }) => {
    try {
      getState() as RootState
      const resultGetState:any = getState()
      const { startY, endY, siteId } = resultGetState.stats.filter
      console.log('resultGetState', startY, endY, siteId)
      const result: TableStatsPrescriberDti[] = await StatsServices.fetchAllPrescriber({ startYear: startY, endYear: endY, siteId })
      return result
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.message)
    }
  }
)

// commence ici tu dois faire en sorte de mettre des state directe dans les thunks

export const getExamBreakdown = createAsyncThunk(
  Actions.STATS_GET_EXAM_BREACKDOWN,
  async (_, { rejectWithValue, getState }) => {
    try {
      getState() as RootState
      const resultGetState:any = getState()
      const { siteId } = resultGetState.stats.filter
      const result = await StatsServices.fetchAllExamBreakdown({siteId: siteId})
      return convertedDataForCharts(result)
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.message)
    }
  }
)

export const getAllTopEvent = createAsyncThunk(
  Actions.STATS_GET_TOP_EVENT,
  async (_, { rejectWithValue, getState }) => {
    try {
      getState() as RootState
      const resultGetState:any = getState()
      const { accountType, eventType, siteId } = resultGetState.stats.filter
      const result: TableStatsUserDti[] = await StatsServices.fetchAllTopEvent({ accountType: accountType, eventType: eventType, siteId: siteId })
      return result
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.message)
    }
  }
)
