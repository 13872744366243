import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { ALDStatusDto } from '@services/dtos'
import moment from 'moment'

interface ALDStatusFormProps {
  initialData?: ALDStatusDto // Optional initial data for editing
  onSubmit: (data: ALDStatusDto) => void // Callback for parent component
}

const ALDStatusForm: React.FC<ALDStatusFormProps> = ({
  initialData,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ALDStatusDto>({
    defaultValues: initialData || {
      libelle: '',
      code: '',
      etat: '',
      source: 'User',
      comment: '',
    },
  })

  const handleFormSubmit: SubmitHandler<ALDStatusDto> = (data) => {
    const isALDSet =
      data.libelle || data.code || data.etat || data.dateFin || data.cimCode

    if (!isALDSet && !data.comment) {
      alert('Veulliez déclarer une ALD ou laisser un commentaire.')
      return
    }
    const formattedDateFin = data.dateFin
      ? moment(data.dateFin).format('DD/MM/YYYY')
      : undefined

    const finalData: ALDStatusDto = {
      ...data,
      dateFin: formattedDateFin,
      source: 'User',
    }

    onSubmit(finalData)
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4 p-4">
      {/* Libelle */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Libellé
        </label>
        <input
          {...register('libelle', { required: 'Libellé est obligatoire' })}
          type="text"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
        />
        {errors.libelle && (
          <p className="text-red-600 text-sm">{errors.libelle.message}</p>
        )}
      </div>

      {/* Code */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Code</label>
        <input
          {...register('code', { required: 'Code est obligatoire' })}
          type="text"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
        />
        {errors.code && (
          <p className="text-red-600 text-sm">{errors.code.message}</p>
        )}
      </div>

      {/* Etat */}
      <div>
        <label className="block text-sm font-medium text-gray-700">État</label>
        <select
          {...register('etat', { required: 'État est obligatoire' })}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
        >
          <option value="">Sélectionner l&apos;état</option>
          <option value="Oui">Oui</option>
          <option value="Terminée">Terminée</option>
        </select>
        {errors.etat && (
          <p className="text-red-600 text-sm">{errors.etat.message}</p>
        )}
      </div>

      {/* Date Fin */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Date de Fin
        </label>
        <input
          {...register('dateFin')}
          type="date"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
        />
      </div>

      {/* CIM Code */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          CIM Code
        </label>
        <input
          {...register('cimCode')}
          type="text"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
        />
      </div>

      {/* Comment */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Commentaire
        </label>
        <textarea
          {...register('comment')}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm"
          rows={3}
        />
      </div>

      {/* Submit Button */}
      <div className="mt-6">
        <button
          type="submit"
          className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
        >
          Enregistrer
        </button>
      </div>
    </form>
  )
}

export default ALDStatusForm
