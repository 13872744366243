import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js'
import { Pie, Bar } from 'react-chartjs-2'
import { fetchPatientEvent, fetchPatientEventByAge } from '@services/statsSercives'
import { DugnutStatsData } from '@services/api'
import { Box, Tabs, Tab, Typography } from '@mui/material'
import { Container } from '@mui/material'


export function StatsTopStatsExamTypePie(data) {
  
  const [pieValue, setPieValue] = useState<DugnutStatsData>()

  ChartJS.register(ArcElement, Tooltip, Legend)
  const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40']  
  useEffect(() => {
    const fetchData = async () => {
      if (data === -1)
        setPieValue(await fetchPatientEventByAge())
      else 
        setPieValue(await fetchPatientEventByAge(data))
    }
    fetchData()
  }, [])

  return (
    <Container maxWidth="md">
      <Pie height={275} options={{ maintainAspectRatio: false, responsive: true, }} data={
        {
          labels: pieValue?.labels,
          datasets: [
            {
              label: 'Dataset 1',
              data: pieValue?.total,
              backgroundColor: colors,
              hoverOffset: 2,
              borderWidth: 2,
            },
          ],
        }
      }/>
    </Container>
  )
}

export function StatsOtherGraphPatientEventBar() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  )
  
  const colors = ['#67366E', '#218CF7', '#D70DB6']
  
  const [cardValue1, setcardValue1] = useState<DugnutStatsData>()
  
  useEffect(() => {
    const fetchData = async () => {
      setcardValue1(await fetchPatientEvent())
    }
    fetchData()
  }, [])
  
  const data = {
    labels: ['Medical order vue', 'Image vue', 'Contre-rendu vue'],
    datasets: [
      {
        label: 'Nombre de patient',
        data: cardValue1?.total,
        backgroundColor: colors,
        hoverOffset: 2,
        borderWidth: 2,
      },
    ],
  }
  
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  }
  
  return (
    <Bar data={data} options={options} />
  )
  
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
  
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
  
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
  
export default function StatsOtherGraphPatientEventModule() {
  const [value, setValue] = React.useState(0)
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="BAR" {...a11yProps(0)} />
          <Tab label="PIE" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <StatsOtherGraphPatientEventBar/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <StatsTopStatsExamTypePie/>
      </CustomTabPanel>
    </Box>
  )
}