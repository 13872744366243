import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import { TextInput } from '@components/inputs'
import { Button } from '@components/buttons'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateEmailSchema } from '@utils/schemas'
import { Text } from '../texts'
import { updateEmail } from '@state/thunks/authThunk'
import { AccountDto } from '@services/api'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))
type UpdateEmailFormProps = {
  account?: AccountDto
}

type Values = {
  email: string
}
export function UpdateEmailForm({ account }: UpdateEmailFormProps) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(updateEmailSchema),
    defaultValues: {
      email: account?.email,
    },
  })

  const onSubmit = ({ email }: Values) => {
    dispatch(updateEmail({ email }))
  }
  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon className="fa fa-envelope" />
            </Grid>
            <Grid item>
              <Text text="email" variant={'h6'} format />
            </Grid>
          </Grid>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6}>
            <TextInput
              name="email"
              placeholder="Email"
              size="small"
              variant="outlined"
              control={control}
              errors={errors}
            />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Button
              text="submit"
              onClick={handleSubmit(onSubmit)}
              format
              color="primary"
            />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}
