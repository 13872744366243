import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, TablePagination, Tabs, Tab, Typography, Box } from '@mui/material'
import { TableStatsPrescriber, TableStatsUser, } from '@services/statsSercives'
import { useSelector } from '@state/store'

/**
 * System of tabs for the table
 */

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function StatsTable() {
  const [value, setValue] = useState(0)
  const [tableData, setTableData] = useState<TableStatsPrescriber[]>([])
  const [tableStatsUser, setTableStatsUser] = useState<TableStatsUser[]>([])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const stats = useSelector((state) => state.stats.tablePrescriber)
  const stats1 = useSelector((state) => state.stats.tableUser)

  useEffect(() => {
    setTableData(stats)
    setTableStatsUser(stats1)
  }, [stats, stats1])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Top prescriber" {...a11yProps(0)} />
          <Tab label="Top user" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <StatsTableTopPrescriber data={tableData} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <StatsTableTopPrescriber data={tableStatsUser} />
      </CustomTabPanel>
    </Box>
  )
}

/**
 * Table for the top prescribers
 */

const StatsTableTopPrescriber: React.FC<{ data: TableStatsPrescriber[] | TableStatsUser[]}> = ({ data }) => {
  const [selected, setSelected] = useState<number[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((row) => row.rank)
      setSelected(newSelected)
    } else {
      setSelected([])
    }
  }

  const newTab = data.map((item) => {
    if (!item.firstName || !item.lastName) {
      return item
    }
    const { firstName, lastName, ...rest } = item
    const reorderedItem = {
      rank: rest.rank,
      Name: `${firstName} ${lastName}`,
      ...rest
    }
    return reorderedItem
  })
  
  const columns: string[] = newTab.length > 0 ? Object.keys(newTab[0]) : []

  const handleClick = (event: React.MouseEvent<unknown>, rank: number) => {
    const selectedIndex = selected.indexOf(rank)
    let newSelected: number[] = []

    if (selectedIndex === -1) {
      newSelected = [...selected, rank]
    } else {
      newSelected = [...selected.slice(0, selectedIndex), ...selected.slice(selectedIndex + 1)]
    }

    setSelected(newSelected)
  }

  const isSelected = (rank: number) => selected.indexOf(rank) !== -1

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const startIndex = page * rowsPerPage
  const endIndex = startIndex + rowsPerPage
  const paginatedData = data.slice(startIndex, endIndex)

  useEffect(() => {
    const handleArrowKeys = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft' && page > 0) {
        setPage(page - 1)
      } else if (event.key === 'ArrowRight' && endIndex < data.length) {
        setPage(page + 1)
      }
    }

    document.addEventListener('keydown', handleArrowKeys)

    return () => {
      document.removeEventListener('keydown', handleArrowKeys)
    }
  }, [page, data.length, endIndex])

  return (
    <div>
      <TableContainer component={Paper} sx={{ borderRadius: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < data.length}
                  checked={selected.length === data.length}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              {columns.map((elem, index) => (
                <TableCell key={index}>{elem.charAt(0).toUpperCase() + elem.slice(1)}</TableCell>
              ))}

            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row) => (
              <TableRow
                key={row.rank}
                onClick={(event) => handleClick(event, row.rank)}
                selected={isSelected(row.rank)}
                sx={{ overflow: 'auto' }}
              >
                <TableCell padding="checkbox">
                  <Checkbox checked={isSelected(row.rank)} />
                </TableCell>
                {columns.map((column, columnIndex) => (
                  <TableCell key={columnIndex}>
                    {Object.prototype.hasOwnProperty.call(row, column) ? row[column] : row['firstName'] + ' ' + row['lastName']}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          sx={{ bgcolor: 'background.paper' }}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  )
}
