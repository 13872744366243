import { Button } from '@components/buttons'
import DialogTitle from '@components/dialogs/DialogTitle'
import { OrderToolbar } from '@components/tables/Toolbars/MedicalOrderToolbar'
import { Text } from '@components/texts'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import DifferenceRoundedIcon from '@mui/icons-material/DifferenceRounded'
import ErrorIcon from '@mui/icons-material/Error'
import HealthAndSafetyRoundedIcon from '@mui/icons-material/HealthAndSafetyRounded'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import MergeIcon from '@mui/icons-material/Merge'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro'
import { deleteType } from '@state/thunks/procedureThunk'
import theme from '@utils/theme'
import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import 'moment/locale/fr'
import { findMedicalOrders } from '@state/thunks/medicalOrderThunk'
import { capitalize } from 'lodash'
import { OrderStatusEnum } from '../../common/interfaces'
import { OrderDto } from '@services/dtos'
import {
  doFindOrders,
  setOrderFilters,
  setSelectedOrder,
} from '@state/reducers/orderReducer'
import 'moment/locale/fr'
import ALDStatusForm from '../../containers/app/billing/form/ALDStatusForm'
import { RegisterPatientForm } from '../../containers/app/billing/form/RegisterPatientForm'
import MedicalOrderDialog, {
  SlideInPortal,
} from '../../containers/app/Worklists/MedicalOrderDialog'
import PatientFusionDialog from '../../containers/app/Worklists/DialogPatientFusion'
import InstantBillComponent from '../billing/InstantBillComponent'
import { setPatientDetails } from '../../state/reducers/patientsReducer'

export interface DialogProps {
  openPatientDetails: boolean
}

const actionsMap: { [key: string]: string[] } = {
  [OrderStatusEnum.pendingRegistration]: [
    'registerPatient',
    'registerPatientWithCV',
    'updateMO',
    'patientDetails',
    'patientMerge',
    'ViewMo',
    'aldStatus',
  ],
  [OrderStatusEnum.registered]: ['ViewMo', 'updateMO', 'patientDetails'],
  [OrderStatusEnum.toQuote]: ['ViewMo'],
  [OrderStatusEnum.toInvoice]: ['ViewMo', 'patientBill'],
  [OrderStatusEnum.medicalOrderCancelled]: ['ViewMo'],
}

type Props = {
  isSelection?: boolean
  onSelectionChange?: (selectedRows: OrderDto[]) => void
  selectionModel?: number[]
  excludeRows?: number[]
}

export default function OrdersTable({
  isSelection,
  onSelectionChange,
  excludeRows,
}: Props) {
  const dispatch = useAppDispatch()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isInstantBillOpen, setIsInstantBillOpen] = useState(false)

  const [openWithCV, setOpenWithCV] = useState(false)

  const [dialogAction, setDialogAction] = useState<
    | 'PatientDetails'
    | 'PatientForm'
    | 'patientFusion'
    | 'MedicalOrder'
    | 'ALDStatus'
    | 'Couverture'
    | null
  >(null)

  const { selectedOrder, filters, orders } = useAppSelector((state) => ({
    medicalOrders: state.order.orders,
    filters: state.order.filters,
    selectedOrder: state.order.selectedOrder,
    orders: state.order.orders,
  }))

  const CustomALDIcon = ({ aldStatus, comment }) => {
    if (aldStatus) {
      return (
        <Tooltip title={`ALD: ${aldStatus.libelle} (${aldStatus.etat})`}>
          <HealthAndSafetyRoundedIcon className="text-green-500" />
        </Tooltip>
      )
    } else if (comment) {
      return (
        <Tooltip title={comment}>
          <HealthAndSafetyRoundedIcon className="text-orange-500" />
        </Tooltip>
      )
    } else {
      return (
        <Tooltip title="Informations ALD manquantes">
          <HealthAndSafetyRoundedIcon className="text-red-500" />
        </Tooltip>
      )
    }
  }

  const CustomCouvertureIcon: React.FC<{ coverageId?: number }> = ({
    coverageId,
  }) => {
    if (coverageId) {
      return <DifferenceRoundedIcon className="text-green-500" />
    } else {
      return (
        <Tooltip title="Couverture non mise à jour">
          <DifferenceRoundedIcon className="text-orange-500" />
        </Tooltip>
      )
    }
  }

  const actionBtnObj = [
    {
      id: 1,
      btnName: 'Accueillir le patient',
      icon: <MeetingRoomIcon className="text-blue-500" />,
      action: (row: OrderDto) => handleRegisterPatientClick(row),
      actionKey: 'registerPatient',
    },
    {
      id: 2,
      btnName: 'Fusionner le patient',
      icon: <MergeIcon className="text-purple-500" />,
      action: (row: OrderDto) => {
        handleClickActionPatientMerge()
        dispatch(setSelectedOrder(row))
      },
      actionKey: 'patientMerge',
    },
    {
      id: 3,
      btnName: 'Voir facture',
      icon: <ErrorIcon className="text-green-500" />,
      action: (row: OrderDto) => handleClickActionPatientBill(row),
      actionKey: 'patientBill',
    },
    {
      id: 4,
      btnName: 'Fiche patient',
      icon: <AssignmentIndIcon className="text-teal-500" />,
      action: (row: OrderDto) => {
        handleCouvertureClick(row)
      },
      actionKey: 'patientDetails',
    },
    {
      id: 5,
      btnName: 'Voir',
      actionKey: 'ViewMo',
      icon: <VisibilityIcon className="text-slate-500" />,
      action: (row: OrderDto) => {
        handleMedicalOrderView()
        dispatch(setSelectedOrder(row))
      },
    },
  ]

  const handleALDStatusClick = (order: OrderDto) => {
    dispatch(setSelectedOrder(order))
    setDialogAction('ALDStatus')
  }

  const handleRegisterPatientClick = (order: OrderDto) => {
    dispatch(setSelectedOrder(order))
    setDialogAction('PatientForm')
    setOpenWithCV(true)
  }
  const handleClickActionPatientMerge = () => {
    setDialogAction('patientFusion')
  }

  const handleMedicalOrderView = () => {
    setDialogAction('MedicalOrder')
  }

  const handleClickActionPatientBill = (mo: OrderDto) => {
    console.log(mo)
  }

  const handleCouvertureClick = (order: OrderDto) => {
    dispatch(setSelectedOrder(order))
    setDialogAction('Couverture')
  }

  useEffect(() => {
    setIsLoading(true)
    dispatch(doFindOrders()).finally(() => {
      setIsLoading(false)
    })
  }, [filters])

  const actions = useMemo(() => {
    if (!filters.status) {
      return []
    }
    return actionsMap[filters.status]
  }, [filters.status])

  const filteredOrders = useMemo(() => {
    if (excludeRows) {
      return orders.filter((o) => !excludeRows.includes(o.id))
    }
    return orders
  }, [orders, excludeRows])

  const columns: GridColumns = [
    {
      field: 'visitNumber',
      headerName: 'N° de séjour',
      width: 120,
      valueGetter: ({ row }) => {
        return row.visitNumber
      },
    },
    {
      field: 'patient.firstName',
      headerName: 'Prénom',
      width: 120,
      valueGetter: (params) => capitalize(params.row.patient.firstName),
    },
    {
      field: 'patient.lastName',
      headerName: 'Nom',
      width: 120,
      valueGetter: ({ row }) => {
        return capitalize(row.patient.lastName)
      },
    },
    {
      field: 'patient.birthDate',
      headerName: 'Né(e) le',
      width: 100,
      valueGetter: ({ row }) => {
        return moment(row.patient.birthDate).format('DD/MM/YYYY')
      },
    },
    {
      field: 'patient.ssn',
      headerName: 'N° sécurité sociale',
      width: 160,
      valueGetter: ({ row }) => {
        return row.patient.ssn
      },
    },
    {
      field: 'exam.label',
      headerName: 'Procédure',
      width: 260,
      renderCell: ({ row }) => {
        return (
          <Tooltip title={`${capitalize(row.exam?.label)} (${row.exam?.code})`}>
            <span>
              {capitalize(row.exam?.label)} ({row.exam?.code})
            </span>
          </Tooltip>
        )
      },
    },

    {
      field: 'plannedDate',
      headerName: 'Heure RDV',
      width: 150,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {moment(params.row.plannedDate).format('DD/MM/YYYY HH:mm')}
          </div>
        )
      },
    },
    {
      field: 'consultingDoctor',
      headerName: 'Médecin référent',
      width: 160,
      valueGetter: ({ row }) => {
        if (row.consultingDoctor?.firstName && row.consultingDoctor?.lastName) {
          const firstName =
            row.consultingDoctor.firstName.charAt(0).toUpperCase() +
            row.consultingDoctor.firstName.slice(1).toLowerCase()
          const lastName = row.consultingDoctor.lastName.toUpperCase()
          return `${firstName} ${lastName}`
        }
        return 'Non renseigné'
      },
    },

    {
      field: 'aldStatus',
      headerName: 'ALD',
      width: 110,
      renderCell: ({ row }) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() => handleALDStatusClick(row)}
          >
            <CustomALDIcon aldStatus={row.aldStatus} comment={row.comment} />
          </div>
        )
      },
    },
    {
      field: 'couvertureStatus',
      headerName: 'Droits',
      width: 110,
      renderCell: ({ row }) => {
        const coverageId = row.visit?.coverageId

        return (
          <div
            className="cursor-pointer"
            onClick={() => handleCouvertureClick(row)}
          >
            <CustomCouvertureIcon coverageId={coverageId} />
          </div>
        )
      },
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      sortable: false,
      hide: isSelection,
      renderCell: (params) => {
        const medicalOrder = params.row
        return (
          <div className="flex space-x-2">
            {actionBtnObj.reduce((acc, el) => {
              if (actions.includes(el.actionKey)) {
                acc.push(
                  <Tooltip
                    key={medicalOrder.id + '-' + el.btnName}
                    title={el.btnName}
                  >
                    <div
                      className="cursor-pointer p-1 hover:bg-gray-200 rounded"
                      onClick={() => {
                        const action = el.action.bind({})
                        action(medicalOrder)
                      }}
                    >
                      {el.icon}
                    </div>
                  </Tooltip>,
                )
              }
              return acc
            }, new Array<React.ReactElement>())}
          </div>
        )
      },
    },
  ]

  const [stateSearch, setStateSearch] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    if (searchTerm === '') {
      setStateSearch(false)
    }
    if (searchTerm !== '') {
      setStateSearch(true)
    }
  }, [searchTerm, setSearchTerm])

  const onRefresh = useCallback(() => {
    setIsLoading(true)
    dispatch(findMedicalOrders({})).finally(() => {
      setIsLoading(false)
    })
  }, [dispatch])

  const _onDeleteConfirm = () => {
    dispatch(deleteType(selectedRows))
    setIsDeleteDialogOpen(false)
  }

  const handleDialogClose = () => {
    setDialogAction(null)
    dispatch(setSelectedOrder(null))
  }

  const handleSelectionChange = (ids: number[]) => {
    const _orders: OrderDto[] = []
    for (const id of ids) {
      const order = orders.find((o) => o.id === id)
      if (order) {
        _orders.push(order)
      }
    }
    if (onSelectionChange) {
      onSelectionChange(_orders)
    }
  }
  return (
    <div>
      <Grid container spacing={4} style={{ height: '100%' }}>
        <Grid item container>
          <Grid container spacing={4} style={{ height: 'calc(100vh - 65px)' }}>
            <Grid item container>
              <DataGridPro
                density="standard"
                style={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: 'white',
                  borderRadius: '15px 15px 25px 25px',
                }}
                checkboxSelection={isSelection}
                onSelectionModelChange={(selected) => {
                  handleSelectionChange(selected as number[])
                }}
                columns={columns}
                rows={filteredOrders}
                rowCount={filters.total}
                disableSelectionOnClick
                pagination
                paginationMode="server"
                disableMultipleSelection
                onPageChange={(page) => {
                  dispatch(setOrderFilters({ page }))
                }}
                initialState={{
                  pinnedColumns: { left: ['actions'], right: [''] },
                }}
                loading={isLoading}
                components={{
                  Toolbar: OrderToolbar,
                  LoadingOverlay: LinearProgress,
                }}
                componentsProps={{
                  toolbar: {
                    onRefresh,
                    onInstantBillClick: () => setIsInstantBillOpen(true),
                  },
                }}
              />
            </Grid>
          </Grid>
          {/* add dialog title with close button */}

          {stateSearch ? (
            <Box
              sx={{
                position: 'absolute',
                top: '40%',
                left: '45%',
                transform: 'translate(-50%, -50%)',
                height: '500px',
                width: '20%',
              }}
            >
              <Paper elevation={5}>
                <Box
                  sx={{
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    position: 'sticky',
                    p: 1,
                    backgroundColor: '#f5f5f5',
                  }}
                >
                  <Typography variant="h6" color="initial">
                    Recherche patient
                  </Typography>
                  <Icon
                    fontSize="large"
                    className="fas fa-times-circle"
                    onClick={() => {
                      setStateSearch(false), setSearchTerm('')
                    }}
                    sx={{ color: '#E91E63', cursor: 'pointer' }}
                  />
                </Box>
              </Paper>
            </Box>
          ) : null}
        </Grid>
      </Grid>
      {dialogAction === 'PatientForm' && selectedOrder && (
        <Dialog
          open={true}
          onClose={handleDialogClose}
          maxWidth="xl"
          fullWidth
          aria-labelledby="form-dialog-title2"
          PaperProps={{
            sx: {
              borderRadius: 5,
            },
          }}
        >
          <DialogTitle
            onClose={() => {
              setDialogAction(null)
            }}
            title="Accueillir le patient"
            style={{
              background: '#fff',
            }}
          />
          <DialogContent sx={{ bgcolor: 'white', minHeight: '80vh' }}>
            <RegisterPatientForm
              onClose={() => {
                setDialogAction(null)
              }}
              patientId={selectedOrder.patient.id}
              visitId={selectedOrder?.visitId}
              isRegistration={!selectedOrder.registeredAt}
            />
          </DialogContent>
        </Dialog>
      )}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          onClose={() => setIsDeleteDialogOpen(false)}
          title="deleteReception"
          style={{
            background: '#fff',
            color: theme.palette.primary.main,
          }}
          format
        />
        <DialogContent
          sx={{
            background: '#E2E6F2',
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            text="deleteConfirmation"
            variant="h5"
            color="secondary"
            sx={{ fontWeight: 'bold', paddingTop: '20px' }}
            format
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
          }}
        >
          <Button
            fullWidth
            textColor="white"
            onClick={() => setIsDeleteDialogOpen(false)}
            color="secondary"
            text="Cancel"
          />
          <Button
            fullWidth
            textColor="white"
            onClick={_onDeleteConfirm}
            color="primary"
            text="Save"
          />
        </DialogActions>
      </Dialog>
      {dialogAction === 'patientFusion' && selectedOrder && (
        <PatientFusionDialog
          patientId={selectedOrder.patient.id}
          open
          onClose={handleDialogClose}
        />
      )}
      {dialogAction === 'ALDStatus' && selectedOrder && (
        <Dialog
          open={true}
          onClose={handleDialogClose}
          fullWidth={true}
          maxWidth="sm"
          aria-labelledby="form-dialog-title2"
          PaperProps={{
            sx: {
              borderRadius: 5,
            },
          }}
        >
          <DialogTitle
            onClose={() => {
              setDialogAction(null)
            }}
            title="Gestion de l'ALD"
            style={{
              background: '#fff',
            }}
          />
          <DialogContent sx={{ bgcolor: '#E2E6F2' }}>
            <ALDStatusForm
              initialData={selectedOrder.aldStatus}
              onSubmit={(data) => {
                console.log('Submitted ALD status', data)
                handleDialogClose()
              }}
            />
          </DialogContent>
        </Dialog>
      )}

      {selectedOrder && dialogAction === 'MedicalOrder' && (
        <MedicalOrderDialog
          isOpen={dialogAction === 'MedicalOrder'}
          onClose={handleDialogClose}
          order={selectedOrder}
        />
      )}

      {selectedOrder && (
        <SlideInPortal
          className="translate-x-12"
          isOpen={dialogAction === 'Couverture'}
          onClose={() => {
            setDialogAction(null)
            setSelectedOrder(null)
            dispatch(setPatientDetails(null))
          }}
          patientId={selectedOrder.patient.id}
        />
      )}

      {isInstantBillOpen && (
        <InstantBillComponent
          isOpen={isInstantBillOpen}
          onClose={() => setIsInstantBillOpen(false)}
        />
      )}
    </div>
  )
}
