import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { useState } from 'react'
import MedicalEquipment from './MedicalEquipment'
import ItEquipment from './itEquipment'
import { useIntl } from 'react-intl'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return (
    <Box sx={{ height: '100%' }}>
      {value === index && <Box sx={{ p: 3, height: '87vh' }}>{children}</Box>}
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Equipments() {
  const [value, setValue] = useState(0)
  const intl = useIntl()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box>
      <Box
        sx={{
          marginTop: '5px',
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="rooms-tabs"
          sx={{
            marginLeft: '54px',
            marginBottom: '-22px',
          }}
        >
          <Tab
            label={intl.formatMessage({ id: 'medicalEquipments' })}
            {...a11yProps(0)}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '5px 5px 0px 0px',
              marginRight: '8px',
            }}
          />
          <Tab
            label={intl.formatMessage({ id: 'itEquipments' })}
            {...a11yProps(1)}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '5px 5px 0px 0px',
              marginRight: '8px',
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <MedicalEquipment />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ItEquipment />
      </TabPanel>
    </Box>
  )
}
