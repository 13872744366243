import { SelectInput, TextInput, EcSwitchInput } from '@components/inputs'
import { yupResolver } from '@hookform/resolvers/yup'
import { Divider, Grid } from '@mui/material'
import { useSelector } from '@state/store'
import { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { addPrepRoomSchema } from '@utils/schemas'
import {
  CreatePrepRoomDto,
  PrepRoom,
  RoomType,
  UpdatePrepRoomDto,
} from '@services/api'
import { SpaceVertical } from '@utils/Spacing'
import { Button } from '@components/buttons'
import { useDispatch } from 'react-redux'
import { createPrepRoom, updatePrepRoom } from '../../../state/thunks/roomThunk'

type AddPrepRoomFormProps = {
  row?: PrepRoom
  onClose?: () => void
  onCancelButtonPress?: (
    ev: MouseEventHandler<HTMLButtonElement> | undefined,
  ) => void
}

export function PrepRoomForm({ row, onClose }: AddPrepRoomFormProps) {
  const dispatch = useDispatch()

  //States
  const [selectedSite, setSelectedSite] = useState<number | null>(null)

  // Form control
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<PrepRoom>({
    resolver: yupResolver(addPrepRoomSchema),
    defaultValues: {
      ...row,
    },
  })

  const onSubmit = (values: PrepRoom) => {
    if (!selectedSite) {
      return
    }
    if (row) {
      const updatePrepRoomDto: UpdatePrepRoomDto = {
        code: values.code,
        label: values.label,
        examRoomId: values.examRoomId,
        isWheelchairCompatible: values.isWheelchairCompatible,
        siteId: selectedSite,
      }
      dispatch(updatePrepRoom({ id: row.id, body: updatePrepRoomDto }))
    } else {
      const createPrepRoomDto: CreatePrepRoomDto = {
        code: values.code,
        label: values.label,
        examRoomId: values.examRoomId,
        isWheelchairCompatible: values.isWheelchairCompatible || false,
        siteId: selectedSite,
      } as CreatePrepRoomDto
      dispatch(createPrepRoom(createPrepRoomDto))
    }
    if (onClose) onClose()
  }

  const handleCancelPress = () => {
    if (onClose) {
      onClose()
    }
    // Closes the update form - Datagrid didnt provide a prop function to close form ()
    const el = (document as Document).querySelector(
      `.MuiDataGrid-row[data-id="${row?.id}"] .MuiIconButton-root.MuiIconButton-sizeSmall`,
    ) as any
    if (el) {
      el.click()
    }
  }

  // Reducers
  const { sites, rooms } = useSelector(({ site, room }) => ({
    sites: site.sites.datas,
    rooms: room.rooms,
  }))
  // Map all sites
  const sitesOptions = useMemo(
    () =>
      sites.map((site) => ({
        value: site.id as number,
        label: site.label,
      })),
    [sites],
  )
  useEffect(() => {
    setSelectedSite(row?.siteId || null)
  }, [row])

  const examRoomsOptions = useMemo(
    () =>
      rooms.reduce(
        (acc, _examRoom) => {
          if (
            _examRoom.type === RoomType.ExamRoom &&
            _examRoom.siteId === selectedSite
          ) {
            acc.push({
              label: _examRoom.label,
              value: _examRoom.id,
            })
          }
          return acc
        },
        [] as {
          label: string
          value: number
        }[],
      ),
    [rooms, selectedSite],
  )

  const _handleSiteChange = (ev) => {
    setSelectedSite(ev.target.value)
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: 2,
        borderRadius: 5,
      }}
    >
      <Grid item xs={12} xl={6}>
        <SelectInput
          name="siteId"
          variant="outlined"
          value={selectedSite}
          options={sitesOptions}
          onChange={_handleSiteChange}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <SelectInput
          name="examRoomId"
          variant="outlined"
          options={examRoomsOptions}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput
          variant="outlined"
          name="code"
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput
          variant="outlined"
          name="label"
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          variant="outlined"
          name="locationInstructions"
          errors={errors}
          control={control}
        />
      </Grid>

      <Grid item xs={12}>
        <SpaceVertical size={10} />
        <Divider variant="middle" />
        <SpaceVertical size={10} />
      </Grid>

      <Grid item xs={2}>
        {/** TODO change component? */}
        <EcSwitchInput control={control} input="wheelchairCompatible" />
      </Grid>

      <Grid item xs={12}>
        <SpaceVertical size={10} />
        <Divider variant="middle" />
        <SpaceVertical size={10} />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-end">
          {onClose && (
            <Grid item xs={2}>
              <Button
                onClick={handleCancelPress}
                color="secondary"
                text="cancel"
                textColor="white"
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <Button
              onClick={handleSubmit(onSubmit)}
              color="primary"
              text="save"
              textColor="white"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
