import { makeStyles } from '@mui/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Icon,
  Tooltip,
} from '@mui/material'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

type EhBasicTableProps = {
  size?: any
  align?: any
  columns: Array<{ name: string; field: string; columnAlign?: string }>
  datas: Array<any>
  actions?: Array<{
    icon: string
    text: string
    onClick: (row?: object) => void
  }>
}

export function EhBasicTable({
  size = 'small',
  align = 'center',
  columns,
  datas,
  actions: action,
  ...resProps
}: EhBasicTableProps) {
  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <Table
        {...resProps}
        className={classes.table}
        aria-label="simple table"
        size={size}
      >
        <TableHead>
          <TableRow>
            {columns.map((value, i) => {
              return (
                <TableCell
                  style={{ fontSize: 16 }}
                  key={i}
                  align={value.columnAlign || align}
                >
                  {value.name}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {datas.map((row, i) => (
            <TableRow key={i}>
              {columns.map((value, j) => {
                return value.field === 'action' ? (
                  <Box mt={1.5} display="flex" alignItems="center" key={j}>
                    {action?.map((action, k) => {
                      return (
                        <Tooltip title={action.text} key={k}>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            style={{ marginRight: 5, marginLeft: 15 }}
                            onClick={() => {
                              action.onClick(row)
                            }}
                          >
                            <Icon className={action.icon} fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )
                    })}
                  </Box>
                ) : (
                  <TableCell key={i} align={value.columnAlign || align}>
                    {row[value.field]}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
