import {
  ExamService,
  UpdateExamDto,
  CreateExamDto,
  UpdateExamTypeDto,
  CreateExamTypeDto,
} from '@services/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'
import { CCAMDto, FindManyCCAMQuery, NGAPDto } from '@services/dtos'
import { CCAMService } from '@services/CCAMService'

export const findAll = createAsyncThunk(
  Actions.PROCEDURE_FIND_ALL,
  async (params: { search?: string }, { rejectWithValue }) => {
    try {
      return await ExamService.findAll(params)
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const updateExam = createAsyncThunk(
  Actions.PROCEDURE_UPDATE,
  async (
    { id, body }: { id: number; body: UpdateExamDto },
    { rejectWithValue },
  ) => {
    try {
      return await ExamService.update({ id, body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const createExam = createAsyncThunk(
  Actions.PROCEDURE_CREATE,
  async (body: CreateExamDto, { rejectWithValue }) => {
    try {
      return await ExamService.create({ body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const deleteExam = createAsyncThunk(
  Actions.PROCEURE_DELETE,
  async (ids: number | number[], { rejectWithValue }) => {
    try {
      return await ExamService.remove({ id: ids })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const findAllType = createAsyncThunk(
  Actions.PROCEDURE_TYPE_FIND_ALL,
  async (_, { rejectWithValue }) => {
    try {
      return await ExamService.findAllTypes()
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const updateType = createAsyncThunk(
  Actions.PROCEDURE_TYPE_UPDATE,
  (
    { id, body }: { id: number; body: UpdateExamTypeDto },
    { rejectWithValue },
  ) => {
    try {
      return ExamService.updateType({ id, body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const createType = createAsyncThunk(
  Actions.PROCEDURE_TYPE_CREATE,
  async (body: CreateExamTypeDto, { rejectWithValue }) => {
    try {
      return await ExamService.createType({ body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const deleteType = createAsyncThunk(
  Actions.PROCEDURE_DELETE_TYPE,
  (id: number | number[], { rejectWithValue }) => {
    try {
      return ExamService.removeType({ id })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const findInstructions = createAsyncThunk(
  Actions.INSTRUCTION_FIND_ALL,
  (_, { rejectWithValue }) => {
    try {
      return ExamService.findAllInstructions()
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const findCcam = createAsyncThunk<CCAMDto[], FindManyCCAMQuery>(
  Actions.CCAM_FIND_ALL,
  async (query, { rejectWithValue }) => {
    try {
      const ccamDto: CCAMDto[] = []
      for (const code of query.codes) {
        const res = await CCAMService.get({ code, examDate: query.examDate })
        if (res?.length) {
          ccamDto.push(res[0])
        }
      }
      return ccamDto
    } catch (err) {
      console.log('ERROR!')
      rejectWithValue(err)
      throw err
    }
  },
)

export const findNgap = createAsyncThunk<NGAPDto[], FindManyCCAMQuery>(
  Actions.NGAP_FIND,
  async (query, { rejectWithValue }) => {
    try {
      const ccamDto: NGAPDto[] = []
      for (const code of query.codes) {
        const res = await CCAMService.getNgap({
          code,
          examDate: query.examDate,
        })
        if (res?.length) {
          ccamDto.push(res[0])
        }
      }
      return ccamDto
    } catch (err) {
      console.log('ERROR!')
      rejectWithValue(err)
      throw err
    }
  },
)
