import { AccordionDetails, Divider, Grid, Checkbox, FormControlLabel,FormControl, FormLabel, RadioGroup, TextField, Radio } from '@mui/material'
import * as Yup from 'yup'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CashRegisterMovSchema } from '@utils/schemas'
import { MovementTypeEnum } from '@utils/schemas'

type patientInformationProps = {
  updatable: boolean
}

type Values = Yup.InferType<typeof CashRegisterMovSchema>

export default function PatientInformation({
  updatable,
}: patientInformationProps) {
  // const intl = useIntl()
  const {
    formState: { errors },
    control,
  } = useForm<Values>({
    resolver: yupResolver(CashRegisterMovSchema),
  })

  return (
    <div>
      <Divider variant="fullWidth" sx={{ marginBottom: 3 }} >Géneral</Divider>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Code de la caisse"
              name="CashRegisterCode"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          
          <Grid item xs={12} lg={6}>
            <TextField
              label="Libellé de la caisse"
              name="CashRegisterLabel"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item style={{ marginTop: 8 }} xs={6}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Type de mouvement</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={MovementTypeEnum.IN}
                name="MovementType"
                row
              >
                {/* MovementTypeEnum  */}
                <FormControlLabel value={MovementTypeEnum.IN} control={<Radio />} label="Entré" />
                <FormControlLabel value={MovementTypeEnum.OUT} control={<Radio />} label="Sortie" />
                <FormControlLabel value={MovementTypeEnum.BOTH} control={<Radio />} label="Les 2" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Divider variant="fullWidth" sx={{ marginTop: 3, }} />
          <Grid item xs={6}>
            <FormControlLabel name='generationAccoutantWrite' control={<Checkbox />} label="Générer les écritures comptables" />
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider variant="fullWidth" sx={{ marginTop: 3, }} />
    </div>
  )
}
