import { useSelector } from '@state/store'
import { Navigate, Outlet } from 'react-router-dom'

export default function AuthLayout() {
  const isAuth = useSelector(({ auth }) => auth.isAuth)
  if (isAuth) return <Navigate to={{ pathname: '/' }} />
  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <Outlet />
    </main>
  )
}
