import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
} from '@mui/material'
import { Button } from '@components/buttons'
import { TextInput } from '@components/inputs'
import { yupResolver } from '@hookform/resolvers/yup'
import { phoneCodeSchema } from '@utils/schemas'
import { Text } from '../texts'
import { verifyCode } from '@state/thunks/authThunk'
import { AppDispatch } from '@state/store'

type ConfirmPhoneDialogProps = {
  onClose: () => void
  open: boolean
}

type Values = {
  code: string
}
export function ConfirmPhoneDialog({ onClose, open }: ConfirmPhoneDialogProps) {
  const dispatch = useDispatch<AppDispatch>()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Values>({
    resolver: yupResolver(phoneCodeSchema),
  })

  const onSubmit = (values: Values) => {
    dispatch(verifyCode({ verificationCode: values.code })).then(() =>
      onClose(),
    )
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
    >
      <DialogContent>
        <Box px={5} py={3} textAlign="center">
          <Text text={'Enter your verification code'} />
          {/* <Text text={`We have sent a message to phone number ending in ******${}`} /> */}
        </Box>
        <Box px={5} py={2}>
          <TextInput control={control} name="code" errors={errors} />
        </Box>
      </DialogContent>
      <Divider />

      <DialogActions>
        <Grid container justifyContent="space-around">
          <Button
            format
            text="submit"
            onClick={handleSubmit(onSubmit)}
            color="primary"
          />
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
