import Chip from '@mui/material/Chip'
import { GridCellParams } from '@mui/x-data-grid-pro'
import { colors } from '@utils/constants'

export const ColoredChipCell = ({ value }: GridCellParams) => {
  const cellColor = colors.find((c) => c.color === value)
  return (
    <Chip
      size="small"
      label={value?.toString()}
      variant="outlined"
      sx={{
        background: `${cellColor?.hex}`,
        color: 'white',
      }}
    />
  )
}
