import { useForm } from 'react-hook-form'

import { Grid } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { ClientDto } from '@services/api'
import { addClientSchema } from '@utils/schemas'
import { Button } from '@components/buttons'
import { TextInput } from '@components/inputs'

type ClientFormProps = {
  row?: ClientDto
  onClose?: () => void
}
export function ClientForm({ row, onClose }: ClientFormProps) {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ClientDto>({
    resolver: yupResolver(addClientSchema),
    defaultValues: {
      ...row,
    },
  })
  const onSubmit = () => {
    // TODO: implement
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: 2,
        borderRadius: 5,
      }}
    >
      <Grid item xs={12} xl={6}>
        <TextInput name="Name" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="finessNumber" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="streetAddress" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="postalCode" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="city" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="country" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="email" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6} container alignItems="center">
        <TextInput name="phoneNumber" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="fax" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={2}>
            <Button
              onClick={onClose}
              color="secondary"
              text="Cancel"
              textColor="white"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={handleSubmit(onSubmit)}
              color="primary"
              text="Save"
              textColor="white"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
