import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ExamDto, ExamTypeDto, Instruction } from '@services/api'
import { CCAMService } from '@services/CCAMService'
import {
  CCAMDto,
  FindExamFromBillingQuery,
  NGAPDto,
  SearchCCAMQuery,
  SearchNGAPQuery,
} from '@services/dtos'
import {
  createExam,
  createType,
  deleteExam,
  deleteType,
  findAll,
  findAllType,
  findCcam,
  findInstructions,
  updateExam,
  updateType,
} from '@state/thunks/procedureThunk'

type procedureState = {
  exams: {
    totalCount: number
    datas: ExamDto[]
  }
  examTypes: ExamTypeDto[]
  instructions: Instruction[]
  ccam: CCAMDto[]
  ngap: NGAPDto[]
}

const initialState: procedureState = {
  exams: {
    totalCount: 0,
    datas: [],
  },
  examTypes: [],
  instructions: [],
  ccam: [],
  ngap: [],
}

export const doFindExamsByCCAM = createAsyncThunk<
  ExamDto[],
  FindExamFromBillingQuery
>('procedure/findExamsByCCAM', async (query) => {
  const response = await CCAMService.findExamsFromBilling(query)
  return response
})

export const doSearchCCAM = createAsyncThunk<CCAMDto[], SearchCCAMQuery>(
  'procedure/searchCcam',
  async (query) => {
    const response = await CCAMService.searchCcam(query)
    return response
  },
)

export const doSearchNGAP = createAsyncThunk<NGAPDto[], SearchNGAPQuery>(
  'procedure/searchNgap',
  async (query) => {
    const response = await CCAMService.searchNgap(query)
    return response
  },
)

const procedureSlice = createSlice({
  name: 'procedure',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(findAll.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.exams = payload
      })
      .addCase(
        findAllType.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.examTypes = payload
        },
      )
      .addCase(
        createType.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.examTypes = [...state.examTypes, payload]
        },
      )
      .addCase(
        updateType.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          const index = state.examTypes.findIndex(
            (_examType) => _examType.id === payload.id,
          )

          if (index > -1) {
            state.examTypes[index] = payload
          }
        },
      )
      .addCase(
        deleteType.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.examTypes = state.examTypes.filter(
            (_type) => !payload.includes(_type.id),
          )
        },
      )
      .addCase(
        createExam.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.exams.datas = [...state.exams.datas, payload]
        },
      )
      .addCase(
        deleteExam.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.exams.datas = state.exams.datas.filter(
            (_exam) => !payload.includes(_exam.id),
          )
        },
      )
      .addCase(
        updateExam.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          const index = state.exams.datas.findIndex(
            (_exam) => _exam.id === payload.id,
          )
          console.log({ index, payload })

          if (index !== -1) {
            state.exams.datas[index] = payload
          }
        },
      )
      .addCase(
        findInstructions.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.instructions = payload
        },
      )
      .addCase(findCcam.fulfilled, (state, { payload }) => {
        state.ccam = payload
      }),
})

// eslint-disable-next-line no-empty-pattern
export const {} = procedureSlice.actions

export default procedureSlice.reducer
