import React from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import StatsOtherGraphPatientEventModule from './graph/StatsOtherGraphPatientEventModule'
import { StatsPatientEventAccount, StatsDeviceTypeBreakdown } from './graph/StatsTopStatsExamTypePie'


export default function StatsDashboard() {
  return (
    <div style={{ display: 'flex', paddingBottom: 25, paddingTop: 25, paddingRight: 16 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'block', }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Card sx={{ height: 400, borderRadius: 3, }}>
                  <CardContent>
                    <StatsOtherGraphPatientEventModule/>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card sx={{ height: 400, borderRadius: 3, }}>
                  <CardContent>
                    <Typography variant="h5">StatsDeviceTypeBreakdown</Typography>
                    <br />
                    <Typography variant="body2">
                      <StatsDeviceTypeBreakdown/>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card sx={{ height: 400, borderRadius: 3, }}>
                  <CardContent>
                    <Typography variant="h5">StatsPatientEventAccount</Typography>
                    <br />
                    <Typography variant="body2">
                      <StatsPatientEventAccount/>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
