import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Container, Grid, Paper } from '@mui/material'
import { SimpleTable } from '@components/tables'
import { GridColumns } from '@mui/x-data-grid-pro'
import { findAll as findAllSites } from '@state/thunks/siteThunk'
import { Actions } from '@state/actions'
import { removeSites } from '../../../state/reducers/siteReducer'
import { useSelector } from '@state/store'
import { findAllQuestions } from '@state/thunks/questionThunk'

export default function Questions() {
  const dispatch = useDispatch()

  const { loading, questions } = useSelector(({ question, loading }) => ({
    questions: question.questions,
    loading: loading[Actions.ROLE_FIND_ALL] || loading[Actions.ROLE_UPDATE],
  }))

  const columns: GridColumns = [
    { field: 'text', headerName: '', flex: 1 },
  ]

  const onRefresh = useCallback(() => {
    dispatch(findAllQuestions(null))
    dispatch(findAllSites())
  }, [dispatch])

  useEffect(() => {
    onRefresh()
    return () => {
      dispatch(removeSites())
    }
  }, [dispatch, onRefresh])

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 30px' }}>
      <Grid container spacing={4} style={{ height: '90vh', marginTop: '25px' }}>
        <Grid item container>
          <Paper elevation={2} style={{ width: '100%', borderRadius: 25 }}>
            <SimpleTable
              rows={questions}
              columns={columns}
              loading={loading}
              simpleToolBarProps={{
                title: 'questions',
                icon: 'fab fa-wpforms',
                
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
