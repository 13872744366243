import { Grid } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
// import { useSelector } from '@state/store'
// import { Actions } from '@state/actions'

export default function Logs() {
  const dispatch = useDispatch()
  // const { datas, loading, totalCount } = useSelector(({ log, loading }) => ({
  //   datas: log.logs.datas,
  //   totalCount: log.logs.totalCount,
  //   loading: loading[Actions.LOG_FIND_ALL],
  // }))
  // const columns: ColumnType<LogDto>[] = [
  //   { name: 'dateTime', title: 'Date', getCellValue: (row) => row.createdAt },
  //   { name: 'action', title: 'Action' },
  //   { name: 'role', title: 'User role', getCellValue: ({ role }) => role },
  // ]
  const onRefresh = useCallback(() => {
    dispatch(findAllLog())
  }, [dispatch])

  useEffect(() => {
    onRefresh()
    return () => {
      dispatch(removeLogs())
    }
  }, [dispatch, onRefresh])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid item>{/* TODO add new table */}</Grid>
      </Grid>
    </Grid>
  )
}
function findAllLog(): any {
  throw new Error('Function not implemented.')
}

function removeLogs(): any {
  throw new Error('Function not implemented.')
}
