import React, { useEffect, useMemo, useState } from 'react'
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  Select,
  MenuItem,
  TextField,
} from '@mui/material'
import {
  DataGridPro,
  GridColumns,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment'
import 'moment/locale/fr'

// Set moment to French locale
moment.locale('fr')

// Types
interface ExternalIncomeDTO {
  id: number
  patientFirstName: string
  patientLastName: string
  acte: string
  date: string
  status: 'pending' | 'paid'
  paymentDate?: string
  paymentReference?: string
  bankAccount: string
}

// Mock data
const mockData: ExternalIncomeDTO[] = [
  {
    id: 1,
    patientFirstName: 'John',
    patientLastName: 'Doe',
    acte: 'Chimiothérapie',
    date: '2024-11-01',
    status: 'paid',
    paymentDate: '2024-11-03',
    paymentReference: 'VIR123456',
    bankAccount: 'BNP-001',
  },
  {
    id: 2,
    patientFirstName: 'Jane',
    patientLastName: 'Smith',
    acte: 'Chimiothérapie',
    date: '2024-11-02',
    status: 'pending',
    bankAccount: 'SG-002',
  },
  {
    id: 3,
    patientFirstName: 'Alice',
    patientLastName: 'Johnson',
    acte: 'Chimiothérapie',
    date: '2024-11-01',
    status: 'paid',
    paymentDate: '2024-11-04',
    paymentReference: 'VIR789012',
    bankAccount: 'CA-003',
  },
  {
    id: 4,
    patientFirstName: 'Bob',
    patientLastName: 'Brown',
    acte: 'Radiologie',
    date: '2024-11-03',
    status: 'paid',
    paymentDate: '2024-11-05',
    paymentReference: 'VIR345678',
    bankAccount: 'BNP-001',
  },
  {
    id: 5,
    patientFirstName: 'Charlie',
    patientLastName: 'Davis',
    acte: 'Radiologie',
    date: '2024-11-04',
    status: 'paid',
    paymentDate: '2024-11-06',
    paymentReference: 'VIR901234',
    bankAccount: 'SG-002',
  },
  {
    id: 6,
    patientFirstName: 'David',
    patientLastName: 'Lee',
    acte: 'Radiologie',
    date: '2024-11-05',
    status: 'paid',
    paymentDate: '2024-11-07',
    paymentReference: 'VIR567890',
    bankAccount: 'CA-003',
  },
]

// Mock bank accounts
const mockBankAccounts = [
  { id: 'BNP-001', label: 'BNP Paribas Principal' },
  { id: 'SG-002', label: 'Société Générale Pro' },
  { id: 'CA-003', label: 'Crédit Agricole Business' },
]

type DateRangeType = 'day' | 'week' | 'month'

const dateRangeLabels = {
  day: 'Jour',
  week: 'Semaine',
  month: 'Mois',
}

// Toolbar component
const ExternalIncomeToolbar = ({
  onImportCsv,
  dateRange,
  onDateRangeChange,
  selectedDate,
  onSelectedDateChange,
}: {
  onImportCsv: () => void
  dateRange: DateRangeType
  onDateRangeChange: (range: DateRangeType) => void
  selectedDate: Moment
  onSelectedDateChange: (date: Moment) => void
}) => {
  return (
    <div className="flex justify-between items-center p-4">
      <div className="flex items-center gap-4">
        <h2 className="text-xl font-bold">Revenus Externes</h2>
        <div className="flex items-center gap-2">
          <Select
            value={dateRange}
            size="small"
            onChange={(e) => onDateRangeChange(e.target.value as DateRangeType)}
          >
            <MenuItem value="day">{dateRangeLabels.day}</MenuItem>
            <MenuItem value="week">{dateRangeLabels.week}</MenuItem>
            <MenuItem value="month">{dateRangeLabels.month}</MenuItem>
          </Select>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={selectedDate}
              onChange={(newDate) => {
                if (newDate) onSelectedDateChange(newDate)
              }}
              slotProps={{ textField: { size: 'small' } }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <button
        className="bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-2 rounded-md text-sm transition-colors duration-200 flex items-center justify-center gap-2"
        onClick={onImportCsv}
      >
        <UploadFileIcon className="mr-2" />
        Importer CSV
      </button>
    </div>
  )
}

const statusLabels = {
  pending: 'En attente',
  paid: 'Payé',
}

const ExternalIncomeTable = () => {
  const [incomes, setIncomes] = useState<ExternalIncomeDTO[]>(mockData)
  const [filters, setFilters] = useState({
    page: 0,
    limit: 10,
  })
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false)
  const [dateRange, setDateRange] = useState<DateRangeType>('month')
  const [selectedDate, setSelectedDate] = useState<Moment>(moment())

  // Filter incomes based on selected date range
  const filteredIncomes = useMemo(() => {
    return incomes.filter((income) => {
      const incomeDate = moment(income.date)
      switch (dateRange) {
        case 'day':
          return incomeDate.isSame(selectedDate, 'day')
        case 'week':
          return incomeDate.isSame(selectedDate, 'week')
        case 'month':
          return incomeDate.isSame(selectedDate, 'month')
        default:
          return true
      }
    })
  }, [incomes, dateRange, selectedDate])

  const handleUpdateIncome = (
    id: number,
    updates: Partial<ExternalIncomeDTO>,
  ) => {
    setIncomes((prevIncomes) =>
      prevIncomes.map((income) =>
        income.id === id ? { ...income, ...updates } : income,
      ),
    )
  }

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        width: 70,
      },
      {
        field: 'patientFirstName',
        headerName: 'Prénom',
        width: 130,
      },
      {
        field: 'patientLastName',
        headerName: 'Nom',
        width: 130,
      },
      {
        field: 'acte',
        headerName: 'Acte',
        width: 130,
      },
      {
        field: 'date',
        headerName: 'Date',
        width: 130,
        renderCell: (params) => moment(params.value).format('DD/MM/YYYY'),
      },
      {
        field: 'status',
        headerName: 'Statut',
        width: 130,
        editable: true,
        renderCell: (params: GridRenderCellParams) => (
          <Select
            value={params.value}
            size="small"
            onChange={(e) =>
              handleUpdateIncome(params.row.id, {
                status: e.target.value as 'pending' | 'paid',
              })
            }
            className={`${
              params.value === 'paid'
                ? 'bg-green-100 text-green-800'
                : 'bg-yellow-100 text-yellow-800'
            }`}
          >
            <MenuItem value="pending">{statusLabels.pending}</MenuItem>
            <MenuItem value="paid">{statusLabels.paid}</MenuItem>
          </Select>
        ),
      },
      {
        field: 'paymentDate',
        headerName: 'Date de paiement',
        width: 180,
        renderCell: (params) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={params.value ? moment(params.value) : null}
              onChange={(newDate) => {
                handleUpdateIncome(params.row.id, {
                  paymentDate: newDate
                    ? newDate.format('YYYY-MM-DD')
                    : undefined,
                })
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  placeholder: 'Sélectionner date',
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
      {
        field: 'paymentReference',
        headerName: 'Référence',
        width: 150,
        renderCell: (params) => (
          <TextField
            size="small"
            value={params.value || ''}
            onChange={(e) =>
              handleUpdateIncome(params.row.id, {
                paymentReference: e.target.value,
              })
            }
            placeholder="Réf. virement"
            className="bg-white"
          />
        ),
      },
      {
        field: 'bankAccount',
        headerName: 'Compte bancaire',
        width: 200,
        renderCell: (params) => (
          <Select
            value={params.value}
            size="small"
            fullWidth
            onChange={(e) =>
              handleUpdateIncome(params.row.id, { bankAccount: e.target.value })
            }
          >
            {mockBankAccounts.map((account) => (
              <MenuItem key={account.id} value={account.id}>
                {account.label}
              </MenuItem>
            ))}
          </Select>
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        sortable: false,
        renderCell: (params) => (
          <IconButton
            onClick={() => handleDelete(params.row.id)}
            size="small"
            title="Supprimer"
          >
            <DeleteIcon color="error" />
          </IconButton>
        ),
      },
    ],
    [],
  )

  const handleDelete = (id: number) => {
    setIncomes(incomes.filter((income) => income.id !== id))
  }

  const handleImportCsv = () => {
    setIsImportDialogOpen(true)
  }

  return (
    <div className="flex flex-1 h-[calc(100vh-100px)]">
      <DataGridPro
        sx={{
          display: 'flex',
          flex: 1,
          padding: '16px',
          backgroundColor: 'white',
          borderRadius: '20px',
        }}
        rows={filteredIncomes}
        columns={columns}
        paginationMode="server"
        pageSize={filters.limit}
        rowCount={filteredIncomes.length}
        initialState={{
          pinnedColumns: { right: ['actions'] },
        }}
        onPageChange={(page) => {
          setFilters({
            ...filters,
            page,
          })
        }}
        components={{
          Toolbar: ExternalIncomeToolbar,
        }}
        componentsProps={{
          toolbar: {
            onImportCsv: handleImportCsv,
            dateRange,
            onDateRangeChange: setDateRange,
            selectedDate,
            onSelectedDateChange: setSelectedDate,
          },
        }}
        pagination
      />

      {/* Import Dialog */}
      <Dialog
        open={isImportDialogOpen}
        onClose={() => setIsImportDialogOpen(false)}
      >
        <DialogContent>
          <div className="p-6 min-w-[400px]">
            <h3 className="text-xl font-semibold mb-6">Importer CSV</h3>
            <div className="flex flex-col items-center justify-center p-8 border-2 border-dashed border-gray-300 rounded-lg bg-gray-50">
              <div className="relative w-full">
                <input
                  type="file"
                  accept=".csv"
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  onChange={(e) => {
                    console.log('File selected:', e.target.files?.[0]?.name)
                    setTimeout(() => {
                      setIsImportDialogOpen(false)
                    }, 1000)
                  }}
                />
                <button
                  className="w-full py-3 px-6 rounded-lg text-base font-semibold
            bg-[#6366F1] text-white hover:bg-[#5558E3] transition-colors
            flex items-center justify-center gap-2"
                >
                  <UploadFileIcon />
                  Parcourir
                </button>
              </div>
              <p className="text-gray-500 mt-4 text-sm">Format accepté: .csv</p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ExternalIncomeTable
