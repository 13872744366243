import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { ExamDto } from '@services/api'
import React, { useState } from 'react'
import { Transition } from '../..//containers/app/Worklists/MedicalOrderDialog'
import { ExamSelectionTable } from '../../containers/app/procedure/ExamSelectionTable'

type Props = {
  excludedExams: ExamDto[]
  isOpen: boolean
  onClose: () => void
  handleConfirm: (examIds: number[]) => void
}

export const ExamSelectorDialog: React.FC<Props> = ({
  excludedExams,
  isOpen,
  onClose,
  handleConfirm,
}) => {
  const [selectedExamIds, setselectedExamIds] = useState<number[]>([])

  const onConfirm = () => {
    handleConfirm(selectedExamIds)
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      TransitionComponent={Transition}
    >
      <DialogTitle>Exam selection</DialogTitle>
      <ExamSelectionTable
        selectedRows={selectedExamIds}
        onSelectionChange={(ids) => {
          setselectedExamIds(ids as number[])
        }}
        excludedExams={excludedExams}
      />
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onConfirm()
          }}
        >
          Confirmer
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ExamSelectorDialog
