import React, { useState, useEffect, useRef } from 'react'
import { debounce } from 'lodash'
import { Search } from '@mui/icons-material'
import { BanService } from '@services/BanServices'

interface CustomAddressAutocompleteProps {
  value: string
  onChange: (value: string) => void
  defaultValue?: string
  defaultSearchText?: string
  placeholder?: string
  disabled?: boolean
  error?: boolean
  helperText?: string
  className?: string
}

export const CustomAddressAutocomplete: React.FC<CustomAddressAutocompleteProps> =
  ({
    onChange,
    defaultValue = '',
    defaultSearchText = '',
    placeholder = 'Search for an address...',
    disabled = false,
    error = false,
    helperText = '',
    className = '',
  }) => {
    const [inputValue, setInputValue] = useState(defaultSearchText)
    const [suggestions, setSuggestions] = useState<string[]>([])
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [activeIndex, setActiveIndex] = useState(-1)

    const wrapperRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      if (defaultValue) onChange(defaultValue)
      if (defaultSearchText) {
        setInputValue(defaultSearchText)
        handleSearch(defaultSearchText)
      }
    }, [defaultValue, defaultSearchText])

    const debouncedSearch = debounce(async (searchTerm: string) => {
      if (searchTerm.length < 3) {
        setSuggestions([])
        setLoading(false)
        setIsOpen(false)
        return
      }

      setLoading(true)
      try {
        const banService = new BanService()
        const results = await banService.searchFullAddress(searchTerm)
        setSuggestions(results.map((result) => result.place))
        setIsOpen(true)
      } catch (error) {
        console.error('Error fetching addresses:', error)
        setSuggestions([])
      } finally {
        setLoading(false)
      }
    }, 300)

    const handleSearch = (searchTerm: string) => {
      setInputValue(searchTerm)
      debouncedSearch(searchTerm)
    }

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          wrapperRef.current &&
          !wrapperRef.current.contains(event.target as Node)
        ) {
          setIsOpen(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (!isOpen) return

      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault()
          setActiveIndex((prev) =>
            prev < suggestions.length - 1 ? prev + 1 : prev,
          )
          break
        case 'ArrowUp':
          e.preventDefault()
          setActiveIndex((prev) => (prev > 0 ? prev - 1 : -1))
          break
        case 'Enter':
          e.preventDefault()
          if (activeIndex >= 0) {
            const selected = suggestions[activeIndex]
            setInputValue(selected)
            onChange(selected)
            setIsOpen(false)
            setActiveIndex(-1)
          }
          break
        case 'Escape':
          setIsOpen(false)
          setActiveIndex(-1)
          break
      }
    }

    const handleSuggestionClick = (suggestion: string) => {
      setInputValue(suggestion)
      onChange(suggestion)
      setIsOpen(false)
      setActiveIndex(-1)
    }

    return (
      <div ref={wrapperRef} className={`relative ${className} w-full`}>
        <div className="relative group">
          <label className="text-sm text-gray-500">Address</label>
          <input
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={(e) => handleSearch(e.target.value)}
            onFocus={() => inputValue.length >= 3 && setIsOpen(true)}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            placeholder={placeholder}
            className={`
            w-full px-4 py-3 pr-12 
            border rounded-lg outline-none transition-all duration-200
            text-gray-800 placeholder-gray-400
            ${
      error ? 'border-red-500' : 'border-gray-200 hover:border-gray-300'
      }
            ${disabled ? 'bg-gray-50 text-gray-500' : 'bg-white'}
            focus:border-blue-500 focus:ring-2 focus:ring-blue-100
            text-base
          `}
          />
          <div className="absolute right-3 top-1/2  pointer-events-none">
            {loading ? (
              <div className="w-5 h-5 border-2 border-blue-500 rounded-full border-t-transparent animate-spin" />
            ) : (
              <Search
                className={`w-5 h-5 ${
                  error ? 'text-red-500' : 'text-gray-400'
                }`}
              />
            )}
          </div>
        </div>

        {helperText && (
          <p
            className={`mt-1.5 text-sm ${
              error ? 'text-red-500' : 'text-gray-500'
            }`}
          >
            {helperText}
          </p>
        )}

        {isOpen && suggestions.length > 0 && (
          <ul
            className="
          absolute z-10 w-full mt-1 
          bg-white border border-gray-200 rounded-lg shadow-lg 
          max-h-60 overflow-auto 
          py-1
          divide-y divide-gray-50
        "
          >
            {suggestions.map((suggestion, index) => (
              <li
                key={suggestion}
                onClick={() => handleSuggestionClick(suggestion)}
                onMouseEnter={() => setActiveIndex(index)}
                className={`
                px-4 py-2.5 cursor-pointer
                text-sm text-gray-700
                transition-colors duration-150
                ${
              activeIndex === index
                ? 'bg-blue-50 text-blue-700'
                : 'hover:bg-gray-50'
              }
              `}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }

export default CustomAddressAutocomplete
