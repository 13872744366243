import { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Divider, Grid } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@components/buttons'
import { SelectInput } from '@components/inputs'
import { TextInput } from '@components/inputs'
import {
  CreateItEquipmentDto,
  ItEquipment,
  UpdateItEquipmentDto,
} from '@services/api'
import { itEquipmentType } from '@utils/constants'
import { itEquipmentSchema } from '@utils/schemas'
import { useSelector } from '@state/store'
import { findAll as findAllSites } from '@state/thunks/siteThunk'
import { SpaceVertical } from '@utils/Spacing'
import {
  createItEquipment,
  updateItEquipment,
} from '../../../state/thunks/itEquipmentThunk'

type AddEquipFormProps = {
  row?: ItEquipment
  onClose?: () => void
  onCancelButtonPress?: (
    ev: MouseEventHandler<HTMLButtonElement> | undefined,
  ) => void
}

export function ItEquipmentForm({ row, onClose }: AddEquipFormProps) {
  const dispatch = useDispatch()
  // State
  const [selectedType, setSelectedType] = useState('')

  // Submit
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ItEquipment>({
    resolver: yupResolver(itEquipmentSchema),
    defaultValues: {
      ...row,
    },
  })

  const onSubmit = (values: ItEquipment) => {
    if (row?.id) {
      const updateItEquipmentDto: UpdateItEquipmentDto = {
        ...values,
        type: selectedType,
      }
      dispatch(updateItEquipment({ id: row.id, body: updateItEquipmentDto }))
    } else {
      const createItEquipmentDto: CreateItEquipmentDto = {
        ...values,
        type: selectedType,
      }
      dispatch(createItEquipment(createItEquipmentDto))
    }
    if (onClose) {
      onClose()
    }
  }

  // Reducers
  const { sites } = useSelector(({ site }) => ({
    sites: site.sites.datas,
  }))
  // Map all sites
  const sitesOptions = useMemo(
    () =>
      sites.map((site) => ({
        value: site.id as number,
        label: site.label,
      })),
    [sites],
  )
  useEffect(() => {
    if (row) {
      setSelectedType(row?.type)
    }
  }, [row])

  useEffect(() => {
    dispatch(findAllSites())
  }, [])

  const _handleTypeChange = (ev) => {
    setSelectedType(ev.target.value)
  }

  const handleCancelPress = () => {
    if (onClose) {
      onClose()
    }
    // Closes the update form - Datagrid didnt provide a prop function to close form ()
    const el = (document as Document).querySelector(
      `.MuiDataGrid-row[data-id="${row?.id}"] .MuiIconButton-root.MuiIconButton-sizeSmall`,
    ) as any
    if (el) {
      el.click()
    }
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: 2,
        borderRadius: 5,
      }}
    >
      <Grid item xs={12}>
        <SelectInput
          name="siteId"
          control={control}
          options={sitesOptions}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectInput
          name="itEquipmentType"
          options={itEquipmentType}
          onChange={_handleTypeChange}
          value={selectedType}
        />
      </Grid>
      {selectedType && (
        <>
          <Grid item xs={12}>
            <SpaceVertical size={10} />
            <Divider variant="middle" />
            <SpaceVertical size={10} />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput name="label" control={control} errors={errors} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput name="code" control={control} errors={errors} />
          </Grid>
          <Grid item xs={12}>
            <TextInput name="ip" control={control} errors={errors} />
          </Grid>
          <Grid item xs={12}>
            <TextInput name="mac" control={control} errors={errors} />
          </Grid>
          <Grid item xs={12}>
            <TextInput name="port" control={control} errors={errors} />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name="infos"
              id="outlined-multiline-static"
              label="Infos"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              control={control}
              errors={errors}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-end">
          {onClose && (
            <Grid item xs={2}>
              <Button
                onClick={handleCancelPress}
                color="secondary"
                text="cancel"
                textColor="white"
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <Button
              onClick={handleSubmit(onSubmit)}
              color="primary"
              text="save"
              textColor="white"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
