import { AccordionDetails, Divider, Grid, Checkbox, FormControlLabel, InputLabel,FormControl, Select,MenuItem, FormHelperText, TextField } from '@mui/material'
import * as Yup from 'yup'
import {
  DateInput,
} from '../../../../components/inputs'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { BankSchema } from '@utils/schemas'

type patientInformationProps = {
  updatable: boolean
}

type Values = Yup.InferType<typeof BankSchema>

export default function PatientInformation({
  updatable,
}: patientInformationProps) {
  const {
    formState: { errors },
    control,
  } = useForm<Values>({
    resolver: yupResolver(BankSchema),
  })

  return (
    <div>
      <Divider variant="fullWidth" sx={{ marginBottom: 3 }} >Géneral</Divider>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Code"
              name="bankCode"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          
          <Grid item xs={12} lg={6}>
            <TextField
              label="Libellé"
              name="bankLabel"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider variant="fullWidth" sx={{ marginTop: 3, }} />
    </div>
  )
}
