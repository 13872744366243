import { useForm } from 'react-hook-form'

import { Grid } from '@mui/material'
import { Button } from '@components/buttons'
import { SelectInput } from '@components/inputs'

import Text from '@components/Text'
import { ClientPreferences, UpdateClientDto, Viewer } from '@services/api'

type ViewerPreferencesFormProps = {
  preferences: ClientPreferences
  onSubmit: (values: UpdateClientDto) => void
  loading: boolean
}

type Values = {
  patientViewer: Viewer
  patientViewerId: number
  doctorViewer: Viewer
  doctorViewerId: number
  radioViewer: Viewer
  radioViewerId: number
  tomoViewer: Viewer
  tomoViewerId: number
  nuclearViewer: Viewer
  nuclearViewerId: number
}

export function ViewersPreferencesForm({
  preferences,
  onSubmit,
  loading,
}: ViewerPreferencesFormProps) {
  const { viewers } = preferences
  const { handleSubmit, control } = useForm<Values>({
    defaultValues: viewers,
  })

  const beforeSubmit = (values: Values) => {
    onSubmit({
      preferences: {
        ...preferences,
        viewers: values,
      },
    })
  }

  const viewerOptions = [
    { label: 'Resolution MD', value: 'RESOLUTION' },
    { label: 'Ohif', value: 'OHIF' },
    { label: 'Myriam', value: 'MYRIAM' },
  ]

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: 2,
        borderRadius: 5,
      }}
    >
      <Grid item container alignItems="center">
        <Grid item xs={1}>
          <Text text="Patient viewer" color="textSecondary" />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            options={viewerOptions}
            name="patientViewer"
            control={control}
          />
        </Grid>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item xs={1}>
          <Text text="Doctor viewer" color="textSecondary" />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            options={viewerOptions}
            name="doctorViewer"
            control={control}
          />
        </Grid>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item xs={1}>
          <Text text="Radio viewer" color="textSecondary" />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            options={viewerOptions}
            name="radioViewer"
            control={control}
          />
        </Grid>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item xs={1}>
          <Text text="Tomo viewer" color="textSecondary" />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            options={viewerOptions}
            name="tomoViewer"
            control={control}
          />
        </Grid>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item xs={1}>
          <Text text="Nuclear viewer" color="textSecondary" />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            options={viewerOptions}
            name="nuclearViewer"
            control={control}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        justifyContent="flex-end"
        sx={{ marginTop: '2px' }}
      >
        <Grid item xs={2}>
          <Button
            text="updateSettings"
            textColor="white"
            onClick={handleSubmit(beforeSubmit)}
            color="primary"
            loading={loading}
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
