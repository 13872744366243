import { Dialog, DialogContent, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { formatSsnPrefix, formatSsn } from '@utils/ssnUtil'
import DialogTitle from '@components/dialogs/DialogTitle'
import { useState } from 'react'

export default function NirhistoryModal({ isOpen, onClose }) {
  const insHistoryEx = [
    {
      date: '2021-11-15', 
      ins: '101066322075460',
      oid: '1.2.250.1.213.1.4.8',
      status: 'OK'
    },
    {
      date: '2021-11-14',
      ins: '101066322075460',
      oid: '1.2.250.1.212.1.4.8',
      status: 'KO'
    },
  ]
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => onClose()}
        maxWidth={'lg'}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: '#E2E6F2',
          },
        }}
      >
        <DialogTitle
          title={'Historique des Ins du patient'}
          style={{
            background: '#fff',
            color: 'E2E6F2',
          }}
          onClose={() => onClose()}
        />
        <DialogContent
          style={{
            minWidth: '850px',
            display: 'flex',
            justifyContent: 'center',
            background: '#E2E6F2',
          }}
        >
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table sx={{ minWidth: 650,}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>INS</TableCell>
                  <TableCell align="right">O.i.d</TableCell>
                  <TableCell align="right">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {insHistoryEx.map((row) => (
                  <TableRow
                    key={row.ins}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {formatSsnPrefix(row.ins.slice(0, 7) )+ ' ' + formatSsn(row.ins.slice(7, 13))}
                    </TableCell>
                    <TableCell align="right">{row.oid}</TableCell>
                    <TableCell align="right">{row.status === 'OK' ? 'Actif' : 'Inactif'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  )}