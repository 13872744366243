import { useSelector } from '@state/store'

import { Container } from '@mui/material'

import HomeMember from './member/HomeMember'
import HomeSuperAdmin from './super-admin/HomeSuperAdmin'
import { AccountRoleEnum } from '@services/api'

export default function Home() {
  const account = useSelector(({ auth }) => auth.account)

  return (
    <Container maxWidth={false}>
      {account?.role === AccountRoleEnum.member && <HomeMember />}
      {account?.role === AccountRoleEnum['super-admin'] && <HomeSuperAdmin />}
    </Container>
  )
}
