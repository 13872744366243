import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getAllPrescriber, getAllTopEvent, getExamBreakdown } from '@state/thunks/statsThunk'
import { Card, CardContent, Typography, Grid, Box } from '@mui/material'

import StatsTable from './TopStats/StatsTable'
import FilterComponent from './TopStats/FilterTopStats'
import { StatsTopStatsExamTypePie } from './graph/StatsTopStatsExamTypePie'

export default function StatsDashboard() {
  const Year = new Date().getFullYear()
  const dispatch = useDispatch()
  
  const onRefresh = useCallback(async () => {
    await Promise.all([
      dispatch(getAllPrescriber()),
      dispatch(getAllTopEvent()),
      dispatch(getExamBreakdown())
    ])
  }, [dispatch, Year])
  
  useEffect(() => {
    onRefresh()
    return () => {
      // dispatch(filterChangePeriod({ startY: Year, endY: Year }))
      // dispatch(removeAllStats())
    }
  }, [dispatch, onRefresh])

  return (
    <div style={{ display: 'flex', paddingTop: 25, paddingRight: 16 }}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Box sx={{ backgroundColor: '#fff', height: '100%', borderRadius: 2, overflow: 'auto' }}>
            <Typography variant="h4" sx={{ textAlign: 'center' }}>Filter</Typography>
            <FilterComponent/>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Box sx={{ display: 'block' }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Card sx={{ height: 250, borderRadius: 3 }}>
                  <CardContent>
                    <Typography variant="h5">Exam type</Typography>
                    <Box sx={{ height: 50 }}>
                      <StatsTopStatsExamTypePie />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card sx={{ height: 250, borderRadius: 3 }}>
                  <CardContent>
                    <Typography variant="h5">Card 1</Typography>
                    <Typography variant="body2">
                      Contenu de la première carte.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card sx={{ height: 250, borderRadius: 3 }}>
                  <CardContent>
                    <Typography variant="h5">Card 1</Typography>
                    <Typography variant="body2">
                      Contenu de la première carte.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Box sx={{ paddingTop: 3 }}>
              <StatsTable/>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
