import { GridToolbarContainer } from '@mui/x-data-grid-pro'
import Text from '../../Text'
import { Icon, Box, alpha, InputBase, styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export type SimpleToolbarProps = {
  title: string
  icon: string
  search?: boolean
  onSearch?: (value: string) => void
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  border: '1px solid rgba(0, 0, 0, 0.125)',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}))

export function SimpleToolbar({ title, icon,search, onSearch }: SimpleToolbarProps) {
  const handleChange = (event: any) => {
    if (onSearch) {
      onSearch(event.target.value)
    }
  }
  return (
    <GridToolbarContainer>
      <Box pl={2} display="flex" alignItems="center" justifyContent="center">
        <Icon
          className={icon}
          style={{ marginRight: '1rem' }}
          color="primary"
        />
        <Text
          variant="h5"
          text={title}
          color="primary"
          format
          sx={{ fontWeight: 'bold' }}
        />
      </Box>
      {search && (
        <Box pl={2} display="flex" alignItems="center" justifyContent="center">
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleChange}
            />
          </Search>
        </Box>
      )}
    </GridToolbarContainer>
  )
}
