import {
  axios,
  CreateDoctorDto,
  DoctorDto,
  getConfigs,
  IRequestConfig,
} from './api'
import { FindDoctorsQuery } from './dtos'
import { PaginatedDto } from './extendedType'

export class DoctorV2Service {
  private static readonly endpoint = 'doctor'

  static findMany(query: FindDoctorsQuery): Promise<PaginatedDto<DoctorDto>> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint + '/v2',
        {},
      )
      configs.params = query
      axios(configs, resolve, reject)
    })
  }

  static create(dto: CreateDoctorDto): Promise<DoctorDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        this.endpoint,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static update(id: number, dto: CreateDoctorDto): Promise<DoctorDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        this.endpoint + '/' + id,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static remove(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        this.endpoint + '/' + id,
        {},
      )
      axios(configs, resolve, reject)
    })
  }
}
