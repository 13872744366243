import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './services/axios'
import { Provider } from 'react-redux'

import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from '@mui/material/styles'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { persistor, store } from './state/store'
import theme from './utils/theme'
import './assets/css/main.css'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterMoment as any}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </LocalizationProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
