import {
  Dialog,
  DialogContent,
  Tabs,
  Tab,
  Tooltip,
  CircularProgress,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { UpTransition } from '../animations'
import SearchOrCreatePatient from '../forms/SearchOrCreatePatient'
import { OrderDetailsComponent } from '../../containers/app/Worklists/MedicalOrderDialog'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import { doCreateEmptyOrder } from '../../state/reducers/orderReducer'
import {
  doCreateCoverege,
  setPatientDetails,
} from '../../state/reducers/patientsReducer'
import { CouvertureDto } from '../../common/interfaces'

type Props = {
  isOpen: boolean
  onClose: () => void
  patientId?: number
}
const InstantBillComponent: React.FC<Props> = ({
  isOpen,
  onClose,
  patientId,
}) => {
  const dispatch = useAppDispatch()
  const order = useAppSelector((state) => state.order.selectedOrder)
  const [selectedPatientId, setSelectedPatientId] = useState<
    number | undefined
  >(patientId)
  const [isCreatingOrder, setIsCreatingOrder] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [coverage, setCoverage] = useState<CouvertureDto | null>(null)

  useEffect(() => {
    dispatch(setPatientDetails(null))
  }, [isOpen])

  useEffect(() => {
    if (selectedPatientId) {
      setIsCreatingOrder(true)
      dispatch(doCreateEmptyOrder(selectedPatientId))
        .unwrap()
        .then((res) => {
          if (coverage && res.visitId) {
            coverage.idVisit = res.visitId
            dispatch(
              doCreateCoverege({ patientId: selectedPatientId, dto: coverage }),
            )
          }
        })
        .finally(() => {
          setIsCreatingOrder(false)
        })
    }
  }, [selectedPatientId])

  useEffect(() => {
    return () => {
      dispatch(setPatientDetails(null))
    }
  }, [])

  const handlePatientChange = (patientId?: number) => {
    setSelectedPatientId(patientId)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={UpTransition}
      fullScreen
      disableEscapeKeyDown
    >
      <button
        type="button"
        className="btn btn-primary absolute top-4 right-8 z-40"
        onClick={onClose}
      >
        <i className="fas fa-times text-3xl"></i>
      </button>

      <DialogContent>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
        >
          <Tab label="Patient" />
          <Tab
            label={
              <div className="flex items-center gap-2">
                {!order &&
                  (isCreatingOrder ? (
                    <CircularProgress size={16} />
                  ) : (
                    <i className="fas fa-lock text-gray-400" />
                  ))}
                Examens
                {!order && (
                  <Tooltip title="Veuillez sélectionner un patient d'abord">
                    <i className="fas fa-exclamation-circle text-yellow-500" />
                  </Tooltip>
                )}
              </div>
            }
            disabled={!order}
          />
        </Tabs>

        <div className="mt-4">
          {activeTab === 0 && (
            <fieldset className="border border-gray-300 p-4 rounded-lg shadow-md">
              <legend className="font-bold text-lg text-gray-700">
                Patient Section
              </legend>
              <SearchOrCreatePatient
                defaultValue={patientId}
                visitId={order?.visitId}
                onChange={handlePatientChange}
                onCoverageChange={setCoverage}
              />
            </fieldset>
          )}

          {activeTab === 1 && (
            <fieldset className="border border-gray-300 p-4 rounded-lg shadow-md">
              <legend className="font-bold text-lg text-gray-700">
                Order Details
              </legend>
              {order && <OrderDetailsComponent order={order} />}
            </fieldset>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default InstantBillComponent
