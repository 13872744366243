import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Icon,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
// import { useDispatch } from 'react-redux'
import { DateInput, SelectInput, TextInput } from '@components/inputs'
import { Button } from '@components/buttons'
import { ZipFranceInput } from '@components/inputs'

import {
  MemberDto,
  SexEnum,
  SuperAdminDto,
} from '@services/api'
import { Text } from '../texts'
import { sex } from '@utils/constants'
import { updateGeneralInfoSchema } from '@utils/schemas'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

type Values = {
  firstName: string
  lastName: string
  sex: SexEnum
  streetAddress: string
  zipCode: string
  city: string
  state: string
  country: string
  birthPlace: string
  birthDate: Date
  ssn?: string
}

type GeneralInfoFormProps = {
  profile: MemberDto | SuperAdminDto
}

export function GeneralInfoForm({ profile }: GeneralInfoFormProps) {
  const classes = useStyles()
  // const dispatch = useDispatch()

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm<Values>({
    defaultValues: profile,
    resolver: yupResolver(updateGeneralInfoSchema) as any,
  })

  const onSubmit = () => {
    // dispatch(
    //   updateGeneralInfo({ body: { ...profile, ...values }, id: profileid }),
    // )
  }

  useEffect(() => {
    // const address = getValues('address')
    // console.log(address)
    // return () => {
    //     cleanup
    // }
  }, [getValues])

  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon className="fa fa-file-alt" />
            </Grid>
            <Grid item>
              <Text text="profile" format />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <TextInput name="firstName" control={control} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextInput name="lastName" control={control} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <SelectInput
              name="sex"
              control={control}
              errors={errors}
              options={sex}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <AddressInput name="address" control={control} />
          </Grid> */}
          <Grid item xs={12} sm={3}>
            <TextInput name="street" control={control} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={3}>
            {/* <TextInput name="postalCode" control={control} errors={errors} /> */}
            <ZipFranceInput name="postalCode" control={control} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextInput name="city" control={control} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextInput name="country" control={control} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateInput name="birthDate" control={control} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput name="birthPlace" control={control} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput name="ssn" control={control} errors={errors} />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          text="submit"
          onClick={handleSubmit(onSubmit)}
          format
          color="primary"
        />
      </CardActions>
    </Card>
  )
}
