import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { setNotification } from '@state/reducers/testReducer'
import { Button } from '../../../components/buttons'
import { SelectInput, TextInput } from '../../../components/inputs'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

type EditNotificationsTemplateDialogProps = {
  field: any
  // {
  //   id: number
  //   name: string
  //   subject: string
  //   content: string
  //   version: string
  // },

  onClose: () => void
  open: boolean
}

type Value = {
  id: number
  name: string
  subject: string
  content: string
  version: string
}

export function EditAddNotificationsTemplateDialog({
  field,
  open,
  onClose,
}: EditNotificationsTemplateDialogProps) {
  const { id, name, subject, content, version } = field
  const classes = useStyles()
  const dispatch = useDispatch()
  // const { notification } = useSelector(({ test }: RootState) => ({
  //   notification: test.notifications.filter((not) => not.id === field.id),
  // }))
  const { handleSubmit, control, register } = useForm<Value>({
    defaultValues: {
      id: id,
      name: name,
      subject: subject,
      content: content,
      version: version,
    },
  })
  const onSubmit = (values: Value) => {
    if (
      values.name === '' ||
      values.subject === '' ||
      values.version === '' ||
      values.content === ''
    ) {
      alert('Thanks for feeding all fields')
    } else {
      values = { ...values, id: id }
      dispatch(setNotification(values))
      onClose()
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        {id ? `Edit ${name}` : 'Add Notification'}
      </DialogTitle>
      <DialogContent>
        <form className={classes.section} noValidate>
          <Grid container spacing={3}>
            <Grid xs={12} item>
              {/* <InputLabel htmlFor="max-width">Select template to use</InputLabel> */}
              <SelectInput
                options={[
                  { label: 'Original', value: 'Original' },
                  { label: 'Custom', value: 'Custom' },
                  { label: 'Disabled', value: 'Disabled' },
                ]}
                name={'version'}
                control={control}
              />
            </Grid>
            <Grid xs={12} item>
              <TextInput name={'subject'} inputRef={register} />
            </Grid>
            <Grid xs={12} item>
              <TextInput name={'content'} inputRef={register} />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit(onSubmit)}
          color="primary"
          text="Save Changes"
        />
        <Button onClick={onClose} color="secondary" text="Close" />
      </DialogActions>
    </Dialog>
  )
}
