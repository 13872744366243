import React, { useMemo, useState } from 'react'
import { useAppDispatch } from '@hooks/reduxHooks'
import { Edit } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  IconButton,
  styled,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@mui/material'
import { CCAMDto, NGAPDto } from '@services/dtos'
import { doSearchCCAM, doSearchNGAP } from '@state/reducers/procedureReducer'
import _ from 'lodash'
import AsyncAutoComplete from '@components/inputs/AsyncAutoComplete'

type Props = {
  onChange: (ev: {
    cotationType: 'NGAP' | 'CCAM'
    value: CCAMDto | NGAPDto
  }) => void
  value?: CCAMDto | NGAPDto
}

const GlassmorphicDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
    border: '1px solid rgba(255, 255, 255, 0.18)',
    borderRadius: '10px',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(2px)',
  },
}))

const CcamAndNgapSearchComponent: React.FC<Props> = ({ onChange, value }) => {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [cotationType, setCotationType] = useState<'CCAM' | 'NGAP'>('CCAM')
  const [result, setResult] = useState<
    { value: CCAMDto; label: string }[] | { value: NGAPDto; label: string }[]
  >([])

  const options = useMemo(
    () =>
      result.map((res) => ({
        value: res.value,
        label: `${res.label} - ${res.value.code}`,
      })),
    [result],
  )

  const handleSearch = async (search: string, cotationType: string) => {
    let res: NGAPDto[] | CCAMDto[]
    if (cotationType === 'CCAM') {
      res = await dispatch(
        doSearchCCAM({ libelle: search, examDate: new Date() }),
      ).unwrap()
    } else {
      res = await dispatch(
        doSearchNGAP({ libelle: search, examDate: new Date() }),
      ).unwrap()
    }
    setResult(
      res.map((r) => ({
        value: r,
        label: r.libelle,
      })),
    )
  }

  const onSearch = async (search: string) => {
    if (!search) {
      return
    }
    await handleSearch(search, cotationType)
  }

  const handleCotationTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCotationType(event.target.value as 'CCAM' | 'NGAP')
    setResult([])
  }

  return (
    <div>
      <IconButton onClick={() => setIsOpen(true)}>
        <Edit />
      </IconButton>
      <GlassmorphicDialog
        maxWidth="lg"
        fullWidth
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onClick={(event) => {
          if (event.target === event.currentTarget) {
            setIsOpen(false)
          }
        }}
      >
        <DialogContent style={{ height: '400px' }}>
          <h1>Search</h1>
          <FormControl component="fieldset">
            <FormLabel component="legend">Cotation Type</FormLabel>
            <RadioGroup
              aria-label="cotationType"
              row
              name="cotationType"
              value={cotationType}
              onChange={handleCotationTypeChange}
            >
              <FormControlLabel value="CCAM" control={<Radio />} label="CCAM" />
              <FormControlLabel value="NGAP" control={<Radio />} label="NGAP" />
            </RadioGroup>
          </FormControl>
          <AsyncAutoComplete
            getValue={(v) => v.code}
            options={options}
            value={value ? { value, label: value.libelle } : null}
            onChange={(v) => {
              onChange({ cotationType, value: v as CCAMDto | NGAPDto })
              setIsOpen(false)
            }}
            handleSearch={onSearch}
          />
        </DialogContent>
      </GlassmorphicDialog>
    </div>
  )
}

export default CcamAndNgapSearchComponent
