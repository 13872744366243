import React, { useEffect, useState } from 'react'
import { useSelector } from '../../../state/store'
import { useAppDispatch } from '../../../hooks/reduxHooks'
import { findMedicalOrders } from '../../../state/thunks/medicalOrderThunk'
import { useLocation } from 'react-router-dom'
import { TransportForm } from './TransportForm'
import { LinearProgress } from '@mui/material'

const MedicalOrderTransport: React.FC = () => {
  const medicalOrder = useSelector((state) => state.medicalOrder.medicalOrders)

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    const search = location.search
    const params = new URLSearchParams(search)
    const accessionNumber = params.get('accessionNumber')

    setIsLoading(true)
    dispatch(
      findMedicalOrders({
        accessionNumber: accessionNumber ? accessionNumber : '',
        includeTransport: true,
      }),
    ).then(() => {
      setIsLoading(false)
    })
  }, [])

  return !isLoading ? (
    <TransportForm row={medicalOrder[0]} />
  ) : (
    <LinearProgress />
  )
}

export default MedicalOrderTransport
