import React, { useEffect, useState } from 'react'
import { FormControlLabel, RadioGroup, Radio, FormLabel, Box, Button, InputLabel,Select, MenuItem, SelectChangeEvent, } from '@mui/material'
import FormControl from '@mui/material/FormControl'

import { fetchSiteLabelV2 } from '@services/statsSercives'
import { SiteDto } from '@services/api'
import { useDispatch } from 'react-redux'
import { useSelector } from '@state/store'
import { filterChangeAccountType, filterChangeEventType, filterChangePeriod, filterChangeSite, removeStats } from '@state/reducers/statsReducer'
import { getAllPrescriber, getExamBreakdown, getAllTopEvent } from '@state/thunks/statsThunk'

export enum AccountTypeEnum {
  'Doctor' = 'doctor',
  'Member' = 'member',
}

export enum EventTypeEnum {
  'all' = 'all',
  'report' = 'report',
  'image' = 'image',
  'medical_order' = 'medical_order',
}

const FilterComponent = () => {
  const Year = new Date().getFullYear()
  const dispatch = useDispatch()
  const filter = useSelector((state) => state.stats.filter)
  const [siteLabel, setSiteLabel] = useState<SiteDto[]>([])
  
  const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedYear = parseInt(event.target.value)
    dispatch(filterChangePeriod(selectedYear))
    dispatch(getAllPrescriber())
  }

  const handleAccountTypeChange = (event: SelectChangeEvent<string>) => {
    const selectedSite: string = event.target.value as string
    dispatch(filterChangeAccountType(selectedSite))
    dispatch(getAllTopEvent())
  }

  const handleEventTypeChange = (event: SelectChangeEvent<string>) => {
    const selectedSite: string = event.target.value as string
    dispatch(filterChangeEventType(selectedSite))
    dispatch(getAllTopEvent())
  }

  const handleSiteChange = (event: SelectChangeEvent<number>) => {
    const selectedSite = event.target.value as number
    if (selectedSite === -1) {
      dispatch(removeStats('site'))
      dispatch(getExamBreakdown())
      dispatch(getAllPrescriber())
      dispatch(getAllTopEvent())
    } else {
      dispatch(filterChangeSite(selectedSite))
      dispatch(getExamBreakdown())
      dispatch(getAllPrescriber())
      dispatch(getAllTopEvent())
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const result: SiteDto[] = await fetchSiteLabelV2()
      setSiteLabel(result)
    }
    fetchData()
  }, [])

  return (
    <Box sx={{ padding: 2, borderRadius: 2 }}>
      <FormControl component="fieldset" sx={{ marginBottom: 2 }}>
        <FormLabel>Period</FormLabel>
        <RadioGroup
          aria-label="year"
          name="year"
          value={filter.startY}
          onChange={handlePeriodChange}
        >
          <FormControlLabel value={Year} control={<Radio />} label="1 year" />
          <FormControlLabel value={Year - 1} control={<Radio />} label="2 years" />
          <FormControlLabel value={Year - 2} control={<Radio />} label="3 years" />
        </RadioGroup>
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel variant="standard">Site</InputLabel>
        <Select
          labelId="site-label"
          id="site-select"
          label="site"
          value={filter.siteId ? filter.siteId : -1}
          onChange={handleSiteChange}
        >
          <MenuItem value={-1}>All</MenuItem>
          {siteLabel.map((elem, index) => (
            <MenuItem key={index} value={elem.id}>{elem.label}</MenuItem>
          ))}
          
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel variant="standard">Event Type</InputLabel>
        <Select
          labelId="eventType-label"
          id="eventType-select"
          label="eventType"
          value={filter.eventType}
          onChange={handleEventTypeChange}
        >
          {Object.values(EventTypeEnum).map((elem, index) => (
            <MenuItem key={index} value={elem}>{elem.charAt(0).toUpperCase() + elem.slice(1)}</MenuItem>
          ))}
          
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel variant="standard">Account Type</InputLabel>
        <Select
          labelId="AccountType-label"
          id="AccountType-select"
          label="AccountType"
          value={filter.accountType}
          onChange={handleAccountTypeChange}
        >
          {Object.values(AccountTypeEnum).map((elem, index) => (
            <MenuItem key={index} value={elem}>{elem.charAt(0).toUpperCase() + elem.slice(1)}</MenuItem>
          ))}
          
        </Select>
      </FormControl>

      <Button variant="outlined" size="medium">
        Export all
      </Button>
    </Box>
  )
}

export default FilterComponent
