import { SiteService, CreateSiteDto, UpdateSiteDto } from '@services/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'
import { authState } from '../reducers/authReducer'

export const findAll = createAsyncThunk(
  Actions.SITE_FIND_ALL,
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState() as { auth: authState }

      return await SiteService.findAll({ clientId: auth.account?.client })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const updateSite = createAsyncThunk(
  Actions.SITE_UPDATE,
  async (
    { id, body }: { id: number; body: UpdateSiteDto },
    { rejectWithValue },
  ) => {
    try {
      return await SiteService.update({ id, body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const createSite = createAsyncThunk(
  Actions.SITE_CREATE,
  async (body: CreateSiteDto, { rejectWithValue }) => {
    try {
      return await SiteService.create({ body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const deleteSite = createAsyncThunk(
  Actions.SITE_DELETE,
  async (ids: number | number[], { rejectWithValue }) => {
    try {
      return await SiteService.delete(ids)
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const findAllSite = createAsyncThunk(
  Actions.SITE_TYPE_FIND_ALL,
  async (_, { rejectWithValue }) => {
    try {
      return await SiteService.findAllTypes()
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
