import {
  CreateMedicalEquipmentDto,
  EquipmentService,
  UpdateMedicalEquipmentDto,
} from '@services/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'

export const findAllMedicalEquipment = createAsyncThunk(
  Actions.MEDICAL_EQUIPMENT_FIND_ALL,
  async (_, { rejectWithValue }) => {
    try {
      return await EquipmentService.findAllMedicalEquipment()
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const updateMedicalEquipment = createAsyncThunk(
  Actions.MEDICAL_EQUIPMENT_UPDATE,
  async (
    { id, body }: { id: number; body: UpdateMedicalEquipmentDto },
    { rejectWithValue },
  ) => {
    try {
      return await EquipmentService.updateMedicalEquipment({ id, body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const createMedicalEquipment = createAsyncThunk(
  Actions.MEDICAL_EQUIPMENT_CREATE,
  async (body: CreateMedicalEquipmentDto, { rejectWithValue }) => {
    try {
      return await EquipmentService.createMedicalEquipment({ body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const deleteMedicalEquipment = createAsyncThunk(
  Actions.MEDICAL_EQUIPMENT_DELETE,
  async (id: number | number[], { rejectWithValue }) => {
    try {
      return await EquipmentService.removeMedicalEquipment(id)
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
