import { Control, Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { Switch, Box, Typography } from '@mui/material'

type EcSwitchInputProps = {
  control: Control<any>
  input: string
  name?: string
  defaultValue?: any
}
export function EcSwitchInput({
  input,
  name = input,
  control,
  defaultValue,
  ...props
}: EcSwitchInputProps) {
  const intl = useIntl()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <Box display="flex" alignItems="center">
          <Switch
            {...props}
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
            color="primary"
          />
          <Typography>{input && intl.formatMessage({ id: input })}</Typography>
        </Box>
      )}
    />
  )
}
