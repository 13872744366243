import React from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Paper,
  Chip,
  Stack,
} from '@mui/material'
import { Person, AssignmentInd, Business, Warning } from '@mui/icons-material'
import moment from 'moment'
import { CouvertureDto } from 'src/common/interfaces'

const formatDate = (date?: string) => {
  if (!date) return 'Non renseigné'
  return moment(date).format('DD/MM/YYYY')
}

const formatValue = (value: any): string => {
  if (value === undefined || value === null || value === '')
    return 'Non renseigné'
  return value.toString()
}

type Props = {
  couverture: CouvertureDto
}
export const CouvertureDisplay: React.FC<Props> = ({ couverture }) => {
  return (
    <Card sx={{ width: '100%', mb: 2 }}>
      <CardContent>
        {true && (
          <Box sx={{ mb: 3 }}>
            <Chip
              icon={<Warning />}
              label="Couverture Obsolète"
              color="warning"
              sx={{ fontWeight: 'bold' }}
            />
          </Box>
        )}

        <Stack spacing={3}>
          <Paper elevation={1} sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Person sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6">Informations du Bénéficiaire</Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Box>
                    <Typography color="text.secondary" variant="body2">
                      Nom
                    </Typography>
                    <Typography variant="body1">
                      {formatValue(couverture.beneficiaireNom)}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography color="text.secondary" variant="body2">
                      Prénom
                    </Typography>
                    <Typography variant="body1">
                      {formatValue(couverture.beneficiairePrenom)}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Box>
                    <Typography color="text.secondary" variant="body2">
                      NIR
                    </Typography>
                    <Typography variant="body1">
                      {formatValue(couverture.beneficiaireNir)}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography color="text.secondary" variant="body2">
                      Date de naissance
                    </Typography>
                    <Typography variant="body1">
                      {formatDate(couverture.beneficaireDateNaissance)}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Box>
                    <Typography color="text.secondary" variant="body2">
                      Nom Usuel
                    </Typography>
                    <Typography variant="body1">
                      {formatValue(couverture.beneficaireNomUsuel)}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Box>
                    <Typography color="text.secondary" variant="body2">
                      Rang de Naissance
                    </Typography>
                    <Typography variant="body1">
                      {formatValue(couverture.rangNaissance)}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={1} sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <AssignmentInd sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6">
                Informations de l&nbsp;Assuré
              </Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Box>
                    <Typography color="text.secondary" variant="body2">
                      Nom
                    </Typography>
                    <Typography variant="body1">
                      {formatValue(couverture.assureNom)}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography color="text.secondary" variant="body2">
                      Prénom
                    </Typography>
                    <Typography variant="body1">
                      {formatValue(couverture.assurePrenom)}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Box>
                    <Typography color="text.secondary" variant="body2">
                      NIR
                    </Typography>
                    <Typography variant="body1">
                      {formatValue(couverture.assureNir)}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography color="text.secondary" variant="body2">
                      Nom Usuel
                    </Typography>
                    <Typography variant="body1">
                      {formatValue(couverture.assureNomUsuel)}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={1} sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Business sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6">Organisme AMO</Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography color="text.secondary" variant="body2">
                  ID Organisme
                </Typography>
                <Typography variant="body1">
                  {formatValue(couverture.idOrganismeAmc)}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography color="text.secondary" variant="body2">
                  Date de début
                </Typography>
                <Typography variant="body1">
                  {formatDate(couverture.dateDebutAmo)}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography color="text.secondary" variant="body2">
                  Date de fin
                </Typography>
                <Typography variant="body1">
                  {formatDate(couverture.dateFinAmo)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={1} sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Business sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6">Organisme AMC</Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography color="text.secondary" variant="body2">
                  ID Organisme
                </Typography>
                <Typography variant="body1">
                  {formatValue(couverture.idOrganismeAmc)}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography color="text.secondary" variant="body2">
                  Date de début
                </Typography>
                <Typography variant="body1">
                  {formatDate(couverture.dateDebutAmc)}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography color="text.secondary" variant="body2">
                  Date de fin
                </Typography>
                <Typography variant="body1">
                  {formatDate(couverture.dateFinAmc)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default CouvertureDisplay
