import { useState } from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import DoctorList from './doctors/DoctorList'

export const ParamterContainer = () => {
  const [tab, setTab] = useState('doctors')

  const handleTabChange = (_, newValue: string) => {
    setTab(newValue)
  }

  return (
    <div className="w-full ">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="parameter tabs"
          className="bg-white"
        >
          <Tab
            label="Docteurs externes"
            value="doctors"
            className="text-gray-700 hover:text-blue-600"
          />
        </Tabs>
      </Box>

      <div className="p-4">
        {tab === 'doctors' && <DoctorList />}
        {tab === 'members' && <div>Members Content Here</div>}
      </div>
    </div>
  )
}

export default ParamterContainer
