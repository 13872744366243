import { DoctorService, CreateDoctorDto, UpdateDoctorDto } from '@services/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'

export const findAll = createAsyncThunk(
  Actions.DOCTOR_FIND_ALL,
  async (_, { rejectWithValue }) => {
    try {
      return await DoctorService.findAll()
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const findOne = createAsyncThunk(
  Actions.DOCTOR_FIND_ONE,
  async (id: number, { rejectWithValue }) => {
    try {
      return await DoctorService.findOne({ id })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const update = createAsyncThunk(
  Actions.DOCTOR_UPDATE,
  async (
    params: { id: number; body: UpdateDoctorDto },
    { rejectWithValue },
  ) => {
    try {
      return await DoctorService.update(params)
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const create = createAsyncThunk(
  Actions.DOCTOR_CREATE,
  async (body: CreateDoctorDto, { rejectWithValue }) => {
    try {
      return await DoctorService.create({ body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
