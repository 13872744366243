export function isBase64(str: string): boolean {
  const base64Regex =
    /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/
  return base64Regex.test(str)
}

export function formatLunarDate(lunarDateStr: string): string {
  const year = parseInt(lunarDateStr.substring(0, 4), 10)
  const month = parseInt(lunarDateStr.substring(4, 6), 10)
  const day = parseInt(lunarDateStr.substring(6, 8), 10)

  return `${year}-${month}-${day}`
}
