import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'

import { Grid } from '@mui/material'

import { CreateSiteDto, Site, SiteDto, UpdateSiteDto } from '@services/api'
import { Button } from '@components/buttons'
import {
  createSite as addSite,
  findAllSite,
  updateSite as updateSite,
} from '@state/thunks/siteThunk'
import { useSelector } from '@state/store'
import { TextInput, SelectInput } from '@components/inputs'
import { yupResolver } from '@hookform/resolvers/yup'
import { siteSchema } from '@utils/schemas'
import { MouseEventHandler, useEffect } from 'react'

type AddSiteFormprops = {
  onClose?: () => void
  row?: SiteDto
  onCancelButtonPress?: (
    ev: MouseEventHandler<HTMLButtonElement> | undefined,
  ) => void
}

export function SiteForm({ row, onClose }: AddSiteFormprops) {
  const dispatch = useDispatch()
  // const options = useSelector(({ superAdmin }: RootState) => superAdmin.options)
  // const [inputValue, setInputValue] = useState('')

  const { clients, client, siteTypes } = useSelector(
    ({ client, auth, site }) => ({
      clients: client.clients.datas,
      client: auth.account?.client,
      siteTypes: site.siteTypes,
    }),
  )

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<Site>({
    resolver: yupResolver(siteSchema),
    defaultValues: {
      ...row,
    },
  })

  useEffect(() => {
    dispatch(findAllSite())
  }, [])

  const onSubmit = (values: SiteDto) => {
    if (row?.id) {
      const updateSiteDto: UpdateSiteDto = {
        code: values.code,
        label: values.label,
        capacity: values.capacity,
        streetAddress: values.streetAddress,
        postalCode: values.postalCode,
        city: values.city,
        country: values.country,
        email: values.email,
        finessNumber: values.finessNumber,
        siteTypeId: values.siteTypeId,
        fax: values.fax,
        phoneNumber: values.phoneNumber,
      }
      dispatch(updateSite({ id: row.id, body: updateSiteDto }))
    } else {
      const createSiteDto: CreateSiteDto = {
        code: values.code,
        label: values.label,
        capacity: values.capacity,
        streetAddress: values.streetAddress,
        postalCode: values.postalCode,
        city: values.city,
        country: values.country,
        email: values.email,
        finessNumber: values.finessNumber,
        siteTypeId: values.siteTypeId,
        fax: values.fax,
        phoneNumber: values.phoneNumber,
        primary: false,
        status: '',
        finessStructNumber: '',
      }
      dispatch(addSite(createSiteDto))
    }
    if (onClose) onClose()
  }
  
  const handleCancelPress = () => {
    if (onClose) {
      onClose()
    }
    // Closes the update form - Datagrid didnt provide a prop function to close form ()
    const el = (document as Document).querySelector(
      `.MuiDataGrid-row[data-id="${row?.id}"] .MuiIconButton-root.MuiIconButton-sizeSmall`,
    ) as any
    if (el) {
      el.click()
    }
  }

  const clientOptions = clients.map(({ name, id }) => ({
    label: name,
    value: id as number,
  }))
  const siteTypeOptions = siteTypes.map((type) => ({
    label: type.label,
    value: type.id,
  }))
  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: 2,
        borderRadius: 5,
      }}
    >
      {!client && (
        <Grid item xs={12} xl={6}>
          <SelectInput
            name="client"
            errors={errors}
            options={clientOptions}
            control={control}
          />
        </Grid>
      )}
      <Grid item xs={12} xl={6}>
        <TextInput name="code" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="label" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="capacity" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <SelectInput
          options={siteTypeOptions}
          control={control}
          name="siteTypeId"
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="finessNumber" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="streetAddress" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="postalCode" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="city" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="country" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="email" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6} container alignItems="center">
        <TextInput name="phoneNumber" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput name="fax" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-end">
          {onClose && (
            <Grid item xs={2}>
              <Button
                onClick={handleCancelPress}
                color="secondary"
                text="cancel"
                textColor="white"
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <Button
              onClick={handleSubmit(onSubmit)}
              color="primary"
              text="save"
              textColor="white"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
