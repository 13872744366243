import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SuperAdminDto } from '@services/api'

type superAdminState = {
  superAdmins: {
    totalCount: number
    datas: SuperAdminDto[]
  }
  selected?: SuperAdminDto
}

const initialState: superAdminState = {
  superAdmins: {
    totalCount: 0,
    datas: [],
  },
}

const superAdminSlice = createSlice({
  name: 'super-admin',
  initialState,
  reducers: {
    removeSuperAdmins: (state) => {
      state.superAdmins = initialState.superAdmins
    },
    setSelected: (state, action: PayloadAction<SuperAdminDto>) => {
      state.selected = action.payload
    },
  },
})

export const { removeSuperAdmins, setSelected } = superAdminSlice.actions

export default superAdminSlice.reducer
