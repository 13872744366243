import { AccordionDetails, Divider, Grid, Checkbox, FormControlLabel, InputLabel,FormControl, Select,MenuItem, FormHelperText, TextField } from '@mui/material'
import * as Yup from 'yup'
import {
  DateInput,
} from '../../../../components/inputs'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CashRegisterSchema } from '@utils/schemas'

type patientInformationProps = {
  updatable: boolean
}

type Values = Yup.InferType<typeof CashRegisterSchema>

export default function PatientInformation({
  updatable,
}: patientInformationProps) {
  // const intl = useIntl()
  const {
    formState: { errors },
    control,
  } = useForm<Values>({
    resolver: yupResolver(CashRegisterSchema),
  })

  return (
    <div>
      <Divider variant="fullWidth" sx={{ marginBottom: 3 }} >Géneral</Divider>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Code de la caisse"
              name="cashRegisterCode"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          
          <Grid item xs={12} lg={6}>
            <TextField
              label="Libellé de la caisse"
              name="cashRegisterLabel"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Code du site</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Code du site"
                name='siteCode'
                // value={age}
                // onChange={}
              >
                <MenuItem value={'DR'}>DES</MenuItem>
                <MenuItem value={'DRE'}>BRV</MenuItem>
                <MenuItem value={'MR'}>CFT</MenuItem>
                <MenuItem value={'MME'}>CDS</MenuItem>
                <MenuItem value={'ENF'}>CCD</MenuItem>
              </Select>
              <FormHelperText>Helper text for errors</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Code du journal</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Code du journal"
                name='logCode'
                // value={age}
                // onChange={}
              >
                <MenuItem value={'DR'}>123432469786</MenuItem>
                <MenuItem value={'DRE'}>98985435678</MenuItem>
                <MenuItem value={'MR'}>124398982469786</MenuItem>
                <MenuItem value={'MME'}>10987632469786</MenuItem>
                <MenuItem value={'ENF'}>44435672883476</MenuItem>
              </Select>
              <FormHelperText>Helper text for errors</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Responsable</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Responsable"
                name='responsible'
                // value={age}
                // onChange={}
              >
                <MenuItem value={'DR'}>Docteur</MenuItem>
                <MenuItem value={'DRE'}>Docteure</MenuItem>
                <MenuItem value={'MR'}>Monsieur</MenuItem>
                <MenuItem value={'MME'}>Madame</MenuItem>
                <MenuItem value={'ENF'}>Enfant</MenuItem>
              </Select>
              <FormHelperText>Helper text for errors</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel name='checkboxOpen' control={<Checkbox />} label="Reprise de solde automatique en ouverture de caisse" />
            <FormControlLabel name='checkboxClose' control={<Checkbox />} label="Ecriture de solde automatique en fermeture de caisse" />
          </Grid>
          <Grid item>
            <Grid item xs={12}>
              <DateInput
                name="startDate"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid item xs={12}>
              <DateInput
                name="endDate"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider variant="fullWidth" sx={{ marginTop: 3, }} />
    </div>
  )
}
