import DialogTitle from '@components/dialogs/DialogTitle'
import { DialogContent } from '@material-ui/core'
import {
  Box,
  Tab,
  Tabs,
  Button,
  Icon,
  Container,
  DialogActions,
  Grid,
} from '@mui/material'
import Text from '@components/Text'

import Dialog from '@mui/material/Dialog'
import theme from '@utils/theme'
import { useIntl } from 'react-intl'
import FormDetails from './form/FormDetails'
import FormCaisseFinance from './form/FormCaisseFinance'
import FormMutuelleChoise from './form/FormMutuelleAndFundChoise'
import { useDispatch } from 'react-redux'
import { Actions } from '@state/actions'
import { useSelector } from '@state/store'
import { deleteType } from '@state/thunks/procedureThunk'
import { MouseEvent, useCallback, useState } from 'react'
import { DataTable } from '@components/tables'
import {
  GridColumns,
  GridRowId,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export interface PatientDialogProps {
  patientId: number
  open: boolean
  setOpen: (open: boolean) => void
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return (
    <Box>
      {value === index && <Box sx={{ paddingTop: '3px' }}>{children}</Box>}
    </Box>
  )
}

export default function FundView() {
  const dispatch = useDispatch()
  const [value, setValue] = useState(0)
  const [updatable, setUpdatable] = useState(false)
  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openMultiDeleteModal, setOpenMultiDeleteModal] = useState(false)
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const [openAddModal, setOpenAddModal] = useState(false)

  const intl = useIntl()
  
  const { loading } = useSelector(({ loading }) => ({
    loading:
      loading[Actions.PROCEDURE_TYPE_FIND_ALL] ||
      loading[Actions.PROCEDURE_TYPE_CREATE] ||
      loading[Actions.PROCEDURE_TYPE_UPDATE],
  }))


  const columns: GridColumns = [
    {
      field: 'col1',
      headerName: '',
      width: 80,
      sortable: false,
      renderCell: (value) => (
        <div
          style={{
            width: '100%',
          }}
        >
          <Icon
            style={{ marginRight: '10px' }}
            className="fas fa-marker"
            fontSize="small"
            onClick={() => setOpenUpdateModal(true)}
            sx={{ color: '#3F3F3F' }}
          />
          <Icon
            className="fas fa-trash-alt"
            fontSize="small"
            onClick={() => setOpenDeleteModal(true)}
            sx={{ color: '#3F3F3F'}}
          />
        </div>
      ),
    },
    {
      field: 'col2',
      headerName: 'Code',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.code}`
    },
    {
      field: 'col3',
      headerName: 'Nom',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.nom}`
    },
    {
      field: 'col4',
      headerName: 'Code postal',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.cp}`
    },
    {
      field: 'col5',
      headerName: 'Ville',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.ville}`
    },
    {
      field: 'col6',
      headerName: 'Taux',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.taux}`
    },
    {
      field: 'col7',
      headerName: 'Début',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.debut}`
    },
    {
      field: 'col8',
      headerName: 'Fin',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.fin}`
    },
    {
      field: 'col9',
      headerName: 'Liens',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.liens}`
    },
    {
      field: 'col10',
      headerName: 'Date de mise à jour',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.datemaj}`
    },
    {
      field: 'col11',
      headerName: 'Code utilisateur',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${params.row.userCode}`
    }
  ]



  const onRefresh = useCallback(() => {
    // TODO
    // dispatch(findAllMedicalOrder())
  }, [dispatch])

  const _onSelectionChange = (selection: GridRowId[]) => {
    setSelectedRows(selection as number[])
  }
  const _onDeleteConfirm = () => {
    dispatch(deleteType(selectedRows))
    setOpenDeleteModal(false)
  }

  const onCloseAdd = () => {
    setOpenAddModal(false)
  }

  const onCloseUpdate = () => {
    setOpenUpdateModal(false)
  }

  const onCloseDelete = () => {
    setOpenDeleteModal(false)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const medicalOrderMockup = [
    {
      id: 0,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    },
    {
      id: 1,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    },
    {
      id: 2,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    },
    {
      id: 3,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    }, {
      id: 4,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    },
    {
      id: 5,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    }, {
      id: 6,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    },
    {
      id: 7,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    }, {
      id: 8,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    },
    {
      id: 9,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    }, {
      id: 10,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    },
    {
      id: 11,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    }, {
      id: 12,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    },
    {
      id: 13,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    }, {
      id: 14,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    },
    {
      id: 15,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    }, {
      id: 16,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    },
    {
      id: 17,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    },
    {
      id: 18,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    },{
      id: 19,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    },{
      id: 20,
      action: 'Modifier',
      code: '123',
      nom: 'John Doe',
      cp: '1000',
      ville: 'Tunis',
      taux: '100',
      debut: '2021-12-12',
      fin: '2021-12-12',
      liens: 0,
      datemaj: '2021-12-12',
      userCode: 1000,
    },
  ]

  const myTabsProps = [
    {
      labelTab: 'Information générales',
      icon: 'fas fa-user-plus',
      title: '123',
      component: <FormDetails updatable={updatable} />,
    },
    {
      labelTab: 'P. Financiers',
      icon: 'fas fa-user-check',
      title: '123',
      component: <FormCaisseFinance updatable={updatable} />,
    },
    {
      labelTab: 'Mutuelle',
      icon: 'fas fa-user-tag',
      title: '123',
      component: <FormMutuelleChoise updatable={updatable} />,
    },
  ]

  return (
    <Grid style={{ height: '85vh'}} >
      <DataTable
        rows={medicalOrderMockup}
        columns={columns}
        loading={loading}
        size={35}
        isDetails
        toolBarProps={{
          title: 'Caisse',
          icon: 'fas fa-cash-register',
          onAdd: () => setOpenAddModal(true),
          onRefresh,
          onDelete: () => {
            selectedRows.length
              ? setOpenMultiDeleteModal(true)
              : alert('please select at least one reception')
          },
        }}
        detailPanelHeight={400}
        form={(props) => <FormDetails {...props} />}
        checkboxSelection
        onSelectionModelChange={_onSelectionChange}
      />

      {/* modal add fund */}
      <Dialog
        open={openAddModal}
        onClose={onCloseAdd}
        PaperProps={{
          style: {
            borderRadius: 20,
            minWidth: '50vw',
            maxWidth: '80vw',
            background: '#E2E6F2',
            width: '65%',
          },
        }}
      >
        <DialogTitle
          onClose={onCloseAdd}
          title="Ajouter une caisse"
          style={{
            background: '#fff',
            color: theme.palette.primary.main,
          }}
        />
        <DialogContent
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 30,
            paddingTop: 10,
            overflow: 'hidden',
            height: '80vh',
          }}
        >
          <Box
            sx={{
              marginTop: '5px',
            }}
          >
            <Box
              sx={{
                marginTop: '5px',
                // backgroundColor: '#fff',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={value}
                onChange={handleChange}
                aria-label="exams-tabs"
              >
                {myTabsProps.map((el, index) => {
                  return (
                    <Tab
                      label={intl.formatMessage({ id: el.labelTab })}
                      {...a11yProps(0)}
                      sx={{
                        borderRadius: '5px 5px 0px 0px',
                        marginRight: '5px',
                        backgroundColor: '#fff',
                      }}
                      key={index}
                    />
                  )
                })}
              </Tabs>
            </Box>
            <div
              style={{
                overflow: 'auto',
                maxHeight: '70vh',
              }}
            >
              {myTabsProps.map((el, index) => {
                return (
                  <TabPanel value={value} index={index} key={index}>
                    {el.component}
                  </TabPanel>
                )
              })}
            </div>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Button variant="contained" color="success" onClick={() => {alert('CRUD en cours')}}>Sauvegarder</Button>
          </Box>
        </DialogContent>
      </Dialog>
      
      {/* modal update */}
      <Dialog
        open={openUpdateModal}
        onClose={onCloseUpdate}
        PaperProps={{
          style: {
            borderRadius: 20,
            minWidth: '50vw',
            maxWidth: '80vw',
            background: '#E2E6F2',
            width: '65%',
          },
        }}
      >
        <DialogTitle
          onClose={onCloseUpdate}
          title="Modifier une caisse"
          style={{
            background: '#fff',
            color: theme.palette.primary.main,
          }}
        />
        <DialogContent
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 30,
            paddingTop: 10,
            overflow: 'hidden',
            height: '80vh',
          }}
        >
          <Box
            sx={{
              marginTop: '5px',
            }}
          >
            <Box
              sx={{
                marginTop: '5px',
                // backgroundColor: '#fff',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={value}
                onChange={handleChange}
                aria-label="exams-tabs"
              >
                {myTabsProps.map((el, index) => {
                  return (
                    <Tab
                      label={intl.formatMessage({ id: el.labelTab })}
                      {...a11yProps(0)}
                      sx={{
                        borderRadius: '5px 5px 0px 0px',
                        marginRight: '5px',
                        backgroundColor: '#fff',
                      }}
                      key={index}
                    />
                  )
                })}
              </Tabs>
            </Box>
            <div
              style={{
                overflow: 'auto',
                maxHeight: '70vh',
              }}
            >
              {myTabsProps.map((el, index) => {
                return (
                  <TabPanel value={value} index={index} key={index}>
                    {el.component}
                  </TabPanel>
                )
              })}
            </div>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Button variant="contained" color="success" onClick={() => {alert('CRUD en cours')}}>modifier</Button>
          </Box>
        </DialogContent>
      </Dialog>
      {/* modal delete */}
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          onClose={() => setOpenDeleteModal(false)}
          title="Supression"
          style={{
            background: '#fff',
            color: theme.palette.primary.main,
          }}
        />
        <DialogContent
          style={{
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
            background: '#E2E6F2',
          }}
        >
          <Text
            text="deleteConfirmation"
            variant="h5"
            color="secondary"
            sx={{ fontWeight: 'bold', paddingTop: '20px', paddingBottom: '20px'}}
            format
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
            paddingBottom: '20px',
            paddingTop: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          <Button
            sx={{ 
              ':hover': {
                backgroundColor: '#333333',
              },
              color: 'white',
              background: '#3F3F3F',
              borderRadius: 5,
            }}
            variant='contained'
            color='success'
            fullWidth
            onClick={() => setOpenDeleteModal(false)}
          >
            Annuler
          </Button>
          <Button
            style={{ color: 'white' }}
            variant='contained'
            color='secondary'
            fullWidth
            sx={{ borderRadius: 5 }}
            onClick={_onDeleteConfirm}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      {/* modal multi delete */}
      <Dialog
        open={openMultiDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          onClose={() => setOpenMultiDeleteModal(false)}
          title="Supression multiple"
          style={{
            background: '#fff',
            color: theme.palette.primary.main,
          }}
        />
        <DialogContent
          style={{
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
            background: '#E2E6F2',
          }}
        >
          <Text
            text="deleteConfirmation"
            variant="h5"
            color="secondary"
            sx={{ fontWeight: 'bold', paddingTop: '20px', paddingBottom: '20px'}}
            format
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
            paddingBottom: '20px',
            paddingTop: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          <Button
            sx={{ 
              ':hover': {
                backgroundColor: '#333333',
              },
              color: 'white',
              background: '#3F3F3F',
              borderRadius: 5,
            }}
            variant='contained'
            color='success'
            fullWidth
            onClick={() => setOpenMultiDeleteModal(false)}
          >
            Annuler
          </Button>
          <Button
            style={{ color: 'white' }}
            variant='contained'
            color='secondary'
            fullWidth
            sx={{ borderRadius: 5 }}
            onClick={_onDeleteConfirm}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
