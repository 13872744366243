import { Tooltip, IconButton as MuiIconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'

// const useStyles = makeStyles({
//   iconBtn: {
//     padding: 0,
//   },
//   icon: {
//     marginRight: '1.5rem',
//   },
// })

const IconButton = styled(MuiIconButton)(() => ({
  iconBtn: {
    padding: 0,
  },
}))

type TooltipButtonProps = {
  title: string
  onClick: () => void
  icon: any
  disable?: boolean
  color?: any
}
export const TooltipButton = ({
  title,
  onClick,
  disable,
  color,
  icon,
}: TooltipButtonProps) => {
  const intl = useIntl()
  // const classes = useStyles()
  return (
    <Tooltip title={intl.formatMessage({ id: title })}>
      <span>
        <IconButton
          size="small"
          color={color ? color : 'primary'}
          aria-label={intl.formatMessage({ id: title })}
          onClick={(e) => {
            e.stopPropagation()
            onClick()
          }}
          // className={classes.iconBtn}
          disabled={disable}
        >
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  )
}
