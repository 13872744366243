import { Box, Container } from '@mui/material'
import { Button } from '@components/buttons'

import NotFoundImage from '../../assets/img/NotFound.svg'

export default function NotFound() {
  return (
    <Container
      style={{
        height: '100%',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box display="grid" justifyItems="center">
        <img src={NotFoundImage} alt="Not Found" style={{ width: 800 }} />
        <Button
          text="Home"
          style={{ width: 500 }}
          textColor="primary"
          size="large"
          link="/"
        />
      </Box>
    </Container>
  )
}
