import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ItEquipment } from '@services/api'
import {
  createItEquipment,
  deleteItEquipment,
  findAllItEquipment,
  updateItEquipment,
} from '@state/thunks/itEquipmentThunk'

type itEquipmentState = {
  itEquipments: ItEquipment[]
}

const initialState: itEquipmentState = {
  itEquipments: [],
}

const itEquipmentSlice = createSlice({
  name: 'it-equipment',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(
        findAllItEquipment.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.itEquipments = payload
        },
      )
      .addCase(
        createItEquipment.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.itEquipments = [...state.itEquipments, payload]
        },
      )
      .addCase(
        updateItEquipment.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          const index = state.itEquipments.findIndex(
            (_equipment) => _equipment.id === payload.id,
          )
          if (index === -1) {
            return
          }

          state.itEquipments[index] = payload
        },
      )
      .addCase(
        deleteItEquipment.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.itEquipments = state.itEquipments.filter(
            (_equipment) => !payload.includes(_equipment.id),
          )
        },
      ),
})

// eslint-disable-next-line no-empty-pattern
export const {} = itEquipmentSlice.actions

export default itEquipmentSlice.reducer
