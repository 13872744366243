import { createAsyncThunk } from '@reduxjs/toolkit'
import { MedicalOrderActions } from '../actions'
import {
  FindMedicalOrdersQuery,
  MedicalOrderService,
} from '../../services/MedicalOrderService'
import { UpdateMedicalOrderDto } from '../../services/api'
import { MedicalOrderState } from '../reducers/medicalOrderReducer'

export const findMedicalOrders = createAsyncThunk(
  MedicalOrderActions.FIND,
  async (query: FindMedicalOrdersQuery, { getState }) => {
    const { medicalOrder } = getState() as { medicalOrder: MedicalOrderState }

    if (query.page === undefined) {
      query.page = medicalOrder.filters.page
    }

    if (query.limit === undefined) {
      query.limit = medicalOrder.filters.limit
    }

    query.search = medicalOrder.filters.search

    query.siteCode = medicalOrder.filters.siteCode
    query.order = 'asc'
    query.sortBy = 'date'
    // TODO remove this
    query.limit = 10

    return await MedicalOrderService.get(query)
  },
)

export const updateMedicalOrder = createAsyncThunk(
  MedicalOrderActions.UPDATE,
  async (data: { id: number; dto: UpdateMedicalOrderDto }) => {
    return await MedicalOrderService.update(data.id, data.dto)
  },
)
