import { CouvertureDto } from 'src/common/interfaces'
import { axios, getConfigs, IRequestConfig } from './api'
import {
  CreateBillDTO,
  FindPatientCoverageQuery,
  PrecotationDto,
  PrecotationValidationResponse,
  ValidatePrecotationDto,
} from './dtos'

export class BillinglService {
  private static apiBackendEndpoint = process.env.REACT_APP_API_URL || ''

  static getCouverture(q: FindPatientCoverageQuery): Promise<CouvertureDto[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.apiBackendEndpoint}/billing/couverture`,
        {},
      )
      configs.params = q
      axios(configs, resolve, reject)
    })
  }

  static createCouverture(
    patientId: number,
    dto: CouvertureDto,
  ): Promise<CouvertureDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.apiBackendEndpoint}/billing/couverture/${patientId}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static createPrecot(dto: PrecotationDto): Promise<PrecotationDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.apiBackendEndpoint}/billing/precotation/${dto.id_medical_order}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static getPrecotations(medicalOrderId: number): Promise<PrecotationDto[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.apiBackendEndpoint}/billing/precotation?medicalOrderIds=${medicalOrderId}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static updatePrectotation(
    id: number,
    dto: PrecotationDto,
  ): Promise<PrecotationDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        `${this.apiBackendEndpoint}/billing/precotation/${id}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static removePrecotation(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        `${this.apiBackendEndpoint}/billing/precotation/${id}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static savePrecotations(
    medicalOrderId: number,
    data: PrecotationDto[],
  ): Promise<PrecotationDto[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.apiBackendEndpoint}/billing/precotation/${medicalOrderId}`,
        {},
      )
      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  static async validatePrecotation(
    dto: ValidatePrecotationDto,
  ): Promise<PrecotationValidationResponse> {
    const res = await fetch(
      `${process.env.REACT_APP_COTATION_API_URL}/cotation/ControleTotal?`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(dto),
      },
    )
    if (res.status !== 200) {
      throw new Error('Failed to validate precotation')
    }
    return res.json()
  }

  static async createBill(dto: CreateBillDTO) {
    const { idResip, idLecteur, ...data } = dto

    const urlParams = new URLSearchParams({
      idResip: idResip.toString(),
      idLecteur,
      securisationFacture: '1',
      apcv: 'false'
    })

    const res = await fetch(
      `http://172.18.162.21:38179/facturation/facturer?${urlParams.toString()}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
      },
    )
    if (res.status !== 200) {
      throw new Error('Failed to create bill')
    }
    return res.json()
  }
}
