import { MouseEventHandler, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useSelector } from '@state/store'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Divider } from '@mui/material'
import { findAll as findAllSites } from '@state/thunks/siteThunk'
import { CreateExamTypeDto, ExamType, UpdateExamTypeDto } from '@services/api'
import { Button } from '@components/buttons'
import { SelectInput, TextInput } from '@components/inputs'
import { SpaceVertical } from '@utils/Spacing'
import { examTypeSchema } from '@utils/schemas'
import ExamSelectionTable from './ExamSelectionTable'
import { createType, updateType } from '../../../state/thunks/procedureThunk'

type AddExamTypeFormProps = {
  row?: ExamType
  onClose?: () => void
  onCancelButtonPress?: (
    ev: MouseEventHandler<HTMLButtonElement> | undefined,
  ) => void
}

export function ExamTypeForm({ row, onClose }: AddExamTypeFormProps) {
  const dispatch = useDispatch()
  const [selectedExams, setSelectedExams] = useState<number[]>([])

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ExamType>({
    resolver: yupResolver(examTypeSchema),
    defaultValues: {
      ...row,
    },
  })
  const onSubmit = (values: ExamType) => {
    if (row?.id) {
      const updateExamTypeDto: UpdateExamTypeDto = {
        ...values,
        exams: selectedExams.map((_examId) => ({ id: _examId })),
      }
      dispatch(updateType({ id: row.id, body: updateExamTypeDto }))
    } else {
      const createExamTypeDto: CreateExamTypeDto = {
        ...values,
        exams: selectedExams.map((_examId) => ({ id: _examId })),
      }
      dispatch(createType(createExamTypeDto))
    }
    if (onClose) onClose()
  }

  const handleCancelPress = () => {
    if (onClose) {
      onClose()
    }
    // Closes the update form - Datagrid didnt provide a prop function to close form ()
    const el = (document as Document).querySelector(
      `.MuiDataGrid-row[data-id="${row?.id}"] .MuiIconButton-root.MuiIconButton-sizeSmall`,
    ) as any
    if (el) {
      el.click()
    }
  }

  // Reducers
  const { sites } = useSelector(({ site }) => ({
    sites: site.sites.datas,
  }))
  // Map all sites
  const sitesOptions = useMemo(
    () =>
      sites.map((site) => ({
        value: site.id as number,
        label: site.label,
      })),
    [sites],
  )
  useEffect(() => {
    if (row) {
      const ids = row.exams.map((_exam) => _exam.id)
      setSelectedExams(ids)
    }
  }, [row])

  useEffect(() => {
    dispatch(findAllSites())
  }, [])

  const _onSelectedExamsChange = (values: number[] | string[]) => {
    setSelectedExams(values as number[])
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: 2,
      }}
    >
      <Grid item xs={12} xl={4}>
        <SelectInput
          name="siteId"
          options={sitesOptions}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} xl={4}>
        <TextInput name="code" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} xl={4}>
        <TextInput
          name="label"
          placeholder="label"
          errors={errors}
          control={control}
        />
      </Grid>

      <Grid item xs={12}>
        <SpaceVertical size={5} />
        <Divider variant="middle" />
        <SpaceVertical size={5} />
      </Grid>

      <Grid item xs={12}>
        <ExamSelectionTable
          selectedRows={selectedExams}
          onSelectionChange={_onSelectedExamsChange}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-end">
          {onClose && (
            <Grid item xs={2}>
              <Button
                onClick={handleCancelPress}
                color="secondary"
                text="cancel"
                textColor="white"
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <Button
              onClick={handleSubmit(onSubmit)}
              color="primary"
              text="save"
              textColor="white"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
