import {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  FormHelperText,
  Checkbox,
  Button,
  Tooltip,
  Icon,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import 'moment/locale/fr'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import ReactAutocomplete from 'react-autocomplete'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PatientDto, SexEnum } from '@services/api'
import { RppsAutocompleteByNameInput } from '@components/RppsAutocompleteByNameInput'
import { DatePicker } from '@mui/lab'
import { BanService } from '@services/BanServices'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { makeStyles } from '@mui/styles'
import cartevital from '@assets/img/cartevital.png'
import logoInsi from '@assets/img/logoINSi.png'
import logoADRi from '@assets/img/logoADRi.png'
import NirhistoryModal from '../NirhistoryModal'
import { patientFormSchema } from '@utils/schemas'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  doCreatePatient,
  fetchOnePatientById,
  doGetCouverture,
} from '@state/thunks/patientsThunk'
import { updatePatient } from '@state/thunks/authThunk'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { doFindOrders, doUpdateOrder } from '@state/reducers/orderReducer'
import CouvertureForm from './CouvertureForm'
import {
  DocumentDTO,
  FindPatientCoverageQuery,
  PatientBrifDTO,
} from '@services/dtos'
import VitalCardReaderComponent from '@components/patient/VitalCardReaderComponent'
import { CouvertureDto } from 'src/common/interfaces'
import {
  doCreateCoverege,
  doGetPatientDocuments,
  setCouverture,
  setPatientDocuments,
} from '@state/reducers/patientsReducer'
import AsyncAddressAutocomplete from '@components/AddressSearchAutocomplete'
import DocumentsList from '@components/documents/DocumentsList'
import DocumentListViewer from '@components/dialogs/DocumentListViewer'
import { DocumentService } from '@services/StorageService'
import { SSNInput } from '../../../../components/inputs/SSNInput'
import { formatSsn } from '../../../../utils/ssnUtil'

export interface RegisterPatientRef {
  saveForm: () => Promise<PatientDto | null>
}

type FormAddPatientProps = {
  onClose?: () => void
  patientObj?: any
  patientId?: number
  onSubmitted?: (patient: PatientDto) => void
  onSuccess?: (patient: PatientDto) => void
  hideCloseButton?: boolean
  containerClassName?: string
  visitId?: number
  onCoverageChange?: (coverage: CouvertureDto | null) => void
  isRegistration?: boolean
}

export const RegisterPatientForm = forwardRef<
  RegisterPatientRef,
  FormAddPatientProps
>(
  (
    {
      onClose,
      patientId,
      onSuccess,
      hideCloseButton,
      containerClassName,
      visitId,
      onCoverageChange,
      isRegistration,
    },
    ref,
  ) => {
    const {
      register,
      getValues,
      trigger,
      handleSubmit,
      setValue,
      watch,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm<PatientBrifDTO>({
      resolver: yupResolver(patientFormSchema),
    })

    const { patient, selectedOrder, coverage, documents } = useAppSelector(
      ({ patients, order }) => ({
        patient: patients.currentPatientInfo,
        selectedOrder: order.selectedOrder,
        coverage: patients.patientCouverture,
        documents: patients.documents,
      }),
    )

    const [activeForm, setActiveForm] = useState<string>('PatientInfos')

    let birthDate = watch('birthDate')
    const sex = watch('sex')
    const birthPlaceDepartment = watch('birthPlaceDepartment')
    const ssn = watch('ssn')
    const birthPlace = watch('birthPlace')

    const [isManualAddressVisible, setIsManualAddressVisible] = useState(false)

    const [isManualBirthPlaceVisible, setIsManualBirthPlaceVisible] =
      useState(false)
    const [birthPlaceSearch, setBirthPlaceSearch] = useState('')
    const [birthPlaceExtra, setBirthPlaceExtra] = useState('')
    const [birthPlaceOptions, setBirthPlaceOptions] = useState([
      { label: '', value: '' },
    ])
    const [isVitalCardReadOpen, setIsVitalCardReadOpen] = useState(false)
    const [formatedBirthDate, setformatedBirthDate] = useState('')

    const [isControlled, setIsControlled] = useState(false)
    const [isEditingDocuments, setIsEditingDocuments] = useState(false)
    const [selectedDocument, setSelectedDocument] =
      useState<DocumentDTO | null>(null)

    const ins = watch('ins')
    const phoneNumber = watch('phoneNumber')
    const address = watch('address')
    const familyDoctor = watch('familyDoctor')

    const dispatch = useAppDispatch()
    const intl = useIntl()

    const [isModalHistoryOpen, setIsModalHistoryOpen] = useState(false)

    const handleCloseModal = () => {
      setIsModalHistoryOpen(false)
    }

    useImperativeHandle(ref, () => ({
      saveForm: async () => {
        const r = await trigger()
        if (!r) {
          return null
        }
        const values = getValues()
        return onSubmit(values)
      },
    }))

    const banService = useRef(new BanService()).current

    const useStyles = makeStyles({
      phoneInput: {
        '& .PhoneInputInput': {
          backgroundColor: 'transparent',
          border: 'none',
          textColor: 'black',
          height: '100%',
        },
      },
    })

    useEffect(() => {
      if (!patient) {
        return
      }
      setValue('sex', patient?.sex || SexEnum.M)
      setValue('firstName', patient?.firstName || '')
      setValue('lastName', patient?.lastName || '')
      setValue('maidenName', patient?.surName || '')
      setValue('phoneNumber', patient?.phoneNumber || '')
      setValue('email', patient?.email || '')
      setValue('address', patient?.address || '')

      birthDate = new Date(patient?.birthDate || '')
      setValue('birthDate', new Date(patient?.birthDate || ''))
      setValue('birthPlace', patient?.placeOfBirth || '')
      if (patient?.placeOfBirth === undefined && patient?.ssn !== undefined) {
        setValue('birthPlace', ' ')
        setBirthPlaceExtra(patient?.ssn.slice(5, 7) + '000')
      }
      setValue('ssn', patient?.ssn || '')
      setValue('weight', patient?.weight || 0)
      setValue('height', patient?.height || 0)
      setValue('familyDoctor', patient?.familyDoctor as any)
      setIsManualBirthPlaceVisible(true)
    }, [patient])

    useEffect(() => {
      if (!patientId) {
        return
      }
      const q: FindPatientCoverageQuery = { idPatient: patientId }

      if (visitId) {
        q.idVisit = visitId
      }

      dispatch(fetchOnePatientById({ id: patientId }))
        .unwrap()
        .then((p) => {
          if (onSuccess) {
            onSuccess(p)
          }
        })
      dispatch(doGetCouverture(q))
        .unwrap()
        .then((res) => {
          if (!res) {
            dispatch(doGetCouverture({ idPatient: patientId }))
          }
        })
      dispatch(doGetPatientDocuments(patientId))
    }, [dispatch, patientId, visitId])

    useEffect(() => {
      if (!coverage) {
        return
      }
      setValue('firstName', coverage.beneficiairePrenom)
      setValue('lastName', coverage.beneficiaireNom)

      setValue('maidenName', coverage.beneficaireNomUsuel)
      const birthDate = moment(coverage.beneficaireDateNaissance, 'YYYYMMDD')
      // check if date is valid
      if (birthDate.isValid()) {
        setValue('birthDate', birthDate.toDate())
      } else {
        setValue('birthDate', undefined)
      }
      setValue('ssn', coverage.beneficiaireNir)
      if (coverage.beneficiaireNir) {
        const sex = coverage.beneficiaireNir[0] === '2' ? SexEnum.F : SexEnum.M
        setValue('sex', sex)
      }
    }, [coverage])

    useEffect(() => {
      if (ref) {
        setIsControlled(true)
      }
    }, [ref])

    useEffect(() => {
      return () => {
        dispatch(setPatientDocuments([]))
      }
    }, [])

    const onSubmit = async (data: PatientBrifDTO) => {
      let _patient: PatientDto | null = null

      if (patientId) {
        _patient = await dispatch(
          updatePatient({
            id: patientId,
            body: {
              firstName: data.firstName,
              lastName: data.lastName,
              surName: data.maidenName,
              sex: data.sex,
              birthDate: data.birthDate,
              address: data.address,
              placeOfBirth: data.birthPlace,
              phoneNumber: data.phoneNumber,
              email: data.email,
              weight: data.weight,
              height: data.height,
              ssn: formatSsn(data.ssn),
              familyDoctor: data.familyDoctor,
            },
          }),
        ).unwrap()

        dispatch(
          enqueueSnackbar({
            message: intl.formatMessage({
              id: 'notifications.patient-update-success',
            }),
            options: { variant: 'success' },
          }),
        )
        if (selectedOrder) {
          const m = moment()
          dispatch(
            doUpdateOrder({
              id: selectedOrder.id,
              dto: {
                registerDate: m.format('DD-MM-YYYY'),
                registerTime: m.format('HH:mm'),
              },
            }),
          ).then(() => {
            dispatch(doFindOrders())
          })
        }
      } else {
        _patient = await dispatch(
          doCreatePatient({
            firstName: data.firstName,
            lastName: data.lastName,
            maidenName: data.maidenName,
            sex: data.sex,
            birthDate: data.birthDate,
            address: data.address,
            placeOfBirth: data.birthPlace,
            phoneNumber: data.phoneNumber,
            email: data.email,
            weight: data.weight,
            height: data.height,
            ssn: formatSsn(data.ssn),
            familyDoctor: data.familyDoctor,
          }),
        ).unwrap()

        if (_patient && documents.length > 0) {
          const pId = _patient.id
          console.log(documents)
          for (const d of documents) {
            await DocumentService.updateDocuments({
              id: d.id,
              dto: { entityId: pId, entityName: 'patient' },
            })
          }
        }
      }
      if (onSuccess) {
        onSuccess(_patient)
      }
      return _patient
    }

    const classes = useStyles()

    useEffect(() => {
      onCoverageChange && onCoverageChange(coverage)
    }, [coverage])

    const setIsManualBirthPlaceVisibleAndRemouve = (checked: boolean) => {
      setIsManualBirthPlaceVisible(checked)
      if (!checked) {
        setBirthPlaceExtra('')
      }
    }

    const debouncedSearchBirth = useCallback(
      debounce((searchTerm) => {
        banService
          .search(searchTerm)
          .then((data) => {
            if (data.length > 0) {
              const storeData = data.map((d) => ({
                label: d.place,
                value: d.id,
              }))
              setBirthPlaceOptions(storeData)
            }
          })
          .catch((error) => {
            console.error('Failed to fetch addresses:', error)
          })
      }, 500),
      [],
    )

    useEffect(() => {
      debouncedSearchBirth(birthPlaceSearch)
    }, [birthPlaceSearch, debouncedSearchBirth])

    const onBirthPlaceChange = (value: string) => {
      const addr = value.replace(/\s/g, '-')
      setBirthPlaceSearch(addr)
      setValue('birthPlace', addr)
    }

    const searchPatient = async (withVitalCard: boolean) => {
      if (withVitalCard) {
        setIsVitalCardReadOpen(true)
      }
    }

    const handleReadVitalCard = async () => {
      setIsVitalCardReadOpen(true)
    }
    const handleVCReadFinish = async (dto: CouvertureDto) => {
      dispatch(setCouverture(dto))
      setIsVitalCardReadOpen(false)
      if (patientId && visitId) {
        dispatch(
          doCreateCoverege({
            patientId,
            dto: { ...dto, idVisit: visitId, idPatient: patientId },
          }),
        )
      }
    }

    const handleSaveForm = async (data: CouvertureDto) => {
      // TODO : check if a coverage already exists attached to this visit
      if (!coverage || !patientId) {
        return
      }
      dispatch(
        doCreateCoverege({
          patientId,
          dto: data,
        }),
      )
    }

    const handleDocumentDeleted = (doc: DocumentDTO) => {
      dispatch(setPatientDocuments(documents.filter((d) => d.id !== doc.id)))
    }

    const handleDocumentAdded = (doc: DocumentDTO) => {
      dispatch(setPatientDocuments([...documents, doc]))
    }

    const coverageUpdateRequired = coverage?.idVisit !== visitId

    return (
      <div>
        <div className="py-4 mt-2">
          {coverageUpdateRequired && <span>La couverture est obsolète.</span>}
        </div>
        <div className="flex flex-row justify-start items-center p-2">
          <Tooltip placement="top" title="Appel ADRi">
            <button onClick={() => searchPatient(true)}>
              <img
                src={logoADRi}
                alt="logoADRi"
                height={65}
                width={65}
                className="transition-opacity duration-300 group-hover:opacity-50"
              />
            </button>
          </Tooltip>
          <Tooltip placement="top" title="Lire la carte vitale">
            <div
              onClick={handleReadVitalCard}
              className="relative cursor-pointer group"
            >
              <Icon
                className="fas fa-search absolute"
                fontSize="medium"
                sx={{
                  color: '#3F3F3F',
                  position: 'absolute',
                  top: '16px',
                  left: '28px',
                  zIndex: 10,
                }}
              />
              <img
                src={cartevital}
                alt="carte vitale"
                className="w-[75px] transition-opacity duration-300 group-hover:opacity-50"
              />
            </div>
          </Tooltip>
        </div>
        <ToggleButtonGroup
          value={activeForm}
          exclusive
          onChange={(_, v) => {
            if (v) {
              setActiveForm(v)
            }
          }}
          size="small"
          sx={{
            position: 'sticky',
            top: '0',
            zIndex: 1001,
            backgroundColor: 'white',
          }}
        >
          <ToggleButton sx={{ width: '100px' }} value="PatientInfos">
            Infos
          </ToggleButton>
          <ToggleButton
            disabled={!visitId || !patientId}
            sx={{ width: '100px' }}
            value="Couverture"
          >
            Couverture
          </ToggleButton>
          <ToggleButton sx={{ width: '100px' }} value="Transport">
            Transport
          </ToggleButton>
          <ToggleButton sx={{ width: 'auto' }} value="Documents">
            Documents
          </ToggleButton>
        </ToggleButtonGroup>
        <div className={containerClassName}>
          {activeForm === 'PatientInfos' && (
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} p={2}>
                  <Grid item xs={12}>
                    <RadioGroup
                      row
                      {...register('sex')}
                      aria-labelledby="sexe"
                      defaultValue={'M'}
                    >
                      <FormControlLabel
                        style={{ color: '#48bef9', fontWeight: 'bold' }}
                        value="M"
                        control={
                          <Radio
                            sx={{
                              color: '#48bef9',
                              '&.Mui-checked': {
                                color: '#48bef9',
                              },
                            }}
                          />
                        }
                        label="Mr"
                      />
                      <FormControlLabel
                        value="F"
                        style={{ color: '#f48dde', fontWeight: 'bold' }}
                        control={
                          <Radio
                            sx={{
                              color: '#f48dde',
                              '&.Mui-checked': {
                                color: '#f48dde',
                              },
                            }}
                          />
                        }
                        label="Mme"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      label="Prénom"
                      variant="outlined"
                      {...register('firstName')}
                      fullWidth
                      inputProps={{ maxLength: 35, shrink: true }}
                      InputLabelProps={{ shrink: true }}
                    />
                    {errors?.firstName && (
                      <FormHelperText error>
                        {errors?.firstName?.message}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      label="Nom"
                      {...register('lastName')}
                      variant="outlined"
                      fullWidth
                      inputProps={{ maxLength: 35 }}
                      InputLabelProps={{ shrink: true }}
                    />
                    {errors?.lastName && (
                      <FormHelperText error>
                        {errors?.lastName?.message}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      label="Nom de naissance"
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 35 }}
                      {...register('maidenName')}
                    />
                    {errors?.maidenName && (
                      <FormHelperText error>
                        {errors?.maidenName?.message}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {!formatedBirthDate && (
                      <DatePicker
                        value={moment(birthDate)}
                        onChange={(ev) => {
                          setValue('birthDate', ev ? ev.toDate() : new Date())
                        }}
                        inputFormat="DD/MM/YYYY"
                        openTo="year"
                        views={['year', 'month', 'day']}
                        disableFuture
                        label={
                          <FormattedMessage
                            id="birthDate"
                            defaultMessage="Date de naissance"
                          />
                        }
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: '100%' }} />
                        )}
                      />
                    )}
                    {formatedBirthDate && (
                      <div className="px-4 py-2 border border-1 border-gray-200">
                        {formatedBirthDate}
                      </div>
                    )}
                    {errors?.birthDate && (
                      <FormHelperText error>
                        {errors?.birthDate?.message}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <SSNInput
                      onChange={(v) => {
                        console.log('ssn', v)
                        setValue('ssn', v)
                      }}
                      birthDate={birthDate ?? undefined}
                      sex={sex as 'M' | 'F' | undefined}
                      birthPlace={birthPlace ?? undefined}
                      value={ssn}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <PhoneInput
                      name="phoneNumber"
                      labels={{ number: 'Numéro de ' }}
                      placeholder="Phone Number"
                      value={phoneNumber}
                      onChange={(v) => setValue('phoneNumber', v)}
                      defaultCountry="FR"
                      international
                      style={{
                        height: '54px',
                        fontSize: '1em',
                        border: '1px solid #ADB1BA',
                        backgroundColor: 'transparent',
                        borderRadius: '3px',
                        padding: '10px',
                      }}
                      className={classes.phoneInput}
                    />
                    {errors?.phoneNumber && (
                      <FormHelperText error>
                        {errors?.phoneNumber?.message}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      type="email"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      {...register('email')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      sx={{
                        paddingRight: '22px',
                      }}
                    >
                      {!isManualAddressVisible && (
                        <AsyncAddressAutocomplete
                          onChange={(v) => setValue('address', v)}
                          value={address}
                          defaultSearchText={patient?.address}
                        />
                      )}
                    </Grid>
                    {isManualAddressVisible && (
                      <fieldset className="border border-1 border-gray-200 px-2 pb-2">
                        <span className="text-sm font-light text-gray-700">
                          Adresse
                        </span>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isManualAddressVisible}
                                onChange={(e) =>
                                  setIsManualAddressVisible(e.target.checked)
                                }
                                color="primary"
                              />
                            }
                            label={
                              isManualAddressVisible
                                ? 'Retour à la recherche'
                                : 'Vous ne trouvez pas la bonne adresse ?'
                            }
                          />
                        </div>
                        <Grid container sx={{ mt: 2 }}>
                          <Grid xs={12}>
                            <TextField
                              variant="outlined"
                              {...register('address')}
                              label={
                                <FormattedMessage
                                  id="adress"
                                  defaultMessage="Ligne 1"
                                />
                              }
                              fullWidth
                              error={Boolean(errors?.address)}
                              helperText={errors?.address?.message}
                              defaultValue={address}
                              InputLabelProps={{ shrink: true }}
                              placeholder='Ex: "1 rue de la paix, 75000 Paris"'
                              size="small"
                            />
                            <TextField
                              className="!mt-2"
                              label="Ligne 2"
                              variant="outlined"
                              fullWidth
                              size="small"
                            />
                            <div className="flex flex-row gap-2 mt-2">
                              <TextField
                                label="Code postal"
                                variant="outlined"
                                type="number"
                                fullWidth
                                size="small"
                              />
                              <TextField
                                label="Ville"
                                variant="outlined"
                                fullWidth
                                size="small"
                              />
                              <TextField
                                label="Pays"
                                variant="outlined"
                                fullWidth
                                size="small"
                                defaultValue="France"
                              />
                            </div>
                          </Grid>
                          {errors?.address && (
                            <FormHelperText error>
                              {errors?.address?.message}
                            </FormHelperText>
                          )}
                        </Grid>
                      </fieldset>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <RppsAutocompleteByNameInput
                      {...register('familyDoctor')}
                      label={
                        <FormattedMessage
                          id="familyDoctor"
                          defaultMessage="Médecin traitant"
                        />
                      }
                      onChange={(value) =>
                        setValue('familyDoctor', value as any)
                      }
                      value={familyDoctor}
                    />
                    {errors?.familyDoctor && (
                      <FormHelperText error>
                        {(errors.familyDoctor as any)?.message}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Grid
                      container
                      sx={{
                        paddingRight: '22px',
                      }}
                    >
                      {!isManualBirthPlaceVisible && (
                        <>
                          <ReactAutocomplete
                            menuStyle={{
                              borderRadius: '3px',
                              boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                              background: '#fff',
                              padding: '10px',
                              fontSize: '1em',
                              position: 'fixed',
                              overflow: 'auto',
                              maxHeight: '50%',
                              zIndex: 100,
                            }}
                            inputProps={{
                              style: {
                                width: '100%',
                                height: '34px',
                                fontSize: '1em',
                                border: '1px solid #ADB1BA',
                                backgroundColor: 'transparent',
                                focus: 'black',
                                borderRadius: '3px',
                                padding: '10px',
                              },
                              hover: {
                                border: '1px solid #000000de',
                              },
                              disabled: isManualBirthPlaceVisible,
                              placeholder: 'Lieu de naissance',
                            }}
                            wrapperStyle={{ width: '100%' }}
                            getItemValue={(item) => item.label}
                            items={birthPlaceOptions}
                            renderItem={(item: any, isHighlighted: any) => (
                              <div
                                style={{
                                  background: isHighlighted
                                    ? 'lightblue'
                                    : 'white',
                                  cursor: 'pointer',
                                  padding: '5px',
                                }}
                              >
                                {item.label}
                              </div>
                            )}
                            value={birthPlaceSearch}
                            onChange={(e) =>
                              setBirthPlaceSearch(
                                e.target.value.replace(/\s/g, '-'),
                              )
                            }
                            onSelect={onBirthPlaceChange}
                          />
                        </>
                      )}
                    </Grid>
                    {isManualBirthPlaceVisible && (
                      <Grid container spacing={1} justifyItems="space-between">
                        <Grid item xs={8}>
                          <TextField
                            size="small"
                            {...register('birthPlace')}
                            variant="outlined"
                            label={
                              <FormattedMessage
                                id="birthPlace"
                                defaultMessage="Lieu de naissance"
                              />
                            }
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            placeholder='Ex: "Paris"'
                            inputProps={{ maxLength: 35 }}
                          />
                        </Grid>
                        {errors?.birthPlace && (
                          <FormHelperText error>
                            {errors?.birthPlace?.message}
                          </FormHelperText>
                        )}
                        <Grid item xs={4}>
                          <TextField
                            size="small"
                            {...register('birthPlaceDepartment')}
                            variant="outlined"
                            label="Departement"
                            placeholder='Ex: "13000"'
                            type="number"
                            fullWidth
                            inputProps={{ maxLength: 5 }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        {errors?.birthPlaceDepartment && (
                          <FormHelperText error>
                            {errors?.birthPlaceDepartment?.message}
                          </FormHelperText>
                        )}
                      </Grid>
                    )}
                    <Grid item justifyContent="space-between" container>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isManualBirthPlaceVisible}
                            onChange={(e) =>
                              setIsManualBirthPlaceVisibleAndRemouve(
                                e.target.checked,
                              )
                            }
                            color="primary"
                          />
                        }
                        label={
                          isManualBirthPlaceVisible
                            ? 'Retour à la recherche'
                            : "Introuvable / Né(e) à l'étranger ?"
                        }
                      />
                      {isManualBirthPlaceVisible && (
                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: '0.75rem',
                            color: 'grey',
                            textAlign: 'center',
                          }}
                        >
                          <FormattedMessage
                            id="birthPlaceTip"
                            defaultMessage="99 pour l'étranger"
                          />
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      {...register('weight', {
                        valueAsNumber: true,
                        onBlur: (e) => {
                          const value = e.target.value
                          if (!value) {
                            if (errors.weight) {
                              clearErrors('weight')
                            }
                          }
                          if (value < 1) {
                            setError('weight', {
                              message: 'Le poids doit être supérieur à 1',
                            })
                          } else if (value > 200) {
                            setError('weight', {
                              message: 'Le poids ne peut pas dépasser 200',
                            })
                          } else if (errors.weight) {
                            clearErrors('weight')
                          }
                        },
                      })}
                      label="Poids"
                      variant="outlined"
                      type="number"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">kg</InputAdornment>
                        ),
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        inputMode: 'numeric',
                      }}
                    />
                    {errors?.weight && (
                      <FormHelperText error>
                        {errors?.weight?.message}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      {...register('height', {
                        valueAsNumber: true,
                        onBlur: (e) => {
                          const value = e.target.value
                          if (!value) {
                            if (errors.height) {
                              clearErrors('height')
                            }
                          } else if (value < 50) {
                            setError('height', {
                              message: 'La taille doit être supérieure à 50',
                            })
                          } else if (value > 250) {
                            setError('height', {
                              message: 'La taille ne peut pas dépasser 250',
                            })
                          } else if (errors.height) {
                            clearErrors('height')
                          }
                        },
                      })}
                      label="Taille"
                      variant="outlined"
                      type="number"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">cm</InputAdornment>
                        ),
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        inputMode: 'numeric',
                      }}
                    />
                    {errors?.height && (
                      <FormHelperText error>
                        {errors?.height?.message}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      size="small"
                      label="Oid"
                      variant="outlined"
                      type="oid"
                      fullWidth
                      {...register('iod')}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex' }}>
                    <Grid xs={8}>
                      <TextField
                        size="small"
                        label="Ins"
                        variant="outlined"
                        type="ins"
                        fullWidth
                        {...register('ins')}
                      />
                    </Grid>
                    <Grid
                      xs={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={ins ? 'Vérifier' : 'Envoyer une vérification'}
                      >
                        <Button
                          variant="text"
                          color={ins ? 'primary' : 'success'}
                          sx={{ borderRadius: '100px' }}
                        >
                          {!ins ? (
                            <Icon className="fa fa-check" />
                          ) : (
                            <Icon className="fa fa-times" />
                          )}
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid
                      xs={2}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Tooltip placement="top" title="Historique des ins">
                        <Button
                          variant="text"
                          sx={{ borderRadius: '100px' }}
                          onClick={() => setIsModalHistoryOpen(true)}
                        >
                          <Icon className="fa fa-history" />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      {...register('comments')}
                      label="Comments"
                      variant="outlined"
                      inputProps={{ maxLength: 500 }}
                      fullWidth
                      multiline
                      rows={4}
                    />
                    {errors?.comments && (
                      <FormHelperText error>
                        {errors?.comments?.message}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid xs={5}></Grid>
                  <div className="mt-4 flex flex-row items-center gap-4 w-full">
                    <button
                      className="bg-blue-500 w-1/2 flex flex-row items-center gap-1 py-2 px-4 rounded-lg text-white"
                      onClick={() => searchPatient(false)}
                    >
                      <img
                        src={logoInsi}
                        alt="logoInsi"
                        style={{ marginLeft: '5px' }}
                        height={40}
                        width={40}
                      />
                      <span className="text-white font-semibold ml-2">
                        Recherche sans carte vital
                      </span>
                    </button>
                    <button className="bg-blue-500 w-1/2 flex flex-row items-center gap-1 py-2 px-4 rounded-lg text-white">
                      <img src={logoInsi} alt="logoInsi" width={40} />
                      <span className="text-white font-semibold ml-2">
                        Vérifier
                      </span>
                    </button>
                  </div>
                </Grid>
                {isControlled === false && (
                  <div className="flex flex-row gap-4 sticky bottom-0 bg-white pt-2 border-t-1 border border-gray-200">
                    {!hideCloseButton && (
                      <button
                        className="bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded w-full"
                        onClick={() => {
                          if (onClose) {
                            onClose()
                          }
                        }}
                      >
                        Fermer
                      </button>
                    )}
                    <button
                      className={
                        'bg-indigo-700 hover:bg-indigo-800 text-white font-bold py-2 px-4 rounded w-full'
                      }
                      onClick={(ev) => {
                        ev.preventDefault()
                        handleSubmit(onSubmit)()
                      }}
                      type="submit"
                    >
                      {isRegistration ? 'Accueillir' : 'Enregistrer'}
                    </button>
                  </div>
                )}
                <NirhistoryModal
                  isOpen={isModalHistoryOpen}
                  onClose={handleCloseModal}
                />
              </form>
              <VitalCardReaderComponent
                visitId={visitId || -1}
                readDate={moment().format('YYYY-MM-DD')}
                isOpen={isVitalCardReadOpen}
                onFinish={handleVCReadFinish}
                onClose={() => setIsVitalCardReadOpen(false)}
              />
            </div>
          )}
          {activeForm === 'Couverture' && visitId && patientId && (
            <CouvertureForm
              onSubmit={handleSaveForm}
              visitId={visitId}
              patientId={patientId}
              isOutdated
              initialData={coverage}
              isControlled={isControlled}
            />
          )}
          {activeForm === 'Transport' && (
            <div className="font-bold text-lg text-center">WIP</div>
          )}
          {activeForm === 'Documents' && (
            <>
              <DocumentsList
                entityId={patientId}
                entityName="patient"
                documents={documents}
                isEditingDocuments={isEditingDocuments}
                setisEditingDocuments={setIsEditingDocuments}
                onDocumentDeleted={handleDocumentDeleted}
                handleOnFileUploaded={handleDocumentAdded}
                setSelectedDocument={setSelectedDocument}
              />
              <DocumentListViewer
                documents={documents}
                selectedDocument={selectedDocument}
                isOpen={!!selectedDocument}
                onClose={() => setSelectedDocument(null)}
                onSelectedDocumentChange={setSelectedDocument}
              />
            </>
          )}
        </div>
      </div>
    )
  },
)
