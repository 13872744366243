import { styled, Theme, CSSObject } from '@mui/material/styles'
import {
  Divider,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemIcon,
} from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import { ListItemLink } from '@components/lists'
import { memberLinks } from '../containers/app/member/HomeMember'
import { superAdminLinks } from '../containers/app/super-admin/HomeSuperAdmin'
import { AccountRoleEnum } from '@services/api'
import { logout } from '@state/thunks/authThunk'
import { useDispatch } from 'react-redux'
import Text from '@components/Text'
import logo from '@assets/img/logos/logo.png'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  marginBottom: '20px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))
type DrawerProps = {
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  role: AccountRoleEnum
}

export default function DrawerMenu({ toggleDrawer, open, role }: DrawerProps) {
  const dispatch = useDispatch()

  const toggle =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      toggleDrawer(open)
    }

  const handleLogout = () => {
    dispatch(logout())
    //TODO: Clear all
  }

  return (
    <Drawer
      variant="permanent"
      open={open}
      onClose={toggle(false)}
      onMouseEnter={toggle(true)}
      onMouseLeave={toggle(false)}
      PaperProps={{
        sx: {
          backgroundColor: '#3f3f3f',
        },
      }}
    >
      <DrawerHeader>
        <img
          src={logo}
          alt="logo"
          style={{
            width: '48px',
            alignSelf: 'center',
            marginTop: '20px',
          }}
        />
      </DrawerHeader>
      <Divider />

      <List>
        {role === AccountRoleEnum['super-admin'] &&
          superAdminLinks.map((link, key) => (
            <ListItemLink
              key={key}
              to={link.to}
              IconProps={{ className: link.icon }}
              text={link.title}
            />
          ))}
        {role === AccountRoleEnum.member &&
          memberLinks.map((link, key) => (
            <ListItemLink
              key={key}
              to={link.to}
              IconProps={{ className: link.icon }}
              text={link.title}
            />
          ))}
      </List>
      <Divider />
      <ListItem
        button
        sx={{ marginTop: 'auto', marginBottom: '5px' }}
        onClick={handleLogout}
      >
        <ListItemIcon>
          <LogoutIcon sx={{ marginRight: '20px', color: '#fff' }} />
          <Text text="logout" format color="#fff" />
        </ListItemIcon>
      </ListItem>
    </Drawer>
  )
}
