import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { DatePicker } from '@mui/lab'
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  LinearProgress,
} from '@mui/material'
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro'
import { ExternalId, PatientDto } from '@services/api'
import { setPatientsFilter } from '@state/reducers/patientsReducer'
import { fetchPatients } from '@state/thunks/patientsThunk'
import { patientSearchSchema } from '@utils/schemas'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { PatientInit } from 'src/containers/app/Worklists/DialogPatientFusion'
import _ from 'lodash'
import moment from 'moment'

type Props = {
  multipleSelection?: boolean
  onConfirm: (data: PatientDto | PatientDto[]) => void
  selectedRows: PatientDto[]
  onClose: () => void
  patientToSearch?: PatientDto | null
}

// TODO add pagination to the table
export const PatientTable: React.FC<Props> = ({
  multipleSelection,
  selectedRows,
  onConfirm,
  patientToSearch,
}) => {
  const { register, setValue, watch } = useForm<PatientInit>({
    resolver: yupResolver(patientSearchSchema),
  })
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const { patients, filters } = useAppSelector(({ patients }) => ({
    patients: patients.patients,
    filters: patients.patientsFilter,
  }))

  const birthDate = watch('birthDate')
  const ipp = watch('ipp')
  const firstName = watch('firstName')
  const lastName = watch('lastName')
  const originIpp = watch('originIpp')
  const sex = watch('sex')

  useEffect(() => {
    setIsLoading(true)
    dispatch(fetchPatients())
      .unwrap()
      .finally(() => {
        setIsLoading(false)
      })
  }, [filters])

  useEffect(() => {
    if (patientToSearch) {
      setValue('firstName', patientToSearch.firstName)
      setValue('lastName', patientToSearch.lastName)
    }
  }, [patientToSearch])

  const filterDebounce = useMemo(() => {
    return _.debounce((args) => {
      dispatch(
        setPatientsFilter({
          ...args,
        }),
      )
    }, 500)
  }, [])

  useEffect(() => {
    filterDebounce({ ipp, firstName, lastName, originIpp, birthDate, sex })
  }, [ipp, firstName, lastName, originIpp, birthDate, sex])

  const columns: GridColumns = useMemo(() => {
    const selectedRowIds = selectedRows?.map((row) => row.id) || []
    return [
      {
        headerName: 'ID',
        field: 'id',
        width: 100,
      },
      {
        headerName: 'external_id',
        field: 'external_id',
        width: 200,
        valueFormatter: ({ value }) => {
          return (value as ExternalId[]).map((eid) => eid.value).join(',')
        },
      },
      {
        headerName: 'Nom',
        field: 'lastName',
        width: 160,
      },
      {
        headerName: 'Prenom',
        field: 'firstName',
        width: 160,
      },
      {
        headerName: 'Date de naissance',
        field: 'birthDate',
        width: 160,
        valueFormatter: ({ value }) => {
          return moment(value as string).format('DD/MM/YYYY')
        },
      },
      {
        headerName: 'Sexe',
        field: 'sex',
        width: 160,
      },
      {
        headerName: 'Action',
        field: 'action',
        width: 200,
        renderCell: (params) => {
          const isSelected = selectedRowIds.includes(params.row.id)
          return (
            <Button
              variant="contained"
              onClick={() => {
                if (isSelected) {
                  onConfirm(
                    multipleSelection
                      ? selectedRows.filter((row) => row.id !== params.row.id)
                      : [],
                  )
                } else {
                  onConfirm(
                    multipleSelection
                      ? [...selectedRows, params.row]
                      : [params.row],
                  )
                }
              }}
              style={{
                color: isSelected ? 'white' : 'black',
                backgroundColor: isSelected ? '#ff7675' : '#55efc4',
                fontWeight: 'bold',
              }}
            >
              {isSelected ? 'Désélectionner' : 'Sélectionner'}
            </Button>
          )
        },
      },
    ] as GridColumns
  }, [selectedRows])

  return (
    <>
      <Grid container rowSpacing={2} spacing={1} mb={2}>
        <Grid item xs={12} md={6}>
          <RadioGroup
            row
            aria-labelledby="sexe"
            {...register('sex')}
            onChange={(ev) => setValue('sex', ev.target.value)}
          >
            <FormControlLabel
              style={{ color: '#48bef9', fontWeight: 'bold' }}
              value="M"
              control={
                <Radio
                  sx={{
                    color: '#48bef9',
                    '&.Mui-checked': {
                      color: '#48bef9',
                    },
                  }}
                  checked={sex === 'M'}
                />
              }
              label="Homme"
            />
            <FormControlLabel
              value="F"
              style={{ color: '#f48dde', fontWeight: 'bold' }}
              control={
                <Radio
                  value="F"
                  sx={{
                    color: '#f48dde',
                    '&.Mui-checked': {
                      color: '#f48dde',
                    },
                  }}
                  checked={sex === 'F'}
                />
              }
              label="Femme"
            />
            <Button
              onClick={() => {
                setValue('sex', undefined)
              }}
            >
              <i className="fas fa-minus-square" style={{ fontSize: 24 }}></i>
            </Button>
          </RadioGroup>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Nom (ou nom de naissance)"
            fullWidth
            {...register('lastName')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            id="lastNameA"
            label="Prénom"
            fullWidth
            {...register('firstName')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            label="Date de naissance"
            value={birthDate === '' ? null : birthDate}
            onChange={(newValue) =>
              newValue === null ? null : setValue('birthDate', newValue)
            }
            renderInput={(props) => (
              <TextField size="small" fullWidth {...props} />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            id="ippA"
            label="IPP"
            {...register('ipp')}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            id="originIppA"
            label="IPP Origine"
            fullWidth
            {...register('originIpp')}
          />
        </Grid>
      </Grid>
      <DataGridPro
        rows={patients}
        getRowId={(row) => row.id}
        columns={columns}
        sx={{ height: '800px' }}
        {...(multipleSelection && {
          selectionModel: selectedRows?.map((row) => row.id) || [],
        })}
        disableMultipleSelection
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={isLoading}
      />
    </>
  )
}
