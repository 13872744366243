import { createAsyncThunk } from '@reduxjs/toolkit'
import { CardReaderDto, getUrl } from '@services/CardReadService'
import { Actions } from '@state/actions'

export const getCartVitalUrl = createAsyncThunk<CardReaderDto, void, any>(
  Actions.GET_VITAL_URL,
  async () => {
    return getUrl()
  },
)
