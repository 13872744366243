import { useForm } from 'react-hook-form'
import { Button } from '../../../components/buttons'
import { SelectInput } from '../../../components/inputs'
import Text from '../../../components/Text'
import { CheckBoxInput } from '../../../components/inputs'
import {
  ClientPreferences,
  SharingPermissionEnum,
  UpdateClientDto,
} from '@services/api'
import { Grid,  } from '@mui/material'

type SharingPreferencesFormProps = {
  preferences: ClientPreferences
  onSubmit: (values: UpdateClientDto) => void
  loading: boolean
}

type Values = {
  maxPermission: SharingPermissionEnum
  ownershipAccept: boolean
  permission: SharingPermissionEnum
  expiration: number
}
export function SharingPreferencesForm({
  preferences,
  onSubmit,
  loading,
}: SharingPreferencesFormProps) {
  const { sharing } = preferences
  const { handleSubmit, control } = useForm<Values>({
    defaultValues: sharing,
  })

  const beforeSubmit = (values: Values) => {
    onSubmit({
      preferences: {
        ...preferences,
        sharing: values,
      },
    })
  }
  const maxPermissionOptions = [
    {
      label: 'View Only',
      value: 'VIEW',
    },
    {
      label: 'View and Report',
      value: 'REPORT',
    },
    {
      label: 'View and Report and Export',
      value: 'EXPORT',
    },
    {
      label: 'Owner',
      value: 'OWNER',
    },
  ]

  const defaultPerm = [
    {
      label: 'View Only',
      value: 'VIEW',
    },
    {
      label: 'View and Report',
      value: 'REPORT',
    },
    {
      label: 'View and Report and Export',
      value: 'EXPORT',
    },
  ]

  const defaultExpiration = [
    {
      label: 'No Expiration',
      value: 0,
    },
    {
      label: '1 week',
      value: 7,
    },
    {
      label: '2 weeks',
      value: 14,
    },
    {
      label: '3 weeks',
      value: 21,
    },
    {
      label: '1 month',
      value: 30,
    },
    {
      label: '2 months',
      value: 60,
    },
  ]

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: 2,
        borderRadius: 5,
      }}
    >
      <Grid item xs={12}>
        <Text text="Defaults" color="textSecondary" />
      </Grid>
      <Grid item xs={12} xl={6}>
        <SelectInput
          options={maxPermissionOptions}
          name="maxPermission"
          control={control}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <CheckBoxInput
          name="ownershipAccept"
          control={control}
          label="Automatically Accept Exam Ownership Offers"
        />
      </Grid>
      <Grid item xs={12}>
        <Text text="New Share Defaults" color="textSecondary" />
      </Grid>
      <Grid item xs={12} xl={6}>
        <SelectInput
          options={defaultPerm}
          name="permission"
          control={control}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <SelectInput
          options={defaultExpiration}
          name="expiration"
          control={control}
        />
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        sx={{ marginTop: '2px' }}
      >
        <Grid item xs={2}>
          <Button
            text="updateRules"
            textColor="white"
            onClick={handleSubmit(beforeSubmit)}
            color="primary"
            loading={loading}
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
