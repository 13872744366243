import {
  IRequestConfig,
  MedicalOrder,
  PaginatedQuery,
  UpdateMedicalOrderDto,
  axios,
  getConfigs,
} from './api'
import queryString from 'querystring'
import { PaginatedDto } from './extendedType'

export interface FindMedicalOrdersQuery extends PaginatedQuery {
  accessionNumber?: string
  clientId?: string
  includeTransport?: boolean
  siteCode?: string
  apiKey?: string
  search?: string
  isTodayOnly?: boolean
  order?: 'asc' | 'desc'
  sortBy?: string
}

export class MedicalOrderService {
  private static readonly endpoint = 'medical-order'

  static get(
    query: FindMedicalOrdersQuery,
  ): Promise<PaginatedDto<MedicalOrder>> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint + '?' + queryString.stringify({ ...query }),
        {},
      )
      configs.headers = {
        'x-api-key': query.apiKey,
      }

      axios(configs, resolve, reject)
    })
  }
  static update(id: number, dto: UpdateMedicalOrderDto): Promise<MedicalOrder> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        this.endpoint + '/' + id,
        {},
      )
      configs.data = dto

      axios(configs, resolve, reject)
    })
  }
}
