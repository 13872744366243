import { PanelResizeHandle } from 'react-resizable-panels'

import styles from './ResizeHandle.module.css'

type Props = {
  className?: string
  id?: string
  horizontal?: boolean
}
export default function ResizeHandle({
  className = '',
  id,
  horizontal = false,
}: Props) {
  return (
    <PanelResizeHandle
      className={[styles.ResizeHandleOuter, className].join(' ')}
      style={{ backgroundColor: '#F3F4F6' }}
      id={id}
    >
      <div className={styles.ResizeHandleInner}>
        <svg
          className={`${styles.Icon} ${horizontal ? 'rotate-90' : ''}`}
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z"
          />
        </svg>
      </div>
    </PanelResizeHandle>
  )
}
