import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { setTerm } from '@state/reducers/testReducer'
import { Button } from '../../../components/buttons'
import { DateInput, SelectInput, TextInput } from '../../../components/inputs'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

type EditTermsTemplateDialogProps = {
  field: any

  //   {
  //   id: number
  //   status: string
  //   effectiveDate: string
  //   timezone: string
  //   terms: string
  //   changeSummary: string
  //   content: string
  // }

  onClose: () => void
  open: boolean
}

type Value = {
  id: number
  status: string
  effectiveDate: string
  timezone: string
  terms: string
  changeSummary: string
  content: string
}

export function EditAddTermsTemplateDialog({
  field,
  open,
  onClose,
}: EditTermsTemplateDialogProps) {
  const { id, status, effectiveDate, timezone, terms, changeSummary, content } =
    field

  const classes = useStyles()
  const dispatch = useDispatch()
  // const [open, setOpen] = React.useState(false)
  const { handleSubmit, control, register } = useForm<Value>({
    defaultValues: {
      id: id,
      status: status,
      effectiveDate: effectiveDate,
      timezone: timezone,
      terms: terms,
      changeSummary: changeSummary,
      content: content,
    },
  })

  const onSubmit = (values: Value) => {
    if (
      values.status === '' ||
      values.effectiveDate === '' ||
      values.timezone === '' ||
      values.terms === '' ||
      values.changeSummary === '' ||
      values.content === ''
    ) {
      alert('Thanks for feeding all fields')
    } else {
      values = { ...values, id: id, status: status }
      dispatch(setTerm(values))
      onClose()
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        {id ? `Edit ${terms}` : 'Add Term'}
      </DialogTitle>
      <DialogContent>
        <form className={classes.section} noValidate>
          <Grid container spacing={3}>
            <Grid xs={12} item>
              <DateInput name={'effectiveDate'} control={control} />
            </Grid>
            <Grid xs={12} item>
              <SelectInput
                options={[
                  { label: 'Eastern', value: 'Eastern' },
                  { label: 'Central', value: 'Central' },
                  { label: 'Mountain', value: 'Mountain' },
                  { label: 'Pacific', value: 'Pacific' },
                ]}
                name={'timezone'}
                control={control}
              />
            </Grid>
            <Grid xs={12} item>
              <TextInput name={'content'} inputRef={register} />
            </Grid>
            <Grid xs={12} item>
              <TextInput name={'changeSummary'} inputRef={register} />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit(onSubmit)}
          color="primary"
          text="Save Changes"
        />
        <Button onClick={onClose} color="secondary" text="Close" />
      </DialogActions>
    </Dialog>
  )
}
