import { Control, Controller } from 'react-hook-form'

import { MenuItem, ListItemText, Checkbox, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { TextInputProps } from './TextInput'

const ITEM_HEIGHT = 96
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type MultiSelectInputProps = {
  options: { value: string | number; label: string }[]
  control?: Control<any>
  fullWidth?: boolean
}

const useStyles = makeStyles({
  textInput: {
    borderRadius: 5,
  },
})

export function MultipleSelectInput({
  options,
  control,
  name,
  fullWidth = true,
  ...props
}: MultiSelectInputProps & TextInputProps) {
  const classes = useStyles()

  const handleChange = (event: any) => {
    if (props.onChange) {
      props.onChange(event)
    }
  }
  const value = props.value as string

  return control ? (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...props}
          {...field}
          className={classes.textInput}
          fullWidth={fullWidth}
          style={{ borderRadius: 5 }}
          InputProps={{
            style: {
              backgroundColor: '#fff',
            },
          }}
          variant="outlined"
          size="small"
          InputLabelProps={{
            style: {
              color: '#464855',
            },
          }}
          select
          SelectProps={{
            multiple: true,
            value: props.value,
            MenuProps: MenuProps,
            onChange: handleChange,
            //onClose: handleClose,
            renderValue: (selected) => (selected as string[]).join(', '),
          }}
        >
          {options.map((opt, i) => (
            <MenuItem key={i} value={opt.label}>
              <Checkbox checked={value.indexOf(opt.label) > -1} />
              <ListItemText primary={opt.label} />
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  ) : (
    <TextField
      {...props}
      className={classes.textInput}
      fullWidth={fullWidth}
      style={{ borderRadius: 5 }}
      InputProps={{
        style: {
          backgroundColor: '#fff',
        },
      }}
      variant="outlined"
      size="small"
      InputLabelProps={{
        style: {
          color: '#464855',
        },
      }}
      select
      SelectProps={{
        multiple: true,
        value: value,
        MenuProps: MenuProps,
        onChange: handleChange,
        //onClose: handleClose,
        renderValue: (selected) => (selected as string[]).join(', '),
      }}
      onChange={props.onChange}
    >
      {options.map((opt, i) => (
        <MenuItem key={i} value={opt.label}>
          <Checkbox checked={value.indexOf(opt.label) > -1} />
          <ListItemText primary={opt.label} />
        </MenuItem>
      ))}
    </TextField>
  )
}
