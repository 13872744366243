import { Grid, Paper } from '@mui/material'
import { EditAddNotificationsTemplateDialog } from './EditAddNotificationsTemplateDialog'
import AddIcon from '@mui/icons-material/Add'
import { Button } from '@components/buttons'
import { EhBasicTable } from '@components/tables'
import { useState } from 'react'

type TemplateScreenProps = {
  notifications: {
    id: number
    name: string
    subject: string
    content: string
    version: string
  }[]
  newNotification: {
    id: number
    name: string
    subject: string
    content: string
    version: string
  }
}

export function NotificationTemplateForm({
  notifications,
}: TemplateScreenProps) {
  const [openDialog, setOpenDialog] = useState(false)
  const [currentRow, setCurrentRow] = useState<object | null>()

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: 2,
          borderRadius: 5,
        }}
      >
        <Grid item xs={12}>
          <Button
            text="addTemplate"
            color="primary"
            startIcon={<AddIcon sx={{ color: 'white' }} />}
            textColor="white"
            onClick={() => {
              // TODO: Add new notification
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={2}>
            <EhBasicTable
              size="medium"
              columns={columns}
              datas={notifications}
              align="left"
              actions={[
                {
                  icon: 'fas fa-pencil-alt',
                  text: 'Edit',
                  onClick: (row) => {
                    setOpenDialog(true)
                    setCurrentRow(row)
                  },
                },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>

      {openDialog && (
        <EditAddNotificationsTemplateDialog
          field={currentRow}
          open={openDialog}
          onClose={() => {
            setOpenDialog(false)
          }}
        />
      )}
    </>
  )
}

const columns = [
  { name: 'Notification name', field: 'name' },
  { name: 'Notification Subject', field: 'subject' },
  { name: 'Status', field: 'version' },
  {
    name: 'Action',
    field: 'action',
    columnAlign: 'left',
  },
]
