import { useState, FC } from 'react'
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material'

type Props = {
  children: (showDialog: () => void) => JSX.Element
  response: () => void
  title: string
  description: string
}
const ConfirmationDialog: FC<Props> = ({
  children,
  response,
  title,
  description,
}) => {
  //local states
  const [open, setOpen] = useState(false)

  const showDialog = () => {
    setOpen(true)
  }

  const hideDialog = () => {
    setOpen(false)
  }

  const confirmRequest = () => {
    response()
    hideDialog()
  }

  return (
    <>
      {children(showDialog)}
      {open && (
        <Dialog
          open={open}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={confirmRequest} color="primary">
              Oui
            </Button>
            <Button onClick={hideDialog} color="primary">
              Non
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default ConfirmationDialog
