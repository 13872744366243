import { createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'
import { FindPatientsQuery, PatientDto, PatientService } from '@services/api'
import { BillinglService } from '@services/BillingService'
import { CouvertureDto, VitalCardDto } from 'src/common/interfaces'
import { PaginatedDto } from '@services/extendedType'
import { PatientsState } from '@state/reducers/patientsReducer'
import {
  PatientMergeHistoryDto,
  PatientServiceV2,
} from '@services/PatientService'
import { CreatePatientDTO, FindPatientCoverageQuery } from '@services/dtos'

export const fetchPatients = createAsyncThunk<
  PaginatedDto<PatientDto>,
  void,
  any
>(Actions.PATIENTS_FIND_ALL, async (_, { rejectWithValue, getState }) => {
  try {
    const { patientsFilter } = (getState() as { patients: PatientsState })
      .patients

    return await PatientService.findAllv2(patientsFilter)
  } catch (e: any) {
    if (e.response) {
      return rejectWithValue(e.response.data.message)
    }
    return rejectWithValue(e.response.data.message)
  }
})

export const fetchOnePatientById = createAsyncThunk<
  PatientDto,
  { id: number; visitId?: number }
>(Actions.PATIENT_FIND_ONE, async (q, { rejectWithValue }) => {
  try {
    return await PatientService.findOne(q)
  } catch (e: any) {
    if (e.response) {
      return rejectWithValue(e.response.data.message)
    }
    return rejectWithValue(e.response.data.message)
  }
})

export const updatePatient = createAsyncThunk<PatientDto, PatientDto, any>(
  Actions.PATIENT_UPDATE,
  async (data: PatientDto, { rejectWithValue }) => {
    try {
      return await PatientService.update(data)
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const doGetCouverture = createAsyncThunk<
  CouvertureDto,
  FindPatientCoverageQuery,
  any
>(Actions.GET_PATIENT_COVERAGE, async (q, { rejectWithValue }) => {
  try {
    const [response] = await BillinglService.getCouverture(q)
    return response
  } catch (e: any) {
    if (e.response) {
      return rejectWithValue(e.response.data.message)
    }
    return rejectWithValue(e.response.data.message)
  }
})

export const doMergePatients = createAsyncThunk<
  void,
  { sourcePatientId: number; targetPatientId: number },
  any
>(Actions.MergePatients, async ({ sourcePatientId, targetPatientId }) => {
  await PatientServiceV2.mergePatient(sourcePatientId, targetPatientId)
})

export const doCancelMerge = createAsyncThunk<
  void,
  { pId: number; mergeId: number },
  any
>(Actions.MergePatients, async ({ pId, mergeId }) => {
  await PatientServiceV2.cancelPatientMerge(pId, mergeId)
})

export const doFetchMergeHistory = createAsyncThunk<
  PatientMergeHistoryDto[],
  number
>(Actions.FETCH_MERGE_HISTORY, async (patientId) => {
  return await PatientServiceV2.findMergeHistory(patientId)
})

export const doCreatePatient = createAsyncThunk<PatientDto, CreatePatientDTO>(
  'patients/create',
  async (data) => {
    return await PatientService.create(data)
  },
)
