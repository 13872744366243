export interface VitalCardDto {
  id: number
}

export interface OrganismeInfo {
  id: number
  type: number
  code: string
  regime: string
  caisse: string
  centre: string
  libelle: string
  codeDestinataire: string
  centreInfo: string
  teletransmissible: boolean
  active: boolean
}

export interface CouvertureDto {
  id: number
  numeroSerieCv: string
  beneficiaireNom: string
  beneficiairePrenom: string
  beneficiaireNir: string
  beneficaireDateNaissance: string
  beneficaireNomUsuel: string
  assureNom: string
  assureNomUsuel: string
  assurePrenom: string
  assureNir: string
  rangNaissance: number
  qualite: number
  idOrganismeAmo: number
  organismeAmo: OrganismeInfo | null
  codeGestionAmo: string
  codeServiceAmo: string
  codeSituationAmo: string
  dateDebutAmo: string
  dateFinAmo: string
  idOrganismeAmc: number
  organismeAmc: OrganismeInfo | null
  dateDebutAmc: string
  dateFinAmc: string
  numeroAdherentAmc: string
  indicateurTraitementAmc: string
  codeRoutageAmc: string
  identifiantHoteAmc: string
  nomDomaineAmc: string
  codeAiguillageStsAmc: string
  idPatient: number
  idVisit: number
  idNature: number
  natureAssurance: string
  idExoneration: number
  exoneration: string
}

export interface CouvertureResponseDto {
  erreur: boolean
  erreurCode: string
  erreurMessage: string
  couverture: CouvertureDto
}

export interface QuotationDto {
  id: number
  code: string
  libelle: string
  codeRegroupement: string
  prixOptam: number
  prixNonOptam: number
  modificateurs: string
}

export enum OrderStatusEnum {
  pendingRegistration = 'TO_REGISTER',
  registered = 'REGISTERED',
  toQuote = 'TO_QUOTE',
  toInvoice = 'TO_INVOICE',
  medicalOrderCancelled = 'CANCELED',
}
export interface DateRange {
  startDate: Date
  endDate: Date
}

export enum FileTypeEnum {
  PRESCRIPTION = 'PRESCRIPTION',
  REPORT = 'REPORT',
  ID = 'ID',
  LETTER = 'LETTER',
  PREVIOUS_EXAM = 'PREVIOUS_EXAM',
  CONSENT = 'CONSENT',
  LABRESULT = 'LABRESULT',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  EFFORT_TEST = 'EFFORT_TEST',
  CHAT_FILE = 'CHAT_FILE',
  SECONDARY_CAPTURE = 'SECONDARY_CAPTURE',
  PROTOCOL_REALISATION = 'PROTOCOL_REALISATION',
  SITE_ACQ_PROTOCOL = 'SITE_ACQ_PROTOCOL',
}
