import { Close } from '@mui/icons-material'
import { DialogTitleProps, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'

import theme from '@utils/theme'

import { Text } from '../texts'

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
}))

type CustomDialogTitleProps = DialogTitleProps & {
  title: string
  onClose?: () => void
  format?: boolean
}

export default function DialogTitle({
  title,
  onClose,
  format = false,
}: CustomDialogTitleProps) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Text variant="h6" text={title} format={format} />
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ color: theme.palette.secondary.main, marginTop: 2 }}
        >
          <Close />
        </IconButton>
      ) : null}
    </div>
  )
}
