import { AccordionDetails, Divider, Grid, Checkbox, FormControlLabel,FormControl, InputLabel, Select, TextField, MenuItem, FormHelperText} from '@mui/material'
import * as Yup from 'yup'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PaymentModeSchema, PaymentModeTypeEnum } from '@utils/schemas'

type patientInformationProps = {
  updatable: boolean
}

type Values = Yup.InferType<typeof PaymentModeSchema>

export default function vPatientInformation({
  updatable,
}: patientInformationProps) {
  useForm<Values>({
    resolver: yupResolver(PaymentModeSchema),
  })

  return (
    <div>
      <Divider variant="fullWidth" sx={{ marginBottom: 3 }} >Géneral</Divider>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Code"
              name="paymentModeCode"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          
          <Grid item xs={12} lg={6}>
            <TextField
              label="Libellé"
              name="paymentModeLabel"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="paymentModeType"
                name=''
                // value={PaymentModeTypeEnum.CB}
                // onChange={}
              >
                <MenuItem value={PaymentModeTypeEnum.CB}>Carte bleue</MenuItem>
                <MenuItem value={PaymentModeTypeEnum.CHQ}>Chèque</MenuItem>
                <MenuItem value={PaymentModeTypeEnum.E}>Espèces</MenuItem>
                <MenuItem value={PaymentModeTypeEnum.G}>Gratuit</MenuItem>
                <MenuItem value={PaymentModeTypeEnum.PP}>Perte et profit</MenuItem>
              </Select>
              <FormHelperText>Helper text for errors</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Code section comptable"
              name="accountingSectionCode"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Divider variant="fullWidth" sx={{ marginTop: 3, }} />
          <Grid item xs={4}>
            <FormControlLabel name="checkboxBank" control={<Checkbox />} label="A remettre en banque" />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel name="checkboxCheck" control={<Checkbox />} label="Saisie du N° de chèque obligatoire" />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel name="checkboxPatientBank" control={<Checkbox />} label="Saisie de la banque du patient obligatoire" />
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider variant="fullWidth" sx={{ marginTop: 3, }} />
    </div>
  )
}
