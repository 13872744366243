import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Icon,
  makeStyles,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { DateInput, SelectInput, TextInput } from '@ether-healthcare/inputs'

import { Text } from '../texts'
import { AccountRoleEnum, Member, SexEnum, SuperAdmin } from '@services/api'
import { AccountDto } from '@services/api'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
}))

type UserInfosCardProps = {
  user: Member | SuperAdmin
  account: AccountDto
}

export function UserInfosCard({ user, account }: UserInfosCardProps) {
  const classes = useStyles()
  const intl = useIntl()
  const {
    formState: { errors },
    control,
  } = useForm<Member>({
    defaultValues: user,
  })

  const sex = Object.keys(SexEnum).map((key) => ({
    value: SexEnum[key],
    label: intl.formatMessage({ id: 'enums.sex.' + SexEnum[key] }),
  }))
  return (
    <Card className={classes.section}>
      <CardHeader
        title={
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <Icon className="fa fa-file-alt" />
            </Grid>
            <Grid item>
              <Text text="profile" variant={'h6'} format />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <TextInput
              name="firstName"
              placeholder="First Name"
              size="small"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <TextInput
              name="lastName"
              placeholder="Last Name"
              size="small"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            {account.role === AccountRoleEnum.patient ? (
              <TextInput
                name="sex"
                placeholder="Sex"
                size="small"
                variant="outlined"
                control={control}
                errors={errors}
                readOnly
              />
            ) : (
              <SelectInput
                name="sex"
                label="Sex"
                size="small"
                variant="outlined"
                control={control}
                errors={errors}
                options={sex}
                readOnly
              />
            )}
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              name="address"
              placeholder="address"
              size="small"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateInput
              name="birthDate"
              control={control}
              errors={errors}
              readOnly
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextInput
              placeholder="birthPlace"
              name="birthPlace"
              size="small"
              variant="outlined"
              control={control}
              errors={errors}
              readOnly
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
