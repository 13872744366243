import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CreateVisitDTO,
  DocumentDTO,
  FindVisitQuery,
  UpdateVisitDTO,
  VisitDTO,
} from '@services/dtos'
import { PaginatedDto } from '@services/extendedType'
import { VisitService } from '@services/visitService'

export interface VisitState {
  visits: PaginatedDto<VisitDTO>
  documents: DocumentDTO[]
  filters: FindVisitQuery
}

const initialState: VisitState = {
  visits: {
    datas: [],
    totalCount: 0,
  },
  filters: {
    page: 1,
    limit: 20,
  },
  documents: [],
}

export const doFindVisits = createAsyncThunk<PaginatedDto<VisitDTO>>(
  'visits/find',
  async (_, { getState }) => {
    const query = (getState() as { visits: VisitState }).visits
      .filters as FindVisitQuery
    const response = await VisitService.get(query)
    return response
  },
)

export const doCreateVisit = createAsyncThunk<VisitDTO, CreateVisitDTO>(
  'visits/create',
  async (dto) => {
    const response = await VisitService.create(dto)
    return response
  },
)

export const doUpdateVisit = createAsyncThunk<
  VisitDTO,
  { id: number; dto: UpdateVisitDTO }
>('visits/update', async ({ id, dto }) => {
  const response = await VisitService.update(id, dto)
  return response
})

export const doRemoveVisit = createAsyncThunk<void, number>(
  'visits/remove',
  async (id) => {
    await VisitService.remove(id)
  },
)

export const doGetVisitDocuments = createAsyncThunk<DocumentDTO[], number>(
  'visits/documents',
  async (id, { rejectWithValue }) => {
    try {
      const response = await VisitService.getDocuments(id)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const visitReducer = createSlice({
  name: 'visits',
  initialState,
  reducers: {
    setVisitFilters: (
      state,
      { payload }: PayloadAction<Partial<FindVisitQuery>>,
    ) => {
      state.filters = { ...state.filters, ...payload }
    },
    setVisitDocuments: (state, { payload }: PayloadAction<DocumentDTO[]>) => {
      state.documents = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doFindVisits.fulfilled, (state, action) => {
      state.visits = action.payload
    })
    builder.addCase(doCreateVisit.fulfilled, (state, action) => {
      state.visits.datas.unshift(action.payload)
    })
    builder.addCase(doUpdateVisit.fulfilled, (state, action) => {
      const index = state.visits.datas.findIndex(
        (visit) => visit.id === action.payload.id,
      )
      if (index !== -1) {
        state.visits.datas[index] = action.payload
      }
    })
    builder.addCase(doRemoveVisit.fulfilled, (state, action) => {
      const index = state.visits.datas.findIndex(
        (visit) => visit.id === action.meta.arg,
      )
      if (index !== -1) {
        state.visits.datas.splice(index, 1)
      }
    })
    builder.addCase(doGetVisitDocuments.fulfilled, (state, { payload }) => {
      state.documents = payload
    })
  },
})

export default visitReducer.reducer
export const { setVisitFilters, setVisitDocuments } = visitReducer.actions
