import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { MedicalEquipment } from '@services/api'
import {
  createMedicalEquipment,
  deleteMedicalEquipment,
  findAllMedicalEquipment,
  updateMedicalEquipment,
} from '@state/thunks/medicalEquipmentThunk'

type medicalEquipmentState = {
  medicalEquipments: MedicalEquipment[]
}

const initialState: medicalEquipmentState = {
  medicalEquipments: [],
}

const medicalEquipmentSlice = createSlice({
  name: 'medical-equipment',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(
        findAllMedicalEquipment.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.medicalEquipments = payload
        },
      )
      .addCase(
        createMedicalEquipment.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.medicalEquipments = [...state.medicalEquipments, payload]
        },
      )
      .addCase(
        updateMedicalEquipment.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          const index = state.medicalEquipments.findIndex(
            (_eq) => _eq.id === payload.id,
          )
          if (index === -1) {
            return
          }

          state.medicalEquipments[index] = payload
        },
      )
      .addCase(
        deleteMedicalEquipment.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.medicalEquipments = state.medicalEquipments.filter(
            (_eq) => !payload.includes(_eq.id),
          )
        },
      ),
})

// eslint-disable-next-line no-empty-pattern
export const {} = medicalEquipmentSlice.actions

export default medicalEquipmentSlice.reducer
