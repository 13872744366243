import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { OrderDto, VisitDTO } from '@services/dtos'
import { useState } from 'react'
import MedicalOrderDialog from '../containers/app/Worklists/MedicalOrderDialog'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import moment from 'moment'

type Props = {
  visit: VisitDTO
}
const OrderSelectorComponent: React.FC<Props> = ({ visit }) => {
  // const [isOrderSelectOpen, setisOrderSelectOpen] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState<OrderDto | null>(null)

  const orders = visit.orders

  // const handleSelectionChange = (orders: OrderDto[]) => {
  //   const newRowIds: OrderDto[] = []
  //   for (const order of orders) {
  //     if (!selectedRows.find((row) => row.id === order.id)) {
  //       newRowIds.push(order)
  //     }
  //   }
  //   setselectedRows([...selectedRows, ...newRowIds])
  // }
  // const rowIds = useMemo(() => {
  //   return selectedRows.map((row) => row.id)
  // }, [selectedRows])

  const handleDetailsClick = (order: OrderDto) => {
    setSelectedOrder(order)
  }

  return (
    <div>
      {/* <Button */}
      {/*   onClick={() => setisOrderSelectOpen(true)} */}
      {/*   variant="contained" */}
      {/*   color="primary" */}
      {/* > */}
      {/*   Ajouter des dossiers */}
      {/* </Button> */}
      {/* <Dialog */}
      {/*   fullWidth */}
      {/*   maxWidth={'' as any} */}
      {/*   open={isOrderSelectOpen} */}
      {/*   onClose={() => setisOrderSelectOpen(false)} */}
      {/*   TransitionComponent={UpTransition} */}
      {/* > */}
      {/*   <div className="p-4"> */}
      {/*     <h2 className="text-xl font-bold">Sélectionner des dossiers</h2> */}
      {/*     <p className="text-sm text-gray-500"> */}
      {/*       Sélectionner les dossiers à ajouter à la visite */}
      {/*     </p> */}
      {/*     selected: {selectedRows.map((r) => r.exam.label).join(', ')} */}
      {/*   </div> */}
      {/*   <DialogContent> */}
      {/*     <Grid container flexWrap="nowrap" gap={2}> */}
      {/*       <Grid item xs={8}> */}
      {/*         <OrdersTable */}
      {/*           selectionModel={rowIds} */}
      {/*           isSelection */}
      {/*           onSelectionChange={handleSelectionChange} */}
      {/*           excludeRows={rowIds} */}
      {/*         /> */}
      {/*       </Grid> */}
      {/*       <Grid item xs={4}> */}
      {/*         <RenderSelectedOrders */}
      {/*           selectedRows={selectedRows} */}
      {/*           onDetailsClick={handleDetailsClick} */}
      {/*         /> */}
      {/*       </Grid> */}
      {/*     </Grid> */}
      {/*   </DialogContent> */}
      {/* </Dialog> */}
      {!!selectedOrder && (
        <MedicalOrderDialog
          isOpen={selectedOrder !== null}
          onClose={() => setSelectedOrder(null)}
          order={selectedOrder}
        />
      )}
      <RenderSelectedOrders
        selectedRows={orders}
        onDetailsClick={handleDetailsClick}
      />
    </div>
  )
}

export const RenderSelectedOrders: React.FC<{
  selectedRows: OrderDto[]
  onDetailsClick: (o: OrderDto) => void
}> = ({ selectedRows, onDetailsClick }) => {
  const Row = (props: { row: OrderDto }) => {
    const { row } = props
    const [open, setOpen] = useState(false)

    return (
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.id}
          </TableCell>
          <TableCell>{row.site.label}</TableCell>
          <TableCell>{row.medicalOrders.length}</TableCell>
          <TableCell>
            <Button
              style={{ color: 'white' }}
              onClick={() => {
                onDetailsClick(row)
              }}
              variant="contained"
              color="primary"
            >
              Details
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Demandes
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Examen</TableCell>
                      <TableCell>Docteur titulaire</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.medicalOrders.map((mo) => (
                      <TableRow key={mo.id}>
                        <TableCell component="th" scope="row">
                          {moment(mo.plannedDate).format('DD/MM/YYYY HH:mm')}
                        </TableCell>
                        <TableCell>
                          {mo.exam.label} ({mo.exam.code})
                        </TableCell>
                        <TableCell>
                          {mo.attendingDoctor?.firstName}{' '}
                          {mo.attendingDoctor?.lastName}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Identifiant</TableCell>
            <TableCell>Site</TableCell>
            <TableCell> Examens</TableCell>
            <TableCell> Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedRows.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default OrderSelectorComponent
