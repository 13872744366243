import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { useState } from 'react'
import ExamRoom from './ExamRoom'
import PrepRoom from './PrepRoom'
import Receptions from './receptions/Receptions'
import WaitingRooms from './waiting_rooms/WaitingRooms'
import Offices from './office/Offices'
import { useIntl } from 'react-intl'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return (
    <Box sx={{ height: '100%' }}>
      {value === index && <Box sx={{ p: 3, height: '87vh' }}>{children}</Box>}
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Rooms() {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const intl = useIntl()
  return (
    <Box>
      <Box
        sx={{
          marginTop: '5px',
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="rooms-tabs"
          sx={{
            marginLeft: '54px',
            marginBottom: '-22px',
          }}
        >
          <Tab
            label={intl.formatMessage({ id: 'receptions' })}
            {...a11yProps(0)}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '5px 5px 0px 0px',
              marginRight: '8px',
            }}
          />
          <Tab
            label={intl.formatMessage({ id: 'waitingRooms' })}
            {...a11yProps(1)}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '5px 5px 0px 0px',
              marginRight: '8px',
            }}
          />
          <Tab
            label={intl.formatMessage({ id: 'examRooms' })}
            {...a11yProps(2)}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '5px 5px 0px 0px',
              marginRight: '8px',
            }}
          />
          <Tab
            label={intl.formatMessage({ id: 'prepRooms' })}
            {...a11yProps(3)}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '5px 5px 0px 0px',
              marginRight: '8px',
            }}
          />
          <Tab
            label={intl.formatMessage({ id: 'doctorOffices' })}
            {...a11yProps(4)}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '5px 5px 0px 0px',
              marginRight: '8px',
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Receptions />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <WaitingRooms />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ExamRoom />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PrepRoom />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Offices />
      </TabPanel>
    </Box>
  )
}
