import axios from 'axios'
import { SiteDto, convertedDataForCharts, DugnutStatsData } from './api'

export interface TableStatsPrescriber {
  rank: number
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  skill: string
  total: number
}

export interface TableStatsUser {
  rank: number
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  total: number
}

export const fetchSiteLabelV2 = async (): Promise<SiteDto[]> => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/site/v2`)
  return response.data
}

export const fetchPrescribers = async (
  startYear: number,
  endYear: number,
  site?: number,
): Promise<TableStatsPrescriber[]> => {
  try {
    const response = await axios.get<TableStatsPrescriber[]>(
      `${process.env.REACT_APP_API_URL}/stats/top/prescriptor`,
      { params: { startYear: startYear, endYear: endYear, siteId: site } },
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchExamTypeBreakdown = async (
  site?: number,
): Promise<DugnutStatsData> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/stats/examtype/breakdown`,
      { params: { siteId: site } },
    )
    return convertedDataForCharts(response.data)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchPatientEvent = async (
  site?: number,
): Promise<DugnutStatsData> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/stats/patient/event`,
      { params: { siteId: site } },
    )
    return convertedDataForCharts(response.data)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchPatientEventByAge = async (
  site?: number,
): Promise<DugnutStatsData> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/stats/patient/event/age`,
      {
        params: { siteId: site },
      },
    )
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchTopUser = async (
  startYear: number,
  endYear: number,
  site?: number,
): Promise<TableStatsUser[]> => {
  try {
    const response = await axios.get<TableStatsUser[]>(
      `${process.env.REACT_APP_API_URL}/stats/top/event`,
      {
        params: { startYear: startYear, endYear: endYear, siteId: site },
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export interface BarStatsDataDti {
  agerange: string
  haveaccount: boolean
  total: number
}

export interface BarValue {
  agerange: string
  total: number
}

export interface BarStatsDataDto {
  tabtrue: BarValue[]
  tabfalse: BarValue[]
}

function transformData(input: BarStatsDataDti[]): BarStatsDataDto {
  const result: BarStatsDataDto = {
    tabtrue: [],
    tabfalse: [],
  }

  input.forEach((item) => {
    const barValue: BarValue = {
      agerange: item.agerange,
      total: item.total,
    }

    if (item.haveaccount) {
      result.tabtrue.push(barValue)
    } else {
      result.tabfalse.push(barValue)
    }
  })

  return result
}

export const fetchPatientEventAccount = async (
  site?: number,
): Promise<BarStatsDataDto> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/stats/patient/account`,
      {
        params: { siteId: site },
      },
    )
    console.log(response.data)
    return transformData(response.data)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const fetchDeviceTypeBreakdown = async (
  site?: number,
): Promise<DugnutStatsData> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/stats/device/type/breakdown`,
      {
        params: { siteId: site },
      },
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
