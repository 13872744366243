import {
  RoomService,
  UpdateExamRoomDto,
  CreateExamRoomDto,
  UpdateReceptionDto,
  UpdatePrepRoomDto,
  UpdateOfficeDto,
  CreatePrepRoomDto,
  CreateReceptionDto,
  CreateOfficeDto,
  CreateWaitingRoomDto,
  UpdateWaitingRoomDto,
} from '@services/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'

export const findAllRooms = createAsyncThunk(
  Actions.ROOM_FIND_ALL,
  (_, { rejectWithValue }) => {
    try {
      return RoomService.findAllRooms()
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const updatePrepRoom = createAsyncThunk(
  Actions.UPDATE_PREP_ROOM,
  (
    { id, body }: { id: number; body: UpdatePrepRoomDto },
    { rejectWithValue },
  ) => {
    try {
      return RoomService.updatePrepRoom({ id, body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const updateReception = createAsyncThunk(
  Actions.UPDATE_RECEPTION,
  (
    { id, body }: { id: number; body: UpdateReceptionDto },
    { rejectWithValue },
  ) => {
    try {
      return RoomService.updateReception({ id, body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const updateWaitingRoom = createAsyncThunk(
  Actions.UPDATE_WAITING_ROOM,
  async (
    { id, body }: { id: number; body: UpdateWaitingRoomDto },
    { rejectWithValue },
  ) => {
    try {
      return RoomService.updateWaitingRoom({ id, body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const updateExamRoom = createAsyncThunk(
  Actions.UPDATE_EXAM_ROOM,
  async (
    { id, body }: { id: number; body: UpdateExamRoomDto },
    { rejectWithValue },
  ) => {
    try {
      return await RoomService.updateExamRoom({ id, body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const updateDoctorOffice = createAsyncThunk(
  Actions.UPDATE_OFFICE,
  async (
    { id, body }: { id: number; body: UpdateOfficeDto },
    { rejectWithValue },
  ) => {
    try {
      return RoomService.updateDoctorOffice({ id, body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const removeReception = createAsyncThunk(
  Actions.REMOVE_RECEPTION,
  async ({ id }: { id: number | number[] }, { rejectWithValue }) => {
    try {
      return RoomService.removeReception({ id })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const removeWaitingRooms = createAsyncThunk(
  Actions.REMOVE_WAITING_ROOM,
  async ({ id }: { id: number | number[] }, { rejectWithValue }) => {
    try {
      return RoomService.removeWaitingRooms({ id })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const removePrepRoom = createAsyncThunk(
  Actions.REMOVE_PREP_ROOM,
  async ({ id }: { id: number | number[] }, { rejectWithValue }) => {
    try {
      return await RoomService.removePrepRoom({ id })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const removeExamRoom = createAsyncThunk(
  Actions.REMOVE_EXAM_ROOM,
  async ({ id }: { id: number | number[] }, { rejectWithValue }) => {
    try {
      return await RoomService.removeExamRoom({ id })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const removeDoctorOffice = createAsyncThunk(
  Actions.REMOVE_OFFICE,
  async ({ id }: { id: number | number[] }, { rejectWithValue }) => {
    try {
      return await RoomService.removeDoctorOffice({ id })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const createReception = createAsyncThunk(
  Actions.CREATE_RECEPTION,
  async (body: CreateReceptionDto, { rejectWithValue }) => {
    try {
      return await RoomService.createReception({ body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const createWaitingRoom = createAsyncThunk(
  Actions.CREATE_WAITING_ROOM,
  async (body: CreateWaitingRoomDto, { rejectWithValue }) => {
    try {
      return await RoomService.createWaitingRoom({ body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const createPrepRoom = createAsyncThunk(
  Actions.CREATE_PREP_ROOM,
  async (body: CreatePrepRoomDto, { rejectWithValue }) => {
    try {
      return await RoomService.createPrepRoom({ body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const createExamRoom = createAsyncThunk(
  Actions.CREATE_EXAM_ROOM,
  async (body: CreateExamRoomDto, { rejectWithValue }) => {
    try {
      return await RoomService.createExamRoom({ body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const createDoctorOffice = createAsyncThunk(
  Actions.CREATE_OFFICE,
  async (body: CreateOfficeDto, { rejectWithValue }) => {
    try {
      return await RoomService.createDoctorOffice({ body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
