import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  styled,
} from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { AuthTitle } from '@components/titles'
import { Button } from '@components/buttons'
import { Text } from '@components/texts'
interface CardProps {
  width?: number
  children?: any
}

type HeaderCardProps = {
  title: string
  children?: JSX.Element
}

type ContentCardProps = {
  children: JSX.Element
}

type ActionsCardProps = {
  backToLoginLink?: boolean
  children?: JSX.Element
}

const StyledContainer = styled(Container)(() => ({
  height: '100vh',
  display: 'flex',
}))
const StyledCard = styled(Card)(() => ({
  borderRadius: 25,
  padding: 25,
}))
const StyledCardHeader = styled(CardHeader)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

function AuthCard({ children }: CardProps) {
  return (
    <StyledContainer>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        direction="column"
      >
        <StyledCard>{children}</StyledCard>
      </Grid>
    </StyledContainer>
  )
}
function Header({ title, children }: HeaderCardProps) {
  return (
    <StyledCardHeader
      title={<AuthTitle />}
      subheader={
        <>
          {children}
          <Text
            text={title}
            variant="h3"
            align="center"
            color="#000000"
            format
          />
        </>
      }
    />
  )
}

function Content({ children }: ContentCardProps) {
  return <CardContent>{children}</CardContent>
}

function Actions({ children, backToLoginLink }: ActionsCardProps) {
  return (
    <CardActions>
      <Grid item container xs={12} sm={12}>
        {children}
        {backToLoginLink && (
          <Button
            startIcon={<ArrowBackIosIcon />}
            text="back-to-login"
            link="/auth/login"
            format
            style={{ color: '#000000' }}
          />
        )}
      </Grid>
    </CardActions>
  )
}
AuthCard.Header = Header
AuthCard.Content = Content
AuthCard.Actions = Actions

export { AuthCard }
