import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Paper,
  Container,
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
  Icon,
  Tooltip,
} from '@mui/material'
import { DataTable } from '@components/tables'
import { removeMembers } from '@state/reducers/memberReducer'
import { findAll as findAllClient } from '@state/thunks/clientThunk'
import { findAll as findAllSites } from '@state/thunks/siteThunk'
import {
  deleteMember,
  doFindMembers as findAllMember,
} from '@state/thunks/memberThunk'
import { removeClients } from '@state/reducers/clientReducer'
import { removeSites } from '@state/reducers/siteReducer'
import { DialogTitle } from '@components/titles'
import { MemberForm } from './MemberForm'
import { useSelector } from '@state/store'
import { GridColumns, GridRowId } from '@mui/x-data-grid-pro'
import { Actions } from '@state/actions'
import theme from '@utils/theme'
import { Button } from '../../../components/buttons'
import Text from '@components/Text'
import AssignRoleToMemberFrom from './AssignRoleToMemberFrom'

export default function Members() {
  const dispatch = useDispatch()
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [openModifyDialog, setOpenModifyDialog] = useState(false)
  const [openAssignRoleDialog, setOpenAssignRoleDialog] = useState(false)
  const [row, setRow] = useState<any>({})
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const { client, members, loading } = useSelector(
    ({ member, auth, loading }) => ({
      members: member.members.datas,
      client: auth.account?.client,
      loading:
        loading[Actions.MEMBER_FIND_ALL] ||
        loading[Actions.MEMBER_CREATE] ||
        loading[Actions.MEMBER_UPDATE],
    }),
  )

  const columns: GridColumns = [
    {
      field: 'col1',
      headerName: '',
      width: 80,
      sortable: false,
      renderCell: (value) => (
        <div
          style={{
            width: '100%',
          }}
        >
          <Tooltip title="Modifier un membre" placement="top">
            <Icon
              style={{ marginRight: '10px' }}
              className="fas fa-marker"
              fontSize="small"
              onClick={() => {
                setRow(value.row)
                setOpenModifyDialog(true)
              }}
              sx={{ color: '#3F3F3F' }}
            />
          </Tooltip>
          <Tooltip title="Assigné rôle" placement="top">
            <Icon
              className="fas fa-users-cog"
              fontSize="small"
              onClick={() => {
                setRow(value.row)
                setOpenAssignRoleDialog(true)
              }}
              sx={{ color: '#3F3F3F', paddingRight: '5px' }}
            />
          </Tooltip>
        </div>
      ),
    },
    { field: 'firstName', headerName: 'Firstname', flex: 1 },
    { field: 'lastName', headerName: 'Lastname', flex: 1 },
    {
      field: 'memberJob',
      headerName: 'Position',
      flex: 1,
      valueGetter: ({ row }) => row.role,
    },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'phoneNumber', headerName: 'Phone', flex: 1 },
    { field: 'address', headerName: 'Address', flex: 1 },
  ]

  const onRefresh = useCallback(() => {
    dispatch(findAllMember({}))
    if (!client) dispatch(findAllClient())
    else dispatch(findAllSites())
  }, [client, dispatch])

  useEffect(() => {
    onRefresh()
    return () => {
      dispatch(removeMembers())
      dispatch(removeClients())
      dispatch(removeSites())
    }
  }, [dispatch, onRefresh])

  const _onSelectionChange = (selection: GridRowId[]) => {
    setSelectedRows(selection as number[])
  }
  const _onDeleteConfirm = () => {
    dispatch(deleteMember(selectedRows))
    setIsDeleteDialogOpen(false)
  }

  return (
    <Container
      maxWidth={false}
      style={{ height: '100%', padding: '10px 10px' }}
    >
      <Grid container spacing={4} style={{ height: '100vh' }}>
        <Grid item container>
          <Paper
            elevation={2}
            style={{
              width: '100%',
              borderRadius: '25px',
            }}
          >
            <DataTable
              rows={members}
              columns={columns}
              loading={loading}
              size={35}
              isDetails
              searchSex={true}
              toolBarProps={{
                title: 'members',
                icon: 'fa fa-hospital-user',
                onAdd: () => setOpenAddDialog(true),
                onRefresh,
                onDelete: () => {
                  selectedRows.length
                    ? setIsDeleteDialogOpen(true)
                    : alert('please select at least one room')
                },
                onSearch(searchTerm) {
                  console.log(searchTerm)
                },
              }}
              detailPanelHeight={400}
              form={(props) => (
                <MemberForm
                  {...props}
                  onClose={() => setOpenAddDialog(false)}
                />
              )}
              checkboxSelection
              onSelectionModelChange={_onSelectionChange}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={openModifyDialog}
        onClose={() => setOpenModifyDialog(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            minWidth: '50%',
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="Modifier un membre"
          onClose={() => setOpenModifyDialog(false)}
          format
        />
        <DialogContent sx={{ background: '#fff', padding: '50px' }}>
          <MemberForm onClose={() => setOpenModifyDialog(false)} row={row} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            minWidth: '50%',
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="addMember"
          onClose={() => setOpenAddDialog(false)}
          format
        />
        <DialogContent sx={{ background: '#fff', padding: '50px' }}>
          <MemberForm onClose={() => setOpenAddDialog(false)} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="deleteReception"
          onClose={() => setIsDeleteDialogOpen(false)}
          format
        />
        <DialogContent
          sx={{
            background: '#fff',
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            text="deleteConfirmation"
            variant="h5"
            color="secondary"
            sx={{ fontWeight: 'bold', paddingTop: '20px' }}
            format
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
          }}
        >
          <Button
            fullWidth
            textColor="white"
            onClick={() => setIsDeleteDialogOpen(false)}
            color="secondary"
            text="Cancel"
          />
          <Button
            fullWidth
            textColor="white"
            onClick={_onDeleteConfirm}
            color="primary"
            text="Save"
          />
        </DialogActions>
      </Dialog>

      {/* Assign role modal */}
      <Dialog
        open={openAssignRoleDialog}
        onClose={() => setOpenAssignRoleDialog(false)}
        maxWidth="md"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="Assigner un rôle"
          onClose={() => setOpenAssignRoleDialog(false)}
          format
        />
        <DialogContent
          sx={{
            background: '#fff',
            minWidth: '750px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <AssignRoleToMemberFrom
            onClose={() => setOpenAssignRoleDialog(false)}
            row={row}
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
          }}
        >
          <Button
            fullWidth
            textColor="white"
            onClick={() => setOpenAssignRoleDialog(false)}
            color="secondary"
            text="Cancel"
          />
          <Button
            fullWidth
            textColor="white"
            // onClick={  }
            color="primary"
            text="Save"
          />
        </DialogActions>
      </Dialog>
    </Container>
  )
}
