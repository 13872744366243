import { useState, useMemo, useEffect } from 'react'
import { Control, Controller } from 'react-hook-form'

import { TextField, Autocomplete } from '@mui/material'
import { makeStyles } from '@mui/styles'
import throttle from 'lodash/throttle'

import { Text } from '../texts'

type AddressInputProps = {
  name: string
  control: Control<any>
}

const useStyles = makeStyles({
  autoComplete: {
    colors: 'black',
  },
})

export function ZipFranceInput({ name, control }: AddressInputProps) {
  const [options, setOptions] = useState<Properties[]>([])
  const [inputValue, setInputValue] = useState('')
  const classes = useStyles()

  const fetchMemo = useMemo(
    () =>
      throttle(
        async (
          request: { input: string },
          callback: ({ features }: { features: Feature[] }) => void,
        ) => {
          const response: Response = await fetch(
            'https://api-adresse.data.gouv.fr/search/?q=' + request.input,
          )
          callback(await response.json())
        },
        200,
      ),
    [],
  )

  useEffect(() => {
    let active = true
    fetchMemo(
      { input: inputValue },
      ({ features }: { features: Feature[] }) => {
        if (active) {
          let newOptions = [] as Properties[]
          if (features) {
            newOptions = [
              ...newOptions,
              ...features.map(({ properties }) => properties),
            ]
          }
          setOptions(newOptions)
        }
      },
    )

    return () => {
      active = false
    }
  }, [inputValue, fetchMemo])
  return (
    <Controller
      render={({ field: { onChange }, ...props }) => (
        <Autocomplete
          className={classes.autoComplete}
          {...props}
          fullWidth
          noOptionsText={<Text text="No results" color="primary" />}
          options={options}
          getOptionLabel={(option) => option.label || ''}
          renderOption={(_,option) => option.postcode}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              margin="dense"
            />
          )}
          onInputChange={(event, data) => setInputValue(data)}
          onChange={(_, data) => {
            setOptions(data ? [data, ...options] : options)
            onChange(data?.postcode)
          }}
        />
      )}
      name={name}
      control={control}
    />
  )
}
