import { AccordionDetails, Divider, Grid, Checkbox, FormControlLabel, InputLabel,FormControl, Select,MenuItem, FormHelperText, TextField } from '@mui/material'
import * as Yup from 'yup'
import {
  DateInput,
} from '../../../../components/inputs'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { fundGeneralInfoSchema, contactStatusEnum } from '@utils/schemas'
import { useIntl } from 'react-intl'

type patientInformationProps = {
  updatable: boolean
}

type Values = Yup.InferType<typeof fundGeneralInfoSchema>

export default function PatientInformation({
  updatable,
}: patientInformationProps) {
  // const intl = useIntl()
  const {
    formState: { errors },
    control,
  } = useForm<Values>({
    resolver: yupResolver(fundGeneralInfoSchema),
  })

  return (
    <div>
      <Divider variant="fullWidth" sx={{ marginBottom: 3 }} >Géneral</Divider>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <TextField
              label="Code organisme"
              name="organizationCode"
              size="medium"
              variant="outlined"
              // helperText={errors}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              label="Nom de l'organisme"
              name="organizationName"
              size="medium"
              variant="outlined"
              // helperText={errors}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              label="Taux de remboursement"
              name="refundRate"
              size="medium"
              variant="outlined"
              InputProps={{
                endAdornment: '%',
              }}
              // helperText={errors}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              label="Dernier bordereau"
              name="lastBordereau"
              size="medium"
              variant="outlined"
              // helperText={errors}
              fullWidth
            />
          </Grid>
          {/* section 1 */}
          <Divider variant="fullWidth" sx={{ marginBottom: 3 }} />
          <Grid item xs={12} lg={6}>
            <TextField
              label="Adresse"
              name="adresse"
              size="medium"
              variant="outlined"
              // helperText={errors}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              label="Code postal"
              name="postalCode"
              size="medium"
              variant="outlined"
              // helperText={errors}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              label="Ville"
              name="city"
              size="medium"
              variant="outlined"
              // helperText={errors}
              fullWidth
            />
          </Grid>

          {/* section 2 */}
          <Grid item xs={12} lg={3}>
            <TextField
              label="Nom du contact"
              name="contactName"
              size="medium"
              variant="outlined"
              // helperText={errors}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Status du contact</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Status du contact"
                name='contactStatus'
                // value={age}
                // onChange={}
              >
                <MenuItem value={contactStatusEnum.DR}>Docteur</MenuItem>
                <MenuItem value={contactStatusEnum.DRE}>Docteure</MenuItem>
                <MenuItem value={contactStatusEnum.MR}>Monsieur</MenuItem>
                <MenuItem value={contactStatusEnum.MME}>Madame</MenuItem>
                <MenuItem value={contactStatusEnum.ENF}>Enfant</MenuItem>
              </Select>
              {/* <FormHelperText>Helper text for errors</FormHelperText> */}
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Adresse électronique"
              name="email"
              size="medium"
              variant="outlined"
              // helperText={errors}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Tél. professionnel"
              name="professionalPhone"
              size="medium"
              variant="outlined"
              // helperText={errors}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Fax"
              name="fax"
              size="medium"
              // helperText={errors}
              variant="outlined"
              fullWidth
            />
            
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider variant="fullWidth" sx={{ marginBottom: 3, marginTop: 3 }} >Télétransmission</Divider>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <TextField
              label="Code règime S.S."
              name="socialSecurityRegime"
              size="medium"
              variant="outlined"
              // helperText={errors}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              label="Code caisse S.S."
              name="socialSecurityFund"
              size="medium"
              variant="outlined"
              // helperText={errors}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              label="Code centre S.S."
              name="socialSecurityCenter"
              size="medium"
              variant="outlined"
              // helperText={errors}
              fullWidth
            />
          </Grid>
          <Divider />
          <Grid item xs={4}>
            <FormControlLabel name='toTransmit' control={<Checkbox />} label="A transmettre" />
            <FormControlLabel name='monacoFund' control={<Checkbox />} label="Caisse monégasque" />
          </Grid>
          <Grid item>
            <Grid item xs={12}>
              <DateInput
                name="activeFrom"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid item xs={12}>
              <DateInput
                name="activeTo"
                control={control}
                errors={errors}
                readOnly
              />
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider variant="fullWidth" sx={{ marginTop: 3, }} />
    </div>
  )
}
