import { axios, getConfigs, IRequestConfig, MemberDto, PatientDto } from './api'
import { DocumentDTO } from './dtos'

export interface PatientMergeHistoryDto {
  id: number
  sourcePatient: PatientDto
  targetPatient: PatientDto
  createdAt: Date
  updatedAt: Date
  mergedBy: MemberDto
  reversedBy: MemberDto
  reversedAt: Date
}

export class PatientServiceV2 {
  private static readonly endpoint = 'patient'

  static mergePatient(
    sourcePatientId: number,
    targetPatientId: number,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.endpoint}/${sourcePatientId}/merge`,
        {},
      )
      configs.data = {
        targetPatientId,
      }
      axios(configs, resolve, reject)
    })
  }

  static cancelPatientMerge(pId: number, mergeId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        `${this.endpoint}/${pId}/cancel-merge/${mergeId}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static findMergeHistory(
    patientId: number,
  ): Promise<PatientMergeHistoryDto[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.endpoint}/${patientId}/merge-history`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static getDocuments(patientId: number): Promise<DocumentDTO[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.endpoint}/${patientId}/get-documents`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }
}
