import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  ButtonProps,
} from '@mui/material'

interface ConfirmationDialogProps {
  open: boolean
  title: string
  message: string
  confirmButtonText?: string
  cancelButtonText?: string
  confirmButtonProps?: Partial<ButtonProps>
  cancelButtonProps?: Partial<ButtonProps>
  isProcessing?: boolean
  processingText?: string
  severity?: 'error' | 'warning' | 'info' | 'success'
  onCancel: () => void
  onConfirm: () => void
}

const ConfirmDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  message,
  confirmButtonText = 'Confirmer',
  cancelButtonText = 'Annuler',
  confirmButtonProps = {},
  cancelButtonProps = {},
  isProcessing = false,
  processingText,
  severity = 'warning',
  onCancel,
  onConfirm,
}) => {
  const getSeverityColor = () => {
    switch (severity) {
      case 'error':
        return 'error'
      case 'warning':
        return 'warning'
      case 'success':
        return 'success'
      case 'info':
        return 'info'
      default:
        return 'primary'
    }
  }

  const defaultConfirmButtonProps: Partial<ButtonProps> = {
    color: getSeverityColor(),
    variant: 'contained',
  }

  const defaultCancelButtonProps: Partial<ButtonProps> = {
    color: 'inherit',
    variant: 'text',
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          disabled={isProcessing}
          {...defaultCancelButtonProps}
          {...cancelButtonProps}
        >
          {cancelButtonText}
        </Button>
        <Button
          onClick={onConfirm}
          disabled={isProcessing}
          startIcon={
            isProcessing ? (
              <CircularProgress size={20} color="inherit" />
            ) : undefined
          }
          {...defaultConfirmButtonProps}
          {...confirmButtonProps}
        >
          {isProcessing
            ? processingText || `${confirmButtonText}ing...`
            : confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
