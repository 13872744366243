import { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import {
  Avatar,
  Backdrop,
  AppBar,
  Box,
  Container,
  Grid,
  Icon,
  Tab,
  Tabs,
  Paper,
} from '@mui/material'
import Logo from '@assets/img/logos/logo.png'

import Text from '@components/Text'
import { findOne as findClient } from '@state/thunks/clientThunk'
import { ClientForm } from '../client/ClientForm'
import { NotificationTemplateForm } from './NotificationsTemplate'
import { SecurityPreferencesForm } from './SecurityPreferencesForm'
import { SharingPreferencesForm } from './SharingPreferencesForm'
import { TermTemplate } from './TermTemplate'
import { ViewersPreferencesForm } from './ViewersPreferencesForm'
import { useSelector } from '@state/store'
import { removeSelected } from '@state/reducers/clientReducer'
import { Actions } from '@state/actions'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ marginTop: 15, width: '100%' }}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const MARGIN_LEFT = 8
const tabs: { Icon: any; text: string }[] = [
  { Icon: <Icon className="fa fa-info" />, text: 'Information' },
  { Icon: <Icon className="fa fa-share" />, text: 'Share Settings' },
  { Icon: <Icon className="fa fa-lock" />, text: 'Password Rules' },
  { Icon: <Icon className="fa fa-x-ray" />, text: 'Viewer settings' },
  { Icon: <Icon className="fa fa-file" />, text: 'Template Terms' },
  { Icon: <Icon className="fa fa-file" />, text: 'Template Notifications' },
  // { Icon: <Icon className="fa fa-desktop" />, text: 'Waiting room display' },
]
export default function Preferences() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams<any>()
  const {
    clientId,
    client,
    notifications,
    newNotification,
    terms,
    newTerm,
    loading,
  } = useSelector(({ test, client, loading, auth }) => ({
    clientId: auth.account?.client,
    notifications: test.notifications,
    newNotification: test.newNotification,
    terms: test.terms,
    newTerm: test.newTerm,
    client: client.selected,
    loading: loading[Actions.CLIENT_FIND_ONE] || loading[Actions.CLIENT_UPDATE],
  }))
  const [value, setValue] = useState(id ? 0 : 1)

  //TODO:
  const handleSubmitSharing = () => {}
  const handleSubmitSecurity = () => {}
  const handleSubmitViewers = () => {}

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (id) dispatch(findClient(parseInt(id)))
    else if (clientId) dispatch(findClient(clientId))
    else navigate(-1)
    return () => {
      dispatch(removeSelected())
    }
  }, [clientId, dispatch, history, id])

  if (!client)
    return (
      <Backdrop open={true}>
        <Avatar src={Logo} alt="logo" />
      </Backdrop>
    )
  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 30px' }}>
      <Grid container spacing={4} style={{ marginTop: 'auto' }}>
        <Grid item container>
          <Paper elevation={2} style={{ width: '100%', borderRadius: 25 }}>
            <AppBar
              position="static"
              sx={{ background: '#fff', borderRadius: '20px 20px 0px 0px' }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                {tabs.map((tab, index) => (
                  <Tab
                    key={index}
                    label={
                      <Box display="flex">
                        {tab.Icon}
                        <Text
                          text={tab.text}
                          style={{ marginLeft: MARGIN_LEFT }}
                        />
                      </Box>
                    }
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <ClientForm />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <SharingPreferencesForm
                preferences={client.preferences}
                onSubmit={handleSubmitSharing}
                loading={loading}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <SecurityPreferencesForm
                preferences={client.preferences}
                onSubmit={handleSubmitSecurity}
                loading={loading}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ViewersPreferencesForm
                preferences={client.preferences}
                onSubmit={handleSubmitViewers}
                loading={loading}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <TermTemplate terms={terms} newTerm={newTerm} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <NotificationTemplateForm
                notifications={notifications}
                newNotification={newNotification}
              />
            </TabPanel>
            {/* <TabPanel value={value} index={6}>
              <DisplaySettingsForm />
            </TabPanel> */}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
