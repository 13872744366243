import { createAsyncThunk } from '@reduxjs/toolkit'
import { AmbulanceActions } from '../actions'
import {
  AmbulanceService,
  FindAmbulanceCompaniesQuery,
  FindAmbulanceDriversQuery as FindPatientTransportsQuery,
} from '../../services/AmbulanceService'
import { AmbulanceState } from '../reducers/ambulanceReducer'
import {
  CreateAmbulanceCompanyDto,
  CreatePatientTransportDto,
  UpdateAmbulanceCompanyDto,
  UpdateAmbulanceDriverDto,
} from '../../services/api'

export const findAmbulanceCompanies = createAsyncThunk(
  AmbulanceActions.FIND_ALL_COMPANIES,

  async (_, { getState }) => {
    const s = getState() as { ambulance: AmbulanceState }
    const { page, limit, siteId } = s.ambulance.filters
    const q: FindAmbulanceCompaniesQuery = {
      limit,
      page,
      siteId,
    }
    const res = await AmbulanceService.getCompanies(q)
    return res
  },
)

export const createAmbulanceCompany = createAsyncThunk(
  AmbulanceActions.CREATE_COMPANY,
  async (dto: CreateAmbulanceCompanyDto) => {
    const res = await AmbulanceService.createCompany(dto)
    return res
  },
)

export const updateAmbulanceCompany = createAsyncThunk(
  AmbulanceActions.UPDATE_COMPANY,
  async (data: { id: number; dto: Partial<UpdateAmbulanceCompanyDto> }) => {
    const res = await AmbulanceService.updateAmbulanceCompany(data.id, data.dto)
    return res
  },
)

export const deleteAmbulanceCompany = createAsyncThunk(
  AmbulanceActions.DELETE_COMPANY,
  async (ids: number[]) => {
    const res = await AmbulanceService.deleteCompanies(ids)
    return res
  },
)

export const findPatientTransports = createAsyncThunk(
  AmbulanceActions.FIND_ALL_DRIVERS,
  async (_, { getState }) => {
    const s = getState() as { ambulance: AmbulanceState }
    const { page, limit, companyId, search } =
      s.ambulance.patientTransportFilters
    const q: FindPatientTransportsQuery = {
      limit,
      page,
      companyId,
      search,
    }
    const res = await AmbulanceService.findPatientTransports(q)

    return res
  },
)

export const createPatientTransport = createAsyncThunk(
  AmbulanceActions.CREATE_DRIVER,
  async (dto: CreatePatientTransportDto) => {
    const res = await AmbulanceService.createPatientTransport(dto)
    return res
  },
)

export const updatePatientTransport = createAsyncThunk(
  AmbulanceActions.UPDATE_DRIVER,
  async (data: { id: number; dto: UpdateAmbulanceDriverDto }) => {
    const res = await AmbulanceService.updatePatientTransport(data.id, data.dto)
    return res
  },
)

export const deletePatientTransports = createAsyncThunk(
  AmbulanceActions.DELETE_DRIVER,
  async (ids: number[]) => {
    const res = await AmbulanceService.deletePatientTransports(ids)
    return res
  },
)
