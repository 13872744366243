import { AccordionDetails, Divider, Grid, Checkbox, FormControlLabel, InputLabel,FormControl, Select,MenuItem, FormHelperText, TextField } from '@mui/material'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CashRegisterSchema } from '@utils/schemas'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AccessEnum,
} from '@services/api'
import { findAll as findAllSites } from '@state/thunks/siteThunk'

type Values = Yup.InferType<typeof CashRegisterSchema> // TODO: rework this
 
export default function PatientInformation() {
  const dispatch = useDispatch()
  const {
    formState: { errors },
    control,
  } = useForm<Values>({
    resolver: yupResolver(CashRegisterSchema),
  })

  // Reducers
  const { sites } = useSelector(({ site }: { site: any }) => ({
    sites: site.sites.datas,
  }))
  
  // Map all sites
  const sitesOptions = useMemo(
    () =>
      sites.map((site) => ({
        value: site.id as number,
        label: site.label,
      })),
    [sites],
  )

  console.log(sitesOptions)

  useEffect(() => {
    dispatch(findAllSites())
  }, [])

  const permissionOptions = Object.values(AccessEnum).map((_v) => ({
    label: _v,
    value: _v,
  }))


  return (
    <div>
      <Divider variant="fullWidth" sx={{ marginBottom: 3, marginTop: 3, }} />
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextField
              label="Nom du rôle"
              name="roleName"
              size="medium"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Site</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="site"
                name='site'
                // value={age}
                // onChange={}
              >
                {/* <MenuItem value={'DR'}>All site</MenuItem> // TODO: make this back end */}
                {sitesOptions.map((site) => (
                  <MenuItem key={site.value} value={site.value}>{site.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextField
              label="Description du rôle"
              name="roleDescription"
              size="medium"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider variant="fullWidth" sx={{ marginTop: 3, marginBottom: 0  }} />
    </div>
  )
}
