import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type testState = {
  language: string
  orgShare: {
    maxPermission: string
    ownershipAccept: boolean
    permission: string
    expiration: number
    viewsNumber: number
    duration: number
    statShareEnabled: boolean
  }
  userShare: {
    orgPerm: string
    orgExpire: number
    proPerm: string
    proExpire: number
  }
  userAccount: {
    firstName: string
    lastName: string
    professionType: string
    providerType: string
    providerSpecialty: string
    defaultAction: string
    mobileNumber: string
    mobileProvider: string
    secondaryEmail: string
    password: string
  }
  passwordRules: {
    changePasswordMandatory: boolean
    changePasswordPeriod: number
    reusePasswordAllowed: boolean
    changeInterdiction: number
    periodInterdiction: number
    otpMandatory: boolean
    otpMethod: string
    failure: number
    period: number
    window: number
    timeout: number
  }
  notifications: {
    id: number
    name: string
    subject: string
    content: string
    version: string
  }[]
  newNotification: {
    id: number
    name: string
    subject: string
    content: string
    version: string
  }
  terms: {
    id: number
    status: string
    effectiveDate: string
    timezone: string
    terms: string
    changeSummary: string
    content: string
  }[]
  newTerm: {
    id: number
    status: string
    effectiveDate: string
    timezone: string
    terms: string
    changeSummary: string
    content: string
  }
  doctors: {
    id: number
    name: string
    organizations: {
      id: number
    }[]
    city: string
    state: string
    favorite: boolean
    recipients: string[]
  }[]
  organizations: {
    id: number
    name: string
    city: string
    state: string
    favorite: boolean
    recipients: string[]
  }[]
}

const initialState: testState = {
  language: navigator.language.split(/[-_]/)[0],
  orgShare: {
    maxPermission: 'View Only',
    ownershipAccept: true,
    permission: 'View Only',
    expiration: 0,
    viewsNumber: 0,
    duration: 1,
    statShareEnabled: false,
  },
  userShare: {
    orgPerm: 'View Only',
    orgExpire: 14,
    proPerm: 'View Only',
    proExpire: 14,
  },
  userAccount: {
    firstName: 'Johan',
    lastName: 'ZOLI',
    professionType: 'Healthcare staff/support',
    providerType: 'MD',
    providerSpecialty: 'Radiology',
    defaultAction: 'Mobile Phone SMS',
    mobileNumber: '0606060606',
    mobileProvider: 'Apple',
    secondaryEmail: 'yohan@zoli.fr',
    password: '12Toto34!',
  },
  passwordRules: {
    changePasswordMandatory: true,
    changePasswordPeriod: 3,
    reusePasswordAllowed: false,
    changeInterdiction: 2,
    periodInterdiction: 3,
    otpMandatory: true,
    otpMethod: 'SMS',
    failure: 3,
    period: 5,
    window: 5,
    timeout: 30,
  },
  notifications: [
    {
      id: 1,
      name: 'Example notifications',
      subject: 'first example of notifcation',
      content: 'This the first Example of notification email or SMS',
      version: 'Original',
    },
    {
      id: 2,
      name: '2nd Example notifications',
      subject: 'second example of notifcation',
      content: 'This the second Example of notification email or SMS',
      version: 'Original',
    },
  ],
  newNotification: {
    id: 0,
    name: 'New Notification',
    subject: '',
    content: '',
    version: 'Original',
  },
  terms: [
    {
      id: 1,
      status: 'Draft',
      effectiveDate: '2021-01-01',
      timezone: 'Eastern',
      terms: '1st Exemple of Terms',
      changeSummary: 'No change',
      content: 'this is the first terms example',
    },
  ],
  newTerm: {
    id: 0,
    status: 'Draft',
    effectiveDate: '',
    timezone: '',
    terms: 'New Terms',
    changeSummary: '',
    content: '',
  },
  doctors: [
    {
      id: 1,
      name: 'Dr Lenzini',
      organizations: [
        {
          id: 3,
        },
        {
          id: 1,
        },
      ],
      city: 'Marseille',
      state: 'France',
      favorite: true,
      recipients: [
        'dr.lenzini@hopital-nord.fr',
        'dr.lenzini@hopital-saint-joseph.fr',
      ],
    },
    {
      id: 2,
      name: 'Dr Do little',
      organizations: [
        {
          id: 2,
        },
      ],
      city: 'New York',
      state: 'New York',
      favorite: false,
      recipients: ['dr.dolittle@hopital-pontchailloux.com'],
    },
  ],
  organizations: [
    {
      id: 1,
      name: 'Hôpital St Joseph',
      city: 'Marseille',
      state: 'France',
      favorite: false,
      recipients: [
        'administration@hopital-saint-joseph.fr',
        'alldoctors@hopital-saint-joseph.fr',
      ],
    },
    {
      id: 2,
      name: 'Hôpital Pontchailloux',
      city: 'Rennes',
      state: 'France',
      favorite: true,
      recipients: ['administration@hopital-pontchailloux.com'],
    },
  ],
}

const testSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    setOrgShare: (state, action: PayloadAction<any>) => {
      state.orgShare = { ...state.orgShare, ...action.payload }
    },
    setUserShare: (state, action: PayloadAction<any>) => {
      state.userShare = action.payload
    },
    setUserAccount: (state, action: PayloadAction<any>) => {
      state.userAccount = action.payload
    },
    setPasswordRules: (state, action: PayloadAction<any>) => {
      state.passwordRules = action.payload
    },
    setotpMethod: (state, action: PayloadAction<any>) => {
      state.passwordRules = {
        ...state.passwordRules,
        otpMethod: action.payload,
      }
    },
    setNotification: (state, action: PayloadAction<any>) => {
      if (action.payload.id === 0) {
        const newId = state.notifications.length + 1
        const newNot = { ...action.payload, id: newId }
        state.notifications.push(newNot)
      }
      const newNotificationState = state.notifications.map((not) =>
        not.id === action.payload.id ? { ...not, ...action.payload } : not,
      )
      state.notifications = newNotificationState
    },
    setTerm: (state, action: PayloadAction<any>) => {
      if (action.payload.id === 0) {
        const newId = state.terms.length + 1
        const newTerm = { ...action.payload, id: newId }
        state.terms.push(newTerm)
      }
      const newTermState = state.terms.map((term) =>
        term.id === action.payload.id ? { ...term, ...action.payload } : term,
      )
      state.terms = newTermState
    },
    setDoctors: (state, action: PayloadAction<any>) => {
      state.doctors = { ...state.doctors, ...action.payload }
    },
    setOrganizations: (state, action: PayloadAction<any>) => {
      state.organizations = { ...state.organizations, ...action.payload }
    },
    cleanSignFail: () => initialState,
  },
})

export const {
  setOrgShare,
  setUserShare,
  setUserAccount,
  setPasswordRules,
  setotpMethod,
  setNotification,
  setTerm,
  setDoctors,
  setOrganizations,
} = testSlice.actions

export default testSlice.reducer
