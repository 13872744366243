export class ResipService {
  private static readonly endpoint = `${process.env.REACT_APP_RESIP_URL}`

  public static async getDashboardUrl(
    resipId: string,
    readerId: string,
  ): Promise<string> {
    const response = await fetch(
      `${ResipService.endpoint}/gestion/TableauBord?idResip=${resipId}&idLecteur=${readerId}`,
    )
    if (response.status >= 400 && response.status < 499) {
      throw new Error(response.statusText)
    } else if (response.status >= 500) {
      throw new Error('Erreur serveur')
    }
    const data = await response.json()
    if (data.error) {
      throw new Error(data.erreurMessage)
    }
    return data.url.replace(
      new RegExp('http://172.18.162.21:8082', 'g'),
      process.env.REACT_APP_BILLING_APP_URL,
    )
  }

  public static async getScorUrl(
    resipId: string,
    readerId: string,
  ): Promise<string> {
    const response = await fetch(
      `${ResipService.endpoint}/gestion/TableauBordScor?idResip=${resipId}&idLecteur=${readerId}`,
    )
    if (response.status >= 400 && response.status < 499) {
      throw new Error(response.statusText)
    } else if (response.status >= 500) {
      throw new Error('Erreur serveur')
    }
    const data = await response.json()
    if (data.error) {
      throw new Error(data.erreurMessage)
    }
    return data.url.replace(
      new RegExp('http://172.18.162.21:8082', 'g'),
      process.env.REACT_APP_BILLING_APP_URL,
    )
  }

  public static async getTeletransmissionUrl(
    resipId: string,
    readerId: string,
  ): Promise<string> {
    const response = await fetch(
      `${ResipService.endpoint}/gestion/Teletransmission?idResip=${resipId}&idLecteur=${readerId}`,
    )
    if (response.status >= 400 && response.status < 499) {
      throw new Error(response.statusText)
    } else if (response.status >= 500) {
      throw new Error('Erreur serveur')
    }
    const data = await response.json()
    if (data.error) {
      throw new Error(data.erreurMessage)
    }
    return data.url.replace(
      new RegExp('http://172.18.162.21:8082', 'g'),
      process.env.REACT_APP_BILLING_APP_URL,
    )
  }

  public static async getReturnManagementUrl(
    resipId: string,
    readerId: string,
  ): Promise<string> {
    const response = await fetch(
      `${ResipService.endpoint}/gestion/GestionRetours?idResip=${resipId}&idLecteur=${readerId}`,
    )
    if (response.status >= 400 && response.status < 499) {
      throw new Error(response.statusText)
    } else if (response.status >= 500) {
      throw new Error('Erreur serveur')
    }
    const data = await response.json()
    if (data.error) {
      throw new Error(data.erreurMessage)
    }
    return data.url.replace(
      new RegExp('http://172.18.162.21:8082', 'g'),
      process.env.REACT_APP_BILLING_APP_URL,
    )
  }

  public static async getUserListUrl(
    resipId: string,
    readerId: string,
  ): Promise<string> {
    const response = await fetch(
      `${ResipService.endpoint}/administration/ListeUtilisateurs?idResip=${resipId}&idLecteur=${readerId}`,
    )
    if (response.status >= 400 && response.status < 499) {
      throw new Error(response.statusText)
    } else if (response.status >= 500) {
      throw new Error('Erreur serveur')
    }
    const data = await response.json()
    if (data.error) {
      throw new Error(data.erreurMessage)
    }
    return data.url.replace(
      new RegExp('http://172.18.162.21:8082', 'g'),
      process.env.REACT_APP_BILLING_APP_URL,
    )
  }

  public static async getUserConfigurationUrl(
    resipId: string,
    readerId: string,
  ): Promise<string> {
    const response = await fetch(
      `${ResipService.endpoint}/administration/Utilisateur?idResip=${resipId}&idLecteur=${readerId}`,
    )
    if (response.status >= 400 && response.status < 499) {
      throw new Error(response.statusText)
    } else if (response.status >= 500) {
      throw new Error('Erreur serveur')
    }
    const data = await response.json()
    if (data.error) {
      throw new Error(data.erreurMessage)
    }
    return data.url.replace(
      new RegExp('http://172.18.162.21:8082', 'g'),
      process.env.REACT_APP_BILLING_APP_URL,
    )
  }

  public static async getGeneralParametersUrl(
    _: string,
    readerId: string,
  ): Promise<string> {
    const response = await fetch(
      `${ResipService.endpoint}/administration/Parametres?idLecteur=${readerId}`,
    )
    if (response.status >= 400 && response.status < 499) {
      throw new Error(response.statusText)
    } else if (response.status >= 500) {
      throw new Error('Erreur serveur')
    }
    const data = await response.json()
    if (data.error) {
      throw new Error(data.erreurMessage)
    }
    return data.url.replace(
      new RegExp('http://172.18.162.21:8082', 'g'),
      process.env.REACT_APP_BILLING_APP_URL,
    )
  }

  public static async getConfigurationDisplayUrl(
    _: string,
    readerId: string,
  ): Promise<string> {
    const response = await fetch(
      `${ResipService.endpoint}/administration/Configuration?idLecteur=${readerId}`,
    )
    if (response.status >= 400 && response.status < 499) {
      throw new Error(response.statusText)
    } else if (response.status >= 500) {
      throw new Error('Erreur serveur')
    }
    const data = await response.json()
    if (data.error) {
      throw new Error(data.erreurMessage)
    }
    return data.url.replace(
      new RegExp('http://172.18.162.21:8082', 'g'),
      process.env.REACT_APP_BILLING_APP_URL,
    )
  }

  public static async getVersionUrl(
    _: string,
    readerId: string,
  ): Promise<string> {
    const response = await fetch(
      `${ResipService.endpoint}/administration/Configuration?idLecteur=${readerId}`,
    )
    if (response.status >= 400 && response.status < 499) {
      throw new Error(response.statusText)
    } else if (response.status >= 500) {
      throw new Error('Erreur serveur')
    }
    const data = await response.json()
    if (data.error) {
      throw new Error(data.erreurMessage)
    }
    return data.url.replace(
      new RegExp('http://172.18.162.21:8082', 'g'),
      process.env.REACT_APP_BILLING_APP_URL,
    )
  }
}
