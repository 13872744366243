import {
  CreateItEquipmentDto,
  EquipmentService,
  UpdateItEquipmentDto,
} from '@services/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'

export const findAllItEquipment = createAsyncThunk(
  Actions.IT_EQUIPMENT_FIND_ALL,
  async (_, { rejectWithValue }) => {
    try {
      return await EquipmentService.findAllItEquipment()
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const updateItEquipment = createAsyncThunk(
  Actions.IT_EQUIPMENT_UPDATE,
  async (
    { id, body }: { id: number; body: UpdateItEquipmentDto },
    { rejectWithValue },
  ) => {
    try {
      return await EquipmentService.updateItEquipment({ id, body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const createItEquipment = createAsyncThunk(
  Actions.IT_EQUIPMENT_CREATE,
  async (body: CreateItEquipmentDto, { rejectWithValue }) => {
    try {
      return await EquipmentService.createItEquipment({ body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const deleteItEquipment = createAsyncThunk(
  Actions.IT_EQUIPMENT_DELETE,
  async (id: number | number[], { rejectWithValue }) => {
    try {
      return await EquipmentService.removeItEquipment({ id })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
