import React, { useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { doGetOrderHistory } from '@state/reducers/orderReducer'
import { OrderDto } from '@services/dtos'
import { Button, Divider, Popover } from '@mui/material'

type Props = {
  order: OrderDto
}

export const RenderOrderDetails: React.FC<{ order: OrderDto }> = ({
  order,
}) => {
  return (
    <div className="p-4 w-[500px] border border-1 border-gray-200 rounded-md">
      <div className="flex justify-between items-center">
        <span className="font-semibold text-sm">Date de l&nbsp;examen</span>
        <span className="text-sm font-light">
          {moment(order.plannedDate).format('DD-MM-YYYY HH:mm')}
        </span>
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className="flex justify-between items-center">
        <span className="font-semibold text-sm">Acceuilli le</span>
        <span className="text-sm font-light">
          {moment(order.registeredAt).format('DD-MM-YYYY HH:mm')}
        </span>
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className="flex justify-between items-center">
        <span className="font-semibold text-sm">Prescripteur</span>
        <span className="text-sm font-light">
          {order.referringDoctor.firstName} {order.referringDoctor.lastName}
        </span>
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className="flex justify-between items-center">
        <span className="font-semibold text-sm">Responsable </span>
        <span className="text-sm font-light">
          {order.attendingDoctor.firstName} {order.attendingDoctor.lastName}
        </span>
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className="flex justify-between items-center">
        <span className="font-semibold text-sm">Consultant </span>
        <span className="text-sm font-light">
          {order.consultingDoctor.firstName} {order.consultingDoctor.lastName}
        </span>
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className="flex justify-between items-center">
        <span className="font-semibold text-sm">Code</span>
        <span className="text-sm font-light">{order.exam.code}</span>
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className="flex justify-between items-center">
        <span className="font-semibold text-sm">Libellé</span>
        <span className="text-sm font-light">{order.exam.label}</span>
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className="flex justify-between items-center">
        <span className="font-semibold text-sm">Site</span>
        <span className="text-sm font-light">{order.site.label}</span>
      </div>
    </div>
  )
}

export const OrderHistoryComponent: React.FC<Props> = ({ order }) => {
  const dispatch = useAppDispatch()
  const orders = useAppSelector(({ order }) => order.orderHistory)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [selectedOrder, setSelectedOrder] = useState<OrderDto | null>(null)

  const handleClick = (
    order: OrderDto,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setSelectedOrder(order)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    dispatch(doGetOrderHistory(order.patient.id))
  }, [order, dispatch])

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const filtered = useMemo(() => {
    return orders.filter((orderItem) => orderItem.id !== order.id)
  }, [order, orders])

  return (
    <div className="h-full w-full overflow-auto ">
      <div className="flex flex-row flex-wrap gap-2">
        {filtered.map((orderItem) => (
          <Button
            key={orderItem.id}
            variant="contained"
            aria-owns={open ? 'mouse-over-popover' : undefined}
            color="primary"
            onMouseEnter={(event) => handleClick(orderItem, event)}
            onMouseLeave={handleClose}
          >
            <div className="relative rounded-md cursor-pointer">
              <div className="flex justify-between items-center">
                <span className="font-light text-sm">
                  {moment(orderItem.plannedDate).format('DD-MM-YYYY HH:mm')}
                </span>
              </div>
              <span className="text-sm">{orderItem.exam?.code}</span>
            </div>
          </Button>
        ))}
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={{ pointerEvents: 'none' }}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          disableRestoreFocus
        >
          {selectedOrder && <RenderOrderDetails order={selectedOrder} />}
        </Popover>
      </div>
    </div>
  )
}

export default OrderHistoryComponent
