import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography } from '@mui/material'

import {
  CreateMemberDto,
  MemberDto,
  MemberRoleEnum,
  SexEnum,
  UpdateMemberDto,
} from '@services/api'
import { createMember, updateMember } from '@state/thunks/memberThunk'
import { addMemberSchema } from '@utils/schemas'
import { SelectInput, TextInput } from '@components/inputs'

import { Button } from '@components/buttons'
import { useSelector } from '@state/store'

type AddMemberFormProps = {
  onClose?: () => void
  row?: MemberDto
}

export default function AssignRoleToMemberFrom({ row, onClose }: AddMemberFormProps) {
  const dispatch = useDispatch()

  const { sites, clients, client } = useSelector(({ client, auth, site }) => ({
    clients: client.clients.datas,
    client: auth.account?.client,
    sites: site.sites.datas,
  }))

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<MemberDto & { password: string }>({
    resolver: yupResolver(addMemberSchema),
    defaultValues: {
      role: '' as any,
      phoneNumber: '',
      ...row,
    },
  })

  //   const onSubmit = (values: MemberDto & { password: string }) => {
  //     if (row?.id) {
  //       const updateMemberDto: UpdateMemberDto = { ...row, ...values }
  //       dispatch(updateMember({ id: row.id as number, body: updateMemberDto }))
  //     } else {
  //       const createMemberDto: CreateMemberDto = {
  //         firstName: values.firstName,
  //         lastName: values.lastName,
  //         address: values.address,
  //         password: values.password,
  //         email: values.email,
  //         motherMaidenName: '',
  //         mobileNumber: '',
  //         phoneNumber: '',
  //         role: values.role,
  //         sex: SexEnum.A,
  //       }

  //       dispatch(createMember(createMemberDto))
  //     }
  //     if (onClose) onClose()
  //   }

  const siteOptions = sites.map((_site) => ({
    label: _site.label,
    value: _site.id,
  }))

  const rolesOptions = Object.values(MemberRoleEnum).map((v) => ({
    label: v,
    value: v,
  }))

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const clientOptions = clients.map(({ name, id }) => ({
    label: name,
    value: id as number,
  }))

  const handleCancelPress = () => {
    if (onClose) {
      onClose()
    }
    // Closes the update form - Datagrid didnt provide a prop function to close form ()
    const el = (document as Document).querySelector(
      `.MuiDataGrid-row[data-id="${row?.id}"] .MuiIconButton-root.MuiIconButton-sizeSmall`,
    ) as any
    if (el) {
      el.click()
    }
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      sx={{
        paddingTop: 5,
        minWidth: 400,
        width: '110vw',
      }}
    >
      <Grid item xs={12}>
        <Typography variant="body1" color="success">Assigner un rôle à {row?.firstName} {row?.lastName}</Typography>
      </Grid>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: 2,
          borderRadius: 5,
        }}
      >
        <Grid item xs={12} xl={6}>
          <SelectInput
            name="siteId"
            options={siteOptions}
            errors={errors}
            control={control}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <SelectInput
            name="role"
            options={rolesOptions}
            errors={errors}
            control={control}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
