import { SelectInput, EcSwitchInput } from '@components/inputs'
import { Grid } from '@mui/material'
import { Control, DeepMap, FieldError } from 'react-hook-form'
import {
  linacTps,
  oncologyInformationSystem,
  scannerBarrettes,
} from '@utils/constants'

type FormTypeProps = {
  control: Control<any>
  errors?: DeepMap<any, FieldError>
}

export function EchographeForm() {
  return <></>
}

export function IRMForm() {
  return <></>
}

export function RemoteControlledTableForm() {
  return <></>
}

export function OsteodentisometreForm() {
  return <></>
}

export function EOSForm() {
  return <></>
}

export function TepScanForm() {
  return <></>
}

export function GammaCameraForm() {
  return <></>
}

export function ScannerForm({ control }: FormTypeProps) {
  return (
    <Grid item xs={12} xl={6}>
      <SelectInput
        options={scannerBarrettes}
        control={control}
        name="numberBarrettes"
      />
    </Grid>
  )
}

export function MammographeForm({ control }: FormTypeProps) {
  return (
    <>
      <Grid item xs={12} xl={6}>
        <EcSwitchInput
          control={control}
          input="tomosynthese"
          defaultValue={0}
        />
        <EcSwitchInput control={control} input="macroMicro" defaultValue={0} />
      </Grid>
    </>
  )
}

export function LinacForm({ control }: FormTypeProps) {
  return (
    <>
      <Grid item xs={12} xl={6}>
        <EcSwitchInput
          control={control}
          input="stereotactic"
          defaultValue={0}
        />
        <EcSwitchInput control={control} input="KVImaging" defaultValue={0} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <EcSwitchInput control={control} input="arcTherapy" defaultValue={0} />
        <EcSwitchInput
          control={control}
          input="mirrorDevice"
          defaultValue={0}
        />
      </Grid>

      <Grid item xs={12} xl={6}>
        <SelectInput options={linacTps} control={control} name="tps" />
      </Grid>

      <Grid item xs={12} xl={6}>
        <SelectInput
          options={oncologyInformationSystem}
          control={control}
          name="oncology"
        />
      </Grid>
    </>
  )
}
