import { CouvertureDto } from 'src/common/interfaces'

export interface CardReaderDto {
  id: string
}

export async function getUrl(): Promise<CardReaderDto> {
  const url = process.env.REACT_APP_CARD_READER_URL
  if (!url) {
    throw new Error('REACT_APP_CARD_READER_URL is not defined')
  }
  try {
    const response = await fetch(url, {
      method: 'GET',
    })
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`)
    }
    const data: CardReaderDto = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export async function generateObjectHash(obj: CouvertureDto): Promise<string> {
  const { id, idPatient, ...relevantFields } = obj
  const sortedObj = Object.keys(relevantFields)
    .sort()
    .reduce((acc, key) => {
      acc[key] = relevantFields[key]
      return acc
    }, {} as Partial<CouvertureDto>)

  const objString = JSON.stringify(sortedObj)
  const msgUint8 = new TextEncoder().encode(objString)
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')

  return hashHex
}

export async function hasObjectChanged(
  originalHash: string,
  currentObj: CouvertureDto,
): Promise<boolean> {
  const currentHash = await generateObjectHash(currentObj)
  return originalHash !== currentHash
}

export async function getVitalCardResipUrl(
  cardReaderId: string,
  readDate: string,
): Promise<string> {
  const url = `${process.env.REACT_APP_RESIP_URL}/lecture/LectureCarte?idResip=1&dateLecteur=${readDate}&idLecteur=${cardReaderId}`

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const cardReadRes = (await response.json()) as {
      url: string
      erreur: boolean
      erreurCode: string
      erreurMessage: string
    }

    if (cardReadRes.erreurCode) {
      throw new Error(cardReadRes.erreurMessage)
    }

    return cardReadRes.url
  } catch (error) {
    throw error instanceof Error
      ? error
      : new Error('An unknown error occurred')
  }
}

export async function sendReaderResponse(
  visitId: number,
  readDate: string,
  cv: any,
): Promise<CouvertureDto> {
  const url = `${process.env.REACT_APP_API_URL}/fabien-billing-service/lecture/getcouvertursummury?idVisit=${visitId}&dateLecture=${readDate}`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(cv),
  })
  if (!response.ok) {
    throw new Error(`Network response was not ok: ${response.statusText}`)
  }
  const data: {
    error: string
    couverture: CouvertureDto
  } = await response.json()

  return data.error ? Promise.reject(data.error) : data.couverture
}
