import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Container,
  Grid,
  Dialog,
  DialogContent,
  Paper,
  DialogActions,
  Button,
  Icon,
  TableContainer,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Popover,
  ListItem,
  ListItemText,
  Box,
} from '@mui/material'
import { useSelector } from '@state/store'
import { DialogTitle } from '@components/titles'
import { findAll as findAllSites } from '@state/thunks/siteThunk'
import { Actions } from '@state/actions'
import theme from '@utils/theme'
import { removeSites } from '../../../state/reducers/siteReducer'
import { deleteRoles, findAllRoles } from '@state/thunks/roleThunk'
import RoleFormCU from './RoleFromCU'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React from 'react'
import { List } from '@mui/icons-material'

export default function Role() {
  const dispatch = useDispatch()
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [openModifyDialog, setOpenModifyDialog] = useState(false)
  
  const { loading, roles, } = useSelector(({ role, loading }) => ({
    roles: role.roles,
    loading: loading[Actions.ROLE_FIND_ALL] || loading[Actions.ROLE_UPDATE],
  }))

  const { sites } = useSelector(({ site }: { site: any }) => ({
    sites: site.sites.datas,
  }))

  const sitesOptions = useMemo(
    () =>
      sites.map((site) => ({
        value: site.id as number,
        label: site.label,
      })),
    [sites],
  )

  useEffect(() => {
    dispatch(findAllSites())
  }, [])

  const role = [
    { id: 1, name: 'Admin', description: 'Admin role' },
    { id: 2, name: 'Patient', description: 'Patient role' },
    { id: 3, name: 'Medecin', description: 'Medecin role' },
    { id: 4, name: 'Super Admin', description: 'Super Admin role' },
    { id: 5, name: 'Secrétariat', description: 'Secrétariat role'},
    { id: 6, name: 'Facturation', description: 'Facturation role' },
    { id: 7, name: 'Comptabilité', description: 'Comptabilité role' },
    { id: 8, name: 'Dev', description: 'Dev role' },
    { id: 9, name: 'Dev Front', description: 'Dev Front role' },
    { id: 10, name: 'Dev Back', description: 'Dev Back role' },
    { id: 11, name: 'Dev Fullstack', description: 'Dev Fullstack role' },
    { id: 12, name: 'Dev Ops', description: 'Dev Ops role' },
    { id: 13, name: 'Dev Secu', description: 'Dev Secu role' },
    { id: 14, name: 'Dev Secu', description: 'Dev Secu role' },
    { id: 15, name: 'Dev Secu', description: 'Dev Secu role' },
  ]

  const MatriceDeDroits = [
    { 
      name: 'Commun',
      subSectionName: [
        {
          name: 'Gestion des utilisateurs',
          rights: {
            'Consulter les informations du patient': 'Consulter les informations du patient',
            'Modifier les informations du patient': 'Modifier les informations du patient',
            'Supprimer les informations du patient': 'Supprimer les informations du patient'
          },
        },
        {
          name: 'Gestion des rôles',
          rights: {
            'Consulter les informations du rôle': 'Consulter les informations du rôle',
            'Modifier les informations du rôle': 'Modifier les informations du rôle',
            'Supprimer les informations du rôle': 'Supprimer les informations du rôle'
          },
        },
        {
          name: 'Gestion des droits',
          rights: {
            'Consulter les informations du droit': 'Consulter les informations du droit',
            'Modifier les informations du droit': 'Modifier les informations du droit',
            'Supprimer les informations du droit': 'Supprimer les informations du droit'
          },
        },
        {
          name: 'Gestion des utilisateurs',
          rights: {
            'Consulter les informations du patient': 'Consulter les informations du patient',
            'Modifier les informations du patient': 'Modifier les informations du patient',
            'Supprimer les informations du patient': 'Supprimer les informations du patient'
          },
        },
        {
          name: 'Gestion des rôles',
          rights: {
            'Consulter les informations du rôle': 'Consulter les informations du rôle',
            'Modifier les informations du rôle': 'Modifier les informations du rôle',
            'Supprimer les informations du rôle': 'Supprimer les informations du rôle'
          },
        },
        {
          name: 'Gestion des droits',
          rights: {
            'Consulter les informations du droit': 'Consulter les informations du droit',
            'Modifier les informations du droit': 'Modifier les informations du droit',
            'Supprimer les informations du droit': 'Supprimer les informations du droit'
          },
        },
        {
          name: 'Gestion des utilisateurs',
          rights: {
            'Consulter les informations du patient': 'Consulter les informations du patient',
            'Modifier les informations du patient': 'Modifier les informations du patient',
            'Supprimer les informations du patient': 'Supprimer les informations du patient'
          },
        },
        {
          name: 'Gestion des rôles',
          rights: {
            'Consulter les informations du rôle': 'Consulter les informations du rôle',
            'Modifier les informations du rôle': 'Modifier les informations du rôle',
            'Supprimer les informations du rôle': 'Supprimer les informations du rôle'
          },
        },
        {
          name: 'Gestion des droits',
          rights: {
            'Consulter les informations du droit': 'Consulter les informations du droit',
            'Modifier les informations du droit': 'Modifier les informations du droit',
            'Supprimer les informations du droit': 'Supprimer les informations du droit'
          },
        },
        {
          name: 'Gestion des utilisateurs',
          rights: {
            'Consulter les informations du patient': 'Consulter les informations du patient',
            'Modifier les informations du patient': 'Modifier les informations du patient',
            'Supprimer les informations du patient': 'Supprimer les informations du patient'
          },
        },
        {
          name: 'Gestion des rôles',
          rights: {
            'Consulter les informations du rôle': 'Consulter les informations du rôle',
            'Modifier les informations du rôle': 'Modifier les informations du rôle',
            'Supprimer les informations du rôle': 'Supprimer les informations du rôle'
          },
        },
        {
          name: 'Gestion des droits',
          rights: {
            'Consulter les informations du droit': 'Consulter les informations du droit',
            'Modifier les informations du droit': 'Modifier les informations du droit',
            'Supprimer les informations du droit': 'Supprimer les informations du droit'
          },
        },
        {
          name: 'Gestion des utilisateurs',
          rights: {
            'Consulter les informations du patient': 'Consulter les informations du patient',
            'Modifier les informations du patient': 'Modifier les informations du patient',
            'Supprimer les informations du patient': 'Supprimer les informations du patient'
          },
        },
        {
          name: 'Gestion des rôles',
          rights: {
            'Consulter les informations du rôle': 'Consulter les informations du rôle',
            'Modifier les informations du rôle': 'Modifier les informations du rôle',
            'Supprimer les informations du rôle': 'Supprimer les informations du rôle'
          },
        },
        {
          name: 'Gestion des droits',
          rights: {
            'Consulter les informations du droit': 'Consulter les informations du droit',
            'Modifier les informations du droit': 'Modifier les informations du droit',
            'Supprimer les informations du droit': 'Supprimer les informations du droit'
          },
        },
      ],
    },
    {
      name: 'Vue logiciel',
      subSectionName: [
        {
          name: 'Gestion des patients',
          rights: {
            'Consulter les informations du patient': 'Consulter les informations du patient',
            'Modifier les informations du patient': 'Modifier les informations du patient',
            'Supprimer les informations du patient': 'Supprimer les informations du patient'
          },
        },
        {
          name: 'Gestion des rendez-vous',
          rights: {
            'Consulter les informations du rendez-vous': 'Consulter les informations du rendez-vous',
            'Modifier les informations du rendez-vous': 'Modifier les informations du rendez-vous',
            'Supprimer les informations du rendez-vous': 'Supprimer les informations du rendez-vous'
          },
        },
        {
          name: 'Gestion des factures',
          rights: {
            'Consulter les informations de la facture': 'Consulter les informations de la facture',
            'Modifier les informations de la facture': 'Modifier les informations de la facture',
            'Supprimer les informations de la facture': 'Supprimer les informations de la facture'
          },
        },
      ],
    },
    {
      name: 'Dev options',
      subSectionName: [
        {
          name: 'Gestion des patients',
          rights: {
            'Consulter les informations du patient': 'Consulter les informations du patient',
            'Modifier les informations du patient': 'Modifier les informations du patient',
            'Supprimer les informations du patient': 'Supprimer les informations du patient'
          },
        },
        {
          name: 'Gestion des rendez-vous',
          rights: {
            'Consulter les informations du rendez-vous': 'Consulter les informations du rendez-vous',
            'Modifier les informations du rendez-vous': 'Modifier les informations du rendez-vous',
            'Supprimer les informations du rendez-vous': 'Supprimer les informations du rendez-vous'
          },
        },
        {
          name: 'Gestion des factures',
          rights: {
            'Consulter les informations de la facture': 'Consulter les informations de la facture',
            'Modifier les informations de la facture': 'Modifier les informations de la facture',
            'Supprimer les informations de la facture': 'Supprimer les informations de la facture'
          },
        },
      ],
    },
    {
      name: 'Role assignement',
      subSectionName: [
        {
          name: 'Gestion des patients',
          rights: {
            'Consulter les informations du patient': 'Consulter les informations du patient',
            'Modifier les informations du patient': 'Modifier les informations du patient',
            'Supprimer les informations du patient': 'Supprimer les informations du patient'
          },
        },
        {
          name: 'Gestion des rendez-vous',
          rights: {
            'Consulter les informations du rendez-vous': 'Consulter les informations du rendez-vous',
            'Modifier les informations du rendez-vous': 'Modifier les informations du rendez-vous',
            'Supprimer les informations du rendez-vous': 'Supprimer les informations du rendez-vous'
          },
        },
        {
          name: 'Gestion des factures',
          rights: {
            'Consulter les informations de la facture': 'Consulter les informations de la facture',
            'Modifier les informations de la facture': 'Modifier les informations de la facture',
            'Supprimer les informations de la facture': 'Supprimer les informations de la facture'
          },
        },
      ],
    }
  ]

  const onRefresh = useCallback(() => {
    dispatch(findAllRoles())
    dispatch(findAllSites())
  }, [dispatch])

  useEffect(() => {
    onRefresh()
    return () => {
      dispatch(removeSites())
    }
  }, [dispatch, onRefresh])

  const _onDeleteConfirm = () => {
    // dispatch(deleteRoles({ ids: selectedRows }))
    setIsDeleteDialogOpen(false)
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)


  return (
    <Container maxWidth={false} style={{ height: '100%', }}>
      <Grid container spacing={4} style={{ height: '100vh' }}>
        <Grid item container>
          <TableContainer>
            <Paper elevation={2} sx={{ borderRadius: '5px',}}>
              <Grid container spacing={2} sx={{ marginBottom: 2, display: 'flex', alignItems: 'center', marginTop: '10px', }}>
                <Grid item xs={1}  sx={{ color: '#3F3F3F', display: 'flex', paddingBottom: 2, alignItems: 'center', justifyContent: 'center', textAlign: 'left'}}>
                  <Icon
                    className="fas fa-filter"
                    fontSize="small"
                    onClick={(e) => { handleClick(e) }}
                    sx={{ color: '#3F3F3F', p:1,  }}
                  />
                  <Typography variant="h5" sx={{ }} >Rôles</Typography>
                </Grid>
                <Grid item xs={9}/>
                <Grid item xs={2} sx={{ display: 'flex', paddingRight: 1, paddingLeft: 1, paddingBottom: 2, alignItems: 'center', justifyContent: 'center', borderRadius: '5px 5px 5px 5px', }}>
                  <IconButton aria-label="filter" size="small">
                    <Icon
                      className="fas fa-filter"
                      fontSize="small"
                      onClick={(e) => { handleClick(e) }}
                      sx={{ color: '#3F3F3F', p:1,  }}
                    />
                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <Box sx={{ maxHeight: '400px' }}>
                        {role.map((role) => (
                          <ListItem key={role.id} dense>
                            <Checkbox
                              // checked={selectedRoles.includes(role.id)}
                              // onChange={(e) => onChange(role.id, e.target.checked)}
                            />
                            <ListItemText primary={role.name} />
                          </ListItem>
                        ))}
                      </Box>
                    </Popover>
                  </IconButton>
                  <IconButton aria-label="plus" size="small">
                    <Icon
                      className="fas fa-plus"
                      fontSize="small"
                      onClick={() => { setOpenAddDialog(true) }}
                      sx={{ color: '#3F3F3F', p:1,  }}
                    />
                  </IconButton>
                  <IconButton aria-label="edit" size="small">
                    <Icon
                      className="fas fa-edit" 
                      fontSize="small"
                      onClick={() => { setOpenModifyDialog(true) }}
                      sx={{ color: '#3F3F3F', p:1,  }}
                    />
                  </IconButton>
                  <IconButton aria-label="delete" size="small">
                    <Icon
                      className="fas fa-trash-alt"
                      fontSize="small"
                      onClick={() => { setIsDeleteDialogOpen(true) }}
                      sx={{ color: '#3F3F3F', p:1,  }}
                    />
                  </IconButton>
                  <IconButton aria-label="redo" size="small">
                    <Icon
                      className="fas fa-redo-alt"
                      fontSize="small"
                      // onClick={() => {}}
                      sx={{ color: '#3F3F3F', p:1,  }}
                    />
                  </IconButton>
                </Grid>        
              </Grid>
            </Paper>
            <Table>
              <TableBody>
                {MatriceDeDroits.map((section, sectionIndex) => (
                  <React.Fragment key={sectionIndex}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', }}>{section.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          <TableBody>
                            {section.subSectionName.map((sousSection, sousSectionIndex) => (
                              <React.Fragment key={sousSectionIndex}>
                                {/* <Paper elevation={2}> */}
                                <Accordion sx={{  backgroundColor: '#ededed', }}>
                                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', }}>{sousSection.name}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container>
                                      <Grid xs={4} item>
                                        <Table size='small'>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>Nom du droit</TableCell>
                                              <TableCell >Description</TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {Object.keys(sousSection.rights).map((rightName, rightIndex) => (
                                              <TableRow key={rightIndex}>
                                                <TableCell >{rightName}</TableCell>
                                                <TableCell >{sousSection.rights[rightName]}</TableCell>
                                                <TableCell>
                                                  <Checkbox
                                                    disabled
                                                    sx={{
                                                      '&.Mui-disabled': { color: 'transparent' },
                                                    }}
                                                  />
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </Grid>
                                      <Grid xs={8} sx={{ overflow: 'auto', }} item>
                                        <Table size='small'>
                                          <TableHead>
                                            <TableRow>
                                              {role.map(role => (
                                                <TableCell style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '20px' }} key={role.id}>{role.name}</TableCell>
                                              ))}
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {Object.keys(sousSection.rights).map((rightName, rightIndex) => (
                                              <TableRow key={rightIndex}>
                                                {role.map(role => (
                                                  <TableCell  sx={{ width: '50px', }} key={role.id}>
                                                    <Checkbox
                                                      // Gérer ici l'état de la checkbox pour chaque droit et chaque rôle
                                                      // Checked={...}
                                                      // OnChange={...}
                                                    />
                                                  </TableCell>
                                                ))}
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </Grid>
                                    </Grid>

                                  </AccordionDetails>
                                </Accordion>
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <MatriceTable matriceDeDroits={MatriceDeDroits} roles={role} /> */}
        </Grid>
      </Grid>

      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            minWidth: '50%',
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="addRole"
          onClose={() => setOpenAddDialog(false)}
          format
        />
        <DialogContent
          sx={{
            background: '#fff',
          }}
        >
          <RoleFormCU />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
            paddingBottom: '20px',
            paddingTop: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          <Button
            sx={{ 
              ':hover': {
                backgroundColor: '#333333',
              },
              color: 'white',
              background: '#3F3F3F',
              borderRadius: 5,
            }}
            variant='contained'
            color='success'
            fullWidth
            // onClick={() => setOpenMultiDeleteModal(false)}
          >
            Annuler
          </Button>
          <Button
            style={{ color: 'white' }}
            variant='contained'
            color='secondary'
            fullWidth
            sx={{ borderRadius: 5 }}
            onClick={_onDeleteConfirm}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openModifyDialog}
        onClose={() => setOpenModifyDialog(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            minWidth: '50%',
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="Modifier un rôle"
          onClose={() => setOpenModifyDialog(false)}
          format
        />
        <DialogContent
          sx={{
            background: '#fff',
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Grid container spacing={2} sx={{ margin: 1, p:1 }}>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Rôle</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="site"
                  name='site'
                // value={age}
                // onChange={}
                >
                  {/* <MenuItem value={'DR'}>All site</MenuItem> // TODO: make this back end */}
                  {role.map((site) => (
                    <MenuItem key={site.id} value={site.name}>{site.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Site</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="site"
                  name='site'
                // value={age}
                // onChange={}
                >
                  {/* <MenuItem value={'DR'}>All site</MenuItem> // TODO: make this back end */}
                  {sitesOptions?.map((site) => (
                    <MenuItem key={site.value} value={site.value}>{site.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
                label="Nouveau nom du rôle"
                name="roleName"
                size="medium"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
                label="Description du rôle"
                name="roleDescription"
                size="medium"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
          }}
        >
          <Button
            sx={{ 
              ':hover': {
                backgroundColor: '#333333',
              },
              color: 'white',
              background: '#3F3F3F',
              borderRadius: 5,
            }}
            variant='contained'
            color='success'
            fullWidth
            // onClick={() => setOpenMultiDeleteModal(false)}
          >
            Annuler
          </Button>
          <Button
            style={{ color: 'white' }}
            variant='contained'
            color='secondary'
            fullWidth
            sx={{ borderRadius: 5 }}
            // onClick={_onModifyConfirm}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setOpenModifyDialog(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            minWidth: '50%',
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="Supprimer un rôle"
          onClose={() => setIsDeleteDialogOpen(false)}
          format
        />
        <DialogContent
          sx={{
            background: '#fff',
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Grid container spacing={2} sx={{ margin: 1, p:1 }}>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Rôle</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="site"
                  name='site'
                // value={age}
                // onChange={}
                >
                  {/* <MenuItem value={'DR'}>All site</MenuItem> // TODO: make this back end */}
                  {role.map((site) => (
                    <MenuItem key={site.id} value={site.name}>{site.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Site</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="site"
                  name='site'
                // value={age}
                // onChange={}
                >
                  {/* <MenuItem value={'DR'}>All site</MenuItem> // TODO: make this back end */}
                  {sitesOptions?.map((site) => (
                    <MenuItem key={site.value} value={site.value}>{site.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
          }}
        >
          <Button
            sx={{ 
              ':hover': {
                backgroundColor: '#333333',
              },
              color: 'white',
              background: '#3F3F3F',
              borderRadius: 5,
            }}
            variant='contained'
            color='success'
            fullWidth
            // onClick={() => setOpenMultiDeleteModal(false)}
          >
            Annuler
          </Button>
          <Button
            style={{ color: 'white' }}
            variant='contained'
            color='secondary'
            fullWidth
            sx={{ borderRadius: 5 }}
            onClick={_onDeleteConfirm}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
