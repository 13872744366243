import {
  ColorInput,
  SelectInput,
  TextInput,
  EcSwitchInput,
} from '@components/inputs'
import { yupResolver } from '@hookform/resolvers/yup'
import { Divider, Grid } from '@mui/material'
import { useSelector } from '@state/store'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { addRoomSchema } from '@utils/schemas'
import { CreateExamRoomDto, UpdateExamRoomDto } from '@services/api'
import { useDispatch } from 'react-redux'
import { createExamRoom, updateExamRoom } from '../../../state/thunks/roomThunk'
import { ExamRoom, RoomType } from '@services/api'
import { TimeInput } from '@components/inputs/TimeInput'
import { SpaceHorizontal, SpaceVertical } from '@utils/Spacing'
import { Button } from '@components/buttons'

type AddExamRoomFormProps = {
  row?: ExamRoom
  onClose?: () => void
}

export function ExamRoomForm({ row, onClose }: AddExamRoomFormProps) {
  const dispatch = useDispatch()
  // Reducers
  const { sites, waitingRooms } = useSelector(({ site, room }) => ({
    sites: site.sites.datas,
    waitingRooms: room.rooms.filter(
      (room) => room.type === RoomType.WaitingRoom,
    ),
  }))

  //States
  const [selectedSite, setSelectedSite] = useState<number | null>(null)

  // Form control
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ExamRoom>({
    resolver: yupResolver(addRoomSchema),
    defaultValues: {
      ...row,
    },
  })

  const waitingRoomOptions = useMemo(
    () =>
      waitingRooms.reduce((acc, _room) => {
        if (_room.siteId === selectedSite) {
          acc.push({
            label: _room.label,
            value: _room.id,
          })
        }

        return acc
      }, [] as { label: string; value: string }[]),
    [selectedSite, waitingRooms],
  )

  useEffect(() => {
    setSelectedSite(row?.siteId || null)
  }, [row])

  const onSubmit = (values: ExamRoom) => {
    if (!selectedSite) {
      return
    }
    if (row?.id) {
      const body: UpdateExamRoomDto = {
        code: values.code,
        label: values.label,
        siteId: selectedSite,
        breakTime: values.breakTime,
        closingTime: values.closingTime,
        color: values.color,
        isWheelchairCompatible: values.isWheelchairCompatible,
        isStretcherCompatible: values.isStretcherCompatible,
        xrayCompatible: values.xrayCompatible,
        shared: values.shared,
        mutualized: values.mutualized,
        waitingRoomId: values.waitingRoomId,
        openingTime: values.openingTime,
        reopeningTime: values.reopeningTime,
      }
      dispatch(updateExamRoom({ id: row.id, body }))
    } else {
      const body: CreateExamRoomDto = {
        code: values.code,
        label: values.label,
        siteId: selectedSite,
        openingTime: values.openingTime,
        breakTime: values.breakTime,
        closingTime: values.closingTime,
        color: values.color,
        isWheelchairCompatible: values.isWheelchairCompatible || false,
        isStretcherCompatible: values.isStretcherCompatible || false,
        xrayCompatible: values.xrayCompatible || false,
        shared: values.shared || false,
        mutualized: values.mutualized || false,
        waitingRoomId: values.waitingRoomId,
        reopeningTime: values.reopeningTime,
      } as CreateExamRoomDto
      dispatch(createExamRoom(body))
    }
    if (onClose) onClose()
  }

  const handleCancelPress = () => {
    if (onClose) {
      onClose()
    }

    // Closes the update form - Datagrid didnt provide a prop function to close form ()
    const el = (document as Document).querySelector(
      `.MuiDataGrid-row[data-id="${row?.id}"] .MuiIconButton-root.MuiIconButton-sizeSmall`,
    ) as any
    if (el) {
      el.click()
    }
  }
  // Map all sites
  const sitesOptions = useMemo(
    () =>
      sites.map((site) => ({
        value: site.id as number,
        label: site.label,
      })),
    [sites],
  )

  const _handleSiteChange = (ev) => {
    setSelectedSite(ev.target.value)
  }

  // const defaultOpeningTime = new Date(1900, 1, 1, 8, 0).getTime()
  // const defaultBreakTime = new Date(1900, 1, 1, 12, 0).getTime()
  // const defaultReopeningTime = new Date(1900, 1, 1, 14, 0).getTime()
  // const defaultClosingTime = new Date(1900, 1, 1, 18, 0).getTime()
  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="flex-start"
      sx={{
        padding: '20px',
      }}
    >
      <Grid item xs={12} xl={6}>
        <SelectInput
          options={sitesOptions}
          name="siteId"
          value={selectedSite}
          onChange={_handleSiteChange}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <SelectInput
          name="waitingRoomId"
          variant="outlined"
          options={waitingRoomOptions}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput
          variant="outlined"
          name="code"
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextInput
          variant="outlined"
          name="label"
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          variant="outlined"
          name="locationInstructions"
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={6} justifySelf="flex-start">
        <EcSwitchInput control={control} input="wheelchairCompatible" />
        <EcSwitchInput control={control} input="stretcherCompatible" />
      </Grid>
      <Grid item xs={6} justifySelf="flex-start">
        <EcSwitchInput control={control} input="xrayCompatible" />
        <EcSwitchInput control={control} input="mutualized" />
      </Grid>
      <Grid item xs={12}>
        <SpaceVertical size={10} />
        <Divider variant="middle" />
        <SpaceVertical size={10} />
      </Grid>
      <Grid item xs={6} xl={3}>
        <TimeInput
          name="openingTime"
          label="openingTime"
          // defaultValue={defaultOpeningTime}
          size="small"
          variant="outlined"
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={6} xl={3}>
        <TimeInput
          label="breakTime"
          name="breakTime"
          // defaultValue={defaultBreakTime}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={6} xl={3}>
        <TimeInput
          name="reopeningTime"
          label="reopeningTime"
          // defaultValue={defaultReopeningTime}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={6} xl={3}>
        <TimeInput
          label="closingTime"
          name="closingTime"
          // defaultValue={defaultClosingTime}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12}>
        <SpaceVertical size={10} />
        <Divider variant="middle" />
        <SpaceVertical size={10} />
      </Grid>
      <Grid item xs={12}>
        <SpaceHorizontal size={20} />
        <ColorInput control={control} input="color" />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-end">
          {onClose && (
            <Grid item xs={2}>
              <Button
                onClick={handleCancelPress}
                color="secondary"
                text="cancel"
                textColor="white"
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <Button
              onClick={handleSubmit(onSubmit)}
              color="primary"
              text="save"
              textColor="white"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
