import flatten from 'flat'

import fr from './fr.json'
import en from './en.json'

type Json = { [key: string]: any }

const lan: Json = {
  en: en,
  fr: fr,
}

export const locales = (locale: string) => flatten(lan[locale]) as Json
