import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  Chip,
  Collapse,
  Stack,
} from '@mui/material'
import {
  Add as AddIcon,
  Download as DownloadIcon,
  Visibility as ViewIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material'
import DocumentUploader from './DocumentUploader'
import { getFileIcon } from '@components/Helpers'
import { Document } from '@services/api'
import { FC, useState } from 'react'
import ConfirmDialog from '@components/dialogs/ConfirmDialog'
import { DocumentService } from '@services/StorageService'

type Props = {
  entityName: string
  entityId?: number
  documents: Document[]
  isEditingDocuments: boolean
  setisEditingDocuments: (value: boolean) => void
  handleOnFileUploaded: (document: Document) => void
  setSelectedDocument: (document: Document) => void
  onDocumentDeleted: (document: Document) => void
}

export const DocumentsList: FC<Props> = ({
  documents,
  isEditingDocuments,
  setisEditingDocuments,
  handleOnFileUploaded,
  setSelectedDocument,
  entityName,
  entityId,
  onDocumentDeleted,
}) => {
  const [deletingDocument, setDeletingDocument] = useState<Document | null>(
    null,
  )
  const [uploadedFile, setUploadedFile] = useState<{
    url: string
    mimetype: string
  } | null>(null)

  const onConfirmDelete = () => {
    if (!deletingDocument) {
      return
    }
    DocumentService.deleteDocument(deletingDocument.id)
    onDocumentDeleted(deletingDocument)
    setDeletingDocument(null)
  }

  const onDocumentUploaded = (document: Document) => {
    handleOnFileUploaded(document)
    setisEditingDocuments(false)
    setUploadedFile(null)
  }

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Stack spacing={0}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  const isEditing = !isEditingDocuments
                  if (!isEditing && uploadedFile) {
                    setUploadedFile(null)
                  }
                  setisEditingDocuments(!isEditingDocuments)
                }}
                color="primary"
                variant="outlined"
                size="small"
              >
                {isEditingDocuments ? 'Fermer' : 'Ajouter un document'}
              </Button>
            </Box>

            <Collapse in={isEditingDocuments}>
              <Box mb={0}>
                <DocumentUploader
                  uploadedFile={uploadedFile}
                  setUploadedFile={setUploadedFile}
                  onUploaded={onDocumentUploaded}
                  entityId={entityId}
                  entityName={entityName}
                />
              </Box>
            </Collapse>

            <List sx={{ width: '100%' }}>
              {documents.map((doc) => (
                <ListItem
                  key={doc.id}
                  sx={{
                    mb: 1,
                    bgcolor: 'background.paper',
                    border: 1,
                    borderColor: 'divider',
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: 'action.hover',
                    },
                  }}
                  secondaryAction={
                    <Stack direction="row" spacing={0}>
                      <Tooltip title="View Document">
                        <IconButton
                          edge="end"
                          onClick={() => setSelectedDocument(doc)}
                          size="small"
                        >
                          <ViewIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Download">
                        <IconButton
                          edge="end"
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_API_URL}/${doc.url}`,
                              '_blank',
                            )
                          }
                          size="small"
                        >
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Download">
                        <IconButton
                          edge="end"
                          onClick={() => setDeletingDocument(doc)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  }
                >
                  <Stack direction="row" spacing={0} alignItems="center">
                    {getFileIcon(doc.gedFile.mimeType)}
                    <Box>
                      <Typography variant="body2" component="div">
                        {doc.gedFile.filename}
                      </Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Chip
                          sx={{ fontSize: 10 }}
                          label={doc.fileType}
                          size="small"
                          variant="outlined"
                        />
                        <Typography variant="caption" color="text.secondary">
                          {new Date(doc.createdAt).toLocaleDateString()}
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </ListItem>
              ))}
              {documents.length === 0 && (
                <Box
                  textAlign="center"
                  py={2}
                  sx={{
                    bgcolor: 'action.hover',
                    borderRadius: 1,
                  }}
                >
                  <Typography color="text.secondary" fontSize={12}>
                    Aucun document trouvé
                  </Typography>
                </Box>
              )}
            </List>
          </Stack>
        </CardContent>
      </Card>
      <ConfirmDialog
        open={!!deletingDocument}
        onCancel={() => setDeletingDocument(null)}
        onConfirm={onConfirmDelete}
        title="Supprimer le document?"
        message="Voulez-vous vraiment supprimer ce document?"
      />
    </>
  )
}

export default DocumentsList
