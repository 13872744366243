import React from 'react'
import { PrecotationValidationResponse } from '../../services/dtos'

interface BillSummaryComponentProps {
  data: PrecotationValidationResponse
}

const BillSummaryComponent: React.FC<BillSummaryComponentProps> = ({
  data,
}) => {
  const { visite, erreurs } = data
  const formattedDate = new Date(visite.dateApplication).toLocaleDateString()

  return (
    <div className="space-y-4 p-4">
      {erreurs.length > 0 && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative">
          Des erreurs ont été détectées lors de la validation
        </div>
      )}

      <div className="bg-white shadow rounded-lg">
        <div className="border-b border-gray-200 px-4 py-3">
          <h2 className="text-lg font-semibold">
            Résumé de la visite du {formattedDate}
          </h2>
        </div>

        <div className="p-4 space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center space-x-2">
              <span className="font-semibold">Montant total:</span>
              <span>{visite.montantTotal.toFixed(2)}€</span>
            </div>
            <div className="flex items-center space-x-2">
              <span className="font-semibold">Dépassement:</span>
              <span>{visite.montantDepassement.toFixed(2)}€</span>
            </div>
          </div>

          <div className="border rounded-lg p-4">
            <h3 className="font-semibold mb-2">Informations PS</h3>
            <div className="grid grid-cols-2 gap-2 text-sm">
              <div>Code spécialité: {visite.situationPs.codeSpecialite}</div>
              <div>
                Code conventionel: {visite.situationPs.codeConventionel}
              </div>
              <div>
                Ident. facturation: {visite.situationPs.identFacturation}
              </div>
              <div>Clé: {visite.situationPs.cleIdentFacturation}</div>
              <div>Contrat PS: {visite.situationPs.contratPs}</div>
            </div>
          </div>

          <div>
            <h3 className="font-semibold mb-2">Actes</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                      Code
                    </th>
                    <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                      Libellé
                    </th>
                    <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                      Prix unitaire
                    </th>
                    <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                      Base remb.
                    </th>
                    <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                      Dépassement
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {visite.actes.map((acte, index) => (
                    <tr key={`${acte.codeActe}-${index}`}>
                      <td className="px-4 py-2 text-sm font-medium">
                        {acte.codeActe}
                      </td>
                      <td className="px-4 py-2 text-sm">{acte.libelleActe}</td>
                      <td className="px-4 py-2 text-sm">
                        {acte.montantPrixUnitaire.toFixed(2)}€
                      </td>
                      <td className="px-4 py-2 text-sm">
                        {acte.montantBaseRemboursement.toFixed(2)}€
                      </td>
                      <td className="px-4 py-2 text-sm">
                        <span
                          className={`inline-flex px-2 py-1 rounded-full text-xs ${
                            acte.montantDepassement > 0
                              ? 'bg-red-100 text-red-800'
                              : 'bg-gray-100 text-gray-800'
                          }`}
                        >
                          {acte.montantDepassement.toFixed(2)}€
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BillSummaryComponent
