import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { LogDto } from '@services/api'

type logState = {
  logs: {
    totalCount: number
    datas: LogDto[]
  }
  selected?: LogDto
}

const initialState: logState = {
  logs: {
    totalCount: 0,
    datas: [],
  },
}

const logSlice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    removeLogs: (state) => {
      state.logs = initialState.logs
    },
    setSelected: (state, action: PayloadAction<LogDto>) => {
      state.selected = action.payload
    },
  },
})

export const { removeLogs, setSelected } = logSlice.actions

export default logSlice.reducer
