import Chip from '@mui/material/Chip'
import { GridCellParams } from '@mui/x-data-grid-pro'
import { ReactNode } from 'react'

export const ChipCell = ({ value }: GridCellParams): ReactNode => {
  return value ? (
    <Chip
      size="small"
      label={value.toString()}
      variant="outlined"
      sx={{ background: '#fff' }}
    />
  ) : (
    <></>
  )
}
