import { CreateDocumentDto } from '@services/dtos'
import { Document } from '@services/api'
import { DocumentService } from '@services/StorageService'
import { FileTypeEnum } from '../../common/interfaces'
import { FC, useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  Box,
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Stack,
  CircularProgress,
  Modal,
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import SaveIcon from '@mui/icons-material/Save'
import ScannerIcon from '@mui/icons-material/Scanner'
import { uid } from 'uid'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { useAppDispatch } from '@hooks/reduxHooks'

type Props = {
  onUploaded?: (document: Document) => void
  entityId?: number
  entityName: string
  setUploadedFile: (file: { url: string; mimetype: string }) => void
  uploadedFile: { url: string; mimetype: string } | null
}

const DocumentUploader: FC<Props> = ({
  onUploaded,
  entityId,
  entityName,
  uploadedFile,
  setUploadedFile,
}) => {
  const dispatch = useAppDispatch()

  // ======================================= STATE =======================================
  const [fileType, setFileType] = useState<FileTypeEnum>(FileTypeEnum.ID)
  const [isSaving, setIsSaving] = useState(false)
  const [isUploading, setisUploading] = useState(false)
  const [scannerModalOpen, setScannerModalOpen] = useState(false)
  const [scannerStatus, setScannerStatus] = useState<
    'idle' | 'scanning' | 'error'
  >('idle')

  // ======================================= FUNCTIONS =======================================
  const uploadFile = (file: File) => {
    return new Promise<{ url: string; mimetype: string }>((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('POST', `${process.env.REACT_APP_API_URL}/static`, true)
      xhr.setRequestHeader('Content-Type', 'application/octet-stream')
      xhr.setRequestHeader(
        'Authorization',
        'Bearer ' + localStorage.getItem('authToken'),
      )
      const extension = file.name.split('.').pop()
      const fileName = `${uid().toString()}.${extension}`

      xhr.setRequestHeader('x-file-name', encodeURI(fileName))
      xhr.responseType = 'json'

      if (fileType) {
        xhr.setRequestHeader('x-file-type', FileTypeEnum.SITE_ACQ_PROTOCOL)
      }

      xhr.onload = () => {
        if (xhr.status === 201) {
          const data = xhr.response
          resolve({ url: data.fileUrl, mimetype: file.type })
        }
      }
      xhr.onerror = () => {
        reject(xhr.response)
      }
      xhr.send(file)
    })
  }

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    if (file) {
      setisUploading(true)
      try {
        const f = await uploadFile(file)
        setUploadedFile(f)
      } catch (err) {
        console.error(err)
      } finally {
        setisUploading(false)
      }
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  })

  const handleSaveDocument = async () => {
    if (!uploadedFile) return

    const fileName = uploadedFile.url.split('/').pop()
    if (!fileName) return

    const dto: CreateDocumentDto = {
      url: uploadedFile.url,
      fileType,
      relatedEntity: entityId,
      entityName: entityName,
      filename: fileName,
      mimeType: uploadedFile.mimetype,
    }
    setIsSaving(true)
    try {
      const d = await DocumentService.createDocument(dto)
      onUploaded?.(d)
      dispatch(
        enqueueSnackbar({
          message: 'Document sauvegardé avec succès',
          type: 'success',
        }),
      )
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }

  const handleScanDocument = async () => {
    setScannerModalOpen(true)
    setScannerStatus('scanning')

    setTimeout(() => {
      setScannerStatus('error')
    }, 8000)
  }

  const renderScannerModal = () => (
    <Modal
      open={scannerModalOpen}
      onClose={() => {
        setScannerModalOpen(false)
        setScannerStatus('idle')
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
          minWidth: 300,
          textAlign: 'center',
        }}
      >
        {scannerStatus === 'scanning' ? (
          <Stack spacing={2} alignItems="center">
            <CircularProgress />
            <Typography>Initialisation du scanner...</Typography>
          </Stack>
        ) : (
          <Stack spacing={2} alignItems="center">
            <Typography color="error">Scanner non connecté</Typography>
            <Button
              variant="contained"
              onClick={() => setScannerModalOpen(false)}
            >
              Fermer
            </Button>
          </Stack>
        )}
      </Box>
    </Modal>
  )

  return (
    <Paper elevation={2} sx={{ p: 3 }}>
      <Stack spacing={2}>
        <FormControl fullWidth size="small">
          <InputLabel id="document-type-label">Document Type</InputLabel>
          <Select
            labelId="document-type-label"
            value={fileType}
            label="Document Type"
            onChange={(e) => setFileType(e.target.value as FileTypeEnum)}
            size="small"
          >
            {Object.values(FileTypeEnum).map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Stack direction="column" spacing={1}>
          <Box
            {...getRootProps()}
            sx={{
              flexGrow: 1,
              border: '2px dashed',
              borderColor: isDragActive ? 'primary.main' : 'grey.300',
              borderRadius: 1,
              p: 2,
              textAlign: 'center',
              cursor: 'pointer',
              bgcolor: isDragActive
                ? 'action.hover'
                : uploadedFile
                ? 'success.lighter'
                : 'background.paper',
              transition: 'all 0.2s ease',
              '&:hover': {
                bgcolor: 'action.hover',
                borderColor: 'primary.main',
              },
              minHeight: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <input {...getInputProps()} />
            <Stack spacing={1} alignItems="center">
              {uploadedFile ? (
                <CheckCircleIcon color="success" sx={{ fontSize: 32 }} />
              ) : (
                <CloudUploadIcon color="action" sx={{ fontSize: 32 }} />
              )}

              {isUploading ? (
                <CircularProgress size={16} />
              ) : (
                <Typography
                  variant="body2"
                  color={uploadedFile ? 'success.main' : 'text.secondary'}
                >
                  {uploadedFile
                    ? uploadedFile.url.split('/').pop()
                    : isDragActive
                    ? 'Déposez le fichier ici'
                    : 'Cliquez ou déposez un fichier'}
                </Typography>
              )}
            </Stack>
          </Box>

          <Button
            variant="outlined"
            size="small"
            startIcon={<ScannerIcon />}
            onClick={handleScanDocument}
            sx={{
              height: 'fit-content',
              alignSelf: 'center',
            }}
          >
            Scanner
          </Button>
        </Stack>

        {uploadedFile && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              size="small"
              startIcon={
                isSaving ? <CircularProgress size={16} /> : <SaveIcon />
              }
              onClick={handleSaveDocument}
              disabled={isSaving}
            >
              {isSaving ? 'Sauvegarde...' : 'Sauvegarder'}
            </Button>
          </Box>
        )}
        {renderScannerModal()}
      </Stack>
    </Paper>
  )
}

export default DocumentUploader
