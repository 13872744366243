import { Control, Controller } from 'react-hook-form'

import { Box, Grid, Tooltip } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

import { colors } from '@utils/constants'
export type ColorInputProps = {
  control: Control<any>
  input: string
}
export function ColorInput({ control, input }: ColorInputProps) {
  return (
    <Controller
      name={input}
      control={control}
      render={({ field: { onChange, value: selected } }) => {
        return (
          <Grid
            container
            justifyContent="space-between"
            style={{
              backgroundColor: '#f1f1f1',
              padding: 10,
              borderRadius: 25,
            }}
          >
            {colors.map(({ color, hex }, index) => (
              <Grid
                item
                key={index}
                style={{
                  padding: 5,
                  backgroundColor: 'white',
                  height: 30,
                  borderRadius: 4,
                  margin: 5,
                  position: 'relative',
                  cursor: 'pointer',
                }}
                xs={2}
                onClick={() => onChange(color)}
              >
                <Tooltip title={color}>
                  <Box
                    bgcolor={hex}
                    width="100%"
                    height="100%"
                    borderRadius={2}
                  >
                    {selected === color && (
                      <CheckIcon
                        style={{
                          color: 'white',
                          position: 'absolute',
                          margin: 'auto',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          width: 20,
                          height: 20,
                          fontWeight: 900,
                        }}
                      />
                    )}
                  </Box>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        )
      }}
    />
  )
}
