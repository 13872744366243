import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Dialog, DialogContent } from '@mui/material'
import { DoctorDto, MemberDto } from '@services/api'
import { OrderDto, RecipientDTO } from '@services/dtos'
import {
  doAddRecipient,
  doGetRecipients,
  doRemoveRecipient,
} from '@state/reducers/orderReducer'
import { FC, useEffect, useState } from 'react'
import DoctorList from '../../containers/app/parameters/doctors/DoctorList'

type Props = { order: OrderDto }

const DoctorRecipient: FC<{ doctor: DoctorDto }> = ({ doctor }) => {
  return (
    <div className="p-3">
      <div className="font-medium text-sm">
        {doctor.firstName} {doctor.lastName}{' '}
        {!!doctor.skill && <span> - {doctor.skill}</span>}
      </div>
      <div className="text-gray-600 text-sm">{doctor.email}</div>
    </div>
  )
}

const PatientRecipient: FC<{ member: MemberDto }> = ({ member }) => {
  return (
    <div className="p-3 mb-2 border rounded-lg bg-gray-50">
      <div className="text-sm">
        {member.firstName} {member.lastName} ({member.email})
      </div>
    </div>
  )
}

const RenderList: FC<{
  recipients: RecipientDTO[]
  onDelete: (id: number) => void
}> = ({ recipients, onDelete }) => {
  return (
    <div className="space-y-2">
      {recipients.map((rc) => (
        <div
          key={rc.id}
          className="flex flex-row items-center relative border rounded-lg bg-gray-50 mb-3"
        >
          {rc.doctor ? (
            <DoctorRecipient key={rc.id} doctor={rc.doctor} />
          ) : (
            rc.member && <PatientRecipient key={rc.id} member={rc.member} />
          )}
          <button
            className="absolute right-0 top-0 p-2 text-gray-500 hover:text-red-500"
            onClick={() => onDelete(rc.id)}
          >
            <i className="fas fa-trash"></i>
          </button>
        </div>
      ))}
    </div>
  )
}

const OrderRecipientList: FC<Props> = ({ order }) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [recipientSelectionEntity, setRecipientSelectionEntity] = useState<
    'member-selection' | 'doctor-selection' | undefined
  >(undefined)

  const { recipients } = useAppSelector(({ order }) => ({
    recipients: order.recipients,
  }))

  useEffect(() => {
    setIsLoading(true)
    dispatch(doGetRecipients(order.id)).finally(() => setIsLoading(false))
  }, [])

  const isSelectRecipientOpen = recipientSelectionEntity !== undefined

  const handleDoctorSelected = (doctor: DoctorDto[]) => {
    console.log(doctor)
    const newRecipients: DoctorDto[] = []
    for (const d of doctor) {
      if (!recipients.find((rp) => rp.doctor?.id === d.id)) {
        newRecipients.push(d)
      }
    }
    for (const d of newRecipients) {
      dispatch(
        doAddRecipient({
          id: order.id,
          dto: {
            doctorId: d.id,
          },
        }),
      )
    }
  }

  const onDeleteRecipient = (recipientId: number) => {
    dispatch(doRemoveRecipient({ recipientId, id: order.id }))
  }

  return (
    <>
      <div className="p-4">
        <button
          className="w-full mb-3 bg-purple-500 hover:bg-purple-600 text-white px-4 py-2 rounded-md text-sm transition-colors duration-200 flex items-center justify-center gap-2"
          onClick={() => setIsAddOpen(true)}
        >
          <i className="fas fa-plus"></i>
          Ajouter un destinataire
        </button>
        <div className="space-y-2">
          <RenderList recipients={recipients} onDelete={onDeleteRecipient} />
        </div>
      </div>

      <Dialog
        fullWidth
        maxWidth="xl"
        open={isAddOpen}
        onClose={() => setIsAddOpen(false)}
        sx={{ height: '98vh' }}
      >
        <DialogContent sx={{ height: '98vh' }} className="p-0 overflow-hidden">
          <div className="">
            <div
              className={`absolute inset-0 flex flex-col p-6 transition-transform duration-300 ease-in-out ${
                isSelectRecipientOpen ? '-translate-x-full' : 'translate-x-0'
              }`}
            >
              <h2 className="text-xl font-semibold mb-6">
                Ajouter un destinataire
              </h2>
              <div className="flex flex-col gap-4">
                <button
                  className="bg-purple-500 hover:bg-purple-600 text-white px-4 py-3 rounded-md text-sm transition-colors duration-200"
                  onClick={() =>
                    setRecipientSelectionEntity('doctor-selection')
                  }
                >
                  Selectionner Docteur
                </button>
                <button
                  className="bg-purple-500 hover:bg-purple-600 text-white px-4 py-3 rounded-md text-sm transition-colors duration-200"
                  onClick={() =>
                    setRecipientSelectionEntity('member-selection')
                  }
                >
                  Selectionner member
                </button>
              </div>
            </div>

            {/* Content Area */}
            <div
              className={`absolute inset-0 transition-transform duration-300 ease-in-out ${
                isSelectRecipientOpen ? 'translate-x-0' : 'translate-x-full'
              }`}
            >
              <div className="h-full flex flex-col p-6">
                <div className="flex items-center mb-6">
                  <button
                    className="bg-purple-500 hover:bg-purple-600 text-white px-4 py-2 rounded-md text-sm transition-colors duration-200"
                    onClick={() => setRecipientSelectionEntity(undefined)}
                  >
                    <i className="fas fa-arrow-left mr-2"></i>
                    Retour
                  </button>
                </div>
                <div className="flex-1 flex flex-row overflow-auto">
                  <div className="w-3/4">
                    {recipientSelectionEntity === 'doctor-selection' && (
                      <DoctorList
                        onSelectionChange={handleDoctorSelected}
                        selectedRows={recipients.reduce((acc, r) => {
                          if (r.doctor) {
                            acc.push(r.doctor)
                          }
                          return acc
                        }, new Array<DoctorDto>())}
                      />
                    )}
                    {recipientSelectionEntity === 'member-selection' && (
                      <div>Member Selector</div>
                    )}
                  </div>
                  <div className="w-1/4 bg-gray-50 p-4">
                    <h3 className="text-lg font-semibold mb-4">
                      Destinataires selectionnés
                    </h3>
                    <div className="space-y-2">
                      <RenderList
                        recipients={recipients}
                        onDelete={onDeleteRecipient}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default OrderRecipientList
