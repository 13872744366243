import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TableStatsPrescriberDti, TableStatsUserDti, DugnutStatsDataDti, ParamStatsGetAllPrescriber } from '@services/api'
import { getAllPrescriber, getAllTopEvent, getExamBreakdown } from '@state/thunks/statsThunk'

// interface FilterChangePeriodPayload {
//   startY: number;
//   endY: number;
// }

type statsState = {
  filter: {
    startY: number
    endY: number
    accountType: string
    eventType: string
    siteId?: number
  }
  tablePrescriber: TableStatsPrescriberDti[],
  tableUser: TableStatsUserDti[],
  examBreakdown: DugnutStatsDataDti,
}

const initialState: statsState = {
  filter: {
    startY: new Date().getFullYear(),
    endY: new Date().getFullYear(),
    accountType: 'doctor',
    eventType: 'all',
  },
  tablePrescriber: [],
  tableUser: [],
  examBreakdown: {} as DugnutStatsDataDti,
}

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    removeAllStats: (state) => {
      state.tablePrescriber = initialState.tablePrescriber
      state.tableUser = initialState.tableUser
      state.examBreakdown = initialState.examBreakdown
      state.filter = initialState.filter
    },
    removeStats: (state, action: PayloadAction<string>) => {
      const name = action.payload
      switch (name) {
        case 'prescriber':
          state.tablePrescriber = initialState.tablePrescriber
          break
        case 'user':
          state.tableUser = initialState.tableUser
          break
        case 'exam':
          state.examBreakdown = initialState.examBreakdown
          break
        case 'filter':
          state.filter = initialState.filter
          break
        case 'site':
          state.filter.siteId = initialState.filter.siteId
          break
        default:
          break
      }
    },
    filterChangePeriod: (state, { payload }: PayloadAction<number>) => {
      state.filter.startY = initialState.filter.startY
      state.filter.startY = payload
    },
    filterChangeSite: (state, { payload }: PayloadAction<number>) => {
      state.filter.siteId = initialState.filter.siteId
      state.filter.siteId = payload
    },
    filterChangeAccountType: (state, { payload }: PayloadAction<string>) => {
      state.filter.accountType = initialState.filter.accountType
      state.filter.accountType = payload
    },
    filterChangeEventType: (state, { payload }: PayloadAction<string>) => {
      state.filter.eventType = initialState.filter.eventType
      state.filter.eventType = payload
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllPrescriber.fulfilled, (state, { payload }: PayloadAction<ParamStatsGetAllPrescriber[] | any>) => {
        state.tablePrescriber = payload
      })
      .addCase(getAllTopEvent.fulfilled, (state, { payload }: PayloadAction<TableStatsUserDti[] | any>) => {
        state.tableUser = payload
      })
      .addCase(getExamBreakdown.fulfilled, (state, { payload }: PayloadAction<DugnutStatsDataDti | any>) => {
        state.examBreakdown = payload
      })
      
})

export const { removeStats, removeAllStats, filterChangePeriod, filterChangeSite, filterChangeAccountType, filterChangeEventType } = statsSlice.actions

export default statsSlice.reducer
