import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import AmbulanceCompanies from './AmbulanceCompanies'
import { Tooltip } from '@mui/material'
import AssignMedicalOrder from './MedicalOrderList'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return (
    <Box sx={{ height: '100%' }}>
      {value === index && <Box sx={{ p: 3, height: '87vh' }}>{children}</Box>}
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Ambulances() {
  const [value, setValue] = useState(0)
  const intl = useIntl()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box>
      <Box
        sx={{
          marginTop: '5px',
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="rooms-tabs"
          sx={{
            marginLeft: '54px',
            marginBottom: '-22px',
          }}
        >
          <Tab
            label={intl.formatMessage({ id: 'ambulanceCompanies' })}
            {...a11yProps(0)}
            sx={{
              backgroundColor: '#fff',
              borderRadius: '5px 5px 0px 0px',
              marginRight: '8px',
            }}
          />
          <Tooltip title="Une société est requise pour déclarer un véhicule">
            <Tab
              label={intl.formatMessage({ id: 'myPatients' })}
              {...a11yProps(1)}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '5px 5px 0px 0px',
                marginRight: '8px',
              }}
            />
          </Tooltip>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AmbulanceCompanies />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <AssignMedicalOrder />
      </TabPanel>
    </Box>
  )
}
