import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'
import { useIntl } from 'react-intl'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import { TextField } from '@mui/material'
import moment from 'moment'

type DateInputProps = {
  name: string
  control: Control<any>
  errors?: DeepMap<any, FieldError>
  minDate?: Date
  maxDate?: Date
  defaultDate?: Date
  readOnly?: boolean
  variant?: 'outlined' | 'standard' | 'filled'
}

export function DateInput({
  name,
  control,
  defaultDate = new Date(),
}: DateInputProps) {
  const intl = useIntl()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultDate}
      render={({ field: { onChange, value } }) => (
        <DesktopDatePicker
          label={intl.formatMessage({ id: name })}
          inputFormat="DD/MM/yyyy"
          value={value}
          onChange={(v) => onChange(moment(v))}
          renderInput={(params) => <TextField {...params} />}
        />
      )}
    />
  )
}
