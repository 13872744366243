import { useDispatch } from 'react-redux'
import { useSelector } from '@state/store'
import { useForm } from 'react-hook-form'

import { Grid } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'

import { SelectInput } from '@components/inputs'
import { TextInput } from '@components/inputs'
import {
  CreateReceptionDto,
  Reception,
  RoomType,
  UpdateReceptionDto,
} from '@services/api'
import { useMemo } from 'react'
import {
  createReception,
  updateReception,
} from '../../../../state/thunks/roomThunk'
import { addReceptionSchema } from '../../../../utils/schemas'
import { Button } from '@components/buttons'

type AddReceptionFormProps = {
  row?: Reception
  onClose?: () => void
}

export function ReceptionsForm({ row, onClose }: AddReceptionFormProps) {
  const dispatch = useDispatch()
  const sites = useSelector(({ site }) => site.sites.datas)

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<Reception>({
    resolver: yupResolver(addReceptionSchema),
    defaultValues: {
      ...row,
    },
  })

  const onSubmit = (values: Reception & { type?: RoomType }) => {
    if (row?.id) {
      const body: UpdateReceptionDto = {
        code: values.code,
        label: values.label,
        siteId: values.siteId,
      }
      //update existing room
      dispatch(updateReception({ id: row.id, body }))
    } else {
      //add room
      const body: CreateReceptionDto = {
        code: values.code,
        label: values.label,
        siteId: values.siteId,
      }
      dispatch(createReception(body))
    }

    if (onClose) onClose()
  }

  const sitesOptions = useMemo(
    () =>
      sites.map((_site) => ({
        label: _site.label,
        value: _site.id,
      })),
    [sites],
  )

  const handleCancelPress = () => {
    if (onClose) {
      onClose()
    }
    // Closes the update form - Datagrid didnt provide a prop function to close form ()
    const el = (document as Document).querySelector(
      `.MuiDataGrid-row[data-id="${row?.id}"] .MuiIconButton-root.MuiIconButton-sizeSmall`,
    ) as any
    if (el) {
      el.click()
    }
  }

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="flex-start"
      sx={{
        padding: '20px',
      }}
    >
      <Grid item xs={12}>
        <SelectInput
          options={sitesOptions}
          control={control}
          name="siteId"
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput name="code" errors={errors} control={control} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput name="label" errors={errors} control={control} />
      </Grid>
      <Grid item container spacing={3} xs={12} justifyContent="flex-end">
        <Grid item xs={2}>
          <Button
            fullWidth
            textColor="white"
            onClick={handleCancelPress}
            color="secondary"
            text="Cancel"
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            fullWidth
            textColor="white"
            onClick={handleSubmit(onSubmit)}
            color="primary"
            text="Save"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
