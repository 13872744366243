import ResizeHandle from '@components/common/ResizeHandle'
import {
  AppBar,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Paper,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material'
import { Document, MedicalOrder } from '@services/api'
import React, { useEffect, useMemo, useState } from 'react'
import { Panel, PanelGroup } from 'react-resizable-panels'
import { TransitionProps } from '@mui/material/transitions'
import moment from 'moment'
import MedicalOrderForm from '@components/medical-order/MedicalOrderForm'
import DocumentListViewer from '@components/dialogs/DocumentListViewer'
import Quotation from './Quotation/Quotation'
import { OrderDto } from '@services/dtos'
import { Add } from '@mui/icons-material'
import { ExamSelectorDialog } from '@components/dialogs/ExamSelectorDialog'
import { useAppDispatch } from '@hooks/reduxHooks'
import {
  doAddMedicalOrder,
  doCreateChildOrder,
  doFindChildOrders,
  doRemoveOrder,
  setSelectedOrder as setReduxSelectedOrder,
  setSelectedOrder,
} from '@state/reducers/orderReducer'
import OrderSelectionComponent from '@components/order/OrderSelectionComponent'
import OrderHistoryComponent from '@components/OrderHistoryComponent'
import report from '../../../assets/attachments/report_rt.pdf'
import PatientDataContainer from '@components/patient/PatientDataContainer'
import OrderRecipientList from '@components/order/OrderRecipientList'
import DocumentsList from '@components/documents/DocumentsList'
import { setPatientDetails } from '../../../state/reducers/patientsReducer'

type Props = {
  order?: OrderDto
  isOpen: boolean
  onClose: () => void
}

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const SlideInPortal = ({
  isOpen,
  onClose,
  patientId,
  visitId,
  className = '',
}: {
  isOpen: boolean
  onClose: () => void
  patientId: number
  className?: string
  visitId?: number
}) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  if (!mounted) return null

  return (
    <div
      className={`fixed inset-0 z-50 transition-opacity duration-300 bg-black bg-opacity-30 ${
        isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
      } ${className}`}
      onClick={onClose}
    >
      <div
        className={`fixed top-0 left-0 h-full w-[60vw] bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="relative h-full">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
          >
            <i className="fas fa-times text-xl"></i>
          </button>

          <div className="p-6 h-full overflow-y-auto">
            <PatientDataContainer
              patientId={patientId}
              visitId={visitId}
              containerClassName="max-h-full"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const MedicalOrderDialog: React.FC<Props> = ({ order, isOpen, onClose }) => {
  const dispatch = useAppDispatch()
  // --------------------------------------- State ---------------------------------------
  const [isCreatingChild, setisCreatingChild] = useState(false)
  const [selectedOrder, setselectedOrder] = useState<OrderDto | null>(null)

  const handleChildOrderCreation = () => {
    if (!selectedOrder) {
      return
    }
    setisCreatingChild(true)
    dispatch(doCreateChildOrder(selectedOrder.id)).finally(() => {
      setisCreatingChild(false)
    })
  }

  useEffect(() => {
    return () => {
      dispatch(setPatientDetails(null))
    }
  }, [])

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth
        fullScreen
        disableEscapeKeyDown
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', background: '#3f3f3f' }}>
          <Toolbar style={{ color: '#FAFAFB' }}>
            <Button
              className="flex flex-row items-center mr-2 inline-block"
              autoFocus
              color="inherit"
              onClick={onClose}
              variant="outlined"
            >
              <i className="fas fa-times mr-2"></i>
              <span className="inline-block">Fermer</span>
            </Button>
            <div className="ml-2">
              <Button
                className={`flex flex-row items-center inline-block ml-4 ${
                  isCreatingChild ? 'text-white' : 'text-black'
                }`}
                autoFocus
                color="inherit"
                onClick={handleChildOrderCreation}
                variant="contained"
                disabled={isCreatingChild}
              >
                {isCreatingChild ? (
                  <CircularProgress
                    size="20px"
                    className="text-md mr-2 text-white"
                    style={{
                      color: 'gray',
                    }}
                  />
                ) : (
                  <i className="fas fa-code-branch mr-2 text-black rotate-90 text-2xl"></i>
                )}
                <span
                  className={`inline-block ${
                    isCreatingChild ? 'text-gray-400' : 'text-black'
                  }`}
                >
                  Créer nouvelle demande
                </span>
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ backgroundColor: '#F3F4F6' }}>
          {!order ? (
            <></>
          ) : (
            <OrderDetailsComponent
              order={order}
              onSelectedOrderChange={setSelectedOrder}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export const OrderDetailsComponent = ({
  order,
  onSelectedOrderChange,
}: {
  order: OrderDto
  onSelectedOrderChange?: (order: OrderDto) => void
}) => {
  const dispatch = useAppDispatch()
  // --------------------------------------- State ---------------------------------------
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    null,
  )
  const [isExamSelectorVisible, setIsExamSelectorVisible] = useState(false)
  const [selectedOrder, setselectedOrder] = useState<OrderDto | null>(null)
  const [isPatientInfoOpen, setIsPatientInfoOpen] = useState(false)
  const [isEditingDocuments, setisEditingDocuments] = useState(false)

  // --------------------------------------- Hooks ---------------------------------------
  useEffect(() => {
    if (!selectedOrder && order) {
      setselectedOrder(order)
      dispatch(doFindChildOrders(order.id))
    }
  }, [order])

  const ordersList = useMemo(() => {
    if (!order) {
      return []
    }
    const orders: OrderDto[] = [order]
    if (order.children.length > 0) {
      orders.push(...order.children)
    }
    return orders
  }, [order])

  useEffect(() => {
    if (selectedOrder) {
      onSelectedOrderChange?.(selectedOrder)
    }
  }, [selectedOrder])

  // --------------------------------------- Handlers ---------------------------------------
  const handleExamSelectionChange = (examIds: number[]) => {
    if (!selectedOrder) {
      return
    }
    for (const exId of examIds) {
      dispatch(doAddMedicalOrder({ examId: exId, orderId: selectedOrder.id }))
        .unwrap()
        .then((order) => {
          setselectedOrder(order)
        })
    }
  }

  const handleRemoveOrder = (orderId: number) => {
    dispatch(doRemoveOrder(orderId))
      .unwrap()
      .then(() => {
        if (order) {
          setselectedOrder(order)
        }
      })
  }
  const onMedicalOrderRemoved = (mo: MedicalOrder) => {
    if (!selectedOrder) {
      return
    }
    const newvalue = { ...selectedOrder }
    newvalue.medicalOrders = newvalue.medicalOrders.filter(
      (medicalOrder) => medicalOrder.id !== mo.id,
    )
    setselectedOrder(newvalue)
  }

  const handleOnFileUploaded = (doc: Document) => {
    if (!order) {
      return
    }
    setisEditingDocuments(false)
    const newDocuments = [...order.documents]
    newDocuments.push(doc)
    const newOrder = { ...order, documents: newDocuments }
    dispatch(setReduxSelectedOrder(newOrder))
  }

  const handleDocumentDeleted = (doc: Document) => {
    if (!order) {
      return
    }
    const newOrder = { ...order }
    newOrder.documents = newOrder.documents.filter(
      (document) => document.id !== doc.id,
    )
    dispatch(setReduxSelectedOrder(newOrder))
  }
  return (
    <div className="h-full">
      <PanelGroup direction="horizontal">
        <Panel id="left-panel" collapsible order={1} defaultSize={20}>
          <>
            <div className="flex flex-row items-center mb-4">
              <Avatar
                className="border border-1 border-gray-400 mr-2"
                style={{
                  width: '60px',
                  height: '60px',
                  cursor: 'pointer',
                }}
                src="https://education.gsu.edu/files/2019/08/Headshot_Placeholder.jpg"
                onClick={() => setIsPatientInfoOpen(true)}
              />
              <div className="leading-tight ">
                <h4 className="text-md font-bold">
                  {order.patient.firstName} {order.patient.lastName}
                </h4>
                <Typography variant="body2" gutterBottom>
                  {moment(order.patient.birthDate).format('DD/MM/YYYY')} (
                  {moment().diff(order.patient.birthDate, 'years')} ans)
                </Typography>
                <p className="text-xs leading-tight">Exam: 0 Solde: 0.00 €</p>
              </div>
            </div>
            <Paper className="p-6 space-y-6 flex flex-col flex-1 h-[calc(100vh-200px)] overflow-y-scroll">
              <Box>
                <Typography variant="h6" className="font-bold mb-3">
                  Compte Rendu
                </Typography>
                <Paper elevation={0} className="bg-gray-50 p-4 rounded">
                  {/* Render the report here */}
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      window.open(report, '_blank')
                    }}
                  >
                    <i className="fas fa-file-pdf"></i>
                    <span className="ml-2">Compte-rendu</span>
                  </Button>
                </Paper>
              </Box>
              <Box>
                <Typography variant="h6" className="font-bold mb-3">
                  Destinataires
                </Typography>
                <OrderRecipientList order={order} />
              </Box>

              <Divider />

              <Box>
                <Typography variant="h6" className="font-bold mb-3">
                  Fichiers attachés
                </Typography>
                <Box className="">
                  <DocumentsList
                    entityId={order.id}
                    entityName="order"
                    documents={order.documents}
                    setisEditingDocuments={setisEditingDocuments}
                    handleOnFileUploaded={handleOnFileUploaded}
                    isEditingDocuments={isEditingDocuments}
                    onDocumentDeleted={handleDocumentDeleted}
                    setSelectedDocument={setSelectedDocument}
                  />
                </Box>
              </Box>
              <Box>
                <Typography variant="h6" className="font-bold mb-3">
                  Historique
                </Typography>
                <Paper elevation={0} className="bg-gray-50 p-4 rounded">
                  <OrderHistoryComponent order={order} />
                </Paper>
              </Box>
            </Paper>
            <div className="mt-4">
              {ordersList.length > 1 && (
                <OrderSelectionComponent
                  orders={ordersList}
                  selectedOrder={selectedOrder}
                  onOrderChange={(order) => setselectedOrder(order)}
                  onRemove={handleRemoveOrder}
                />
              )}
            </div>
          </>
        </Panel>
        <ResizeHandle horizontal className="" />
        <Panel order={2} defaultSize={80}>
          <Paper className="p-2 h-full flex flex-col">
            <h4 className="text-md font-bold">Demande</h4>
            <Divider
              sx={{
                marginTop: 2,
                marginBottom: 1,
              }}
            />
            {!!selectedOrder && <MedicalOrderForm order={selectedOrder} />}
            <div className="mb-4 mt-6">
              <Button
                variant="contained"
                onClick={() => setIsExamSelectorVisible(true)}
                size="small"
                style={{ marginTop: '12px' }}
              >
                <Add />
                Ajouter un exam
              </Button>
            </div>
            {selectedOrder && selectedOrder.medicalOrders.length !== 0 && (
              <Quotation
                order={selectedOrder}
                onMedicalOrderRemoved={onMedicalOrderRemoved}
              />
            )}
          </Paper>
        </Panel>
      </PanelGroup>
      {order && (
        <SlideInPortal
          isOpen={isPatientInfoOpen}
          onClose={() => setIsPatientInfoOpen(false)}
          patientId={order.patient.id}
          visitId={order.visit?.id}
          className="z-[100000]"
        />
      )}

      {order && (
        <DocumentListViewer
          isOpen={selectedDocument !== null}
          documents={order.documents}
          onClose={() => {
            setSelectedDocument(null)
          }}
          selectedDocument={selectedDocument || undefined}
          onSelectedDocumentChange={(doc) => {
            setSelectedDocument(doc)
          }}
        />
      )}
      <ExamSelectorDialog
        isOpen={isExamSelectorVisible}
        onClose={() => setIsExamSelectorVisible(false)}
        handleConfirm={handleExamSelectionChange}
        // excludedExams={selectedOrder.medicalOrders.map((mo) => mo.exam)}
        excludedExams={[]}
      />
    </div>
  )
}

export default MedicalOrderDialog
