import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Container,
  Grid,
  Dialog,
  DialogContent,
  Paper,
  DialogActions,
} from '@mui/material'
import { DataTable } from '@components/tables'
import {
  deleteMedicalEquipment,
  findAllMedicalEquipment,
} from '@state/thunks/medicalEquipmentThunk'
import { useSelector } from '@state/store'
import { GridColumns, GridRowId } from '@mui/x-data-grid-pro'
import { DialogTitle } from '@components/titles'
import { Actions } from '@state/actions'
import theme from '@utils/theme'
import { MedicalEquipmentForm } from './MedicalEquipmentForm'
import { Text } from '@components/texts'
import { Button } from '@components/buttons'

export default function Equipments() {
  const dispatch = useDispatch()
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const { loading, medicalEquipments } = useSelector(
    ({ medicalEquipment, loading }) => ({
      medicalEquipments: medicalEquipment.medicalEquipments,
      loading:
        loading[Actions.MEDICAL_EQUIPMENT_FIND_ALL] ||
        loading[Actions.MEDICAL_EQUIPMENT_CREATE] ||
        loading[Actions.MEDICAL_EQUIPMENT_UPDATE],
    }),
  )

  const columns: GridColumns = [
    { field: 'code', headerName: 'Code', flex: 1 },
    { field: 'label', headerName: 'Label', flex: 1 },
    { field: 'commissioningDate', headerName: 'Commissioning date', flex: 1 },
  ]

  const onRefresh = useCallback(() => {
    dispatch(findAllMedicalEquipment())
  }, [dispatch])

  useEffect(() => {
    onRefresh()
    // return () => {
    //   dispatch(removeSites())
    // }
  }, [dispatch, onRefresh])

  const _onSelectionChange = (selection: GridRowId[]) => {
    setSelectedRows(selection as number[])
  }
  const _onDeleteConfirm = () => {
    dispatch(deleteMedicalEquipment(selectedRows))
    setIsDeleteDialogOpen(false)
  }

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 30px' }}>
      <Grid container spacing={4} style={{ height: '95vh' }}>
        <Grid item container>
          <Paper
            elevation={2}
            style={{ width: '100%', borderRadius: '0px 25px 25px 25px' }}
          >
            <DataTable
              rows={medicalEquipments}
              columns={columns}
              loading={loading}
              toolBarProps={{
                title: 'medicalEquipments',
                icon: 'fas fa-microscope',
                onAdd: () => setOpenAddDialog(true),
                onRefresh,
                onDelete: () => {
                  selectedRows.length
                    ? setIsDeleteDialogOpen(true)
                    : alert('please select at least one reception')
                },
              }}
              detailPanelHeight={400}
              form={(props) => (
                <MedicalEquipmentForm
                  {...props}
                  onClose={() => setOpenAddDialog(false)}
                />
              )}
              checkboxSelection
              onSelectionModelChange={_onSelectionChange}
            />
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        scroll="paper"
        PaperProps={{
          sx: {
            borderRadius: 5,
            minWidth: '50%',
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="addMedicalEquipment"
          onClose={() => setOpenAddDialog(false)}
          format
        />
        <DialogContent
          sx={{
            background: '#fff',
            padding: '50px',
          }}
        >
          <MedicalEquipmentForm onClose={() => setOpenAddDialog(false)} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          title="deleteReception"
          onClose={() => setIsDeleteDialogOpen(false)}
          format
        />
        <DialogContent
          sx={{
            background: '#fff',
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            text="deleteConfirmation"
            variant="h5"
            color="secondary"
            sx={{ fontWeight: 'bold', paddingTop: '20px' }}
            format
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
          }}
        >
          <Button
            fullWidth
            textColor="white"
            onClick={() => setIsDeleteDialogOpen(false)}
            color="secondary"
            text="Cancel"
          />
          <Button
            fullWidth
            textColor="white"
            onClick={_onDeleteConfirm}
            color="primary"
            text="Save"
          />
        </DialogActions>
      </Dialog>
    </Container>
  )
}
