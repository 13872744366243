import {
  Add,
  Refresh,
  ViewColumn,
  Delete,
  Inventory2,
  Archive,
} from '@mui/icons-material'
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid-pro'
import Text from '../../Text'
import { useIntl } from 'react-intl'
import {
  Tooltip,
  IconButton,
  Icon,
  Box,
  Checkbox,
  Menu,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'

export type ToolbarProps = {
  title?: string
  icon?: string
  onRefresh: () => void
  onAdd?: () => void
  onDelete?: () => void
  onSearch?: (searchTerm: string, sexValue: string) => void
  searchSex?: boolean
  onMore?: () => void
  onLot?: () => void
}

export function Toolbar({
  title,
  icon,
  onRefresh,
  onAdd,
  onDelete,
  onSearch,
  searchSex,
  onMore,
  onLot,
}: ToolbarProps) {
  const apiRef = useGridApiContext()
  searchSex = searchSex || false
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const columns = apiRef.current.getAllColumns()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const selectedRowCount = apiRef.current.getSelectedRows().size

  const handleHide = (event: React.MouseEvent<any>) => {
    const column = columns.find(
      (column) => column.field == event.currentTarget.id,
    )
    if (column) apiRef.current.setColumnVisibility(column.field, !!column.hide)
  }

  // search patient

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [sexValue, setSexValue] = useState<string>('F')

  useEffect(() => {
    if (onSearch) {
      const searchResult = onSearch(searchTerm, sexValue)
    }
  }, [searchTerm, sexValue])

  return (
    <GridToolbarContainer>
      <Box ml={2} display="flex" alignItems="center" justifyContent="center">
        {icon ? (
          <Icon
            className={icon}
            style={{ marginRight: '1rem', width: '2rem' }}
            color="primary"
          />
        ) : null}
        {title ? (
          <Text
            variant="h5"
            text={title ? title : ''}
            color="primary"
            format
            sx={{ fontWeight: 'bold' }}
          />
        ) : null}
      </Box>

      {onSearch ? (
        <Box
          sx={{
            flexGrow: 1,
            marginLeft: 1,
            marginRight: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Grid
            xs={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            {searchSex ? (
              <RadioGroup
                row
                aria-labelledby="sexe"
                defaultValue={'M'}
                value={sexValue}
                name="sex"
                onChange={(e) => setSexValue(e.target.value)}
              >
                <FormControlLabel
                  style={{ color: '#48bef9', fontWeight: 'bold' }}
                  value="M"
                  control={
                    <Radio
                      sx={{
                        color: '#48bef9',
                        '&.Mui-checked': {
                          color: '#48bef9',
                        },
                      }}
                    />
                  }
                  label="H"
                />
                <FormControlLabel
                  value="F"
                  style={{ color: '#f48dde', fontWeight: 'bold' }}
                  control={
                    <Radio
                      sx={{
                        color: '#f48dde',
                        '&.Mui-checked': {
                          color: '#f48dde',
                        },
                      }}
                    />
                  }
                  label="F"
                />
              </RadioGroup>
            ) : null}
          </Grid>
          <Grid xs={searchSex ? 8 : 12}>
            <TextField
              id="PatientSearch"
              label="Rechercher..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              style={{
                width: '70%',
              }}
            />
          </Grid>
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1 }} />
      )}

      {/* <GridToolbarDensitySelector /> */}
      {/* <GridToolbarExport /> */}
      {/* <GridToolbarFilterButton /> */}
      <Tooltip title={intl.formatMessage({ id: 'column' })}>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <ViewColumn />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {columns.map((column, key) => (
          <MenuItem key={key} onClick={handleHide} id={column.field}>
            <FormControlLabel
              control={<Checkbox checked={!column.hide} />}
              label={column.headerName as string}
            />
          </MenuItem>
        ))}
      </Menu>
      {onAdd && (
        <Tooltip title={intl.formatMessage({ id: 'add' })}>
          <IconButton onClick={onAdd}>
            <Add />
          </IconButton>
        </Tooltip>
      )}
      {onDelete && (
        <Tooltip title={intl.formatMessage({ id: 'delete' })}>
          <IconButton onClick={onDelete}>
            <Delete />
          </IconButton>
        </Tooltip>
      )}
      {onMore && (
        <Tooltip title="Accéder au lot">
          <IconButton onClick={onMore}>
            <Inventory2 />
          </IconButton>
        </Tooltip>
      )}
      {selectedRowCount > 1 && (
        <Tooltip title="Créer un lot">
          <IconButton onClick={onLot}>
            <Archive />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={intl.formatMessage({ id: 'refresh' })}>
        <IconButton onClick={onRefresh}>
          <Refresh />
        </IconButton>
      </Tooltip>
    </GridToolbarContainer>
  )
}
