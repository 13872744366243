import { Control, Controller } from 'react-hook-form'

import {  Checkbox, CheckboxProps, FormControlLabel } from '@mui/material'
// import { useIntl } from 'react-intl'

type CheckBoxInputProps = CheckboxProps & {
  control: Control<any>
  label: string
  name: string
}

export function CheckBoxInput({
  control,
  label,
  name,
  ...props
}: CheckBoxInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          sx={{ display: 'flex', justifyContent: 'center' }}
          control={
            <Checkbox
              onChange={({ target }) => onChange(target.checked)}
              name={name}
              checked={value}
              color="primary"
              {...props}
            />
          }
          label={label}
        />
      )}
    />
  )
}
