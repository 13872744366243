import { ClientService, CreateClientDto, UpdateClientDto } from '@services/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'

export const findAll = createAsyncThunk(
  Actions.CLIENT_FIND_ALL,
  async (_, { rejectWithValue }) => {
    try {
      const clients: any = await ClientService.findAll()
      return clients
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const findOne = createAsyncThunk(
  Actions.CLIENT_FIND_ONE,
  async (id: number, { rejectWithValue }) => {
    try {
      return await ClientService.findOne({ id })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const update = createAsyncThunk(
  Actions.CLIENT_UPDATE,
  async (
    { id, body }: { id: number; body: UpdateClientDto },
    { rejectWithValue },
  ) => {
    try {
      return await ClientService.update({ id, body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const create = createAsyncThunk(
  Actions.CLIENT_CREATE,
  async (body: CreateClientDto, { rejectWithValue }) => {
    try {
      return await ClientService.create({ body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
