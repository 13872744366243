import { Control, Controller } from 'react-hook-form'

import {
  CheckboxProps,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { Function } from 'lodash'
// import { useIntl } from 'react-intl'

type RadioInputProps = CheckboxProps & {
  input: string
  control: Control<any>
  label: string
  options: { value: string; label: string }[]
  radioGroupClass?: string
  defaultValue?: string
  labelClass?: string
  row?: boolean
}

export function RadioInput({
  input,
  control,
  label,
  options,
  radioGroupClass,
  labelClass,
  defaultValue,
  row,
}: RadioInputProps) {
  // const intl = useIntl()
  return (
    <div className={radioGroupClass}>
      {/* <label className={labelClass} >
        {label}
      </label> */}
      <Typography className={labelClass}>{label}</Typography>
      <Controller
        name={input}
        control={control}
        render={({ field }) => (
          <RadioGroup {...field} defaultValue={defaultValue} row={row || false}>
            {options.map(({ value, label }, index) => (
              <FormControlLabel
                key={index}
                value={value}
                control={<Radio size="small" color="primary" />}
                label={label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </div>
  )
}
