import { axios, getConfigs, IRequestConfig } from './api'
import {
  CreateVisitDTO,
  DocumentDTO,
  FindVisitQuery,
  UpdateVisitDTO,
  VisitDTO,
} from './dtos'
import { PaginatedDto } from './extendedType'

export class VisitService {
  private static readonly endpoint = 'visits'

  static get(query: FindVisitQuery): Promise<PaginatedDto<VisitDTO>> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        this.endpoint,
        {},
      )
      configs.params = query
      axios(configs, resolve, reject)
    })
  }

  static create(dto: CreateVisitDTO): Promise<VisitDTO> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        this.endpoint,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static update(id: number, dto: UpdateVisitDTO): Promise<VisitDTO> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'patch',
        'application/json',
        `${this.endpoint}/${id}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static remove(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        `${this.endpoint}/${id}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static getDocuments(id: number): Promise<DocumentDTO[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.endpoint}/${id}/documents`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }
}
