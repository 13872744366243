import {
  Description as DocumentIcon,
  Image as ImageIcon,
  PictureAsPdf as PdfIcon,
  InsertDriveFile as FileIcon,
} from '@mui/icons-material'

export const getFileIcon = (mimeType: string) => {
  if (mimeType.includes('pdf')) return <PdfIcon />
  if (mimeType.includes('image')) return <ImageIcon />
  if (mimeType.includes('text')) return <DocumentIcon />
  return <FileIcon />
}

export const getFileTypeLabel = (mimeType: string) => {
  if (mimeType.includes('pdf')) return 'PDF'
  if (mimeType.includes('image')) return 'Image'
  if (mimeType.includes('text')) return 'Document'
  return 'File'
}
