import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { verifyEmail } from '@state/thunks/authThunk'
import { useQuery } from '../../hooks/useQuery'
import { Text } from '@components/texts'

export default function VerificationEmail({ location }: any) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const query = useQuery()

  useEffect(() => {
    if (location && location.search) {
      const verificationUUID = query.get('verificationUUID')
      const userId = query.get('userId')
      if (verificationUUID && userId) {
        dispatch(
          verifyEmail({
            userId: userId,
            verificationUUID: verificationUUID,
          }),
        )
      } else {
        dispatch(
          enqueueSnackbar({
            type: 'error',
            message: 'Invalid user informations',
          }),
        )
      }
    }
    navigate('/auth/login')
  }, [])

  return <Text text="email-verification-in-progress" />
}
