import React, { useState } from 'react'
import { Box, Tabs, Tab } from '@mui/material'
import OrdersTable from '@components/tables/OrderTable'
import { TabContext, TabPanel } from '@mui/lab'
import VisitsTable from '@components/tables/VisitsTable'
import ResipLayout from '../../../../src/layouts/ResipLayout'
import logo from '../../../../src/assets/img/logos/ariane-fse.png'
import ExternalIncomeTable from '@components/tables/ExternalIncomeTable'

export default function Worklists() {
  const [value, setValue] = useState(0)

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <TabContext value={value.toString()}>
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            style={{ backgroundColor: 'white', padding: 0, margin: 0 }}
          >
            <Tab label="Dossiers" {...a11yProps(0)} />
            <Tab label="Séjour patient" {...a11yProps(1)} />
            <Tab label="Ressources externes" {...a11yProps(2)} />
            <Tab label="Gestion & Suivi" {...a11yProps(3)} />
          </Tabs>
          <TabPanel value="0">
            <OrdersTable />
          </TabPanel>
          <TabPanel value="1">
            <VisitsTable />
          </TabPanel>
          <TabPanel value="2">
            <ExternalIncomeTable />
          </TabPanel>
          <TabPanel value="3">
            <ResipLayout />
          </TabPanel>
        </div>
      </TabContext>
      {/* center the image top screen  */}
      <div className="absolute rounded-full top-6 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <img src={logo} alt="logo" style={{ width: '176px' }} />
      </div>
    </>
  )
}
