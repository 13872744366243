import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Container, Grid, Dialog, DialogContent, Paper } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid-pro'
import { removeClients } from '@state/reducers/clientReducer'
import { findAll as findAllClient } from '@state/thunks/clientThunk'
import { Actions } from '@state/actions'
import { ClientForm } from './ClientForm'
import { useSelector } from '@state/store'
import { DataTable } from '@components/tables/DataTable'
import { DialogTitle } from '@components/titles'
import { ChipCell } from '@components/tables/cells'

export default function Clients() {
  const dispatch = useDispatch()
  const [openAddDialog, setOpenAddDialog] = useState(false)

  const { clients, loading } = useSelector(({ client, loading }) => ({
    clients: client.clients.datas,
    loading:
      loading[Actions.CLIENT_FIND_ALL] ||
      loading[Actions.CLIENT_CREATE] ||
      loading[Actions.CLIENT_UPDATE],
  }))

  const columns2: GridColumns = [
    { field: 'name', headerName: 'Name', minWidth: 150 },
    { field: 'siretNumber', headerName: 'SIRET', minWidth: 150 },
    { field: 'address', headerName: 'Address', minWidth: 150 },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 150,
      renderCell: ChipCell,
    },
    {
      field: 'companyStatus',
      headerName: 'Status',
      minWidth: 150,
      valueGetter: ({ row }) => row.status,
    },
    { field: 'postalCode', headerName: 'Code', minWidth: 150 },
    { field: 'city', headerName: 'City', minWidth: 150 },
    { field: 'country', headerName: 'Country', minWidth: 150 },
    { field: 'phoneNumber', headerName: 'Tel', minWidth: 150 },
  ]

  const onRefresh = useCallback(() => {
    dispatch(findAllClient())
  }, [dispatch])

  useEffect(() => {
    onRefresh()
    return () => {
      dispatch(removeClients())
    }
  }, [dispatch, onRefresh])

  return (
    <Container maxWidth="xl" style={{ height: '100%' }}>
      <Grid container spacing={4}>
        <Grid item container>
          <Paper elevation={2} style={{ width: '100%' }}>
            <DataTable
              rows={clients}
              columns={columns2}
              loading={loading}
              toolBarProps={{
                title: 'sites',
                icon: 'fa fa-users',
                onAdd: () => setOpenAddDialog(true),
                onRefresh,
              }}
              detailPanelHeight={400}
              form={(props) => (
                <ClientForm
                  {...props}
                  onClose={() => setOpenAddDialog(false)}
                />
              )}
              initialState={{ pinnedColumns: { left: ['label'] } }}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          title="add"
          onClose={() => setOpenAddDialog(false)}
          format
        />
        <DialogContent>
          <ClientForm onClose={() => setOpenAddDialog(false)} />
        </DialogContent>
      </Dialog>
    </Container>
  )
}
