import { MenuItem } from '@mui/material'

import { TextInput, TextInputProps } from './TextInput'

type SelectInputProps = TextInputProps & {
  options: { value: string | number; label: string }[]
}

export function SelectInput({ options, ...props }: SelectInputProps) {
  return (
    <TextInput {...props} style={{ borderRadius: 5 }} select>
      {options.map((option, index: number) => (
        <MenuItem key={index} value={option.value}>
          <em>{option.label}</em>
        </MenuItem>
      ))}
    </TextInput>
  )
}
