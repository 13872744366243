import { Grid } from '@mui/material'
import Text from '../Text'

export function AuthTitle() {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Text
        text="app-name"
        variant="h1"
        style={{ padding: 5, color: '#000000', fontWeight: 'bold'}}
        format
      />
    </Grid>
  )
}
