import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../../../components/buttons'
import {
  SelectInput,
  CheckBoxInput,
  RadioInput,
} from '../../../components/inputs'
import {
  ClientPreferences,
  FailureNumberEnum,
  LockoutPeriodEnum,
  TwoFAMethodEnum,
  UpdateClientDto,
} from '@services/api'

type PasswordRuleFormProps = {
  preferences: ClientPreferences
  onSubmit: (values: UpdateClientDto) => void
  loading: boolean
}

type Values = {
  failureNumber: FailureNumberEnum
  lockoutPeriod: LockoutPeriodEnum
  inactivityTimeout: number
  twoFAMandatory: boolean
  twoFAMethod: TwoFAMethodEnum
  passwordChangesMandatory: boolean
  passwordChangesPeriod: number
  samePasswordAllowed: boolean
}
export function SecurityPreferencesForm({
  preferences,
  onSubmit,
  loading,
}: PasswordRuleFormProps) {
  const { security } = preferences
  const { handleSubmit, control, register, watch } = useForm<Values>({
    defaultValues: security,
  })

  const [pwdChangesMandatory, setPwdChangesMandatory] = useState<boolean>(
    security.passwordChangesMandatory,
  )
  const [twoFAMandatory, setTwoFAMandatory] = useState<boolean>(
    security.twoFAMandatory,
  )

  const beforeSubmit = (values: Values) => {
    onSubmit({
      preferences: {
        ...preferences,
        security: values,
      },
    })
  }

  useEffect(() => {
    const { passwordChangesMandatory, twoFAMandatory } = watch()
    setTwoFAMandatory(twoFAMandatory)
    setPwdChangesMandatory(passwordChangesMandatory)
  }, [watch])

  return (
    <Grid container spacing={2}>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        paddingLeft={1}
        mt={3}
        mb={3}
      >
        <Box width="50%">
          <CheckBoxInput
            name="passwordChangesMandatory"
            control={control}
            label="Obligation to change password"
          />

          {pwdChangesMandatory && (
            <CheckBoxInput
              name="samePasswordAllowed"
              control={control}
              label="Using the same password is allowed"
            />
          )}
          {pwdChangesMandatory && (
            <Box maxWidth="60%">
              <SelectInput
                options={[
                  {
                    label: '3 months',
                    value: 3,
                  },
                  {
                    label: '6 months',
                    value: 6,
                  },
                  {
                    label: '1 year',
                    value: 12,
                  },
                  {
                    label: '2 years',
                    value: 24,
                  },
                ]}
                name="passwordChangesPeriod"
                control={control}
                inputRef={register}
                style={{ maxWidth: '100%' }}
              />
            </Box>
          )}
        </Box>
        <Box width="50%">
          <CheckBoxInput
            name="twoFAMandatory"
            control={control}
            label="2FA Mandatory"
          />
          {twoFAMandatory && (
            <Box display="flex" flexDirection="row">
              <RadioInput
                row={true}
                control={control}
                input="twoFAMethod"
                label="OTP Method"
                options={[
                  { label: 'SMS', value: 'SMS' },
                  { label: 'Email', value: 'EMAIL' },
                ]}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Grid item xs={12} xl={6}>
        <SelectInput
          options={[
            {
              label: '3 (Default)',
              value: 3,
            },
            {
              label: '5',
              value: 5,
            },
            {
              label: '8',
              value: 8,
            },
            {
              label: '10',
              value: 10,
            },
          ]}
          name="failureNumber"
          control={control}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <SelectInput
          options={[
            {
              label: '5 min',
              value: 5,
            },
            {
              label: '15 min (Default)',
              value: 15,
            },
            {
              label: '30 min',
              value: 30,
            },
            {
              label: '1 hour',
              value: 60,
            },
            {
              label: '2 hours',
              value: 120,
            },
            {
              label: 'Permanent',
              value: 0,
            },
          ]}
          name="lockoutPeriod"
          control={control}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <SelectInput
          options={[
            {
              label: '30 min (Default)',
              value: 30,
            },
            {
              label: '45 min',
              value: 45,
            },
            {
              label: '1 hour',
              value: 60,
            },
            {
              label: 'No timeout',
              value: 0,
            },
          ]}
          name="inactivityTimeout"
          control={control}
        />
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        sx={{ marginTop: '2px' }}
      >
        <Grid item xs={2}>
          <Button
            text="updateRules"
            textColor="white"
            onClick={handleSubmit(beforeSubmit)}
            color="primary"
            loading={loading}
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
