import { RoleService, CreateRoleDto, UpdateRoleDto } from '@services/api'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'

export const findAllRoles = createAsyncThunk(
  Actions.ROLE_FIND_ALL,
  async (_, { rejectWithValue }) => {
    try {
      const roles: any = await RoleService.findAllRoles()
      return roles
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const findOneRole = createAsyncThunk(
  Actions.ROLE_FIND_ONE,
  async (id: number, { rejectWithValue }) => {
    try {
      return await RoleService.findOneRole({ id })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const updateRole = createAsyncThunk(
  Actions.ROLE_UPDATE,
  async (
    { id, body }: { id: number; body: UpdateRoleDto },
    { rejectWithValue },
  ) => {
    try {
      return await RoleService.updateRole({ id, body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const createRole = createAsyncThunk(
  Actions.ROLE_CREATE,
  async (body: CreateRoleDto, { rejectWithValue }) => {
    try {
      return await RoleService.createRole({ body })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const deleteRoles = createAsyncThunk(
  Actions.ROLE_REMOVE,
  async ({ ids }: { ids: number[] }, { rejectWithValue }) => {
    try {
      return await RoleService.deleteRole({ ids })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
