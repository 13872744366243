import { makeStyles } from '@mui/styles'
import { FormControl, Checkbox } from '@mui/material'
import { Box, ListItemText, MenuItem, Typography } from '@mui/material'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  box: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

type CheckBoxGroupInputProps = {
  options: { value: string | number; label: string }[]
  label?: string
  onValueChange: (a: string[]) => void
  values: string[]
  renderText?: (el: any) => React.ReactElement
}

export function CheckBoxGroupInput({
  options,
  label,
  onValueChange,
  values,
  renderText,
  ...props
}: CheckBoxGroupInputProps) {
  const classes = useStyles()

  const _handleClick = (value: string) => {
    if (values.includes(value)) {
      onValueChange(values.filter((_v) => _v !== value))
    } else {
      onValueChange([...values, value])
    }
  }

  return (
    <div className={classes.root} {...props}>
      <FormControl>
        <Typography className="title-check-group">{label}</Typography>
        <Box className={classes.box}>
          {options.map((opt, i) => {
            const indexOf = values.indexOf(opt.value as string)
            return (
              <MenuItem
                key={i}
                value={opt.value}
                onClick={() => _handleClick(opt.value as string)}
              >
                <Checkbox checked={indexOf > -1} />
                {renderText ? (
                  renderText(opt.value)
                ) : (
                  <ListItemText primary={opt.label} />
                )}
              </MenuItem>
            )
          })}
        </Box>
      </FormControl>
    </div>
  )
}
