import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ClientDto } from '@services/api'
import { findOne, findAll } from '@state/thunks/clientThunk'

type clientState = {
  clients: {
    totalCount: number
    datas: ClientDto[]
  }
  selected?: ClientDto
}

const initialState: clientState = {
  clients: {
    totalCount: 0,
    datas: [],
  },
}

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    removeClients: (state) => {
      state.clients = initialState.clients
    },
    setSelected: (state, action: PayloadAction<ClientDto>) => {
      state.selected = action.payload
    },
    removeSelected: (state) => {
      state.selected = initialState.selected
    },
  },
  extraReducers: (builder) => builder.addCase(findOne.fulfilled, (state, { payload }: PayloadAction<any>) => {
    state.selected = payload
  }).addCase(findAll.fulfilled, (state, { payload }: PayloadAction<any>) => {
    state.clients = payload
  })
})

export const { removeClients, setSelected, removeSelected } =
  clientSlice.actions

export default clientSlice.reducer
