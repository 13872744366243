import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale } from 'chart.js'
import { Doughnut, Bar } from 'react-chartjs-2'
import Container from '@mui/material/Container'
import { useSelector } from '@state/store'
import { fetchPatientEventAccount, fetchDeviceTypeBreakdown, BarStatsDataDto, BarValue} from '@services/statsSercives'
import { useEffect, useState } from 'react'
import { DugnutStatsData } from '@services/api'

function StatsTopStatsExamTypePie() {
  
  ChartJS.register(ArcElement, Tooltip, Legend)
  const colors = ['#9d03fc', '#18ab32', '#5733FF', '#5d63fc', '#33eee3', '#Ee555F']
  const data = useSelector((state) => state.stats.examBreakdown)

  return (
    <Container maxWidth="md">
      <Doughnut height={175}  options={{ maintainAspectRatio: false, }} data={
        {
          labels: data?.labels,
          datasets: [
            {
              label: 'Dataset 1',
              data: data?.total ,
              backgroundColor: colors,
              hoverOffset: 2,
              borderWidth: 2,
            },
          ],
        }
      }/>
    </Container>
  )
}

function StatsPatientEventAccount() {
  const [barData, setBarData] = useState<BarStatsDataDto>()

  useEffect(() => {
    fetchPatientEventAccount().then((data) => setBarData(data))
  }, [])

  const calculateAverage = (data: BarValue[]) => {
    const totalSum = data.reduce((sum, item) => sum + item.total, 0)
    return data.length > 0 ? totalSum / data.length : 0
  }

  const ageRanges = ['-18', '18-25', '26-35', '36-45', '46-55', '56-65', '65+']
  const trueAverages = ageRanges.map(
    (range) => calculateAverage(barData?.tabtrue.filter((item) => item.agerange === range) || [])
  )
  const falseAverages = ageRanges.map(
    (range) => calculateAverage(barData?.tabfalse.filter((item) => item.agerange === range) || [])
  )

  const data = {
    labels: ageRanges,
    datasets: [
      {
        label: 'With Account',
        data: trueAverages,
        backgroundColor: 'rgba(75,255,75,0.6)',
      },
      {
        label: 'Without Account',
        data: falseAverages,
        backgroundColor: 'rgba(255,75,132,0.6)',
      },
    ],
  }

  const options = {
    scales: {
      x: { beginAtZero: true },
      y: { beginAtZero: true },
    },
  }

  ChartJS.register(BarElement, Tooltip, Legend, CategoryScale)

  return <Bar data={data} options={options} />
}

function StatsDeviceTypeBreakdown(data) {
  
  const [pieValue, setPieValue] = useState<DugnutStatsData>()
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    legend: {
      display: false,
      position: 'right',
    },
  }

  ChartJS.register(ArcElement, Tooltip, Legend)
  const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40']  
  useEffect(() => {
    const fetchData = async () => {
      if (data === -1)
        setPieValue(await fetchDeviceTypeBreakdown())
      else 
        setPieValue(await fetchDeviceTypeBreakdown(data))
    }
    fetchData()
  }, [])

  return (
    <Container maxWidth="md">
      <Doughnut height={300} options={options} data={
        {
          labels: pieValue?.labels,
          datasets: [
            {
              label: 'Dataset 1',
              data: pieValue?.total,
              backgroundColor: colors,
              hoverOffset: 2,
              borderWidth: 2,
            },
          ],
        }
      }/>
    </Container>
  )
}

export { StatsTopStatsExamTypePie, StatsPatientEventAccount, StatsDeviceTypeBreakdown }